import React, { Component } from 'react';
import Grow from '@material-ui/core/Grow';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/core/styles';
import i18n from '../../config/lang/i18n';

const defaultSearchStyles = (theme) => ({
  main: {
    display: 'flex',
    flex: '1 0 auto',
    alignItems: 'center'
  },
  searchText: {
    flex: '0.8 0',
    margin: '0 1%'
  },
  clearIcon: {
    '&:hover': {
      color: 'black'
    }
  }
});

class CustomSearchRender extends Component {
	constructor() {
		super();

		this.searchInput = React.createRef();
	}
  handleTextChange = (event) => {
		this.props.onSearch(event.target.value);
  };

  componentDidMount() {
    this.searchInput.current.addEventListener('keydown', this.onKeyDown, false);
  }

  componentWillUnmount() {
    this.searchInput.current.removeEventListener('keydown', this.onKeyDown, false);
  }

  onKeyDown = (event) => {
    if (event.keyCode === 13) {
      this.props.searchAction(this.props.searchText);
    } else {
			this.handleTextChange(event)
		}
  };

  clearSearchText = (_) => {
		this.props.onSearch('');
		this.props.searchAction();
  };

  search = (_) => {
    this.props.searchAction(this.props.searchText);
  };

  render() {
    const {
      classes,
      options,
      searchText,
    } = this.props;

    return (
      <Grow appear in={true} timeout={300}>
        <div className={classes.main} ref={el => (this.rootRef = el)}>
          <SearchIcon />
          <TextField
						ref={this.searchInput}
            placeholder={'Search'}
            className={classes.searchText}
            InputProps={{
              'aria-label': options.textLabels.toolbar.search,
              endAdornment: (
                <IconButton
                  className={classes.clearIcon}
                  onClick={this.clearSearchText}>
                  <ClearIcon />
                </IconButton>
              )
            }}
            value={searchText || ''}
            onChange={this.handleTextChange}
            fullWidth={true}
            inputRef={el => (this.searchField = el)}
          />
          <Button size='small' className='btn-dark' onClick={this.search}>
            {i18n.t('common.search', '검색')}
          </Button>
        </div>
      </Grow>
    );
  }
}

export default withStyles(defaultSearchStyles, { name: 'CustomSearchRender' })(CustomSearchRender);
