import React from 'react';
import MaskedInput from 'react-text-mask';

function MaskedMobileNumber(props) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        mask={[/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
        showMask
      />
    );
}

export default MaskedMobileNumber