import React, {Component} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import i18n from "../../../config/lang/i18n";
import notification from "../../component/Notification";
import APIUtils from '../../../utils/APIUtils';
import {Button, Dialog, DialogContent, FormControl, Grid, MenuItem, Select, TextField} from '@material-ui/core';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import ConfirmDialog from "../../component/ConfirmDialog";
import utils from "../../../utils/Utils";

class ChangeRoleForm extends Component {
  constructor() {
    super();


    this.state = {
      pid: undefined,
      userId: undefined,
      userName: undefined,
      email: undefined,
      role: undefined,
      createFormState: false,
      clickState: false,
      isInviteForm: false,
    }

    this.handleChange = this.handleChange.bind(this);
    this.changeRole = this.changeRole.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
  }

  showChangeRoleForm(pid, userId, userName, email, role) {
    this.setState({pid: pid, userId: userId, userName: userName, email: email, role: role, createFormState: true, isInviteForm: false})
  }

  showChangeRoleFormOfInvite(pid, userId, userName, email, role) {
    this.setState({pid: pid, email: email, userId: userId, userName: userName, role: role, createFormState: true, isInviteForm: true})
  }

  changeRole() {
    if (!this.state.isInviteForm) {
      APIUtils.project.changeProjectMemberRole({
        pid: this.state.pid, userId: this.state.userId, role: this.state.role}, (data) => {
        if (data.status === 200) {
          notification.info(i18n.t('common.editSuccessful'));
          this.toggle();
          this.props.getMembers();
        } else {
          notification.error(data.message);
        }
      });
    } else {
      APIUtils.project.inviteUpdateProjectMember({
        pid: this.state.pid,
        params: {
          email: this.state.email,
          role: this.state.role
          }
        }, (data) => {
        if (data.status === 200) {
          notification.info(i18n.t('common.editSuccessful'));
          this.toggle();
          this.props.getMembers();
        } else {
          notification.error(data.message);
        }
      });
    }

  }

  toggle() {
    this.setState({createFormState: !this.state.createFormState});
  }

  handleChange(event) {
    const target = event.target;
    this.setState({[target.name]: target.value});

  }

  render() {
    return (
      <>
        <Dialog
          classes={{paper: 'modal-content dialog-maru-custom'}}
          fullWidth
          maxWidth="md"
          open={this.state.createFormState}
          onClose={this.toggle}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{i18n.t('memberList.editRole')}</DialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={12} hidden={this.state.userId===undefined}>
                <label className="font-weight-bold mb-2">{i18n.t('userList.userId')}</label>
                <TextField
                  className="input-maru-custom"
                  fullWidth
                  name="userId"
                  variant="standard"
                  size="small"
                  disabled={true}
                  value={this.state.userId}
                />
              </Grid>
              <Grid item xs={12} hidden={this.state.userName===undefined}>
                <label className="font-weight-bold mb-2">{i18n.t('userList.name')}</label>
                <TextField
                  className="input-maru-custom"
                  fullWidth
                  name="name"
                  variant="standard"
                  size="small"
                  disabled={true}
                  value={this.state.userName}
                />
              </Grid>
              <Grid item xs={12} hidden={this.state.email===undefined}>
                <label className="font-weight-bold mb-2">{i18n.t('userList.email')}</label>
                <TextField
                  className="input-maru-custom"
                  fullWidth
                  name="email"
                  variant="standard"
                  size="small"
                  disabled={true}
                  value={this.state.email}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <label className="font-weight-bold mb-2">
                    {i18n.t('project.role', {})}
                  </label>
                  <Select
                    name="role"
                    value={this.state.role}
                    onChange={this.handleChange}
                    displayEmpty>
                    <MenuItem value={utils.BILL}>{i18n.t("memberList.role.bill")}</MenuItem>
                    <MenuItem value={utils.MANAGER}>{i18n.t("memberList.role.manager")}</MenuItem>
                    <MenuItem value={utils.VIEWER}>{i18n.t("memberList.role.viewer")}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <div></div>
            <div>
              <Button className='m-3 button-maru-contained btn-gradient shadow-none'
                      hidden={!this.state.clickState}>
                <span className="btn-wrapper--icon">
                  <FontAwesomeIcon icon={['fas', 'cog',]} spin/>
                </span>
                ...
              </Button>
              <Button onClick={this.changeRole} className='m-3 button-maru-contained btn-gradient shadow-none'
                      hidden={this.state.clickState}>
                {i18n.t('common.save', {})}</Button>
              <Button onClick={() => this.setState({
                createFormState: false
              })} className='button-maru-gray-contained'>{i18n.t('common.cancel', {})}</Button>
            </div>
          </DialogActions>
        </Dialog>
        <ConfirmDialog ref={this.deleteConfirmDialog} />
      </>
    )
  }
}

export default ChangeRoleForm;
