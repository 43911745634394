import React, { Component } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import i18n from "../../../../config/lang/i18n";
import TitBreadcrumbs from "../../../component/TitBreadcrumbs";
import MUITable from "openmaru/component/MUITable";
import APIUtils from '../../../../utils/APIUtils';
import utils from "../../../../utils/Utils";
import { Button, Tooltip, Box } from '@material-ui/core';
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import koLocale from 'date-fns/locale/ko';
import { DateTime } from 'luxon';

import BillDetailList from "../../bill/BillDetailList";
import notification from "../../../component/Notification";
import ConfirmDialog from "../../../component/ConfirmDialog";

class BillManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      billingMonth: undefined,
      pid: undefined,
      data: [],
      page: 1,
      size: 15,
      totalCount: 0,
      searchText: '',
      sort: {},
      isLoading: false,
      searchMonth: new Date(),
    }

    this.detailPopup = React.createRef();
    this.confirmDialog = React.createRef();

    this.showBillDetail = this.showBillDetail.bind(this);
    this.getBillsList = this.getBillsList.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.createBill = this.createBill.bind(this);
    this.recreateBill = this.recreateBill.bind(this);
    this.payment = this.payment.bind(this);
    this.paymentCancel = this.paymentCancel.bind(this);
  }

  getBillsList() {
    let billingMonth = DateTime.fromJSDate(this.state.searchMonth).toFormat('yyyyMM')
    this.setState({ isLoading: true, billingMonth: billingMonth });

    APIUtils.superAdmin.getBillsList({
      billingMonth: billingMonth
    }, (data) => {
      if(data.status === 200) {
        this.setState({data: data.body.map((row) => {
          row.name = row.project.name;
          row.pid = row.project.pid;
          return row;
        })}); 
      } else {
        notification.error(data.message);
      }
      this.setState({ isLoading: false });
    })
  }

  handleDateChange(value, callback) {
    this.setState({
      searchMonth: value
    }, () => {
      if(callback) {
        callback();
      }
    })
  }

  showBillDetail(bill) {
    this.detailPopup.current.showBillDetail(bill.billId, bill.pid, bill.project.name);
  }

  createBill(bill) {
    APIUtils.superAdmin.createBill({
      pid: bill.project.pid,
      billingMonth: this.state.billingMonth
    }, (data) => {
      if (data.status === 200) {
        this.getBillsList();
        notification.info(i18n.t('common.requestSuccessful', '요청 정보를 처리 하였습니다.'))
      } else {
        notification.error(data.message);
      }
    });
  }

  recreateBill(bill) {
    APIUtils.superAdmin.recreateBill({
      pid: bill.pid,
      billingMonth: this.state.billingMonth
    }, (data) => {
      if (data.status === 200) {
        this.getBillsList();
        notification.info(i18n.t('common.requestSuccessful', '요청 정보를 처리 하였습니다.'))
      } else {
        notification.error(data.message);
      }
    });
  }

  payment(bill) {
    APIUtils.superAdmin.payment({
      pid: bill.pid,
      billId: bill.billId
    }, (data) => {
      if (data.status === 200) {
        notification.info(i18n.t('common.requestSuccessful', '요청 정보를 처리 하였습니다.'))
      } else {
        notification.error(data.message);
      }

      if (data.status !== 500) {
        this.getBillsList();
      }
    });
  }

  paymentCancel(bill) {
    APIUtils.superAdmin.paymentCancel({
      pid: bill.pid,
      billId: bill.billId
    }, (data) => {
      if (data.status === 200) {
        this.getBillsList();
        notification.info(i18n.t('common.requestSuccessful', '요청 정보를 처리 하였습니다.'))
      } else {
        notification.error(data.message);
      }

      if (data.status !== 500) {
        this.getBillsList();
      }
    });
  }

  render () {
    const columns = [
      { label: i18n.t('project.projectId', '프로젝트 아이디'), name: 'pid',
        options: {
          setCellProps: (_ => ({style: {minWidth: "7vw", maxWidth: "7vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center'}}>{value}</div>
          }
        }
      },
      { label: i18n.t('project.projectName', '프로젝트 이름'), name: 'name',
        options: {
          setCellProps: (_ => ({style: {minWidth: "7vw", maxWidth: "7vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center'}}>{utils.string.unescape(value)}</div>
          }
        }
      },
      { label: i18n.t('billManagement.billId', '청구서 아이디'), name: 'billId',
        options: {
          setCellProps: (_ => ({style: {minWidth: "7vw", maxWidth: "7vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center'}}>{value}</div>
          }
        }
      },
      { label: i18n.t('billManagement.billingDate', '청구일'), name: 'billingDate',
        options: {
          setCellProps: (_ => ({style: {minWidth: "7vw", maxWidth: "7vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center'}}>{utils.date.fromToString(value, 'yyyyMMdd', 'yyyy-MM-dd')}</div>
          }
        }
      },
      { label: i18n.t('billManagement.amount', '청구금액'), name: 'amount', 
        options: {
          setCellProps: (_ => ({style: {minWidth: "7vw", maxWidth: "7vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center'}}>{utils.string.numberToCommas(value)}</div>
          }
        }
      },
      { label: i18n.t('billManagement.paymentDueDate', '결제예정일'), name: 'paymentDate',
        options: {
          setCellProps: (_ => ({style: {minWidth: "7vw", maxWidth: "7vw"}})),
          customBodyRenderLite: (dataIndex) => {
            let rowData = this.state.data[dataIndex];
            let paymentDate = rowData?rowData.paymentDate:'';
            return <div style={{textAlign: 'center'}}>{utils.date.fromToString(paymentDate, 'yyyyMMdd', 'yyyy-MM-dd')}</div>
          }
        }
      },
      { label: `${i18n.t('common.status', '상태')}`, name: 'pay',
        options: {
          setCellProps: (_ => ({style: {minWidth: "7vw", maxWidth: "7vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            let color = 'dark';
            if (value && value.status === '1001') {
              color = 'success';
            } else if (value && value.status === '1002') {
              color = 'dark';
            } else if (value && value.status === '1003') {
              color = 'danger';
            } else if ( value && value.status === '1004') {
              color = 'warning';
            }
            return (<div className="d-flex justify-content-between">
              <Tooltip title={(value && value.resultMessage)?value.resultMessage:''} arrow placement="top-start" classes={{tooltip: "p-3"}}>
                <div className={'badge badge-' + color} style={{textAlign: 'center', margin: '0 auto'}}>{value?value.statusNm:'결제대기'}</div>
              </Tooltip>
            </div>)
          }
        }
      },
      { label: i18n.t('paymentsManagement.amount', '결제 금액'), name: 'pay',
        options: {
          setCellProps: (_ => ({style: {minWidth: "7vw", maxWidth: "7vw"}})),
          customBodyRenderLite: (dataIndex) => {
            let rowData = this.state.data[dataIndex];
            let status = (rowData&&rowData.pay)?rowData.pay.status:'';
            let amount = (rowData&&rowData.pay)?utils.string.numberToCommas(rowData.pay.amount):''
            return <div style={{textAlign: 'center'}} className={(status==='1002')?'textCancelLine':''}>{amount}</div>
          }
        }
      },
      { label: i18n.t('common.command'), name: "",
        options: {
          setCellProps: (_ => ({style: {minWidth: "9vw", maxWidth: "9vw"}})),
          customBodyRenderLite: (dataIndex) => {
            let rowData = this.state.data[dataIndex];
            let projectStatus = rowData.project.status;
            return (<div className="text-center">
              <Tooltip title={i18n.t('billManagement.billDetail')} arrow placement="top-start" classes={{tooltip: "p-3"}}>
                <span>
                  <Button
                    onClick={() => this.showBillDetail(rowData)}
                    className="btn-dark mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                    disabled={rowData.billId?false:true}
                  >
                    <FontAwesomeIcon icon={['fas', 'list-alt']} className="font-size-md"/>
                  </Button>
                </span>
              </Tooltip>
              <Tooltip title={i18n.t('paymentsManagement.issueBills')} arrow placement="top-start" classes={{tooltip: "p-3"}}>
                <span>
                  <Button
                      onClick={() =>
                        this.confirmDialog.current.open({
                          title: utils.string.unescape(rowData.project.name) + ' ' +  utils.date.getYearMonthStr(this.state.billingMonth, 'yyyyMM') + ' - ' + i18n.t('billManagement.createMessage'),
                          type: 'info'
                        }, () => {
                          this.createBill(rowData)
                        })
                      }
                      className="btn-dark mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                      disabled={utils.string.isNotBlank(rowData.billId)?true:false}
                  >
                    <FontAwesomeIcon icon={['fas', 'file-invoice-dollar']} className="font-size-md"/>
                  </Button>
                </span>
              </Tooltip>
              <Tooltip title={i18n.t('paymentsManagement.reissueBills')} arrow placement="top-start" classes={{tooltip: "p-3"}}>
                <span>
                  <Button
                    onClick={() =>
                        this.confirmDialog.current.open({
                          title: utils.string.unescape(rowData.project.name) + ' ' +  utils.date.getYearMonthStr(this.state.billingMonth, 'yyyyMM') + ' - ' + i18n.t('billManagement.recreateMessage'),
                          type: 'info'
                        }, () => {
                          this.recreateBill(rowData)
                        })
                      }
                      className="btn-dark mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                      disabled={(utils.string.isBlank(rowData.billId) || (rowData.pay && rowData.pay.status === '1001'))?true:false}
                  >
                    <FontAwesomeIcon icon={['fas', 'redo-alt']} className="font-size-md"/>
                  </Button>
                </span>
              </Tooltip>
              <Tooltip title={i18n.t('paymentsManagement.requestPayment')} arrow placement="top-start" classes={{tooltip: "p-3"}}>
                <span>
                  <Button
                    onClick={() =>
                        this.confirmDialog.current.open({
                          title: utils.string.unescape(rowData.project.name) + ' ' +  utils.date.getYearMonthStr(this.state.billingMonth, 'yyyyMM') + ' - ' + i18n.t('paymentsManagement.requestPaymentMessage'),
                          type: 'info'
                        }, () => {
                          this.payment(rowData)
                        })
                      }
                      className="btn-dark mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                      disabled={(projectStatus === '1003' || utils.string.isBlank(rowData.billId) || rowData.amount === 0 || (rowData.billId && (rowData.pay && (rowData.pay.status === '1001' || rowData.pay.status === '1004'))))?true:false}
                  >
                    <FontAwesomeIcon icon={['fas', 'money-check-alt']} className="font-size-md"/>
                  </Button>
                </span>
              </Tooltip>
              <Tooltip title={i18n.t('paymentsManagement.requestPaymentCancel')} arrow placement="top-start" classes={{tooltip: "p-3"}}>
                <span>
                  <Button
                    onClick={() =>
                      this.confirmDialog.current.open({
                        title: utils.string.unescape(rowData.project.name) + ' ' +  utils.date.getYearMonthStr(this.state.billingMonth, 'yyyyMM') + ' - ' + i18n.t('paymentsManagement.requestPaymentCancelMessage'),
                        type: 'info'
                      }, () => {
                        this.paymentCancel(rowData)
                      })
                    }
                    className="btn-dark mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                    disabled={(projectStatus !== '1003' && rowData.billId && (rowData.pay && (rowData.pay.status === '1001' || rowData.pay.status === '1004')))?false:true}
                  >
                    <FontAwesomeIcon icon={['fas', 'strikethrough']} className="font-size-md"/>
                  </Button>
                </span>
              </Tooltip>
            </div>)
          }
        }
      },
    ];

    const options = {
      onTableInit: (e) => {
        this.getBillsList();
      },
      draggableColumns: {
        enabled: true
      },
      serverSide: false,
      rowsPerPage: 15,
      filter: false,
      responsive: 'simple',
      selectableRows: 'none',
      search: true,
      searchOpen: true,
      customToolbar: () => {
        return (<>
          <Box className="d-inline-flex align-items-center justify-content-end mx-1" style={{width: '6.5rem'}}>
            <Button className="bg-white px-2 py-2" onClick={()=>{
              this.setState({
                searchMonth: DateTime.fromJSDate(this.state.searchMonth).minus({months: 1}).toJSDate()
              }, this.getBillsList)
            }}>
              <FontAwesomeIcon icon={['fas', 'angle-left']}  className="" />
            </Button>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={koLocale}>
              <DatePicker
                views={["year", "month"]}
                // minDate={new Date("2018-03-01")}
                maxDate={new Date()}
                value={this.state.searchMonth}
                onChange={this.handleDateChange}
                format="yyyy-MM"
                variant="inline"
                autoOk={true}
                onMonthChange={(value)=>{
                  this.handleDateChange(value, this.getBillsList)
                }}
              />
            </MuiPickersUtilsProvider>
            <Button disabled={(
              DateTime.fromJSDate(this.state.searchMonth).toFormat("yyyyLL") >= DateTime.fromJSDate(new Date()).toFormat("yyyyLL"))} className="bg-white px-2 py-2" onClick={()=>{
              this.setState({
                searchMonth: DateTime.fromJSDate(this.state.searchMonth).plus({months: 1}).toJSDate()
              }, this.getBillsList)
            }}>
              <FontAwesomeIcon icon={['fas', 'angle-right']}  className="" />
            </Button>
          </Box>
        </>)
      }
    };
      
    return (
      <>
        <TitBreadcrumbs title={i18n.t('paymentsManagement.title', '결제 관리')} />
        <div className="app-page-title">
          <div>
            <h1><FontAwesomeIcon icon={['fas', 'receipt']}
                                  className="icon"/> {i18n.t('paymentsManagement.title', '결제 관리')}</h1>
          </div>
        </div>
        <MUITable columns={columns} data={this.state.data} options={options} loading={this.state.isLoading}/>
        <BillDetailList ref={this.detailPopup} />
        <ConfirmDialog ref={this.confirmDialog}/>
      </>
    )
  }
}

export default BillManagement;
