import React, {Component} from 'react';
import { connect } from 'react-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import i18n from "../../../config/lang/i18n";
import notification from "../../component/Notification";
import APIUtils from '../../../utils/APIUtils';
import utils from "../../../utils/Utils";

import { Button, Dialog, DialogContent, DialogActions, Tooltip } from '@material-ui/core';
import DialogTitle from "@material-ui/core/DialogTitle";
import MUITable from "../../component/MUITable";
import { setUserInvites } from '../../../reducers/OpenmaruReducer';

class ProjectMemberInvite extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invites: [],
      popupState: props.isOpen,
      isLoading: false
    }

    this.getInvites = this.getInvites.bind(this);
    this.acceptInvite = this.acceptInvite.bind(this);
    this.rejectInvite = this.rejectInvite.bind(this);
  }

  getInvites() {
    this.setState({isLoading: true});
    APIUtils.project.getInvites({}, (data) => {
      if (data.status === 200) {
        if (data.body && data.body.length >= 0) {

          let invites = [];
          let totalCount = data.body.length;
          data.body.forEach((invite) => {
            invite.num = totalCount--;
            invites.push(invite);
          });

          this.props.setUserInvites(invites)
          this.setState({
            isLoading: false
          })
        }
      } else {
        notification.error(data.message)
      }
    });
  }

  acceptInvite(pid) {
    APIUtils.project.acceptInvite({pid: pid}, (data) => {
      if (data.status === 201) {
        notification.info(i18n.t('common.requestSuccessful'));
        this.getInvites();

        this.props.closeCallback();
      } else {
        notification.warn(data.message);
      }
    });
  }

  rejectInvite(pid) {
    APIUtils.project.rejectInvite({pid: pid}, (data) => {
      if (data.status === 200) {
        notification.info(i18n.t('common.requestSuccessful'));
        this.getInvites();

        this.props.closeCallback();
      } else {
        notification.warn(data.message);
      }
    });
  }

  render() {
    let isOpen = this.props.isOpen;
    let close = this.props.close;
    const inviteListData = this.props.userInvites;

    const columns = [
      {label: i18n.t('common.num', '번호'), name: 'num', 
        options: {
          setCellProps: (_ => ({style: {minWidth: "1vw", maxWidth: "1vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center'}}>{value}</div>
          }
        }
      },
      {label: i18n.t('project.inviteUser', '초대자'), name: 'fromUserName',
        options: {
          setCellProps: (_ => ({style: {minWidth: "4vw", maxWidth: "4vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center'}}>{value}</div>
          }
        }
      },
      {label: i18n.t('project.projectName', '프로젝트'), name: 'projectName',
        options: {
          setCellProps: (_ => ({style: {minWidth: "4vw", maxWidth: "4vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center'}}>{value}</div>
          }
        }
      },
      {label: i18n.t('project.role', '권한'), name: 'roleName',
        options: {
          setCellProps: (_ => ({style: {minWidth: "4vw", maxWidth: "4vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center'}}>{value}</div>
          }
        }
      },
      {label: i18n.t('project.inviteDate', '초대일'), name: 'created',
        options: {
          setCellProps: (_ => ({style: {minWidth: "6vw", maxWidth: "6vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center'}}>{utils.date.millisToString(value)}</div>
          }
        }
      },
      {label: '', name: 'pid', options: {display: false}},
      {label: '', name: 'updated', options: {display: false}},
      {
        label: i18n.t('common.accept', '수락') + '/' + i18n.t('common.reject', '거절'),
        name: "",
        options: {
          sort: false,
          filter: false,
          setCellProps: (_ => ({style: {minWidth: "4vw", maxWidth: "4vw"}})),
          customBodyRenderLite: (dataIndex, rowIndex) => {
            return (
              <div style={{textAlign: 'center'}}>
                  <Tooltip title={i18n.t('common.accept')} arrow placement="top-start"
                         classes={{tooltip: "p-3"}}>
                    <span>
                      <Button
                        disabled={this.state.isLoading}
                        className="btn-dark mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                        onClick={() => this.acceptInvite(inviteListData[rowIndex].pid)}>
                        <FontAwesomeIcon icon={['fas', 'user-check']} className="font-size-sm"/>
                      </Button>
                    </span>
                  </Tooltip>
                  <Tooltip title={i18n.t('common.reject')} arrow placement="top-start"
                           classes={{tooltip: "p-3"}}>
                    <span>
                      <Button
                        hidden={this.state.isLoading}
                        disabled={this.state.isLoading || (inviteListData[rowIndex]?inviteListData[rowIndex].status:'') === '1002'}
                        className="btn-dark mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                        onClick={() => this.rejectInvite(inviteListData[rowIndex].pid)}>
                        <FontAwesomeIcon icon={['fas', 'user-slash']} className="font-size-sm"/>
                      </Button>
                    </span>
                  </Tooltip>
              </div>
            );
          }
        }
      }
    ];

    const options = {
      onTableInit: (e) => {
      },
      refresh: () => {
        this.getInvites();
      },
      draggableColumns: {
        enabled: true
      },
      rowsPerPage: 5,
      filter: false,
      responsive: 'simple',
      selectableRows: 'none',
      search: false,
      print: false,
      download: false,
      viewColumns: false,
      setTableProps: () => {
        return {
          size: 'small'
        };
      },
      display: false
    };
    
    return (
      <>
        <Dialog
          classes={{paper: 'modal-content dialog-maru-custom'}}
          fullWidth
          maxWidth="md"
          open={isOpen}
          onClose={close}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {i18n.t('project.inviteList')}
          </DialogTitle>
          <DialogContent>
            <MUITable columns={columns} options={options} data={inviteListData} loading={this.state.isLoading}/>
          </DialogContent>
          <DialogActions>
            <div>
            </div>
            <div>
              <Button onClick={close} className='button-maru-gray-contained'>{i18n.t('common.close', {})}</Button>
            </div>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  userInvites: state.OpenmaruReducer.userInvites
})

const mapDispatchToProps = (dispatch) => ({
  setUserInvites: (invites) => dispatch(setUserInvites(invites))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectMemberInvite);
