import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import i18n from "../../../config/lang/i18n";
import ReactQuill from 'react-quill';
import {Button, Dialog, DialogContent, Grid, TextField} from '@material-ui/core';

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import ConfirmDialog from "../../component/ConfirmDialog";
import APIsLists from "../../../utils/APIUtils";
import notification from "../../component/Notification";
import utils from "../../../utils/Utils";

class QnaForm extends Component {
  constructor() {
    super();


    this.reactQuillRef = null; // ReactQuill component

    this.state = {
      seq: undefined,
      pid: undefined,
      subject: '',
      content: '',
      createFormState: false,
      clickState: false,
    }

    this.subjectInput = React.createRef();

    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleReactQuillChange = this.handleReactQuillChange.bind(this);
    this.createAndUpdateQna = this.createAndUpdateQna.bind(this);
  }

  componentDidMount() {
  }

  showCreateAndUpdateForm(board) {
    this.setState({pid: board.pid, seq: '', subject: '', content: '', createFormState: true})
    if (utils.string.isNotEmpty(board.seq)) {
      this.setState({
        seq: board.seq, 
        subject: utils.string.unescape(board.subject), 
        content: utils.string.unescape(board.content)
      })
    }
  }

  createAndUpdateQna() {

    if (this.state.subject === '') {
      notification.error(i18n.t('qna.subjectEmptyMessage', '제목을 입력하십시요.'), {});
      return;
    }

    if (this.state.content === '') {
      notification.error(i18n.t('qna.contentEmptyMessage', '내용을 입력하십시요.'), {});
      this.reactQuillRef.focus();
      return;
    }

    this.setState({clickState: true});
    if (utils.string.isEmpty(this.state.seq)) {
      APIsLists.board.write({
        pid: this.state.pid,
        body: {
          pid: this.state.pid,
          subject: this.state.subject,
          content: this.state.content
        }
      }, (data) => {
        if (data.status === 201) {
          this.props.closeCallback();
          this.setState({createFormState: false});
        } else {
          notification.error(data.message, {});
        }

        this.setState({clickState: false});
      });
    } else {
      APIsLists.board.edit({
        pid: this.state.pid,
        seq: this.state.seq,
        body: {
          pid: this.state.pid,
          subject: this.state.subject,
          content: this.state.content
        }
      }, (data) => {
        if (data.status === 200) {
          this.props.closeCallback();
          this.setState({createFormState: false});
        } else {
          notification.error(data.message, {});
        }

        this.setState({clickState: false});
      });
    }
  }

  toggle() {
    this.setState({createFormState: !this.state.createFormState});
  }

  handleChange(event) {
    const target = event.target;
    this.setState({[target.name]: target.value}, () => {

    });
  }

  handleReactQuillChange(content) {
    this.setState({content: content}, () => {

    });
  }

  render() {
    return (
      <>
        <Dialog
          classes={{paper: 'modal-content dialog-maru-custom'}}
          fullWidth
          maxWidth="lg"
          open={this.state.createFormState}
          onClose={this.toggle}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {i18n.t('qna.write')}
          </DialogTitle>
          <DialogContent style={{height: '600px'}}>
            <Grid container spacing={1}>
              <Grid item xs={12} hidden={this.state.seq?false:true}>
                <label className="font-weight-bold mb-2">
                  {i18n.t('qna.seq', '글번호')}
                </label>
                <TextField
                  className="input-maru-custom"
                  fullWidth
                  disabled={true}
                  name="seq"
                  variant="standard"
                  size="small"
                  value={this.state.seq}
                />
              </Grid>
              <Grid item xs={12}>
                <label className="font-weight-bold mb-2">
                  {i18n.t('qna.subject', '제목')}
                </label>
                <TextField
                  className="input-maru-custom"
                  autoFocus={true}
                  inputRef={this.subjectInput}
                  fullWidth
                  name="subject"
                  variant="standard"
                  size="small"
                  inputProps={{maxLength: 200}}
                  value={this.state.subject}
                  onChange={this.handleChange}
                  error={this.state.subject === '' ? true : false}
                />
              </Grid>
              <Grid item xs={12}>
                <label className="font-weight-bold mb-2">
                  {i18n.t('qna.content', '내용')}
                </label>
                <ReactQuill
                  // style={{height: '400px'}}
                  ref={(el) => {
                    this.reactQuillRef = el
                  }}
                  theme="snow"
                  value={this.state.content}
                  onChange={this.handleReactQuillChange}
                  error={this.state.content === '' ? true : false}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button className='m-3 btn-primary btn-gradient shadow-none bg-love-kiss' color="primary"
                    hidden={!this.state.clickState}>
              <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={['fas', 'cog',]} spin/>
              </span>
              ...
            </Button>
            <div></div>
            <div>
              <Button onClick={this.createAndUpdateQna} className='m-3 btn-primary btn-gradient shadow-none button-maru-contained'
                      hidden={this.state.clickState}>
                {utils.string.isEmpty(this.state.seq)?i18n.t('qna.write', '문의하기'):i18n.t('qna.edit', '문의수정')}</Button>
              <Button onClick={() => this.setState({
                createFormState: false
              })} className='button-maru-gray-contained'>{i18n.t('common.cancel', '취소')}</Button>
            </div>
          </DialogActions>
        </Dialog>
        <ConfirmDialog ref={this.deleteConfirmDialog}/>
      </>
    )
  }
}

export default QnaForm
