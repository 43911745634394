import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from '../../../config/lang/i18n';
import { Grid, Button, Card, CardContent } from '@material-ui/core';
import TitBreadcrumbs from '../../component/TitBreadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import APIsLists from '../../../utils/APIUtils';
import notification from '../../component/Notification';
import queryString from 'query-string';
import utils from '../../../utils/Utils';
import clsx from 'clsx';
import ReactQuill from 'react-quill';
import PerfectScrollbar from 'react-perfect-scrollbar';
import avatar from '../../../assets/images/avatars/default-avatar.png';
import openmaru_logo from '../../../assets/images/avatars/openmaru_logo.png';
import ConfirmDialog from '../../component/ConfirmDialog';

class QnaContent extends Component {
  constructor(props) {
    super(props);

    this.reactQuillRef = null; // ReactQuill component

    this.state = {
      currentPage: 1,
      pid: '',
      seq: '',
      comment: '',
      board: {},
      boardComments: [],
      userImg: ''
    };

    this.doneConfirmDialog = React.createRef();
    this.getBoard = this.getBoard.bind(this);
    this.handleReactQuillChange = this.handleReactQuillChange.bind(this);
    this.createComment = this.createComment.bind(this);
    this.goToList = this.goToList.bind(this);
    this.done = this.done.bind(this);
    this.reOpen = this.reOpen.bind(this);
  }

  componentDidUpdate() {}

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    this.setState({ pid: query.id, seq: query.seq }, () => {
      this.getBoard();
    });
    if (query.page && query.page > 0) {
      this.setState({ currentPage: query.page });
    }

    APIsLists.user.getUserImg({}, (data)=> {
      if (data.status === 200 && data.body) {
        this.setState({
          userImg: data.body.userImg
        })
      }
    })
  }

  getBoard() {
    APIsLists.board.getBoardContent(
      { pid: this.state.pid, seq: this.state.seq },
      (data) => {
        if (data.status === 200 && data.body) {
          const commentList = {board: data.body};

          if (data.body.boardComments && data.body.boardComments.length > 0) {
            data.body.boardComments.forEach((comment, index) => {
              commentList[`comment${index}`] = comment.comment;
              commentList[`commentUpdate${index}`] = comment.comment;
              commentList[`commentEditable${index}`] = false;
            })
            this.setState({ boardComments: data.body.boardComments });
          }
          this.setState(commentList);
        } else {
          notification.error(data.message);
          this.goToList();
        }
      }
    );
  }

  handleReactQuillChange(comment) {
    this.setState({ comment });
  }

  handleUpdateQuillChange(comment, index) {
    this.setState({[`commentUpdate${index}`]: comment})
  }

  createComment() {
    if (this.state.comment === '') {
      notification.error(i18n.t('qna.contentEmptyMessage', '내용을 입력하십시요.'), {});
      this.reactQuillRef.focus();
      return;
    }
    APIsLists.board.createBoardComment(
      {
        pid: this.state.pid,
        seq: this.state.seq,
        body: {
          comment: this.state.comment
        }
      },
      (data) => {
        if (data.status === 201) {
          this.setState({comment: ''});
          this.getBoard();
        } else {
          notification.error(data.message);
        }
      }
    );
  }

  done() {
    this.doneConfirmDialog.current.open(
      {
        title: i18n.t('qna.doneConfirmMsg', '해결 완료 처리 하시겠습니까?')
      },
      () => {
        APIsLists.board.done(
          { pid: this.state.pid, seq: this.state.seq },
          (data) => {
            if (data.status === 200 && data.success) {
              this.goToList();
            } else {
              notification.error(data.message);
            }
          }
        );
      }
    );
  }

  reOpen() {
    this.doneConfirmDialog.current.open(
      {
        title: i18n.t('qna.reOpenConfirmMsg', '재오픈 하시겠습니까?')
      },
      () => {
        APIsLists.board.reOpen(
          { pid: this.state.pid, seq: this.state.seq },
          (data) => {
            if (data.status === 200 && data.success) {
              this.goToList();
            } else {
              notification.error(data.message);
            }
          }
        );
      }
    );
  }

  goToList() {
    this.props.history.push(
      `/qnaList?id=${this.state.pid}&page=${this.state.currentPage}`
    );
  }

  updateComment(index, commentSeq) {
    this.doneConfirmDialog.current.toggle();
    APIsLists.board.updateBoardComment({
      commentSeq,
      pid: this.state.pid,
      body: {
        comment: this.state[`commentUpdate${index}`]
      }
    }, (data) => {
      if(data.status === 200 && data.success) {
        notification.info(i18n.t("qna.changedContent", "답변이 변경되었습니다."))
        this.setState({
          [`comment${index}`]: this.state[`commentUpdate${index}`],
          [`commentEditable${index}`]: !this.state[`commentEditable${index}`]
        })
      } else {
        notification.error(data.message);
      }
    })
  }

  cancelEditableComment(i) {
    this.doneConfirmDialog.current.toggle();
    this.setState({
      [`commentUpdate${i}`]: this.state[`comment${i}`],
      [`commentEditable${i}`]: !this.state[`commentEditable${i}`]
    })
  }

  editableComment(i) {
    this.setState({
      [`commentEditable${i}`]: !this.state[`commentEditable${i}`]
    })
  }

  removeComment(index, commentSeq) {
    this.doneConfirmDialog.current.toggle();
    APIsLists.board.removeBoardComment({
      commentSeq,
      pid: this.state.pid,
    }, (data) => {      
      if(data.status === 200 && data.success) {
        notification.info(i18n.t("qna.removedContent", "답변이 삭제되었습니다."))
        this.getBoard();
      }
    })
  }

  render() {
    return (
      <>
        <TitBreadcrumbs title={i18n.t('qna.titleDesc', '문의 상세')} />

        <div className="app-page-title">
          <div>
            <h1>
              <FontAwesomeIcon icon={['fas', 'comment-dots']} className="icon" />{' '}
              {i18n.t('qna.titleDesc', '문의 상세')}
            </h1>
          </div>
        </div>

        <Card className="card-box h-100 card-maru-custom">
          <CardContent className="p-4">
            <div className="d-flex flex-column flex-lg-row align-items-start justify-content-between">
              <div className="text-center text-lg-left mb-3 mb-lg-0 max-w-70">
                <div className="d-flex justify-content-between align-items-start">
                  <h1 className="display-4 font-weight-bold">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.board.subject
                      }}
                    />
                  </h1>
                  <div
                    className='px-2'
                    hidden={!this.state.board.doneed}>
                    <div className="badge badge-success">Completed</div>
                  </div>
                </div>
                <p className="mb-0 text-black-50">
                  {this.state.board.userName}
                </p>
              </div>
              <div className="text-center text-lg-left">
                <Button
                  hidden={this.state.board.doneed}
                  onClick={() => this.done()}
                  size="small"
                  variant="text"
                  className="button-maru-second-outlined m-1">
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={['fas', 'thumbs-up']} />
                  </span>
                  <span className="btn-wrapper--label">
                    {i18n.t('qna.resolved', '해결 완료')}
                  </span>
                </Button>
                <Button
                  hidden={!this.state.board.doneed}
                  onClick={() => this.reOpen()}
                  size="small"
                  variant="text"
                  className="button-maru-outlined m-1">
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={['fas', 'lock-open']} />
                  </span>
                  <span className="btn-wrapper--label">
                    {i18n.t('qna.reOpen', '종료')}
                  </span>
                </Button>
                <Button
                  onClick={() => this.goToList()}
                  size="small"
                  variant="text"
                  className="button-maru-gray-outlined m-1">
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={['fas', 'desktop']} />
                  </span>
                  <span className="btn-wrapper--label">
                    {i18n.t('common.list', '목록')}
                  </span>
                </Button>
              </div>
            </div>
            <div className="divider my-3" />
            <Grid container>
              <Grid item xs={12} md={4} className="list-item-maru-custom">
                <div className="mb-1">
                  <FontAwesomeIcon icon={['fas', 'square']} />
                  <span>{i18n.t('common.created', '생성일')}</span>
                </div>
                <div className='font-weight-bold'>{utils.date.millisToString(this.state.board.created)}</div>
              </Grid>
              <Grid item xs={12} md={4} className="list-item-maru-custom">
                <div className="mb-1">
                  <FontAwesomeIcon icon={['fas', 'square']} />
                  <span>{i18n.t('common.updated', '수정일')}</span>
                </div>
                <div className='font-weight-bold'>{utils.date.millisToString(this.state.board.updated)}</div>
              </Grid>
            </Grid>
            <Grid item className="list-item-maru-custom">
              <div className="mb-1">
                <FontAwesomeIcon icon={['fas', 'square']} />
                <span>{i18n.t('qna.content', '내용')}</span>
              </div>
              <div
                className="h-auto"
                dangerouslySetInnerHTML={{
                  __html: utils.string.unescape(
                    utils.string.toString(this.state.board.content)
                  )
                }}
              />
            </Grid>
            <Grid item className="list-item-maru-answer">
              <div className="mb-1">
                <FontAwesomeIcon icon={['fas', 'square']} />
                <span>{i18n.t('qna.answer', '답변')}</span>
              </div>
              <div className="bubble-area">
                <div 
                  hidden={
                    this.state.board.boardComments &&
                    this.state.board.boardComments.length > 0
                      ? false
                      : true
                  }
                  className="app-inner-content-layout--main order-3 order-lg-2">
                  <PerfectScrollbar>
                    <div className="chat-wrapper p-3">
                      {this.state.boardComments.map((comment, i) => {
                        return (
                          <div
                            className={
                              'chat-item p-2 mb-2 ' +
                              (utils.auth.getUserId() === comment.userId
                                ? ''
                                : 'chat-item-reverse')
                            }
                            key={i}>
                            <div
                              className={
                                'align-box-row ' +
                                (utils.auth.getUserId() !== comment.userId
                                  ? ''
                                  : 'flex-row-reverse')
                              }>
                              <div className="avatar-icon-wrapper avatar-icon-lg align-self-start">
                                <div className="avatar-icon rounded-circle shadow-none">
                                  <img
                                    alt="..."
                                    src={
                                      comment.isSuperAdmin
                                        ? openmaru_logo
                                        : utils.string.isBlank(comment.userImg)
                                        ? utils.string.isBlank(
                                            this.props.userSelectedProject.logoImg
                                          )
                                          ? avatar
                                          : this.props.userSelectedProject.logoImg
                                        : comment.userImg
                                    }
                                  />
                                </div>
                              </div>
                              <div className={utils.auth.getUserId() !== comment.userId ? 'ml-1' : 'mr-1'}>
                                <div className="chat-box text-second">
                                  <div hidden={this.state[`commentEditable${i}`]}
                                    dangerouslySetInnerHTML={{
                                      __html: utils.string.unescape(
                                        this.state[`comment${i}`]
                                      )
                                    }}
                                  />
                                  <div hidden={!this.state[`commentEditable${i}`]}>
                                    <ReactQuill
                                      className="d-inline-block align-bottom bg-white"
                                      theme="snow"
                                      value={utils.string.unescape(this.state[`commentUpdate${i}`])}
                                      onChange={(text) => {
                                        this.handleUpdateQuillChange(text, i);
                                      }}
                                      error={this.state[`commentUpdate${i}`] === '' ? true : false}
                                    />
                                    <Button className="m-1 px-2 py-2 align-baseline bg-white" onClick={() => {
                                      this.doneConfirmDialog.current.open({
                                        title: i18n.t("qna.confirmChangeContent", "답변 내용을 변경하시겠습니까?"),
                                        type: 'warn'
                                      }, () => {
                                        this.updateComment(i, comment.commentSeq);
                                      })
                                    }}>
                                      <FontAwesomeIcon icon={['fas', 'check']} className="text-first"/>
                                    </Button>
                                    <Button className="m-1 px-2 py-2 align-baseline bg-white" onClick={() => {
                                      this.doneConfirmDialog.current.open({
                                        title: i18n.t("qna.confirmRemoveChangesContent", "작성 중인 변경 내용이 취소됩니다."),
                                        type: 'warn'
                                      }, () => {
                                        this.cancelEditableComment(i);
                                      })
                                    }}>
                                      <FontAwesomeIcon icon={['fas', 'times']} className="text-dark"/>
                                    </Button>
                                  </div>

                                </div>
                                <small className="mt-2 d-block text-black-50">
                                  <FontAwesomeIcon
                                    icon={['far', 'clock']}
                                    className="mr-1 opacity-5"
                                  />
                                  {utils.date.millisToString(comment.created)}(
                                  {utils.date.millisToString(comment.updated)})
                                </small>
                              </div>
                              <Button hidden={!(utils.auth.getUserId() === comment.userId)} className='p-1' onClick={() => {
                                this.editableComment(i);
                              }}>
                                <FontAwesomeIcon icon={['far', 'edit']} className='text-dark opacity-5'/>
                              </Button>
                              <Button hidden={!(utils.auth.getUserId() === comment.userId)} className='p-1' onClick={() => {
                                this.doneConfirmDialog.current.open({
                                  title: i18n.t("qna.confirmRemoveContent", "답변을 삭제하시겠습니까?"),
                                  type: 'warn'
                                }, () => {
                                  this.removeComment(i, comment.commentSeq);
                                })
                              }}>
                                <FontAwesomeIcon icon={['far', 'trash-alt']} className='text-dark opacity-5'/>
                              </Button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </PerfectScrollbar>
                </div>
              </div>
              <div className="card-footer p-0 answer-text-area" hidden={this.state.board.doneed}>
                <div className={clsx(
                    'd-flex transition-base p-2',
                    { 'bg-secondary': '' }
                )}>
                  <ReactQuill
                    className="w-100"
                    ref={(el) => {
                      this.reactQuillRef = el;
                    }}
                    theme="snow"
                    value={utils.string.unescape(this.state.comment)}
                    onChange={this.handleReactQuillChange}
                    error={this.state.comment === '' ? true : false}
                  />
                  <div className="button-area">
                    <div className="avatar-icon-wrapper avatar-initials avatar-icon-lg">
                      <div className="avatar-icon bg-neutral-dark text-black">
                        {this.state.userImg ? <img alt="..." src={ this.state.userImg } /> : "W"}
                      </div>
                      <div
                        className="badge badge-success badge-position badge-position--bottom-center badge-circle"
                        title="Badge bottom center">
                        Online
                      </div>
                    </div>
                    <Button
                      onClick={() => this.createComment()}
                      // size="small"
                      variant="text"
                      className="button-maru-second-contained mx-0">
                      {/* <span className="btn-wrapper--icon">
                        <FontAwesomeIcon icon={['fas', 'user-edit']} />
                      </span> */}
                      <span className="btn-wrapper--label">
                        {i18n.t('common.write', '쓰기')}
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </Grid>
          </CardContent>
        </Card>
        <ConfirmDialog ref={this.doneConfirmDialog} />
      </>
    );
  }
}

QnaContent = connect(
  (state) => ({
    bodyState: state.OpenmaruReducer.bodyState,
    userSelectedProject: state.OpenmaruReducer.userSelectedProject
  }),
  (dispatch) => ({})
)(QnaContent);

export default QnaContent;
