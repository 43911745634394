import React from 'react';
import MUIDataTable from 'mui-datatables';
import { Tooltip, IconButton, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../../assets/muidatatables.scss';
import i18n from '../../config/lang/i18n';


function MUITable({ title, columns, data, options={}, className, loading=false, components={} }) {
	const basicOptions = {
		onTableInit: (e) => {},
		draggableColumns: {
			enabled: true
		},
		filter: false,
		responsive: 'simple',
		rowsPerPageOptions: [5, 10, 15, 20, 30, 50],
		pagination: true,
		serverSide: true,
		selectableRows: 'none',
		search: true,
		searchOpen: true,
    download: options.download?options.download:true,
		setTableProps: () => {
			return {
				size: 'small'
			};
		},
		display: false,
		textLabels: {
			body: {
				noMatch: i18n.t('common.gridListEmpty'),
				toolTip: i18n.t('common.sort'),
				columnHeaderTooltip: column => `Sort for ${column.label}`
			},
			pagination: {
				next: i18n.t('datatable.nextPage'),
				previous: i18n.t('datatable.previousPage'),
				rowsPerPage: i18n.t('datatable.perPage'),
				displayRows: i18n.t('datatable.displayRows'),
			},
			toolbar: {
				search: i18n.t('common.search'),
				downloadCsv: i18n.t('common.download'),
				print: i18n.t('datatable.print'),
				viewColumns: i18n.t('datatable.viewColumns'),
			},
			viewColumns: {
				title: i18n.t('datatable.showColumns'),
				titleAria: i18n.t('datatable.toggleColumns'),
			},
			selectedRows: {
				text: i18n.t('datatable.selectedRows'),
				delete: i18n.t('common.delete'),
				deleteAria: i18n.t('datatable.selectedRowsDelete'),
			},
		},
		onDownload: (buildHead, buildBody, columns, data) => {
			return "\uFEFF" + buildHead(columns) + buildBody(data); 
		},
	};
	const cloneOptions = Object.assign({}, options);

	if(cloneOptions.refresh === false) {
	 	delete basicOptions.customToolbar;
	} else {
		let refreshFunction = () => {};
		const originalToolbarElement =  cloneOptions.customToolbar? cloneOptions.customToolbar(): '';
		
		if(typeof (cloneOptions.refresh) === 'function')  {
			refreshFunction = cloneOptions.refresh;
		} else {
			refreshFunction = cloneOptions.onTableInit;
		}

		cloneOptions.customToolbar = () => {
			return (<>
				<Tooltip title={i18n.t('common.refresh', '새로고침')}>
					<IconButton className='custom-toolbar-icon' onClick={refreshFunction}>
						<FontAwesomeIcon icon={['fas', 'sync-alt']} className="font-size-lg"/>
					</IconButton>
				</Tooltip>
				{cloneOptions.customToolbar && originalToolbarElement}
			</>)
		}
	}

	const tableOptions = Object.assign({}, basicOptions, cloneOptions);

  return (
    <div className="position-relative h-100">
			{loading && (
				<div style={{
					position: 'absolute',
					zIndex: 20000,
					width: '100%',
					height: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					backgroundColor: 'rgba(255,255,255, 0.5)'
				}}>
					<div className='d-flex flex-column align-items-center justify-content-between h-180px'>
						<div className='datatable-loading-logo'/>
						<Typography>Loading...</Typography>
					</div>
				</div>
			)}
      <MUIDataTable
        title={title}
        data={data}
        columns={columns}
				options={tableOptions}
				className={className}
				components={components}
      />
    </div>
  );
}

export default MUITable;
