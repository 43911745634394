import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DashboardIcon from '@material-ui/icons/Dashboard';
import i18n from '../../../../config/lang/i18n';
import TitBreadcrumbs from '../../../component/TitBreadcrumbs';
import APIUtils from '../../../../utils/APIUtils';
import notification from "../../../component/Notification";

class InfoPage extends Component {
  constructor() {
    super();

    this.state = {
      gitLastHash: '',
      gitLastDate: '',
      gitLastHashUI: '',
      gitLastDateUI: '',
      apiUrl: process.env.REACT_APP_API_URL
    }

    this.k8sCtlCacheClear = this.k8sCtlCacheClear.bind(this);
  }

  componentDidMount() {
    APIUtils.superAdmin.getAppInfo({
      start: this.state.start,
      end: this.state.end
    }, (data) => {
      if (data.status === 200) {
        if (data.body.version) {
          this.setState({gitLastHash: data.body.version.gitLastHash, gitLastDate: data.body.version.gitLastDate, gitLastHashUI: data.body.version.gitLastHashUI, gitLastDateUI: data.body.version.gitLastDateUI});
        }
      } else {
        notification.error(data.message);
      }
    });
  }

  k8sCtlCacheClear(cacheName){
    APIUtils.superAdmin.clearCache({cacheName: cacheName}, (data, response) => {
      console.log("data",data)
    })
  }

  render() {
    return (
      <>
        <TitBreadcrumbs title={i18n.t('adminInfoPage.title', '관리자 정보')} />
        <div className="app-page-title">
          <div>
            <h1 className="d-flex align-items-center">
              <DashboardIcon fontSize="large" className="mr-1" />{' '}
              {i18n.t('adminInfoPage.title', '관리자 정보')}
            </h1>
          </div>
        </div>
        <div>
          <h4>
            <FontAwesomeIcon icon={['fas', 'info-circle']} className="icon"/> UI Version : {this.state.gitLastHashUI}, {this.state.gitLastDateUI}
          </h4>
          <h4>
            <FontAwesomeIcon icon={['fas', 'info-circle']} className="icon"/> API Version : {this.state.gitLastHash}, {this.state.gitLastDate}
          </h4>
          <hr/>
          <h4>Cache Clear</h4>
          <h5>
            <button onClick={() => this.k8sCtlCacheClear('k8sCtlCache')} className="MuiButtonBase-root MuiButton-root MuiButton-text btn-dark MuiButton-textSizeSmall MuiButton-sizeSmall">
              <FontAwesomeIcon icon={['fas', 'broom']} className="icon"/>  K8S_CTL_CACHE</button>
          </h5>
          <hr/>
          <h4>
            <a href='https://ap-northeast-2.console.aws.amazon.com/ec2`/v2/home?region=ap-northeast-2#Home:' target='_blank' rel="noopener noreferrer">
              <FontAwesomeIcon icon={['fas', 'external-link-alt']} className="icon"/>  Amazon EC2 Console</a>
          </h4>
          <h4>
            <a href='https://console-openshift-console.apps.apm.a-apne2.openmaru.io/' target='_blank' rel="noopener noreferrer">
              <FontAwesomeIcon icon={['fas', 'external-link-alt']} className="icon"/>  OKD(OpenShift) Console</a>
          </h4>
          <h4>
            <a href='http://openmaru-cloud-apm.apps.apm.a-apne2.openmaru.io/' target='_blank' rel="noopener noreferrer">
              <FontAwesomeIcon icon={['fas', 'external-link-alt']} className="icon"/>  OPENMARU APM Infra Monitoring Console</a>
          </h4>
          <h4>
            <a href='https://grafana-openshift-monitoring.apps.apm.a-apne2.openmaru.io/' target='_blank' rel="noopener noreferrer">
              <FontAwesomeIcon icon={['fas', 'external-link-alt']} className="icon"/>  Grafana Monitoring Console</a>
          </h4>
          <h4>
            <a href='https://kibana-openshift-logging.apps.apm.a-apne2.openmaru.io' target='_blank' rel="noopener noreferrer">
              <FontAwesomeIcon icon={['fas', 'external-link-alt']} className="icon"/>  EFK Logging Kibana Console</a>
          </h4>
          <h4>
            <a href='http://jenkins.dev.opennaru.com/jenkins/' target='_blank' rel="noopener noreferrer">
              <FontAwesomeIcon icon={['fas', 'external-link-alt']} className="icon"/>  Jenkins Console</a>
          </h4>
          <h4>
            <a href='https://www.openmaru.io/wp-admin/' target='_blank' rel="noopener noreferrer">
              <FontAwesomeIcon icon={['fas', 'external-link-alt']} className="icon"/>  Wordpress Admin</a>
          </h4>
          <h4>
            <a href='https://manager.payletter.com/Login' target='_blank' rel="noopener noreferrer">
              <FontAwesomeIcon icon={['fas', 'external-link-alt']} className="icon"/>  PayLetter Admin</a>
          </h4>

        </div>
      </>
    );
  }
}

export default InfoPage;
