import React, {Component} from 'react';
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import i18n from "../../config/lang/i18n";
import utils from "../../utils/Utils";
import {Breadcrumbs} from "@material-ui/core";
import {NavLink, withRouter} from 'react-router-dom';

class TitBreadcrumbs extends Component {
  constructor() {
    super();

    this.state = {
      pid: undefined,
      userSelectedProject: {name: '', subDomain: ''}
    }
  }
  componentDidUpdate() {
    if (this.props.userSelectedProject) {
      if (Object.entries(this.state.userSelectedProject).toString() !== Object.entries(this.props.userSelectedProject).toString()) {
        this.setState({userSelectedProject: this.props.userSelectedProject})
      }
    }
  }

  componentDidMount() {
    if (this.props.userSelectedProject) {
      this.setState({userSelectedProject: this.props.userSelectedProject})
    }
  }

  render() {
    return (
      <>
        <div hidden={!utils.auth.hasSuperAdmin()}>
          <Breadcrumbs aria-label="breadcrumb">
            <NavLink to="/admin/projectList">
              <FontAwesomeIcon icon={['fas', 'home']} className="icon"/> {i18n.t('breadcrumbs.home', '홈')}
            </NavLink>
            <span>
              SuperAdmin / {this.props.title}
            </span>
          </Breadcrumbs>
        </div>
        <div hidden={utils.auth.hasSuperAdmin()}>
          <Breadcrumbs aria-label="breadcrumb">
            <NavLink to="/projectsDashboard">
              <FontAwesomeIcon icon={['fas', 'home']} className="icon"/> {i18n.t('breadcrumbs.home', '홈')}
            </NavLink>
            <span>
              <span hidden={utils.string.isBlank(this.props.userSelectedProject.name) ? true : false}>
                {utils.string.toString(utils.string.unescape(this.props.userSelectedProject.name))}({utils.string.toString(this.props.userSelectedProject.subDomain)})
              </span>
               &nbsp;/ {this.props.title}
            </span>
          </Breadcrumbs>
        </div>
      </>
    );
  }
}

TitBreadcrumbs = connect(
  (state) => ({
    userSelectedProject: state.OpenmaruReducer.userSelectedProject,
  }),
  (dispatch) => ({
  })
)(TitBreadcrumbs);
export default withRouter(TitBreadcrumbs);
