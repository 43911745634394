import React, {Component} from 'react';
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import i18n from "../../../config/lang/i18n";
import MUITable from "../../component/MUITable";
import ConfirmDialog from "../../component/ConfirmDialog";
import utils from "../../../utils/Utils";
import APIsLists from "../../../utils/APIUtils";
import notification from "../../component/Notification";
import {Button, Tooltip} from "@material-ui/core";
import queryString from 'query-string';
import {withRouter} from 'react-router-dom';
import {setUserSelectedProject, setShowBodyLoading} from "../../../reducers/OpenmaruReducer";
import QnaForm from "./QnaForm";
import TitBreadcrumbs from "../../component/TitBreadcrumbs";

class QnaList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      pid: undefined,
      boards: [],
      createFormState: false,
      contentViewState: false,
      isLoading: false
    }

    this.formPopup = React.createRef();
    this.deleteMemberConfirmDialog = React.createRef();
    this.getBoards = this.getBoards.bind(this);
  }

  componentDidUpdate() {
    const query = queryString.parse(this.props.location.search);
    if (query.id !== this.state.pid) {
      this.setState({pid: query.id}, () => {
        this.getBoards();
      });
    }
  }

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    this.setState({pid: query.id, currentPage: (query.page?(query.page):1)}, () => {
      this.getBoards();
    });

    // const query = queryString.parse(this.props.location.search);
    // (query.page?(query.page - 1):0);
  }

  getBoards() {
    this.setState({isLoading: true});

    if (utils.string.isBlank(this.state.pid)) {
      notification.error(i18n.t('project.pidEmptyMsg', '프로젝트 아이디가 없습니다.'));
      return;
    }

    APIsLists.board.getBoards({pid: this.state.pid}, (data) => {
      if (data.status === 200) {
        let boards = [];
        let totalCount = data.body.length;
        data.body.forEach((board, i) => {
          board.num = totalCount--;
          boards.push(board);
        });
        this.setState({
          boards: boards,
          isLoading: false
        })
      } else {
        notification.error(data.message);
      }
    });
  }

  showCreateAndUpdateForm(board) {
    if (board === undefined) {
      this.formPopup.current.showCreateAndUpdateForm({pid: this.state.pid});
    } else {
      this.formPopup.current.showCreateAndUpdateForm(board);
    }
  }

  render() {

    const columns = [
      {
        label: i18n.t('common.num', '번호'), name: 'num', options: {
          setCellProps: (_ => ({style: {minWidth: "2vw", maxWidth: "2vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center', cursor: 'pointer'}}>{value}</div>
          }
        }
      },
      {
        label: i18n.t('qna.subject', '제목'), name: 'subject', options: {
          setCellProps: (_ => ({style: {minWidth: "14vw", maxWidth: "14vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div className="text-overflow-ellipsis" style={{textAlign: 'left', cursor: 'pointer'}} dangerouslySetInnerHTML={{__html: value}}/>
          }
        }
      },
      {
        label: i18n.t('userList.name', '이름'), name: 'userName', options: {
          setCellProps: (_ => ({style: {minWidth: "4vw", maxWidth: "4vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center', cursor: 'pointer'}}>{value}</div>
          }
        }
      },
      {
        label: i18n.t('common.status', '상태'), name: 'doneed', options: {
          setCellProps: (_ => ({style: {minWidth: "4vw", maxWidth: "4vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            let isLastCommentSuperAdmin = this.state.boards[tableMeta.rowIndex].isLastCommentSuperAdmin;
            let ing = '';
            let color = 'dark';
            if (isLastCommentSuperAdmin && isLastCommentSuperAdmin === true) {
              ing = i18n.t('qna.checkingCustomer', '답변됨(고객)');
            } else if (isLastCommentSuperAdmin && isLastCommentSuperAdmin === false) {
              ing = i18n.t('qna.reComment', '추가 질의(확인 중 - 관리자)');
              color = 'danger';
            } else {
              ing = i18n.t('qna.checkingOpennaru', '확인 중 - 관리자');
              color = 'danger';
            }
            if (value&&value === true) {
              color = 'success';
            }
            return (<div className="d-flex justify-content-between" style={{cursor: 'pointer'}}>
                <div className={'badge badge-' + color} style={{textAlign: 'center', margin: '0 auto'}}>{(value&&value===true)?i18n.t('qna.resolved', '해결 완료'):ing}</div>
            </div>)
          }
        }
      },
      {
        label: i18n.t('common.created', '생성일'),name: 'created', options: {
          setCellProps: (_ => ({style: {minWidth: "4vw", maxWidth: "4vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center', cursor: 'pointer'}}>{utils.date.millisToString(value)}</div>
          }
        }
      },
      {
        label: i18n.t('common.command'),
        name: "",
        options: {
          sort: false,
          filter: false,
          setCellProps: (_ => ({style: {minWidth: "4vw", maxWidth: "4vw"}})),
          customBodyRenderLite: (dataIndex, rowIndex) => {
            return (
              <div style={{textAlign: 'center'}}>
                <Tooltip title={i18n.t('common.edit')} arrow placement="top-start"
                         classes={{tooltip: "p-3"}}>
                  <Button
                    className="btn-dark mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                    onClick={(event) => {this.showCreateAndUpdateForm(this.state.boards[dataIndex]); event.stopPropagation();}}>
                    <FontAwesomeIcon icon={['far', 'edit']} className="font-size-sm"/>
                  </Button>
                </Tooltip>
                <Tooltip title={i18n.t('common.delete')} arrow placement="top-start" classes={{tooltip: "p-3"}}>
                  <Button
                    className="btn-dark mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                    onClick={(event) => {
                      this.deleteMemberConfirmDialog.current.open({
                        title: this.state.boards[dataIndex].seq + ' - ' + i18n.t('common.confirmDeleteMessage', '삭제 하시겠습니까?'),
                        type: 'delete'
                      }, () => {
                        APIsLists.board.delete({
                          pid: this.state.boards[dataIndex].pid,
                          seq: this.state.boards[dataIndex].seq
                        }, (data) => {
                          if (data.status === 200) {
                            notification.info(i18n.t('common.deleteSuccessful', '삭제되었습니다.'));
                            this.getBoards();
                          }
                        });
                      });
                      event.stopPropagation();
                    }}>
                    <FontAwesomeIcon icon={['fas', 'ban']} className="font-size-sm"/>
                  </Button>
                </Tooltip>
              </div>
            );
          }
        }
      }
    ];

    const options = {
      page: this.state.currentPage - 1,
      onTableInit: (e) => {
      },
      refresh: () => {
        this.getBoards();
      },
      draggableColumns: {
        enabled: true
      },
      serverSide: false,
      rowsPerPage: 15,
      filter: false,
      responsive: 'simple',
      selectableRows: 'none',
      search: true,
      searchOpen: true,
      setTableProps: () => {
        return {
          size: 'small'
        };
      },
      display: false,
      onRowClick: (dataIndex, rowIndex, dd) => {
        let board = this.state.boards[rowIndex.dataIndex];
        this.props.history.push(`/qnaList/qnaContent?id=${board.pid}&seq=${board.seq}&page=${this.state.currentPage}`);
      },
      onChangePage: (currentPage) => {
        let page = currentPage + 1;
        this.setState({currentPage: page})
        this.props.history.replace(`/qnaList?id=${this.state.pid}&page=${page}`);
      }
    };


    return (
      <>
        <TitBreadcrumbs title={i18n.t('qna.title', '문의하기')} />

        <div className="app-page-title">
          <div>
            <h1><FontAwesomeIcon icon={['fas', 'question-circle']} className="icon"/> {i18n.t('qna.title', '1:1 문의')}</h1>
          </div>
          <div className="d-flex align-items-center">
            <Button variant="contained" className="btn-danger m-2"
                    onClick={() => this.showCreateAndUpdateForm()}>
              <span className="btn-wrapper--icon">
                  <FontAwesomeIcon icon={['fas', 'user-edit']}/>
              </span>
              <span className="btn-wrapper--label">{i18n.t('qna.write', '문의하기')}</span>
            </Button>
          </div>
        </div>
        <div className="h-100" hidden={this.state.contentViewState}>
          <MUITable columns={columns} options={options} data={this.state.boards} loading={this.state.isLoading}/>
        </div>
        <ConfirmDialog ref={this.excludeMemberConfirmDialog}/>
        <QnaForm ref={this.formPopup} closeCallback={this.getBoards} />
        <ConfirmDialog ref={this.deleteMemberConfirmDialog}/>
      </>
    );
  }
}

QnaList = connect(
  (state) => ({
    userProjects: state.OpenmaruReducer.userProjects,
    userSelectedProject: state.OpenmaruReducer.userSelectedProject,
  }),
  (dispatch) => ({
    setShowBodyLoading: (enable) => dispatch(setShowBodyLoading(enable)),
    setUserSelectedProject: (selectedProject) => dispatch(setUserSelectedProject(selectedProject))
  })
)(QnaList);
export default withRouter(QnaList);
