import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router'
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, Menu, Button, MenuItem} from '@material-ui/core';
import { makeStyles  } from '@material-ui/core/styles';
import ConfirmDialog from '../component/ConfirmDialog';
import SelectLanguage from '../component/SelectLanguage';
import APIUtils from '../../utils/APIUtils';
import utils from "../../utils/Utils";
import i18n from 'config/lang/i18n';
import { setUserProjects } from '../../reducers/OpenmaruReducer';

const selectStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#2F2F2F',
    width: 160
  },
  listStyle: {
    color: 'white',
    fontWeight: 'normal',
    'li': {
      display: 'flex',
      justifyContent: 'space-between',
      alignContents: 'center',
      
    },
    listIcon: {
      marginRight: 10
    }
  }
}));

const HeaderUserbox = (props) => {
  const classes = selectStyles();

  const confirmDialog = useRef(null);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);

  const [userId] = useState(utils.auth.getUserId('userId'));
  const { setUserProjects } = props;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        variant='text'
        onClick={handleClick}
        className='mr-4 btn-transition-none text-left ml-2 p-0 bg-transparent d-flex align-items-center'
        >
        <div className='d-none d-xl-block'>
          <div className='font-size-lg' id="header-user-id">{userId}</div>
        </div>
        <span className='pl-1 pl-xl-3'>
          <FontAwesomeIcon icon={['fas', 'angle-down']} className='opacity-5' />
        </span>
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        classes={{ paper: classes.root, list: classes.listStyle }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem className='align-items-center' onClick={() => { 
          history.push('/account');
          handleClose()
        }}>
          <FontAwesomeIcon icon={['fas', 'user-circle']} size='1x' className='mr-2'/>
          <Typography variant='inherit'>{i18n.t("common.profile")}</Typography>
        </MenuItem>
        <MenuItem className='align-items-center'>
          <FontAwesomeIcon icon={['fas', 'globe']} size='1x' className='mr-2'/>
          <SelectLanguage/>
        </MenuItem>
        <MenuItem 
          className='btn-transition-none align-items-center'
          onClick={() => {
            confirmDialog.current.open({
                title: i18n.t('common.logoutQuestion', `{{userId}} 로그아웃 하시겠습니까?`, {userId: userId}),
                type: 'warn'
              }, _ => {
                APIUtils.logout((data) => {
                  setUserProjects([]);
                  history.push('/login');
                });
              })
          }}
        >
          <FontAwesomeIcon icon={['fas', 'sign-out-alt']} size='1x' className='mr-2'/>
          <Typography variant='inherit'>{i18n.t("common.logout")}</Typography>
        </MenuItem>
        <ConfirmDialog ref={confirmDialog}/>
      </Menu>
    </>
  );
};

const mapStateToProps = (state) => ({
  userProjects: state.OpenmaruReducer.userProjects
});

const mapDispatchToProps = (dispatch) => ({
  setUserProjects: (projects) => dispatch(setUserProjects(projects))
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderUserbox);
