import React, { Component } from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Collapse, Grid, InputAdornment,
        FormControl, Tab, Tabs, MenuItem, Select} from "@material-ui/core";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import i18n from "../../config/lang/i18n";
import {CodeBlock} from "react-code-blocks";
import TextCopyButton from "./TextCopyButton";
import APIUtils from '../../utils/APIUtils';
import utils from "../../utils/Utils";
import { Link } from 'react-router-dom';
import CTextField from "openmaru/component/CTextField";
import notification from "openmaru/component/Notification";
import Dropzone from 'react-dropzone';
import CheckIcon from '@material-ui/icons/Check';
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';
import CloudUploadTwoToneIcon from '@material-ui/icons/CloudUploadTwoTone';

export const fileToBase64 = (file) => {
  return new Promise(resolve => {
    var reader = new FileReader();
    reader.onload = function (event) {
      resolve(event.target.result);
    };
    reader.readAsDataURL(file);
  });
};


function Panel({index, value, children}) {
  return (<div hidden={(index !== value)}>{children}</div>);
}


class ProjectTutorial extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      value: 0,
      agentType: 0,
      orderWasTab: [false, false, false, false],
      orderSysTab: [false, false, false, false],
      userInfo: {},
      projectInfo: {},
      pid: '',
      subDomain: '',
      invalidSubDomain: false,
      name: '',
      description: '',
      company: '',
      team: '',
      service: utils.getDefaultServiceProvider(),
      timeZone: 'Asia/Seoul',
      projectRole: '',
      logoImg: undefined,
      returnUrl: '',
      healthCheck: '',
      connectableExternalBrowser: false
    }

    this.nameInput = React.createRef();
    this.subDomainInput = React.createRef();
    this.subscriptionRegisterWindowRef = React.createRef();

    this.showPopup = this.showPopup.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleOpenState = this.handleOpenState.bind(this);
    this.setAgentType = this.setAgentType.bind(this);
    this.orderTabToggle = this.orderTabToggle.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.createProject = this.createProject.bind(this);
    this.checkApmHealth = this.checkApmHealth .bind(this);
    this.go2APM = this.go2APM .bind(this);
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    clearInterval(this.state.healthCheck);
  }

  showPopup() {
    APIUtils.user.getUser({}, (data) => {
      if(data.status === 200) {
        let userInfo = data.body;
        let company = userInfo.company ? userInfo.company : '';
        let team = userInfo.team ? userInfo.team : '';

        this.setState({
          userInfo: userInfo,
          company: company,
          team: team,
          show: true
        });
      }
    })
  }

  toggle() {
    this.setState({show: !this.state.show});
  }

  handleOpenState(newValue) {
    this.setState({value: newValue}, () => {
      this.setState({healthCheck: setInterval(this.checkApmHealth, (5*1000))});
      this.checkApmHealth();
    });
  }

  handleChange(event) {
    const target = event.target;
    this.setState({[target.name]: (event.target.tagName === 'INPUT' && event.target.type === 'text')?utils.string.unescape(target.value):target.value}, () => {
      if (target.name === "subDomain") {
        if (utils.string.checkSubDomain(this.state.subDomain)) {
          this.setState({invalidSubDomain: false})
        } else {
          this.setState({invalidSubDomain: true})
        }
      }
    });
  }

  setAgentType(event, type) {
    this.setState({agentType: type});
  }

  orderTabToggle(tab, type) {
    const prevState = this.state[type];

    this.setState({
      [type]: prevState.map((value, index) => (tab === index? !value : false))
    })
  }

  go2APM() {
    utils.goToApm(this.state.apmServerDomain);
  }

  closePopup() {
    this.setState({
      show: false,
      value: 0,
      agentType: 0,
      orderWasTab: [false, false, false, false],
      orderSysTab: [false, false, false, false],
      userInfo: {},
      projectInfo: {},
      pid: '',
      subDomain: '',
      invalidSubDomain: false,
      name: '',
      description: '',
      company: '',
      team: '',
      service: utils.getDefaultServiceProvider(),
      timeZone: 'Asia/Seoul',
      projectRole: '',
      logoImg: undefined,
      returnUrl: '',
      healthCheck: '',
      connectableExternalBrowser: false
    })
    clearInterval(this.state.healthCheck);
    this.props.closeCallback(this.state.project);
  }

  checkApmHealth() {
    let pid = utils.string.isNotBlank(this.state.pid)? this.state.pid: '';

    if (this.state.apmStatus === '1003') {
      APIUtils.checkApmHealth({pids: this.state.pid, isExternalBrowserApi: true}, (data)=> {
        if(data.status === 200) {
          data.body.forEach(createdProject => {
            this.setState({connectableExternalBrowser: createdProject.subDomain})
          })
        }
      });
    } else {
      APIUtils.project.getProjectInfo({pid: pid}, (data) => {
        if (data.status === 200) {
          const newState = Object.assign(this.state, data.body, {projectInfo: data.body});

          this.setState(newState);
        }
      })
    }
  }

  createProject() {
    let serviceProvider = '';
    let serviceRegion = '';
    if (this.state.service !== '') {
      if (this.state.service.indexOf('-') > -1) {
        let serviceArr = this.state.service.split('-');
        serviceProvider = serviceArr[0];
        serviceRegion = serviceArr[1];
      }
    }

    if (utils.string.isBlank(this.state.name) || utils.string.byteLen(this.state.name) > 100) {
      notification.warn(i18n.t('project.nameErrorMessage'), {});
      this.nameInput.current.focus();
      return;
    }

    if (!utils.string.checkSubDomain(this.state.subDomain)) {
      notification.warn(i18n.t('project.subDomainErrorMessage'), {});
      this.subDomainInput.current.focus();
      return;
    }

    APIUtils.project.createProjects({
      body: {
        subDomain: this.state.subDomain,
        name: this.state.name,
        description: this.state.description,
        company: this.state.company,
        team: this.state.team,
        serviceProvider: serviceProvider,
        serviceRegion: serviceRegion,
        timeZone: this.state.timeZone,
        logoImg: this.state.logoImg,
      }
    }, (data) => {
      if (data.status === 201) {
        const newState = Object.assign(this.state, data.body);

        this.setState(newState, _ => {
          this.handleOpenState(1);
        })
        notification.info(i18n.t('common.saveSuccessful'));
      } else {
        notification.error(data.message, {});
      }
    })
  }

  onDrop(acceptedFiles) {
    acceptedFiles.forEach(file => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (event) {}; // data url!
      fileToBase64(file).then(result => {
        this.setState({logoImg: result});
      });
    });
  }

  render() {
    const copyBlockText = {
      firewall: '' +
      '## IP Address\n' +
      '13.125.51.109\n' +
      '13.209.130.53\n\n' +
      '## PORT\n' +
      '443',
      wasAgentFilePath: window.location.origin + '/api/v1/projects/' + this.state.pid + '/download/was-agent.zip',
      openmaruServerHost: this.state.openmaruHost,
      openmaruServerHostDesc: '## khan-agent.conf\n\n' +
        'khan.host=' + this.state.openmaruHost,
      wasTomcat: 'export AGENT_OPTS=" -javaagent:/[업로드 경로]/khan-agent/khan-agent[버전명].jar "\n' +
        'export AGENT_OPTS="$AGENT_OPTS -Dkhan.config.file=khan-agent.conf "\n' +
        'export JAVA_OPTS="$AGENT_OPTS $JAVA_OPTS -noverify "\n',
      wasSpringBoot: 'java -jar app.jar -javaagent:/svc/test/khan-agent/khan-agent-5.1.0.jar -Dkhan.config.file=khan-agent.conf -noverify "\n',
      sysAgentFilePath: window.location.origin + '/api/v1/projects/' + this.state.pid + '/download/sys-agent.zip',
      sysAgentConfPath: 'vi khan-sys-agent/conf/khan-sys-agent.conf',
      sysAgentConf: 'khan.host=' + this.state.openmaruHost + '\n' +
        'khan.port=\n',
      sysPluginsDisabledPath: 'vi khan-sys-agent/plugins/disabled.txt',
      sysPluginsDisabledConf: 'khan-plugin-apache \n' +
        'khan-plugin-nginx \n' +
        'khan-plugin-cubrid \n' +
        'khan-plugin-docker \n' +
        'khan-plugin-crio \n',
      sysAgentCliPath: 'cd khan-sys-agent/bin',
      sysAgentStart: './start.sh',
      sysAgentEnd: './kill.sh ',
      sysAgentLog: './tail.sh'
    }

    return (
      <>
        <Dialog
          classes={{paper: 'modal-content dialog-maru-custom'}}
          fullWidth
          maxWidth="md"
          open={this.state.show}
          onClose={this.toggle}
          aria-labelledby="tutorial-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <div id="tutorial-dialog-title">
              <div className={(!this.state.value&& 'dialog-focus-title') + ' basic-title'}>프로젝트 생성</div>
              <div>.........</div>
              <div className={(this.state.value&& 'dialog-focus-title') + ' basic-title'}>에이전트 설치</div>
            </div>
          </DialogTitle>
          <DialogContent>
            <Panel index={0} value={this.state.value}>
              <Grid container spacing={2}>
                <Grid item xs={7}>
                  <div hidden={true}>
                    <label className="font-weight-bold mb-2">
                      {i18n.t('project.id', {})}
                    </label>
                    <CTextField
                      className="input-maru-custom"
                      fullWidth
                      disabled={true}
                      name="pid"
                      size="small"
                      value={this.state.pid}
                    />
                  </div>

                  <label className="font-weight-bold mb-2">
                    {i18n.t('project.name', {})}
                  </label>
                  <CTextField
                    className="input-maru-custom"
                    inputRef={this.nameInput}
                    fullWidth
                    name="name"
                    size="small"
                    inputProps={{maxLength: 100}}
                    value={this.state.name}
                    onChange={this.handleChange}
                  />
                  <label className="font-weight-bold my-2">
                    {i18n.t('project.subDomain', {})}
                  </label>
                  <CTextField
                    className="input-maru-custom"
                    fullWidth
                    inputRef={this.subDomainInput}
                    name="subDomain"
                    size="small"
                    inputProps={{maxLength: 20}}
                    value={this.state.subDomain}
                    onChange={this.handleChange}
                    error={this.state.invalidSubDomain}
                    helperText={i18n.t('project.subDomainHelperText', '영문으로 시작하고 숫자와 - 기호를 사용 할 수 있습니다.(최대 10자)')}
                    InputProps={{
                      startAdornment: (<InputAdornment position="start">
                        <span className="font-size-xs text-dark">https://</span>
                      </InputAdornment>),
                      endAdornment: (<InputAdornment position="end">
                        <span className="font-size-xs text-dark">.apm.{this.state.service}.openmaru.io</span>
                      </InputAdornment>),
                      style:{overflowX:"scroll",overflowY: "hidden"}
                    }}
                  />
                </Grid>
                <Grid item xs={5}>
                  <div className="d-flex justify-content-between">
                    <label className="font-weight-bold mb-2">
                      {i18n.t('project.logo', '로고 이미지')}
                    </label>
                  </div>
                  <div className="dropzone text-center">
                    <div className="dropzone-inner-wrapper dropzone-inner-wrapper-alt">
                      <Dropzone onDrop={this.onDrop}>
                        {({getRootProps, getInputProps, isDragAccept, isDragReject, isDragActive}) => (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div>
                              {utils.string.isEmpty(this.state.logoImg) && isDragAccept && (
                                <div
                                  className="btn-icon hover-scale-rounded shadow-light-sm rounded-lg text-first">
                                  <CheckIcon className="d-30"/>
                                </div>
                              )}
                              {utils.string.isEmpty(this.state.logoImg) && isDragReject && (
                                <div
                                  className="btn-icon hover-scale-rounded shadow-light-sm rounded-lg text-first">
                                  <CloseTwoToneIcon className="d-40"/>
                                </div>
                              )}
                              {utils.string.isEmpty(this.state.logoImg) && !isDragActive && (
                                <div
                                  className="btn-icon hover-scale-rounded shadow-light-sm rounded-lg">
                                  <CloudUploadTwoToneIcon className="d-40"/>
                                </div>
                              )}
                              {utils.string.isNotEmpty(this.state.logoImg) && (
                                <div
                                  className="btn-icon hover-scale-rounded shadow-light-sm rounded-lg text-first">
                                  <img
                                    className="img-fluid img-fit-container rounded-sm"
                                    src={this.state.logoImg}
                                    alt="..."
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <label className="font-weight-bold mb-2">
                    {i18n.t('project.description', {})}
                  </label>
                  <CTextField
                    className="input-maru-custom"
                    fullWidth
                    name="description"
                    size="small"
                    inputProps={{maxLength: 1000}}
                    value={this.state.description}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <label className="font-weight-bold mb-2">
                    {i18n.t('project.company', {})}
                  </label>
                  <CTextField
                    className="input-maru-custom"
                    fullWidth
                    name="company"
                    size="small"
                    inputProps={{maxLength: 100}}
                    value={this.state.company}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <label className="font-weight-bold mb-2">
                    {i18n.t('project.team', {})}
                  </label>
                  <CTextField
                    className="input-maru-custom"
                    fullWidth
                    name="team"
                    size="small"
                    inputProps={{maxLength: 100}}
                    value={this.state.team}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <label className="font-weight-bold mb-2">
                      {i18n.t('project.service', {})}
                    </label>
                    <Select
                      name="service"
                      value={this.state.service}
                      onChange={this.handleChange}
                      disabled={ this.state.pid ? true : false }
                      displayEmpty>
                      {
                        utils.getServiceProviders().map(provider => {
                          return (<MenuItem value={provider} key={provider}>{i18n.t('project.region.' + provider, {})}</MenuItem>)
                        })
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <label className="font-weight-bold mb-2">
                      {i18n.t('common.timeZone', {})}
                    </label>
                    <Select
                      name="timeZone"
                      value={this.state.timeZone}
                      onChange={this.handleChange}
                      disabled={ this.state.pid ? true : false }>
                      {
                        Object.keys(utils.timeZones).map(key => {
                          return (<MenuItem value={key} key={key}>{'(' + utils.timeZones[key].GMT + ') ' + utils.timeZones[key].DESC}</MenuItem>)
                        })
                      }
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Panel>
            <Panel index={1} value={this.state.value}>
              <Tabs value={this.state.agentType} onChange={this.setAgentType}
                variant='fullWidth'
                centered>
                <Tab label="WAS" />
                <Tab label="SYS" />
              </Tabs>
              <Panel index={0} value={this.state.agentType}>
                <div className="timeline-list mb-5">
                  <div className="timeline-item timeline-item-icon">
                    <div className="timeline-item--content">
                      <div className="timeline-item--icon-wrapper rounded-pill bg-warning text-white">
                        <FontAwesomeIcon icon={['far', 'building']} />
                      </div>
                      <h4 className="timeline-item--label mb-2 font-weight-bold nav-link" onClick={_=>this.orderTabToggle(0, 'orderWasTab')}>
                        {i18n.t('agent.firewallOption', '(옵션) 내부 방화벽이 있을 경우')}
                      </h4>
                      <Collapse in={this.state.orderWasTab[0]}>
                        <p className="text-left">
                          {i18n.t('agent.firewallMsg', '내부 방화벽이 있는 경우(TCP Outbound) 목적지 정보에 아래 내역을 추가 해주십시요.')}
                          <TextCopyButton copyText={copyBlockText.firewall} positionTop="1rem"/>
                          <CodeBlock  text={copyBlockText.firewall}
                                      language="powershell"
                                      showLineNumbers={false}
                                      theme="dracula" />
                        </p>
                      </Collapse>
                    </div>
                  </div>
                  <div className="timeline-item timeline-item-icon">
                    <div className="timeline-item--content">
                      <div className="timeline-item--icon-wrapper rounded-pill bg-danger text-white">
                        <FontAwesomeIcon icon={['fas', 'download']}/>
                      </div>
                      <h4 className="timeline-item--label mb-2 font-weight-bold">
                        <Button className="button-maru-outlined m-1" onClick={() => window.location.href = '/api/v1/projects/' + this.state.pid + '/download/was-agent.zip'}>
                          <span className="btn-wrapper--label">WAS 에이전트 다운로드</span>
                        </Button>
                      </h4>
                      <p>다운로드 받은 에이전트 파일을 WAS 서버에 복사한 후 압축을 해제합니다.</p>
                    </div>
                  </div>
                  <div className="timeline-item timeline-item-icon">
                    <div className="timeline-item--content">
                      <div className="timeline-item--icon-wrapper rounded-pill bg-danger text-white">
                        <FontAwesomeIcon icon={['far', 'gem']} />
                      </div>
                      <h4 className="timeline-item--label mb-2 font-weight-bold">
                        WAS 설정
                      </h4>
                    </div>
                  </div>
                  <div className="timeline-item">
                    <div className="timeline-item--content">
                      <div className="timeline-item--icon bg-warning" />
                      <h4 className="timeline-item--label mb-2 font-weight-bold nav-link" onClick={_=>this.orderTabToggle(1, 'orderWasTab')}>
                        Tomcat
                      </h4>
                      <Collapse in={this.state.orderWasTab[1]}>
                        <p className="text-left">
                          {i18n.t('agent.tomcatMsg', 'Tomcat 의 경우 catalina.sh 파일에 아래 설정을 추가합니다.')}
                        </p>
                        <TextCopyButton copyText={copyBlockText.wasTomcat} positionTop="0.2rem"/>
                        <CodeBlock  text={copyBlockText.wasTomcat}
                          language="powershell"
                          showLineNumbers={false}
                          theme="dracula" />
                      </Collapse>
                    </div>
                  </div>
                  <div className="timeline-item">
                    <div className="timeline-item--content">
                      <div className="timeline-item--icon bg-warning" />
                      <h4 className="timeline-item--label mb-2 font-weight-bold nav-link" onClick={_=>this.orderTabToggle(2, 'orderWasTab')}>
                        Spring Boot
                      </h4>
                      <Collapse in={this.state.orderWasTab[2]}>
                        <p className="text-left">
                          {i18n.t('agent.springBootMsg', 'SpringBoot 의 경우 catalina.sh 파일에 아래 설정을 추가합니다.')}
                        </p>
                        <TextCopyButton copyText={copyBlockText.wasSpringBoot} positionTop="0.2rem"/>
                        <CodeBlock  text={copyBlockText.wasSpringBoot}
                          language="powershell"
                          showLineNumbers={false}
                          theme="dracula" />
                      </Collapse>
                    </div>
                  </div>
                  <div className="timeline-item">
                    <div className="timeline-item--content">
                      <div className="timeline-item--icon bg-warning" />
                      <h4 className="timeline-item--label mb-2 font-weight-bold nav-link" onClick={_=>this.orderTabToggle(3, 'orderWasTab')}>
                        기타
                      </h4>
                      <Collapse in={this.state.orderWasTab[3]}>
                        <Link className="mr-2" to={"/agentInstall?type=jboss&id=" + this.state.pid} onClick={()=>this.props.closeCallback(this.state.project)}>JBoss 7.x</Link>
                        <Link className="mr-2" to={"/agentInstall?type=jeus6&id=" + this.state.pid} onClick={()=>this.props.closeCallback(this.state.project)}>JEUS 6</Link>
                        <Link className="mr-2" to={"/agentInstall?type=jeus7&id=" + this.state.pid} onClick={()=>this.props.closeCallback(this.state.project)}>JEUS 7/8</Link>
                        <Link className="mr-2" to={"/agentInstall?type=weblogic&id=" + this.state.pid} onClick={()=>this.props.closeCallback(this.state.project)}>WebLogic</Link>
                        <Link className="mr-2" to={"/agentInstall?type=wildfly&id=" + this.state.pid} onClick={()=>this.props.closeCallback(this.state.project)}>Wildfly 14(Java 11)</Link>
                        <Link className="mr-2" to={"/agentInstall?type=jetty&id=" + this.state.pid} onClick={()=>this.props.closeCallback(this.state.project)}>Jetty 9</Link>
                        <Link className="mr-2" to={"/agentInstall?type=regin&id=" + this.state.pid} onClick={()=>this.props.closeCallback(this.state.project)}>Regin 4.x</Link>
                      </Collapse>
                    </div>
                  </div>
                </div>
              </Panel>
              <Panel index={1} value={this.state.agentType}>
                <div className="timeline-list mb-5">
                  <div className="timeline-item timeline-item-icon">
                    <div className="timeline-item--content">
                      <div className="timeline-item--icon-wrapper rounded-pill bg-warning text-white">
                        <FontAwesomeIcon icon={['far', 'building']} />
                      </div>
                      <h4 className="timeline-item--label mb-2 font-weight-bold nav-link" onClick={_=>this.orderTabToggle(0, 'orderWasTab')}>
                        {i18n.t('agent.firewallOption', '(옵션) 내부 방화벽이 있을 경우')}
                      </h4>
                      <Collapse in={this.state.orderSysTab[0]}>
                        <p className="text-left">
                          {i18n.t('agent.firewallMsg', '내부 방화벽이 있는 경우(TCP Outbound) 목적지 정보에 아래 내역을 추가 해주십시요.')}
                          <TextCopyButton copyText={copyBlockText.firewall} positionTop="1rem"/>
                          <CodeBlock  text={copyBlockText.firewall}
                                      language="powershell"
                                      showLineNumbers={false}
                                      theme="dracula" />
                        </p>
                      </Collapse>
                    </div>
                  </div>
                  <div className="timeline-item timeline-item-icon">
                    <div className="timeline-item--content">
                      <div className="timeline-item--icon-wrapper rounded-pill bg-danger text-white">
                        <FontAwesomeIcon icon={['fas', 'download']} />
                      </div>
                      <h4 className="timeline-item--label mb-2 font-weight-bold">
                        <Button className="button-maru-outlined m-1" onClick={() => window.location.href = '/api/v1/projects/' + this.state.pid + '/download/sys-agent.zip'}>
                          <span className="btn-wrapper--label">SYS 에이전트 다운로드</span>
                        </Button>
                      </h4>
                      <p>다운로드 받은 에이전트 파일을 SYS 서버에 복사한 후 압축을 해제합니다.</p>
                    </div>
                  </div>
                  <div className="timeline-item timeline-item-icon">
                    <div className="timeline-item--content">
                      <div className="timeline-item--icon-wrapper rounded-pill bg-danger text-white">
                        <FontAwesomeIcon icon={['fas', 'users-cog']} />
                      </div>
                      <h4 className="timeline-item--label mb-2 font-weight-bold">
                        SYS 에이전트 설정
                      </h4>
                      <p className="text-left">
                        {i18n.t('agent.wasConfigurationMsg', '해제된 파일을 아래와 같이 각 애플리케이션 서버별로 설정 해주십시오.')}
                      </p>
                    </div>
                  </div>
                  <div className="timeline-item">
                    <div className="timeline-item--content">
                      <div className="timeline-item--icon bg-warning" />
                      <h4 className="timeline-item--label mb-2 font-weight-bold nav-link" onClick={_=>this.orderTabToggle(1, 'orderSysTab')}>
                        conf/khan-sys-agent.conf
                      </h4>
                      <Collapse in={this.state.orderSysTab[1]}>
                        <p className="text-left">
                          {i18n.t('agent.sysMsg')}
                        </p>
                        <TextCopyButton copyText={copyBlockText.sysAgentConfPath} positionTop="0.2rem"/>
                        <TextCopyButton copyText={copyBlockText.sysAgentConf} positionTop="2.5rem"/>
                        <CodeBlock text={
                          `$ ${copyBlockText.sysAgentConfPath}\n\n` +
                          copyBlockText.sysAgentConf}
                          language="powershell"
                          showLineNumbers={false}
                          theme="dracula" />
                      </Collapse>
                    </div>
                  </div>
                  <div className="timeline-item">
                    <div className="timeline-item--content">
                      <div className="timeline-item--icon bg-warning" />
                      <h4 className="timeline-item--label mb-2 font-weight-bold nav-link" onClick={_=>this.orderTabToggle(2, 'orderSysTab')}>
                        plugins/disabled.txt
                      </h4>
                      <Collapse in={this.state.orderSysTab[2]}>
                        <p className="text-left">
                          {i18n.t('agent.sysPluginDisableMsg')}
                        </p>
                        <TextCopyButton copyText={copyBlockText.sysPluginsDisabledPath} positionTop="0.3rem"/>
                        <TextCopyButton copyText={copyBlockText.sysPluginsDisabledConf} positionTop="2.5rem"/>
                        <CodeBlock text={
                          `$ ${copyBlockText.sysPluginsDisabledPath}\n\n` +
                          copyBlockText.sysPluginsDisabledConf}
                          language="powershell"
                          showLineNumbers={false}
                          theme="dracula" />
                      </Collapse>
                    </div>
                  </div>
                  <div className="timeline-item">
                    <div className="timeline-item--content">
                      <div className="timeline-item--icon" />
                      <h4 className="timeline-item--label mb-2 font-weight-bold nav-link" onClick={_=>this.orderTabToggle(3, 'orderSysTab')}>
                        {i18n.t('agent.sysCtrlMsg', '시스템 에이전트 시작 및 종료')}
                      </h4>
                      <Collapse in={this.state.orderSysTab[3]}>
                        <TextCopyButton copyText={copyBlockText.sysAgentCliPath} positionTop="1.5rem"/>
                        <TextCopyButton copyText={copyBlockText.sysAgentStart} positionTop="2.8rem"/>
                        <TextCopyButton copyText={copyBlockText.sysAgentEnd} positionTop="6.5rem"/>
                        <TextCopyButton copyText={copyBlockText.sysAgentLog} positionTop="10.3rem"/>
                        <CodeBlock text={ '## start\n' +
                          `$ ${copyBlockText.sysAgentCliPath}\n` +
                          `$ ${copyBlockText.sysAgentStart}\n\n` +
                          '## stop\n' +
                          `$ ${copyBlockText.sysAgentEnd} (or ./stop.sh)\n\n` +
                          '## log\n' +
                          `$ ${copyBlockText.sysAgentLog}\n`}
                          language="powershell"
                          showLineNumbers={false}
                          theme="dracula" />
                      </Collapse>
                    </div>
                  </div>
                </div>
              </Panel>
            </Panel>
          </DialogContent>
          <DialogActions>
            <div>
              <Button className='mr-3 button-maru-gray-contained' onClick={this.closePopup}>나가기</Button>
              {this.state.value?(
                <Button className={'shadow-none' + (this.state.connectableExternalBrowser === true)?'button-maru-contained':'button-maru-second-contained'}
                  variant="outlined"
                  disabled={(this.state.connectableExternalBrowser === false)}
                  onClick={this.go2APM}>
                  <span className="btn-wrapper--icon">
                    {(this.state.connectableExternalBrowser === true)?
                      (<FontAwesomeIcon icon={['far', 'eye']} className="font-size-sm" />)
                      :(<FontAwesomeIcon icon={['fas', 'spinner']} className="font-size-sm" spin />)}
                  </span>
                  <span className="btn-wrapper--label">{i18n.t('common.monitoring', '모니터링')}</span>
                </Button>)
              : (<Button className='shadow-none button-maru-contained' onClick={this.createProject}>
                {i18n.t('common.next', {})}
                  <FontAwesomeIcon icon={['fas', 'arrow-right']} className="font-size-sm ml-2" />
                </Button>)}
            </div>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default ProjectTutorial;
