import React, { Component } from 'react';
import { connect } from 'react-redux';
import {setShowBodyLoading} from '../../../reducers/OpenmaruReducer';
import {withRouter} from 'react-router-dom';
import queryString from 'query-string';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import i18n from "../../../config/lang/i18n";
import {Grid, Button, Card, Box} from "@material-ui/core";
import APIsLists from "../../../utils/APIUtils";
import TitBreadcrumbs from "../../component/TitBreadcrumbs";
import Chart from 'react-apexcharts';
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import koLocale from 'date-fns/locale/ko';
import { DateTime } from 'luxon';
import notification from "openmaru/component/Notification";

class SystemUsage extends Component {
  constructor(props) {
    super(props);

    const initialDateObject = DateTime.fromJSDate(new Date());
    this.state = {
      pid: undefined,
      project: {},
      data: {},
      totalStats: {
        totalCubAgents: {},
        totalCubCores: {},
        totalWasAgents: {},
        totalWasCores: {},
      },
      chartData: {
        averageCubAgents: {},
        averageCubCores: {},
        averageWasAgents: {},
        averageWasCores: {}
      },
      searchDate: new Date(),
      startDate: initialDateObject.startOf('month').toMillis(),
      endDate: initialDateObject.endOf('month').toMillis(),
      dateLabel: [],
      averageOfStats: {
        cubCores: 0,
        cubAgents: 0,
        wasCores: 0,
        wasAgents: 0
      }
    }

    this.handleDateChange = this.handleDateChange.bind(this);
    this.getSystemUsage = this.getSystemUsage.bind(this);
    this.makeDataLabel = this.makeDataLabel.bind(this);
  }

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    this.setState({pid: query.id}, () => {
      this.getSystemUsage();
    })
  }

  componentDidUpdate() {
    const query = queryString.parse(this.props.location.search);
    if (query.id !== this.state.pid) {
      this.setState({pid: query.id}, () => {
        this.getSystemUsage();
      })
    }
  }

  makeDataLabel() {
    const searchDateObject = DateTime.fromJSDate(this.state.searchDate);
    const endDay = parseInt(searchDateObject.endOf('month').toFormat("dd"));
    const labelDate = [];
    const durationFormat = {
      totalCubAgents: {},
      totalCubCores: {},
      totalWasAgents: {},
      totalWasCores: {}
    }
    const averageFormat = {
      averageCubAgents: {},
      averageCubCores: {},
      averageWasAgents: {},
      averageWasCores: {}
    }

    for(let index = 0; index < endDay; index++) {
      const addedDateObject = searchDateObject.startOf('month').plus({days: index});
      labelDate.push(addedDateObject.toFormat("yyyy'-'LL'-'dd"));
      durationFormat.totalCubAgents[addedDateObject.toFormat("yyyyLLdd")] = 0;
      durationFormat.totalCubCores[addedDateObject.toFormat("yyyyLLdd")] = 0;
      durationFormat.totalWasAgents[addedDateObject.toFormat("yyyyLLdd")] = 0;
      durationFormat.totalWasCores[addedDateObject.toFormat("yyyyLLdd")] = 0;

      averageFormat.averageCubAgents[addedDateObject.toFormat("yyyyLLdd")] = 0;
      averageFormat.averageCubCores[addedDateObject.toFormat("yyyyLLdd")] = 0;
      averageFormat.averageWasAgents[addedDateObject.toFormat("yyyyLLdd")] = 0;
      averageFormat.averageWasCores[addedDateObject.toFormat("yyyyLLdd")] = 0;
    }

    return ({
      dateLabel: labelDate,
      totalStats: durationFormat,
      chartData: averageFormat
    })
  }

  getInfo() {
    this.getProjectInfo();
  }

  getProjectInfo() {
    APIsLists.project.getProjectInfo({pid: this.state.pid}, (data) => {
      if (data.status === 200 && data.body) {
        this.setState({project: data.body})
      } else {
        notification.error(data.body, {title: data.status});
      }
    });
  }

  handleDateChange(value) {
    this.setState({
      searchDate: value
    })
  }

  getSystemUsage() {
    this.props.setShowBodyLoading(this.props.showBodyLoading+1);
    const setDateObject =  DateTime.fromJSDate(this.state.searchDate);
    const startDate = setDateObject.startOf('month').toMillis();
    const endDate = setDateObject.endOf('month').toMillis();

    const {dateLabel, totalStats, chartData} = this.makeDataLabel();

    APIsLists.project.getSystemUsage({
      pid: this.state.pid,
      startDate: startDate,
      endDate: endDate
    }, (data) => {
      const tempTotalObject = Object.assign(totalStats);
      const tempAverageObject = Object.assign(chartData);

      if(data.status === 200) {
        if(data.body.length)  {
          for(const value of data.body) {
            const dailyStandard= DateTime.fromMillis(value.dataDateTime).toFormat("yyyyLLdd")
  
            tempTotalObject.totalCubAgents[dailyStandard] = (tempTotalObject.totalCubAgents[dailyStandard]>=0)? (tempTotalObject.totalCubAgents[dailyStandard]+value.cubAgentCount): tempTotalObject.totalCubAgents[dailyStandard];
            tempTotalObject.totalCubCores[dailyStandard] = (tempTotalObject.totalCubCores[dailyStandard]>=0)? (tempTotalObject.totalCubCores[dailyStandard]+value.cubTotalCores): tempTotalObject.totalCubCores[dailyStandard];
            tempTotalObject.totalWasAgents[dailyStandard] = (tempTotalObject.totalWasAgents[dailyStandard]>=0)? (tempTotalObject.totalWasAgents[dailyStandard]+value.wasAgentCount): tempTotalObject.totalWasAgents[dailyStandard];
            tempTotalObject.totalWasCores[dailyStandard] = (tempTotalObject.totalWasCores[dailyStandard]>=0)? (tempTotalObject.totalWasCores[dailyStandard]+value.wasTotalCores): tempTotalObject.totalWasCores[dailyStandard];
          }
  
          for(let initDate = DateTime.fromMillis(this.state.startDate); initDate.toFormat("yyyyLLdd") <= DateTime.fromMillis(this.state.endDate).toFormat("yyyyLLdd"); initDate = initDate.plus({days: 1})) {
            tempAverageObject.averageCubAgents[initDate.toFormat("yyyyLLdd")] = tempTotalObject.totalCubAgents[initDate.toFormat("yyyyLLdd")]? parseFloat(tempTotalObject.totalCubAgents[initDate.toFormat("yyyyLLdd")]/24).toFixed(2): 0;
            tempAverageObject.averageCubCores[initDate.toFormat("yyyyLLdd")] = tempTotalObject.totalCubCores[initDate.toFormat("yyyyLLdd")]? parseFloat(tempTotalObject.totalCubCores[initDate.toFormat("yyyyLLdd")]/24).toFixed(2): 0;
            tempAverageObject.averageWasAgents[initDate.toFormat("yyyyLLdd")] = tempTotalObject.totalWasAgents[initDate.toFormat("yyyyLLdd")]? parseFloat(tempTotalObject.totalWasAgents[initDate.toFormat("yyyyLLdd")]/24).toFixed(2): 0;
            tempAverageObject.averageWasCores[initDate.toFormat("yyyyLLdd")] = tempTotalObject.totalWasCores[initDate.toFormat("yyyyLLdd")]? parseFloat(tempTotalObject.totalWasCores[initDate.toFormat("yyyyLLdd")]/24).toFixed(2): 0;
          }
        }

        this.setState({
          totalStats: tempTotalObject,
          chartData: tempAverageObject,
          startDate: startDate,
          endDate: endDate,
          dateLabel: dateLabel
        })
      } else {
        notification.warn(data.message);
      }
      this.props.setShowBodyLoading(this.props.showBodyLoading-1);
    })
  }
  
	render() {
    const chartsOptions = {
      stroke: {
        curve: 'smooth',
        width: [0, 4]
      },
      chart: {
        toolbar: {
          show: true
        }
      },
      colors: ['#4191ff', '#bbd4f8', '#247B7B', '#C7EBF0', '#DB5461'],
      fill: {
        opacity: 1
      },
      labels: this.state.dateLabel,
      xaxis: {
        type: 'datetime',
        labels: {
          format: 'MM/dd',
        }
      },
      yaxis: [{
        title: {
          text: 'Number of Cores',
        },
        labels: {
          formatter: function (value) {
            return parseFloat(value).toFixed(2);
          }
        }
      }],
      markers: {
        size: 1
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [2],
        formatter: function(value, opt) {          
          return new Intl.NumberFormat().format(value);
        },
      },
      grid: {
        strokeDashArray: '5',
        borderColor: 'rgba(125, 138, 156, 0.3)'
      },
      tooltip: {
        x: {
          formatter: function (value) {
            return new Intl.DateTimeFormat().format(value);
          }
        },
        y: {
          formatter: function (value) {
            return value? parseFloat(value).toFixed(2): 0;
          }
        }
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'right',
        floating: false,
      }
    };

    const chartsData = [{
        name: 'Cubrid Core',
        type: 'column',
        data: Object.values(this.state.chartData.averageCubCores)
      }, {
        name: 'WAS Core',
        type: 'column',
        data: Object.values(this.state.chartData.averageWasCores)
      }, {
        name: 'Cubrid Agent',
        type: 'column',
        data: Object.values(this.state.chartData.averageCubAgents)
      }, {
        name: 'WAS Agent',
        type: 'column',
        data: Object.values(this.state.chartData.averageWasAgents)
      }];

    return (
      <>
        <TitBreadcrumbs title={i18n.t('systemUsage.title', '시스템 사용량')} />
        <div className="app-page-title">
          <div>
            <h1><FontAwesomeIcon icon={['fas', 'cogs']}
                                 className="icon"/> {i18n.t('systemUsage.title', '시스템 사용량')}</h1>
          </div>
        </div>

        <Card className="card-box p-4">
          <Box className="d-flex align-items-center">
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={koLocale}>
              <label className="mr-2">{i18n.t("systemUsage.selectDuration")} : </label>
              <DatePicker
                views={["year", "month"]}
                // minDate={new Date("2018-03-01")}
                maxDate={new Date()}
                value={this.state.searchDate}
                onChange={this.handleDateChange}
                format="yyyy-MM"
                variant="inline"
                autoOk={true}
              />
            </MuiPickersUtilsProvider>
            <Button
              variant="text"
              className="btn-outline-first m-1 px-2 py-1"
              onClick={this.getSystemUsage}             
              >
              <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={['fas', 'chart-line']}/>
              </span>
              <span className="btn-wrapper--label">{i18n.t("systemUsage.searchDuration")}</span>
            </Button>
          </Box>
          <Chart
            options={chartsOptions}
            series={chartsData}
            type="line"
            height={550}
          />

          <Grid item lg={8} className="mx-auto mt-2 p-0">
            <div className="text-center font-size-sm font-weight-bold text-black-50">
              {i18n.t("systemUsage.currentDate")} {DateTime.fromJSDate(new Date()).toFormat("yyyy'-'LL'-'dd")}
            </div>
            <div className="text-center font-size-sm font-weight-bold text-black-50">
            {i18n.t("systemUsage.duration")} : {DateTime.fromMillis(this.state.startDate).toFormat("yyyy'-'LL'-'dd")} 
              ~ {DateTime.fromMillis(this.state.endDate).toFormat("yyyy'-'LL'-'dd")}
            </div>
          </Grid>
        </Card>
      </>
		)
	}
}

SystemUsage = connect(
  (state) => ({
    showBodyLoading: state.OpenmaruReducer.showBodyLoading
  }),
  (dispatch) => ({
    setShowBodyLoading: (enable) => dispatch(setShowBodyLoading(enable)),
  })
)(SystemUsage);

export default withRouter(SystemUsage);
