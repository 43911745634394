import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MUITable from "openmaru/component/MUITable";
import i18n from "../../../config/lang/i18n";
import APIUtils from "../../../utils/APIUtils";
import utils from "../../../utils/Utils";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, Tooltip, IconButton } from '@material-ui/core';
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import koLocale from 'date-fns/locale/ko';
import Alert from '@material-ui/lab/Alert';
import clsx from 'clsx';
import notification from 'openmaru/component/Notification';
import Chart from 'react-apexcharts';
import { DateTime } from 'luxon';

class BillDetailList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isChartLoading: false,
      pid: '',
      billId: '',
      bill: undefined,
      projectName: undefined,
      cubPrices: [],
      wasPrices: [],
      pays: undefined,
      activeTab: '0',
      detailPopupState: false,
      dateLabel: [],
      rowsPerPage: 10,
      dailyAmountOfWas: {},
      dailyAmountOfCub: {},
      startDate: '',
      endDate: '',
      monthlyData: {}
    }

    this.toggle = this.toggle.bind(this);
    this.getBillDatailList = this.getBillDatailList.bind(this);
    this.getBillPayList = this.getBillPayList.bind(this);
    this.makeDataLabel = this.makeDataLabel.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  handleDateChange(value) {
    this.setState({
      isLoading: true,
      isChartLoading: true,
      searchMonth: value,
      month: DateTime.fromJSDate(value).toFormat('yyyyLL'),
      wasPrices: this.state.monthlyDate[DateTime.fromJSDate(value).toFormat('yyyyLL')].was,
      cubPrices: this.state.monthlyDate[DateTime.fromJSDate(value).toFormat('yyyyLL')].cub
    }, () => {
      this.makeDataLabel();
    })
  }

  showBillDetail(billId, pid, projectName) {
    this.setState({detailPopupState: true, pid: pid, billId: billId, projectName: projectName, bill: '', cubPrices: [], wasPrices: [], activeTab: '0'}, () => {
      this.getBillDatailList();
    });
  }

  getBillDatailList() {
    this.setState({ isLoading: true, isChartLoading: true, cubPrices: [], wasPrices: [] });

    let getList = APIUtils.project.getProjectBillDetail;
    if (utils.auth.hasSuperAdmin()) {
      getList = APIUtils.superAdmin.getBillDetail;
    }
    getList({pid: this.state.pid, billId: this.state.billId}, (data) => {
      if (data.status === 200) {
        const bill = data.body;
        if (!bill.detail || !bill.detail.cubPrices || !bill.detail.wasPrices) {
          notification.error(i18n.t('billManagement.detailDataEmptyMessage'));
        } else {
          const startMonthObject = DateTime.fromFormat(bill.useStartDate, 'yyyyLLdd')
          const endMonth = DateTime.fromFormat(bill.useEndDate, 'yyyyLLdd').toFormat("yyyyLL")
          const startMonth = DateTime.fromFormat(bill.useStartDate, 'yyyyLLdd').toFormat('yyyyLL');

          let targetMonth = '';
          let months = [];
          let monthly = [];

          if(bill.useStartDate) {
            for(let indexMonth = 0; targetMonth < endMonth; indexMonth++) {
              targetMonth = startMonthObject.plus({months: indexMonth}).toFormat("yyyyLL");
              months.push(targetMonth);
              monthly[targetMonth] = { was: [], cub: [] };
            }
          } else {
            monthly[bill.useMonth] = { was: [], cub: [] };
          }
          
          this.setState({
            bill: bill,
            month: bill.useMonth? bill.useMonth: startMonth,
            startDate: bill.useStartDate,
            endDate: bill.useEndDate,
            searchMonth: DateTime.fromFormat(bill.useMonth? bill.useMonth: startMonth, 'yyyyLL').toJSDate(),
            monthlyDate: monthly
          }, () => {
            const iterationNumber = (bill.detail.cubPrices.length > bill.detail.wasPrices.length) ? bill.detail.cubPrices.length : bill.detail.wasPrices.length;

            for(let index = 0; index < iterationNumber; index++) {
              const dataTime = '';
              
              if(bill.detail.cubPrices[index]) {
                dataTime = DateTime.fromMillis(bill.detail.cubPrices[index].dataTime).toFormat("yyyyLL");
                if(this.state.monthlyDate[dataTime]) {
                  this.state.monthlyDate[dataTime].cub.push(bill.detail.cubPrices[index])
                }
              }
              if(bill.detail.wasPrices[index]) {
                dataTime = DateTime.fromMillis(bill.detail.wasPrices[index].dataTime).toFormat("yyyyLL");
                if(this.state.monthlyDate[dataTime]) {
                  this.state.monthlyDate[dataTime].was.push(bill.detail.wasPrices[index])
                }
              }
            }
            this.setState({
              cubPrices: this.state.monthlyDate[this.state.month].cub,
              wasPrices: this.state.monthlyDate[this.state.month].was,
            }, () => {
              this.makeDataLabel();
            })
          });
        }
      } else {
        notification.error(data.message);
      }
    });
  }

  getBillPayList() {
    if (!utils.auth.hasSuperAdmin()) {
      return;
    }
    this.setState({ isLoading: true });

    APIUtils.superAdmin.billPaymentList({pid: this.state.pid, billId: this.state.billId}, (data) => {
      if (data.status === 200) {
        var pays = data.body;
        this.setState({pays: pays});
      } else {
        notification.error(data.message);
      }
      this.setState({ isLoading: false });
    });
  }

  toggle() {
    this.setState({detailPopupState: !this.state.detailPopupState});
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({activeTab: tab})
    }
  };

  makeDataLabel() {
    const searchDateObject = DateTime.fromFormat(this.state.month, "yyyyLL");
    const endDay = parseInt(searchDateObject.endOf('month').toFormat("dd"));
    const labelDate = [];
    const dailyAmountOfWas = {};
    const dailyAmountOfCub = {};

    for(let index = 0; index < endDay; index++) {
      const addedDateObject = searchDateObject.startOf('month').plus({days: index});
      labelDate.push(addedDateObject.toFormat("yyyy'-'LL'-'dd"));
      dailyAmountOfWas[addedDateObject.toFormat("yyyy'-'LL'-'dd")] = 0;
      dailyAmountOfCub[addedDateObject.toFormat("yyyy'-'LL'-'dd")] = 0;
    }

    if(this.state.wasPrices.length) {
      this.state.wasPrices.forEach((wasPriceObject, index) => {
        if((typeof(wasPriceObject.discountPrice) === 'number')  && (dailyAmountOfWas[DateTime.fromMillis(wasPriceObject.dataTime).toFormat("yyyy'-'LL'-'dd")] >= 0)) {
          dailyAmountOfWas[DateTime.fromMillis(wasPriceObject.dataTime).toFormat("yyyy'-'LL'-'dd")] += wasPriceObject.discountPrice;
        }
        
        if(this.state.cubPrices.length) {
          const cubPriceObject = this.state.cubPrices[index];
          if((typeof(cubPriceObject.discountPrice) === 'number')  && (dailyAmountOfCub[DateTime.fromMillis(cubPriceObject.dataTime).toFormat("yyyy'-'LL'-'dd")] >= 0)) {
            dailyAmountOfCub[DateTime.fromMillis(cubPriceObject.dataTime).toFormat("yyyy'-'LL'-'dd")] += cubPriceObject.discountPrice;
          }
        }
      })
    }

    this.setState({
      dateLabel: labelDate,
      dailyAmountOfWas: dailyAmountOfWas,
      dailyAmountOfCub: dailyAmountOfCub,
      isChartLoading: false,
      isLoading: false,
    })
  }

  render () {
    const columns = [
      { label: i18n.t('common.time', '시간'), name: 'dataTime',
        options: {
          setCellProps: (_ => ({style: {minWidth: "6vw", maxWidth: "6vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center'}}>{utils.date.millisToString(value, 'yyyy-MM-dd HH:mm')}</div>
          }
        }
      },
      { label: i18n.t('billManagement.agentCount', '에이전트 수'), name: 'agents',
        options: {
          setCellProps: (_ => ({style: {minWidth: "4vw", maxWidth: "4vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center'}}>{utils.string.numberToCommas(value)}</div>
          }
        }
      },
      { label: i18n.t('billManagement.cpuCores', '코어 수'), name: 'cores',
        options: {
          setCellProps: (_ => ({style: {minWidth: "4vw", maxWidth: "4vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center'}}>{utils.string.numberToCommas(value)}</div>
          }
        }
      },
      { label: i18n.t('billManagement.price', '가격'), name: 'normalPrice',
        options: {
          setCellProps: (_ => ({style: {minWidth: "4vw", maxWidth: "4vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center'}}>{utils.string.numberToCommas(Math.round(value))}</div>
          }
        }
      },
      { label: i18n.t('billManagement.discountPrice', '할인 가격'), name: 'discountPrice',
        options: {
          setCellProps: (_ => ({style: {minWidth: "4vw", maxWidth: "4vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center'}}>{utils.string.numberToCommas(Math.round(value))}</div>
          }
        }
      },
      { label: i18n.t('billManagement.discountPartner', '파트너 할인율'), name: 'discountRatePartner',
        options: {
          setCellProps: (_ => ({style: {minWidth: "2vw", maxWidth: "2vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center'}}>{value}%</div>
          }
        }
      },
      { label: i18n.t('billManagement.discountVolume', '볼륨 할인율'), name: 'discountRateVolume',
        options: {
          setCellProps: (_ => ({style: {minWidth: "2vw", maxWidth: "2vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center'}}>{value}%</div>
          }
        }
      }
    ];

    const options = {
      onTableInit: (e) => {
      },
      refresh: () => {
        this.getBillDatailList();
      },
      draggableColumns: {
        enabled: true
      },
      serverSide: false,
      rowsPerPage: this.state.rowsPerPage,
      rowsPerPageOptions: [5, 10, 24, 30],
      filter: false,
      responsive: 'simple',
      selectableRows: 'none',
      search: true,
      searchOpen: true,
      customToolbar: () => {
        return (
          <Tooltip title={i18n.t('common.allData', '전체')}>
            <IconButton className='custom-toolbar-icon' onClick={() => {
              const detailPrices = this.state.monthlyDate[this.state.month];
              this.setState({
                wasPrices: detailPrices.was,
                cubPrices: detailPrices.cub
              })
            }}>
              <FontAwesomeIcon icon={['fas', 'reply-all']} className="font-size-lg"/>
            </IconButton>
          </Tooltip>
        )
      }
    };

    const payColumns = [
      { label: i18n.t('paymentsManagement.payId', '결제 아이디'), name: 'payId',
        options: {
          setCellProps: (_ => ({style: {minWidth: "8vw", maxWidth: "8vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center'}}>{value}</div>
          }
        }
      },
      { label: i18n.t('paymentsManagement.pgCodeNm', 'PG사'), name: 'pgCodeNm',
        options: {
          setCellProps: (_ => ({style: {minWidth: "4vw", maxWidth: "4vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center'}}>{value}</div>
          }
        }
      },
      { label: i18n.t('paymentsManagement.payTypeNm', '결제 종류'), name: 'payTypeNm',
        options: {
          setCellProps: (_ => ({style: {minWidth: "4vw", maxWidth: "4vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center'}}>{value}</div>
          }
        }
      },
      { label: i18n.t('paymentsManagement.cardName', '카드명'), name: 'crdActName',
        options: {
          setCellProps: (_ => ({style: {minWidth: "4vw", maxWidth: "4vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center'}}>{value}</div>
          }
        }
      },
      { label: i18n.t('paymentsManagement.cardNumber', '카드번호'), name: 'crdActNumber',
        options: {
          setCellProps: (_ => ({style: {minWidth: "4vw", maxWidth: "4vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center'}}>{value}</div>
          }
        }
      },
      { label: i18n.t('common.status', '상태'), name: 'status',
        options: {
          setCellProps: (_ => ({style: {minWidth: "4vw", maxWidth: "4vw"}})),
          customBodyRenderLite: (value, rowIndex) => {
            var rowData = this.state.pays[rowIndex];
            let color = 'success';
            if (rowData && rowData.status === '1002') {
              color = 'dark';
            } else if (rowData && rowData.status === '1003') {
              color = 'danger';
            } else if ( rowData && rowData.status === '1004') {
              color = 'warning';
            }
            return (<div className="d-flex justify-content-between">
              <Tooltip title={rowData?rowData.resultMessage:''} arrow placement="top-start" classes={{tooltip: "p-3"}}>
                <div className={'badge badge-' + color} style={{textAlign: 'center', margin: '0 auto'}}>{rowData?rowData.statusNm:''}</div>
              </Tooltip>
            </div>)
          }
        }
      },
      { label: i18n.t('paymentsManagement.pgResultCoce', 'PG사 결과 코드'), name: 'resultCode',
        options: {
          setCellProps: (_ => ({style: {minWidth: "4vw", maxWidth: "4vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center'}}>{value?value:''}</div>
          }
        }
      },
      { label: i18n.t('paymentsManagement.pgResultMessage', 'PG사 결과 메시지'), name: 'resultMessage',
        options: {
          setCellProps: (_ => ({style: {minWidth: "4vw", maxWidth: "4vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center'}}>{value}</div>
          }
        }
      },
      { label: i18n.t('common.created', '생성일'), name: 'created',
        options: {
          setCellProps: (_ => ({style: {minWidth: "4vw", maxWidth: "4vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center'}}>{utils.date.millisToString(value, "yyyy'-'LL'-'dd HH:mm:ss")}</div>
          }
        }
      },
    ];

    const payOptions = {
      onTableInit: (e) => {
      },
      refresh: () => {
        this.getBillPayList();
      },
      draggableColumns: {
        enabled: true
      },
      serverSide: false,
      rowsPerPage: 10,
      filter: false,
      responsive: 'simple',
      selectableRows: 'none',
      search: true,
      searchOpen: true
    };

    let totalPrice = 0;
    let cubRangeDiscount = 0;
    let wasRangeDiscount = 0;
    let cubTotalDiscountPrice = 0;
    let wasTotalDiscountPrice = 0;

    if (this.state.bill && this.state.bill.detail) {
      totalPrice = this.state.bill.detail.totalPrice;
      cubRangeDiscount = this.state.bill.detail.cubRangeDiscount;
      wasRangeDiscount = this.state.bill.detail.wasRangeDiscount;
      cubTotalDiscountPrice = this.state.bill.detail.cubTotalDiscountPrice;
      wasTotalDiscountPrice = this.state.bill.detail.wasTotalDiscountPrice;
    }

    const chartsOptions = {
      chart: {
        type: 'area',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        },
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: false,
            zoom: false,
            reset: false,
            pan: false,
            zoomin: false,
            zoomout: false
          },
          export: {
            csv: {
              filename: `${i18n.t("billManagement.billDetail")}-${this.state.month}`,
              columnDelimiter: ',',
              headerCategory: "\uFEFF" + 'Day of Amount',
              headerValue: 'value',
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString()
              }
            },
            svg: {
              filename: `${i18n.t("billManagement.billDetail")}-${this.state.month}`,
            },
            png: {
              filename: `${i18n.t("billManagement.billDetail")}-${this.state.month}`,
            }
          },
          autoSelected: 'zoom' 
        },
        events: {
          markerClick: (event, chartContext, { seriesIndex, dataPointIndex, config}) => {
            this.setState({isLoading: true})
            const allWasPrices = this.state.bill.detail.wasPrices;
            const allCubPrices = this.state.bill.detail.cubPrices;
            const todayDataFilter = (priceOfType) => {
              return this.state.dateLabel[dataPointIndex] === DateTime.fromMillis(priceOfType.dataTime).toFormat("yyyy'-'LL'-'dd");
            }
            
            this.setState({
              wasPrices: allWasPrices.filter(todayDataFilter),
              cubPrices: allCubPrices.filter(todayDataFilter),
              isLoading: false
            })
          }
        },
        zoom: {
          enabled: true
        }
      },
      stroke: {
        curve: 'smooth'
      },
      colors: ['#44AF69', '#FCAB10', '#E2A0FF', '#2B9EB3', '#C7EBF0', '#DB5461'],
      fill: {
        opacity: 1
      },
      markers: {
        size: 1
      },
      labels: this.state.dateLabel,
      xaxis: {
        type: 'datetime',
        labels: {
          format: 'MM/dd',
        }
      },
      yaxis: [{
        title: {
          text: i18n.t("billManagement.billAmount", "금액"),
        },
        labels: {
          formatter: function (value) {
            return new Intl.NumberFormat().format(value);
          }
        }
      }],
      markers: {
        size: 1
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [2],
        formatter: function(value, opt) {          
          return new Intl.NumberFormat().format(value);
        },
      },
      grid: {
        strokeDashArray: '5',
        borderColor: 'rgba(125, 138, 156, 0.3)'
      },
      tooltip: {
        x: {
          formatter: function (value) {
            return new Intl.DateTimeFormat().format(value);
          }
        },
        y: {
          formatter: function (value) {
            return value? new Intl.NumberFormat().format(value): 0;
          }
        }
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
      }
    };

    const chartsData = [{
        name: "Cubrid Price",
        data: Object.values(this.state.dailyAmountOfCub)
      }, {
        name: "WAS Price",
        data: Object.values(this.state.dailyAmountOfWas)
      }];

    return (
      <>
        <Dialog
          classes={{paper: 'modal-content dialog-maru-custom'}}
          fullWidth
          maxWidth="lg"
          open={this.state.detailPopupState}
          onClose={this.toggle}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {i18n.t('billManagement.billDetail', '청구서 상세') + ' - ' + utils.string.unescape(this.state.projectName?this.state.projectName:'') + (' (' + utils.date.getYearMonthStr(this.state.bill?this.state.bill.billingDate:'', 'yyyyMMdd') + ')')}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={6}>
              <Grid item lg={4}>
                <Alert
                  variant="outlined"
                  icon={false}
                  className="shadow-error-sm"
                  severity="error">
                  <div className="d-flex align-items-center align-content-center">
                    <span className="font-size-lg d-block btn-icon d-40 mr-3 text-center bg-neutral-danger rounded-sm text-danger">
                      <FontAwesomeIcon icon={['fas', 'check']} />
                    </span>
                    <span>
                      <strong className="d-block">{utils.string.numberToCommas(totalPrice)}</strong>
                      {i18n.t('billManagement.totalPrice', '전체 금액')}
                    </span>
                  </div>
                </Alert>
              </Grid>
              <Grid item lg={8}>
                <Alert
                  variant="outlined"
                  icon={false}
                  className="shadow-error-sm"
                  severity="error">
                  <div className="d-flex align-items-center align-content-center">
                    <span className="font-size-lg d-block btn-icon d-40 mr-3 text-center bg-neutral-danger rounded-sm text-danger">
                      <FontAwesomeIcon icon={['fas', 'question']} />
                    </span>
                    <span>
                      <strong className="d-block">({utils.string.numberToCommas(cubTotalDiscountPrice)} - {cubRangeDiscount}%) + ({utils.string.numberToCommas(wasTotalDiscountPrice)} - {wasRangeDiscount}%)</strong>
                      (Cubrid Agent - SYS{i18n.t('billManagement.discountRange', '기간 할인율')}) + (WAS Agent - WAS{i18n.t('billManagement.discountRange', '기간 할인율')})
                    </span>
                  </div>
                </Alert>
              </Grid>
            </Grid>
            <hr/>
            {
              !this.state.isChartLoading &&
              (<>
                {(DateTime.fromFormat(this.state.startDate, 'yyyyLLdd').toFormat('yyyyLL') 
                !== DateTime.fromFormat(this.state.endDate, 'yyyyLLdd').toFormat('yyyyLL')) && 
                (<>
                  <label className="px-2 pt-1 pb-2">{i18n.t("systemUsage.selectDuration")}</label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={koLocale}>
                    <DatePicker
                      views={["year", "month"]}
                      minDate={DateTime.fromFormat(this.state.startDate, 'yyyyLLdd').toJSDate()}
                      maxDate={DateTime.fromFormat(this.state.endDate, 'yyyyLLdd').toJSDate()}
                      value={this.state.searchMonth}
                      className="input-maru-custom"
                      onChange={(event) => {
                        this.handleDateChange(event);
                      }}
                      format="yyyy-MM"
                      variant="inline"
                      autoOk={true}
                    />
                  </MuiPickersUtilsProvider>
                </>)}
                <Chart
                  options={chartsOptions}
                  series={chartsData}
                  type='area'
                  height={250} />
              </>)
            }
            <List className="nav-tabs nav-tabs-danger d-flex">
              <ListItem
                button
                className="font-weight-bold"
                disableRipple
                selected={this.state.activeTab === '0'}
                onClick={() => {
                  this.toggleTab('0');
                }}>
                WAS Agent
              </ListItem>
              <ListItem
                button
                className="font-weight-bold"
                disableRipple
                selected={this.state.activeTab === '1'}
                onClick={() => {
                  this.toggleTab('1');
                }}>
                Cubrid Agent
              </ListItem>
              <ListItem
                button
                className="font-weight-bold"
                style={utils.auth.hasSuperAdmin()?{}:{display: 'none'}}
                disableRipple
                selected={this.state.activeTab === '2'}
                onClick={() => {
                  this.setState({ isLoading: false }, () => {
                    this.getBillPayList();
                  });
                  this.toggleTab('2');
                }}>
                {i18n.t('billManagement.title', '결제 내역')}
              </ListItem>
            </List>
            <div className={clsx('tab-item-wrapper', {active: this.state.activeTab === '0'})}>
              <MUITable columns={columns} data={this.state.wasPrices?this.state.wasPrices:[]} options={options} loading={this.state.isLoading}/>
            </div>
            <div className={clsx('tab-item-wrapper', {active: this.state.activeTab === '1'})}>
              <MUITable columns={columns} data={this.state.cubPrices?this.state.cubPrices:[]} options={options} loading={this.state.isLoading}/>
            </div>
            <div className={clsx('tab-item-wrapper', {active: this.state.activeTab === '2'})}>
              <MUITable columns={payColumns} data={this.state.pays?this.state.pays:[]} options={payOptions} loading={this.state.isLoading}/>
            </div>
          </DialogContent>
          <DialogActions>
            <div></div>
            <div>
              <Button onClick={() => this.setState({
                detailPopupState: false
              })} className="button-maru-gray-contained">{i18n.t('common.confirm', {})}</Button>
            </div>
            <div></div>
          </DialogActions>
        </Dialog>

      </>
    )
  }
}

export default BillDetailList;
