import React, {Component} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import i18n from "../../../config/lang/i18n";
import notification from "../../component/Notification";
import APIUtils from '../../../utils/APIUtils';
import utils from "../../../utils/Utils";


import {Button, Dialog, DialogContent, FormControl, Grid, MenuItem, Select, TextField} from '@material-ui/core';

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";

class InviteForm extends Component {
  constructor() {
    super();


    this.state = {
      pid: undefined,
      email: '',
      invalidEmail: false,
      role: 'VIEWER',
      createFormState: false,
      clickState: false,
    }

    this.emailInput = React.createRef();

    // this.showInviteForm = this.showInviteForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.inviteMember = this.inviteMember.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  /*static someMethod(com) {
    console.log("test: ", com)


  }*/

  componentDidMount() {
  }

  showInviteForm(pid) {
    this.setState({pid: pid, email: '', invalidEmail: false, role: 'VIEWER'
      , createFormState: true})
  }

  inviteMember() {
    if (utils.string.isEmpty(this.state.pid)) {
      notification.warn(i18n.t('userList.pidErrorMessage', {mail: this.state.pid}));
      return;
    }

    if (!utils.string.checkEmail(this.state.email)) {
      notification.warn(i18n.t('userList.emailErrorMessage', {mail: this.state.email}));
      this.emailInput.current.focus();
      return;
    }
    if (utils.string.isEmpty(this.state.role)) {
      notification.warn(i18n.t('project.roleErrorMessage', {mail: this.state.role}));
      return;
    }

    this.setState({clickState: true});
    APIUtils.project.inviteProjectMember({
      pid: this.state.pid,
      params: {
        email: this.state.email,
        role: this.state.role
      }
    }, (data) => {
      if (data.status === 201) {
        if (data.success === false) {
          notification.warn('(' + this.state.email + ') ' + data.message);
        } else {
          notification.info(i18n.t('common.requestSuccessful'));
          this.props.getMembers();
          this.toggle();
        }
      } else {
        notification.error(data.message);
      }
      this.setState({clickState: false});
    });
    return;
  }

  toggle() {
    this.setState({createFormState: !this.state.createFormState});
  }

  handleChange(event) {
    const target = event.target;
    this.setState({[target.name]: target.value}, () => {
      if (target.name === "email") {
        if (utils.string.checkEmail(this.state.email)) {
          this.setState({invalidEmail: false})
        } else {
          this.setState({invalidEmail: true})
        }
      }
    });

  }

  render() {
    return (
      <>
        <Dialog
          classes={{paper: 'modal-content dialog-maru-custom'}}
          fullWidth
          maxWidth="md"
          open={this.state.createFormState}
          onClose={this.toggle}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {i18n.t('memberList.invite')}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <label className="font-weight-bold mb-2">
                  {i18n.t('userList.email', {})}
                </label>
                <TextField
                  className="input-maru-custom"
                  autoFocus={true}
                  inputRef={this.emailInput}
                  fullWidth
                  name="email"
                  variant="standard"
                  size="small"
                  inputProps={{maxLength: 100}}
                  value={this.state.email}
                  onChange={this.handleChange}
                  error={this.state.invalidEmail}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <label className="font-weight-bold mb-2">
                    {i18n.t('project.role', {})}
                  </label>
                  <Select
                    name="role"
                    value={this.state.role}
                    onChange={this.handleChange}
                    displayEmpty>
                    <MenuItem value={utils.BILL}>{i18n.t("memberList.role.bill")}</MenuItem>
                    <MenuItem value={utils.MANAGER}>{i18n.t("memberList.role.manager")}</MenuItem>
                    <MenuItem value={utils.VIEWER}>{i18n.t("memberList.role.viewer")}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <div></div>
            <div>
              <Button className='m-3 button-maru-contained btn-gradient shadow-none'
                      hidden={!this.state.clickState}>
                <span className="btn-wrapper--icon">
                  <FontAwesomeIcon icon={['fas', 'cog',]} spin/>
                </span>
                ...
              </Button>
              <Button onClick={this.inviteMember} className='m-3 button-maru-contained btn-gradient shadow-none'
                      hidden={this.state.clickState}>
                {i18n.t('common.invite', {})}</Button>
              <Button onClick={() => this.setState({
                createFormState: false
              })} className='button-maru-gray-contained'>{i18n.t('common.cancel', {})}</Button>
            </div>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default InviteForm;
