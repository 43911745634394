import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Grid, Card, CardContent, Container, Box } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CountUp from 'react-countup';
import DashboardIcon from '@material-ui/icons/Dashboard';
import i18n from '../../../config/lang/i18n';
import TitBreadcrumbs from '../../component/TitBreadcrumbs';
import APIUtils from '../../../utils/APIUtils';
import notification from "../../component/Notification";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      totalUsers: 0,
      billUsers: 0,
      newbie: 0,
      trialExpiredUser: 0,
      totalProject: 0,
      oneDayNewProject: 0,
      paidProject: 0,
      trialProject: 0,
      pocExpiredProject: 0,
      lastMonthPayedCount: 0,
      lastMonthPayedAmount: 0,
      thisMonthPayCount: 0,
      thisMonthPayAmount: 0,
      wasAgentCount: 0,
      wasTotalCores: 0,
      sysAgentCount: 0,
      sysTotalCores: 0,
      sysTotalSockets: 0,
      totalQna: 0,
      qnaTodo: 0
    };
  }

  componentDidMount() {
    this.getDashboardData();
  }

  getDashboardData() {
    APIUtils.superAdmin.getDashboardInfo({}, (data)=> {
      const dashboardData = data.body;

      if(data.status === 200) {
        this.setState({
          totalUsers: dashboardData.totalUser,
          billUsers: dashboardData.totalBillUser,
          newbie: dashboardData.totalLastDayUser,
          trialExpiredUser: dashboardData.totalCancelUser,
          totalProject: dashboardData.totalProject,
          oneDayNewProject: dashboardData.totalLastDayProject,
          paidProject: dashboardData.totalPaidProject,
          trialProject: dashboardData.totalPocIngProject,
          pocExpiredProject: dashboardData.pocExpiredProject,
          lastMonthIssuedBillCount: dashboardData.totalLastMonthBill,
          lastMonthIssuedBillAmount: dashboardData.totalLastMonthBillAmount,
          lastMonthPayedCount: dashboardData.totalLastMonthPay,
          lastMonthPayedAmount: dashboardData.totalLastMonthPayAmount,
          thisMonthPayCount: dashboardData.totalThisMonthPay,
          thisMonthPayAmount: dashboardData.totalThisMonthPayAmount,
  
          wasAgentCount: dashboardData.wasAgentCount,
          wasTotalCores: dashboardData.wasTotalCores,
          sysAgentCount: dashboardData.sysAgentCount,
          sysTotalCores: dashboardData.sysTotalCores,
          sysTotalSockets: dashboardData.sysTotalSockets,
  
          totalQna: dashboardData.totalQNA,
          qnaTodo: dashboardData.totalWatingQNA
        })
      } else {
        notification.warn(data.message)
      }
    })

  }

  render() {
    return (
      <>
        <TitBreadcrumbs title={i18n.t('adminDashboard.title', '대시보드')} />
        <div className="app-page-title">
          <div>
            <h1 className="d-flex align-items-center">
              <DashboardIcon fontSize="large" className="mr-1" />{' '}
              {i18n.t('adminDashboard.title', '대시보드')}
            </h1>
          </div>
        </div>
        <Card className="card-box">
          <CardContent className="p-4">
            <h6 className="font-weight-bold font-size-lg mb-1 text-black">
              {i18n.t('adminDashboard.usersStatistic', '사용자 통계')}
            </h6>
            <Grid container spacing={6}>
              <Grid item xl={3} md={6}>
                <NavLink to="/admin/userList">
                  <Card className="bg-secondary p-3 text-center rounded">
                    <div className="d-flex align-items-center">
                      <div className="d-40 rounded-circle btn-icon font-size-sm bg-first text-white text-center font-size-lg mr-3">
                        <FontAwesomeIcon icon={['fas', 'users']} />
                      </div>
                      <div className="text-black-50">
                        {i18n.t('adminDashboard.totalUsers', '총 사용자')}
                      </div>
                    </div>
                    <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                      <CountUp
                        start={0}
                        end={this.state.totalUsers}
                        duration={2}
                        separator=","
                        delay={0}
                        prefix=""
                        suffix=""
                      />
                    </div>
                    <div className="text-black-50 text-center opacity-8 pt-3" />
                  </Card>
                </NavLink>
              </Grid>
              <Grid item xl={3} md={6}>
                <NavLink to="/admin/userList">
                  <Card className="bg-secondary p-3 text-center rounded">
                    <div className="d-flex align-items-center">
                      <div className="d-40 rounded-circle btn-icon font-size-sm bg-warning text-white text-center font-size-lg mr-3">
                        <FontAwesomeIcon icon={['fas', 'user-plus']} />
                      </div>
                      <div className="text-black-50">
                        {i18n.t('adminDashboard.newUsers', '새로운 사용자')}
                      </div>
                    </div>
                    <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                      <CountUp
                        start={0}
                        end={this.state.newbie}
                        duration={2}
                        separator=","
                        delay={0}
                        prefix=""
                        suffix=""
                      />
                    </div>
                    <div className="text-black-50 text-center opacity-8 pt-3" />
                  </Card>
                </NavLink>
              </Grid>
              <Grid item xl={3} md={6}>
                <NavLink to="/admin/userList">
                  <Card className="bg-secondary p-3 text-center rounded">
                    <div className="d-flex align-items-center">
                      <div className="d-40 rounded-circle btn-icon font-size-sm bg-success text-white text-center font-size-lg mr-3">
                        <FontAwesomeIcon icon={['fas', 'file-invoice-dollar']} />
                      </div>
                      <div className="text-black-50">
                        {i18n.t('adminDashboard.billUsers', '결제 권한 사용자')}
                      </div>
                    </div>
                    <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                      <CountUp
                        start={0}
                        end={this.state.billUsers}
                        duration={2}
                        separator=","
                        delay={0}
                        prefix=""
                        suffix=""
                      />
                    </div>
                    <div className="text-black-50 text-center opacity-8 pt-3" />
                  </Card>
                </NavLink>
              </Grid>
              <Grid item xl={3} md={6}>
                <NavLink to="/admin/userList">
                  <Card className="bg-secondary p-3 text-center rounded">
                    <div className="d-flex align-items-center">
                      <div className="d-40 rounded-circle btn-icon font-size-sm bg-danger text-white text-center font-size-lg mr-3">
                        <FontAwesomeIcon icon={['fas', 'stopwatch']} />
                      </div>
                      <div className="text-black-50">
                        {i18n.t(
                          'adminDashboard.trialExpiredUser',
                          '무료 사용 만료 사용자'
                        )}
                        ({i18n.t("adminDashboard.inAWeek")})
                      </div>
                    </div>
                    <div className="display-3 text-center line-height-sm text-second d-flex align-items-center pt-3 justify-content-center">
                      <CountUp
                        start={0}
                        end={this.state.trialExpiredUser}
                        duration={2}
                        separator=","
                        delay={0}
                        prefix=""
                        suffix=""
                      />
                    </div>
                    <div className="text-black-50 text-center opacity-8 pt-3" />
                  </Card>
                </NavLink>
              </Grid>
            </Grid>
            <Box className="d-flex py-4">
              <Container className="px-0">
                <h6 className="font-weight-bold font-size-lg mb-1 text-black">
                  {i18n.t('adminDashboard.projectsStatistic', '프로젝트 통계')}
                </h6>
                <Grid container className="bg-secondary text-center rounded card text-black border p-1">
                  <Grid container item spacing={2}>
                    <Grid item sm={6} className="p-4">
                      <div className="py-2 d-flex align-items-center justify-content-center">
                        <FontAwesomeIcon
                          icon={['fas', 'cubes']} size="3x"
                          className="mr-3 text-dark"
                        />
                        <div>
                          <span className="d-block opacity-7 ">
                            {i18n.t(
                              'adminDashboard.totalProjects',
                              '총 프로젝트'
                            )}
                          </span>
                          <span className="font-weight-bold font-size-xxl">
                            <CountUp
                              start={0}
                              end={this.state.totalProject}
                              duration={2}
                              separator=","
                              prefix=""
                              suffix=""
                            />
                          </span>
                        </div>
                      </div>
                    </Grid>
                    <Grid item sm={6} className="p-4">
                      <div className="py-2 d-flex align-items-center justify-content-center">
                        <FontAwesomeIcon
                          icon={['fas', 'cube']} size="3x"
                          className="mr-3 text-dark"
                        />
                        <div>
                          <span className="d-block opacity-7">
                            {i18n.t(
                              'adminDashboard.newProjects',
                              '신규 프로젝트'
                            )}
                            ({i18n.t("adminDashboard.lastDay")})
                          </span>
                          <span className="font-weight-bold font-size-xxl">
                            <CountUp
                              start={0}
                              end={this.state.oneDayNewProject}
                              duration={2}
                              separator=","
                              prefix=""
                              suffix=""
                            />
                          </span>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <div className="divider" />
                  <Grid container spacing={0}>
                    <Grid item md={4} className="py-4">
                      <div className="divider-v divider-v-lg" />
                      <div className="text-center">
                        <div>
                          <FontAwesomeIcon size="lg"
                            icon={['fas', 'credit-card']}
                            className="text-dark opacity-3"
                          />
                        </div>
                        <div className="mt-2 line-height-sm">
                          <b className="font-size-lg">
                            <CountUp
                              start={0}
                              end={this.state.paidProject}
                              duration={2}
                              separator=","
                              prefix=""
                              suffix=""
                            />
                          </b>
                          <span className="text-black-50 d-block">
                            {i18n.t(
                              'adminDashboard.payedProjects',
                              '결제완료'
                            )}
                          </span>
                        </div>
                      </div>
                    </Grid>
                    <Grid item md={4} className="py-4">
                      <div className="divider-v divider-v-lg" />
                      <NavLink to="/admin/projectList">
                        <div className="text-center">
                          <div>
                            <FontAwesomeIcon size="lg"
                              icon={['fas', 'hourglass-half']}
                              className="text-dark opacity-3"
                            />
                          </div>
                          <div className="mt-2 line-height-sm">
                            <b className="font-size-lg">
                              <CountUp
                                start={0}
                                end={this.state.trialProject}
                                duration={2}
                                separator=","
                                prefix=""
                                suffix=""
                              />
                            </b>
                            <span className="text-black-50 d-block">
                              {i18n.t(
                                'adminDashboard.trailProjects',
                                '무료기간'
                              )}
                            </span>
                          </div>
                        </div>
                      </NavLink>
                    </Grid>
                    <Grid item md={4} className="py-4">
                      <NavLink to="/admin/projectList">
                        <div className="text-center">
                          <div>
                            <FontAwesomeIcon size="lg"
                              icon={['fas', 'stopwatch']}
                              className="text-dark opacity-3"
                            />
                          </div>
                          <div className="mt-2 line-height-sm">
                            <b className="font-size-lg">
                              <CountUp
                                start={0}
                                end={this.state.trialExpiredUser}
                                duration={2}
                                separator=","
                                prefix=""
                                suffix=""
                              />
                            </b>
                            <span className="text-black-50 d-block">
                              {i18n.t(
                                'adminDashboard.trialExpiredProjects',
                                '무료기간만료'
                              )}
                            </span>
                          </div>
                        </div>
                      </NavLink>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
              <Box className="m-4" />
              <Container className="px-0">
                <h6 className="font-weight-bold font-size-lg mb-1 text-black">
                  {i18n.t('adminDashboard.paymentsStatistic', '결제내역')}
                </h6>
                <Grid container className="bg-secondary text-center rounded card text-black border">
                  <div>{i18n.t('adminDashboard.lastMonth', '지난달')}</div>
                  <Grid container item spacing={0}>
                    <Grid item sm={6}>
                      <NavLink to="/admin/paymentsManagement">
                        <div className="text-center">
                          <div>
                            <FontAwesomeIcon
                              icon={['far', 'copy']}
                              className="font-size-xxl text-success"
                            />
                          </div>
                          <div className="mt-2 line-height-sm">
                            <b className="font-size-lg">
                              <CountUp
                                start={0}
                                end={this.state.lastMonthPayedCount}
                                duration={2}
                                separator=","
                                prefix=""
                                suffix={i18n.t("adminDashboard.count")}
                              />
                            </b>
                            <span className="text-black-50 d-block">
                              {i18n.t(
                                'adminDashboard.paymentsCount',
                                '결제건수'
                              )}
                            </span>
                          </div>
                        </div>
                      </NavLink>
                    </Grid>
                    <Grid item sm={6}>
                      <NavLink to="/admin/paymentsManagement">
                        <div className="text-center">
                          <div>
                            <FontAwesomeIcon
                              icon={['fas', 'file-invoice-dollar']}
                              className="font-size-xxl text-info"
                            />
                          </div>
                          <div className="mt-2 line-height-sm">
                            <b className="font-size-lg">
                              <CountUp
                                start={0}
                                end={this.state.lastMonthPayedAmount}
                                duration={2}
                                separator=","
                                prefix=""
                                suffix={i18n.t("adminDashboard.won")}
                              />
                            </b>
                            <span className="text-black-50 d-block">
                              {i18n.t('adminDashboard.paymentsAmount', '금액')}
                            </span>
                          </div>
                        </div>
                      </NavLink>
                    </Grid>
                  </Grid>
                  <div className="divider" />
                  <div>{i18n.t('adminDashboard.thisMonth', '이번달')}</div>
                  <Grid container item spacing={0} className="p-0">
                    <Grid item sm={6}>
                      <div className="text-center">
                        <div>
                          <FontAwesomeIcon
                            icon={['far', 'copy']}
                            className="font-size-xxl text-success"
                          />
                        </div>
                        <div className="mt-2 line-height-sm">
                          <b className="font-size-lg">
                            <CountUp
                              start={0}
                              end={this.state.thisMonthPayCount}
                              duration={2}
                              separator=","
                              prefix=""
                              suffix={i18n.t("adminDashboard.count")}
                            />
                          </b>
                          <span className="text-black-50 d-block">
                            {i18n.t('adminDashboard.paymentsCount', '결제건수')}
                          </span>
                        </div>
                      </div>
                    </Grid>
                    <Grid item sm={6}>
                      <div className="text-center">
                        <div>
                          <FontAwesomeIcon
                            icon={['fas', 'file-invoice-dollar']}
                            className="font-size-xxl text-info"
                          />
                        </div>
                        <div className="mt-2 line-height-sm">
                          <b className="font-size-lg">
                            <CountUp
                              start={0}
                              end={this.state.thisMonthPayAmount}
                              duration={2}
                              separator=","
                              prefix=""
                              suffix={i18n.t("adminDashboard.won")}
                            />
                          </b>
                          <span className="text-black-50 d-block">
                            {i18n.t('adminDashboard.paymentsAmount', '금액')}
                          </span>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </Box>
            <Box className="d-flex py-4">
              <Container className="px-0">
                <h6 className="font-weight-bold font-size-lg mb-1 text-black">
                  {i18n.t('agent.agentStatistic', '에이전트 통계')}
                </h6>
                <Grid container spacing={6}>
                  <Grid item sm={6}>
                    <NavLink
                      to="#"
                      className="card bg-white text-black card-box">
                      <CardContent>
                        <div className="align-box-row align-items-start">
                          <div className="mr-3">
                            <div className="bg-danger text-center text-white font-size-xl d-50 rounded-circle btn-icon">
                              <FontAwesomeIcon icon={['fas', 'laptop-code']} />
                            </div>
                          </div>
                          <div className="font-weight-bold">
                            <small className="text-black-50 d-block mb-1 text-uppercase">
                              WAS (Count / Core)
                            </small>
                            <span className="font-size-xxl mt-2">
                              <CountUp
                                start={0}
                                end={this.state.wasAgentCount}
                                duration={2}
                                separator=","
                                prefix=""
                                suffix=""
                              />
                              /
                              <CountUp
                                start={0}
                                end={this.state.wasTotalCores}
                                duration={2}
                                separator=","
                                prefix=""
                                suffix=""
                              />
                            </span>
                          </div>
                          <div className="ml-auto card-hover-indicator align-self-center">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xl"
                            />
                          </div>
                        </div>
                      </CardContent>
                    </NavLink>
                  </Grid>
                  <Grid item sm={6}>
                    <NavLink
                      to="#"
                      className="card bg-white text-black card-box">
                      <CardContent>
                        <div className="align-box-row align-items-start">
                          <div className="mr-3">
                            <div className="bg-danger text-center text-white font-size-xl d-50 rounded-circle btn-icon">
                              <FontAwesomeIcon icon={['fas', 'laptop']} />
                            </div>
                          </div>
                          <div className="font-weight-bold">
                            <small className="text-black-50 d-block mb-1 text-uppercase">
                              SYS (Count / Core / Socket)
                            </small>
                            <span className="font-size-xxl mt-2">
                              <CountUp
                                start={0}
                                end={this.state.sysAgentCount}
                                duration={2}
                                separator=","
                                prefix=""
                                suffix=""
                              />
                              /
                              <CountUp
                                start={0}
                                end={this.state.sysTotalCores}
                                duration={2}
                                separator=","
                                prefix=""
                                suffix=""
                              />
                              /
                              <CountUp
                                start={0}
                                end={this.state.sysTotalSockets}
                                duration={2}
                                separator=","
                                prefix=""
                                suffix=""
                              />
                            </span>
                          </div>
                          <div className="ml-auto card-hover-indicator align-self-center">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xl"
                            />
                          </div>
                        </div>
                      </CardContent>
                    </NavLink>
                  </Grid>
                </Grid>
              </Container>
              <Box className="m-4" />
              <Container className="px-0">
                <h6 className="font-weight-bold font-size-lg mb-1 text-black">
                  {i18n.t('adminDashboard.qnaStatistic', '1:1 문의 통계')}
                </h6>
                <Grid container spacing={6}>
                  <Grid item sm={6}>
                    <NavLink
                      to="/admin/qnaList"
                      className="card bg-white text-black card-box">
                      <CardContent>
                        <div className="align-box-row align-items-start">
                          <div className="mr-3">
                            <div className="bg-happy-fisher text-center text-white font-size-xl d-50 rounded-circle btn-icon">
                              <FontAwesomeIcon icon={['fas', 'question']} />
                            </div>
                          </div>
                          <div className="font-weight-bold">
                            <small className="text-black-50 d-block mb-1 text-uppercase">
                              {i18n.t('adminDashboard.totalQna', '질의 수')}
                            </small>
                            <span className="font-size-xxl mt-2">
                              <CountUp
                                start={0}
                                end={this.state.totalQna}
                                duration={2}
                                separator=","
                                prefix=""
                                suffix=""
                              />
                            </span>
                          </div>
                          <div className="ml-auto card-hover-indicator align-self-center">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xl"
                            />
                          </div>
                        </div>
                      </CardContent>
                    </NavLink>
                  </Grid>
                  <Grid item sm={6}>
                    <NavLink
                      to="/admin/qnaList"
                      className="card bg-white text-black card-box">
                      <CardContent>
                        <div className="align-box-row align-items-start">
                          <div className="mr-3">
                            <div className="bg-happy-fisher text-center text-white font-size-xl d-50 rounded-circle btn-icon">
                              <FontAwesomeIcon icon={['far', 'comment-dots']} />
                            </div>
                          </div>
                          <div className="font-weight-bold">
                            <small className="text-black-50 d-block mb-1 text-uppercase">
                              {i18n.t(
                                'adminDashboard.noCommentQna',
                                '응답 안된 질의 수'
                              )}
                            </small>
                            <span className="font-size-xxl mt-2">
                              <CountUp
                                start={0}
                                end={this.state.qnaTodo}
                                duration={2}
                                separator=","
                                prefix=""
                                suffix=""
                              />
                            </span>
                          </div>
                          <div className="ml-auto card-hover-indicator align-self-center">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xl"
                            />
                          </div>
                        </div>
                      </CardContent>
                    </NavLink>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </CardContent>
        </Card>
      </>
    );
  }
}

export default Dashboard;
