import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import Sidebar from '../layout-component/Sidebar';
import Header from '../layout-component/Header';
import Footer from '../layout-component/Footer';
import { ToastContainer  } from "react-toastify";
import LoadingBody from "../../openmaru/component/LoadingBody";
import {setBodyState} from "../../reducers/OpenmaruReducer";


const LeftSidebar = (props) => {
  const {
    bodyState,
    setBodyState,

    children,
    sidebarToggle,
    sidebarToggleMobile,
    sidebarFixed,
    headerFixed,
    headerSearchHover,
    headerDrawerToggle,
    footerFixed,
    contentBackground
  } = props;

  const setupBodyState = (bodyState) => {
    setBodyState(bodyState);
  };

  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth
  })
  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }

    window.addEventListener('resize', handleResize)
  }, [])

  return (
    <>
      <div
        className={clsx('app-wrapper', contentBackground, {
          'header-drawer-open': headerDrawerToggle,
          'app-sidebar-collapsed': sidebarToggle,
          'app-sidebar-mobile-open': sidebarToggleMobile,
          'app-sidebar-fixed': sidebarFixed,
          'app-header-fixed': headerFixed,
          'app-footer-fixed': footerFixed,
          'search-wrapper-open': headerSearchHover
        })}>
        <div>
          <Sidebar />
        </div>
        <div className="app-main">
          <Header />
          <div className="app-content">
            <div className="app-content--inner"
                 ref={el => {
                   if (!el) return;

                   let bodyTop = el.getBoundingClientRect().top;
                   let bodyLeft = el.getBoundingClientRect().left;
                   let bodyWidth = el.getBoundingClientRect().width;
                   let bodyHeight = el.getBoundingClientRect().height;

                   if (bodyState.top !== bodyTop || bodyState.left !== bodyLeft || bodyState.width !== bodyWidth || bodyState.height !== bodyHeight) {
                     setupBodyState({top: bodyTop, left: bodyLeft, width: bodyWidth, height: bodyHeight})
                   }
                 }}>
              <div className="app-content--inner__wrapper">{children}</div>
              <LoadingBody />
            </div>
            <Footer />
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

LeftSidebar.propTypes = {
  children: PropTypes.node
};

const mapDispatchToProps = (dispatch) => ({
  setBodyState: (bodyState) => dispatch(setBodyState(bodyState))
});

const mapStateToProps = (state) => ({
  bodyState: state.OpenmaruReducer.bodyState,

  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarFixed: state.ThemeOptions.sidebarFixed,
  headerFixed: state.ThemeOptions.headerFixed,
  headerSearchHover: state.ThemeOptions.headerSearchHover,
  headerDrawerToggle: state.ThemeOptions.headerDrawerToggle,

  footerFixed: state.ThemeOptions.footerFixed,

  contentBackground: state.ThemeOptions.contentBackground
});

export default connect(mapStateToProps, mapDispatchToProps)(LeftSidebar);