import React, { Component } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { Button, Container, Grid, TextField, InputAdornment,Checkbox } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToastContainer } from 'react-toastify';
import notification from 'openmaru/component/Notification';

import APIUtils from 'utils/APIUtils';
import utils from '../../utils/Utils';
import i18n from '../../config/lang/i18n';
import Footer from '../layout-component/Footer';
import '../../assets/login.scss';
import queryString from 'query-string';
import ChannelService from 'utils/ChannelTalk';
import Jasypt from "jasypt";
import OtpReg from "openmaru/page/OtpReg";
import OtpInput from "openmaru/page/OtpInput";

class Agree extends Component {
  constructor(props) {
    super(props);

    this.agreeCheckAndNext = this.agreeCheckAndNext.bind(this);
    this.handleCheck = this.handleCheck.bind(this);

    this.state = {
      checkItem1: false,
      checkItem2: false,
      checkItem3: false,
      checkItem4: false
    };
  }

  componentDidMount() {}

  handleCheck(event) {
    const target = event.target;
    this.setState({[target.name]: target.checked});
  }

  agreeCheckAndNext() {
    if(this.state.checkItem1 && this.state.checkItem2 && this.state.checkItem3 && this.state.checkItem4){
      this.props.history.push('/Signup');
    }else{
      notification.error("필수항목을 체크해주세요.");
    }
  }

  render() {

    return (
      <>
        <div className="app-wrapper bg-white min-vh-100">
          <div className="app-main min-vh-100">
            <div className="app-content p-0 bg-maru-gray">
              <div className="app-content--inner d-flex align-items-center">
                <div className="flex-grow-1 w-100 d-flex align-items-center">
                  <div className="bg-composed-wrapper--content py-5">
                    <Container maxWidth='md'>
                      <Grid container spacing={10} className="maru-box-shadow">
                        <Grid  item md={12} className="d-flex flex-column justify-content-between align-items-center bg-maru-grady">
                          <div className="d-flex justify-conten-start align-items-center">
                            <div className="logo-style mt-3"></div>
                            <div className="text-white ml-3"><h1>{i18n.t('marketing.title', '마케팅 활용 및 정보 수신 동의')} </h1></div>
                          </div>
                          <div className="MuiPaper-root MuiCard-root card-box MuiPaper-elevation1 MuiPaper-rounded mb-3 bg-white">
                              <p>{i18n.t('marketing.content1', '귀하는 개인(신용)정보의 선택적인 수집∙이용, 제공에 대한 동의를 거부할 수 있습니다. 다만, 동의하지 않을 경우 관련 편의제공(이벤트 안내, 공지사항)안내 등 이용 목적에 따른 혜택에 제한이 있을 수 있습니다.')}
                              <br></br>{i18n.t('marketing.content2', '이외 계약과 관련된 불이익은 없습니다. 동의한 경우에도 귀하는 동의를 철회하거나 마케팅 목적으로 귀하에게 연락하는 것을 중지하도록 요청할 수 있습니다.')}
                              <br></br>
                              {i18n.t('marketing.content3-1', '자세한 내용은')} <b><a href="https://www.gov.openmaru.io/privacy-policy">{i18n.t('marketing.content3-2', '개인정보 처리방침')}</a></b> {i18n.t('marketing.content3-3', '에서 확인하시길 바랍니다. 감사합니다.')}
                              </p>
                            </div>
                          <div className="MuiPaper-root MuiCard-root card-box MuiPaper-elevation1 MuiPaper-rounded mb-3 bg-white">
                          <table>
                              <tbody>
                              <tr style={{borderBottom:'1px solid lightgrey'}}>
                              <td><b>{i18n.t('marketing.tableTitle1', '마케팅 및 광고에의 활용')}</b></td>
                              <td><b>{i18n.t('marketing.tableTitle2', '수집·이용하는 개인정보의 항목')}</b></td>
                              <td><b>{i18n.t('marketing.tableTitle3', '처리 및 보유기간')}</b></td>
                              </tr>
                              <tr>
                                <td>
                                <p>{i18n.t('marketing.tableContent1', '신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공 , 인구통계학적 특성에 따른 서비스 제공 및 광고 게재 , 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다')}</p></td>
                                <td>{i18n.t('marketing.tableContent2', '이메일 주소, 휴대전화 번호, 로그인 ID, 이름')}<p></p>
                                <p>{i18n.t('marketing.tableContent3', '(선택) 회사명, 부서명, 마케팅 채널 (이메일) 수신 동의여부')}</p></td>
                                <td>{i18n.t('marketing.tableContent4', '1. 원칙: 회원탈퇴 시까지')}<p></p>
                                <p>{i18n.t('marketing.tableContent5', '2. 단, 회원의 삭제요청이 있을 경우 즉시 삭제')}</p></td>
                              </tr>
                              </tbody>
                              </table>
                            </div>
                          
                        </Grid>
                      </Grid>
                    </Container>
                  </div>
                </div>
              </div>
              {ChannelService.showButton()}
            </div>
            <Footer />
            <ToastContainer />
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Agree);
