import React, {Component} from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Select, MenuItem, Grid } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import { DateTime } from 'luxon';
import DateFnsUtils from '@date-io/date-fns';
import koLocale from 'date-fns/locale/ko';

import i18n from '../../config/lang/i18n';

class DateTimeDialog extends Component {
  constructor() {
    super();
    
    this.state = {
      inputText: '',
      inputDatetime: new Date(),
      selectTime: new Date().getHours(),
      timeRange: new Array(24),
      open: false,
      okCallback: _=>{}
    }

    this.inputElement = React.createRef();
    this.handleInputText = this.handleInputText.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }

  handleInputText(event) {
    this.setState({inputText: event.target.value});
  }

  open(callback, yyyyMMddHHmm) {
    this.setState({
      open: !this.state.open,
      okCallback: callback? callback : _ => {}
    });
  }
  close() {
    this.setState({open: false});
  }

  render() {
    const {title, okButtonName, cancelButtonName, contents = []} = this.props;
    const timeRange = [];

    for(let time = 0; time < 24; time++) {
      timeRange.push(<MenuItem key={time} value={time}>{time}</MenuItem>);
    }

    return (
      <Dialog open={this.state.open} onClose={this.close}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <ul>
            {contents.map((content, index) => {
              return <li key={index}>{content}</li>
            })}
          </ul>
          <label className="font-weight-bold">{i18n.t("project.subscriptionFinishedDateTime")}</label>
          <Grid container>
            <Grid item >
              <label className="mr-2 d-inline-block line-height-sm py-2">{i18n.t("project.subscriptionFinishedDay")}</label>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={koLocale}>
                <DatePicker
                  views={["year", "month", "date"]}
                  value={this.state.inputDatetime}
                  minDate={new Date()}
                  onChange={(date) => {
                    this.setState({inputDatetime: date});
                  }}
                  format="yyyy-MM-dd"
                  variant="inline"
                  autoOk={true}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item>
              <label className="mr-2">{i18n.t("project.subscriptionFinishedTime")}</label>
              <Select
                value={this.state.selectTime}
                onChange={(event)=> {                
                  this.setState({
                    inputDatetime: DateTime.fromJSDate(this.state.inputDatetime).set({hour: event.target.value}).toJSDate(),
                    selectTime: event.target.value
                  })
                }}
              >
                {timeRange}
              </Select>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button size="small" className="button-maru-contained" onClick={() => {
            this.state.okCallback(this.state.inputDatetime);
          }}>{okButtonName}</Button>
          <Button size="small" className="button-maru-second-contained" onClick={this.close}>{cancelButtonName}</Button>
        </DialogActions>
      </Dialog>
    )
  }
}
export default DateTimeDialog;
