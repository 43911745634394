import React, {Component} from 'react';
import APIsLists from 'utils/APIUtils';
import { Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, Button, Grid, TextField } from '@material-ui/core';
import i18n from '../../../config/lang/i18n';
import utils from "../../../utils/Utils";
import MUITable from '../../../openmaru/component/MUITable';

class ConnectedProjects extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      userId: "",
      data: [],
    }

    this.handleToggle = this.handleToggle.bind(this);
    this.getUserProjects = this.getUserProjects.bind(this);
  }

  handleToggle = (userData) => {
    let stateObject = {};
    if(userData) {
      stateObject = {
        open: !this.state.open,
        userId: userData.userId,
        email: userData.email,
        name: userData.name,
        company: userData.company
      }
    } else {
      stateObject = {
        open: !this.state.open
      }
    }
    this.setState(stateObject);
  }

  getUserProjects = () => {
    APIsLists.superAdmin.getUserProjects({userId: this.state.userId}, (response) => {
      this.setState({data: response.body})
    })
  }

  render() {
    const columns = [
      { label: i18n.t('project.id'), name: 'pid',
        options: {
          setCellProps: (_ => ({style: {minWidth: "5vw", maxWidth: "5vw"}})),
          customBodyRender: (value, tableMeta) => (
            <div className={'text-center' + (this.state.data[tableMeta.rowIndex].isCanceled?' textCancelLine':'')}>{value}</div>
          )
        }
      },
      { label: i18n.t('project.subDomain'), name: 'subDomain',
        options: {
          setCellProps: (_ => ({style: {minWidth: "5vw", maxWidth: "5vw"}})),
          customBodyRender: (value, tableMeta) => (
            <div className="text-center">{this.state.data[tableMeta.rowIndex].isCanceled?'':value}</div>
          )
        }
      },
      { label: i18n.t('project.projectName'), name: 'name',
        options: {
          setCellProps: (_ => ({style: {minWidth: "10vw", maxWidth: "10vw"}})),
          customBodyRender: (value) => (
            <div className="text-center">{utils.string.unescape(value)}</div>
          )
        }
      },
      { label: i18n.t('project.company'), name: 'company',
        options: {
          setCellProps: (_ => ({style: {minWidth: "7vw", maxWidth: "7vw"}})),
          customBodyRender: (value) => (
            <div className="text-center">{utils.string.unescape(value)}</div>
          )
        }
      },
      { label: i18n.t('project.team'), name: 'team',
        options: {
          setCellProps: (_ => ({style: {minWidth: "7vw", maxWidth: "7vw"}})),
          customBodyRender: (value) => (
            <div className="text-center">{utils.string.unescape(value)}</div>
          )
        }
      },
      { label: i18n.t('project.createdId'), name: 'createdId',
        options: {
          setCellProps: (_ => ({style: {minWidth: "5vw", maxWidth: "5vw"}})),
          customBodyRender: (value) => {
            return <div className="text-center">{value}</div>;
          }
        }
      },
     
      { label: i18n.t('common.status'), name: 'type',
        options: {
          sort: true,
          filter: false,
          setCellProps: (_ => ({style: {minWidth: "4vw", maxWidth: "4vw"}})),
          customBodyRenderLite: (dataIndex, rowIndex) => {
            let rowData = this.state.data[rowIndex];

            let type = rowData?rowData.type:'';
            let status = rowData?rowData.status:'';

            let statusNm = '';
            let ribbon = '';
            if (type === '1001') {
              statusNm = i18n.t('project.poc', '무료사용');
              ribbon = 'primary';
            } else if (type === '1002') {
              statusNm = i18n.t('project.paid', '구독중');
              ribbon = 'info';
            } else if (type === '1003') {
              statusNm = i18n.t('project.licensed', '계약됨');
              ribbon = 'first';
            }

            if (status === '1002') {
              statusNm += '(' + i18n.t('project.status.' + status, '만료') +')';
              ribbon = 'danger';
            } else if (status === '1003') {
              statusNm += '(' + i18n.t('project.status.' + status, '연체') +')';
              ribbon = 'danger';
            } else if (status === '1004') {
              statusNm += '(' + i18n.t('project.status.' + status, '중지') +')';
              ribbon = 'danger';
            }

            return (<div className="d-flex justify-content-between">
              <div className={'badge badge-' + ribbon} style={{textAlign: 'center', margin: '0 auto'}}>{statusNm}</div>
            </div>)
          }
        }
      },
      { label: i18n.t('project.apmVersion'), name: 'apmVersion',
        options: {
          setCellProps: (_ => ({style: {minWidth: "6vw", maxWidth: "6vw"}})),
          customBodyRender: (value) => {
            let releaseVersion = '';
            if (value) {
              let verArr = value.split(" ");
              if (verArr.length === 3) {
                releaseVersion = verArr[1];
              }
            }
            return <Tooltip arrow title={value?value:''} placement="left"><div className="text-center">{releaseVersion}</div></Tooltip>;
          }
        }
      },
      { label: i18n.t('project.service'), name: 'serviceRegion',
        options: {
          sort: false,
          filter: false,
          setCellProps: (_ => ({style: {minWidth: "4vw", maxWidth: "4vw"}})),
          customBodyRenderLite: (dataIndex, rowIndex) => {
            return (this.state.data[rowIndex] ? (
              <div className="text-center">
                {this.state.data[rowIndex].serviceProvider}-
                {this.state.data[rowIndex].serviceRegion}
              </div>
            ) : '')
          }
        }
      },
      { label: i18n.t('common.timeZone'), name: 'timeZone',
        options: {
          setCellProps: (_ => ({style: {minWidth: "8vw", maxWidth: "8vw"}})),
          customBodyRender: (value) => (
            <div className="text-center">{value}</div>
          )
        }
      },
      { label: i18n.t('project.role'), name: 'role',
        options: {
          sort: false,
          filter: false,
          setCellProps: (_ => ({style: {minWidth: "4vw", maxWidth: "4vw"}})),
          customBodyRenderLite: (dataIndex, rowIndex) => {
            return (this.state.data[rowIndex] ? (
              <div className="text-center">
                {this.state.data[rowIndex].projectMember.roleName}
              </div>
            ) : '')
          }
        }
      }
    ];

    const options = {
      search: false,
      print: false,
      viewColumns: false,
      draggableColumns: {
        enabled: true
      },
      download: false,
      refresh: false,
      pagination: false,
      onTableInit: (e) => {
        this.getUserProjects();
      }
    };


    return (
      <>
        <Dialog
          classes={{paper: 'dialog-maru-custom'}}
          maxWidth="xl"
          open={this.state.open}
          onClose={this.handleToggle}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            사용자 프로젝트
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item md={3} sm={6}>
                <label className="font-weight-bold mb-2">
                  {i18n.t('userList.userId', {})}
                </label>
                <TextField
                  className="input-maru-custom"
                  fullWidth
                  disabled
                  name="userId"
                  variant="standard"
                  size="small"
                  value={this.state.userId}
                />
              </Grid>
              <Grid item md={3} sm={6}>
                <label className="font-weight-bold mb-2">
                  {i18n.t('userList.email', {})}
                </label>
                <TextField
                  className="input-maru-custom"
                  fullWidth
                  disabled
                  name="email"
                  variant="standard"
                  size="small"
                  inputProps={{ maxLength: 100 }}
                  value={utils.string.unescape(this.state.email)}
                />
              </Grid>
              <Grid item md={3} sm={6}>
                <label className="font-weight-bold mb-2">
                  {i18n.t('userList.name', {})}
                </label>
                <TextField
                  className="input-maru-custom"
                  disabled
                  fullWidth
                  name="name"
                  variant="standard"
                  size="small"
                  value={this.state.name}
                />
              </Grid>
              <Grid item md={3} sm={6}>
                <label className="font-weight-bold mb-2">
                  {i18n.t('userList.company', {})}
                </label>
                <TextField
                  className="input-maru-custom"
                  disabled
                  fullWidth
                  name="name"
                  variant="standard"
                  size="small"
                  value={this.state.company}
                />
              </Grid>
            </Grid>
            <MUITable columns={columns} data={this.state.data} options={options} loading={this.state.isLoading}/>
          </DialogContent>
          <DialogActions>
            <div></div>
              <div>
                <Button className="button-maru-gray-contained" onClick={this.handleToggle}>
                  {i18n.t('common.close')}
                </Button>
              </div>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default ConnectedProjects;