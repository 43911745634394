export const SET_USER_PROJECT_LIST       = 'OPENNARU_OPTIONS/SET_USER_PROJECT_LIST';
export const SET_USER_SELECTED_PROJECT   = 'OPENNARU_OPTIONS/SET_USER_SELECTED_PROJECT';
export const SHOW_BODY_LOADING           = 'OPENNARU_OPTIONS/SHOW_LOADING';
export const SET_BODY_STATE              = 'OPENNARU_OPTIONS/SET_BODY_STATE';
export const SET_USER_INVITE_LIST        = 'OPENNARU_OPTIONS/SET_USER_INVITE_LIST';
export const SET_USER_NOTI_LIST          = 'OPENNARU_OPTIONS/SET_USER_NOTI_LIST';

export const setUserProjects = (userProjects) => ({
  type: SET_USER_PROJECT_LIST,
  userProjects
});
export const setUserSelectedProject = (userSelectedProject) => ({
  type: SET_USER_SELECTED_PROJECT,
  userSelectedProject
});
export const setShowBodyLoading = (showBodyLoading) => ({
  type: SHOW_BODY_LOADING,
  showBodyLoading: showBodyLoading < 0? 0: showBodyLoading
});
export const setBodyState = (bodyState) => ({
  type: SET_BODY_STATE,
  bodyState
});

export const setUserInvites = (userInvites) => ({
  type: SET_USER_INVITE_LIST,
  userInvites
})

export const setUserNotis = (userNotis) => ({
  type: SET_USER_NOTI_LIST,
  userNotis
})

export default function reducer(
  state = {
    // Sidebar
    userProjects: [],
    userSelectedProject: {pid: ' ', name: '', subDomain: ''},
    showBodyLoading: 0,
    bodyState: {top: 74, left: 320, width: 1600, height: 831},
    userInvites: [],
    userNotis: []
  },
  action
) {
  switch (action.type) {
    case SET_USER_PROJECT_LIST:
      return {
        ...state,
        userProjects: action.userProjects
      };
    case SET_USER_SELECTED_PROJECT:
      return {
        ...state,
        userSelectedProject: action.userSelectedProject
      };
    case SHOW_BODY_LOADING:
      return {
        ...state,
        showBodyLoading: action.showBodyLoading
      };
    case SET_BODY_STATE:
      return {
        ...state,
        bodyState: action.bodyState
      };
    case SET_USER_INVITE_LIST:
      return {
        ...state,
        userInvites: action.userInvites
      }
    case SET_USER_NOTI_LIST:
      return {
        ...state,
        userNotis: action.userNotis
      }
    default:
      break;
  }
  return state;
}
