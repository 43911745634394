import React from 'react';
import i18n from '../config/lang/i18n';
import TalkLogo from '../assets/images/logos/openmaru_talk_icon.png';
import {Tooltip, Hidden, Button} from '@material-ui/core';

class ChannelService {
  constructor() {
    this.loadScript();
  }

  loadScript() {
    var w = window;
    if (w.ChannelIO) {
      return (window.console.error || window.console.log || function(){})('ChannelIO script included twice.');
    }
    var ch = function() {
      ch.c(arguments);
    };
    ch.q = [];
    ch.c = function(args) {
      ch.q.push(args);
    };
    w.ChannelIO = ch;
    function l() {
      if (w.ChannelIOInitialized) {
        return;
      }
      w.ChannelIOInitialized = true;
      var s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
      s.charset = 'UTF-8';
      var x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    }
    if (document.readyState === 'complete') {
      l();
    } else if (window.attachEvent) {
      window.attachEvent('onload', l);
    } else {
      window.addEventListener('DOMContentLoaded', l, false);
      window.addEventListener('load', l, false);
    }
  }

  boot(settings) {
    window.ChannelIO('boot', settings);
  }

  shutdown() {
    window.ChannelIO('shutdown');
  }

  hide() {
    window.ChannelIO('hideMessenger');
  }

  openChat() {
    window.ChannelIO('openChat', undefined, 'something'); 
  }

  showMessenger() {
    window.ChannelIO('showMessenger');
  }

  showButton() {
    if (window.location.href.indexOf("passwordHelp") !== -1) {
      return(<></>);
    }else{
      return (
        <>
          <Hidden smDown={true}>
            <Tooltip title={i18n.t("common.openChat")} placement="left-start">
              <div style={{
              cursor: 'pointer',
              backgroundColor: '#ed1b23',
              borderRadius: '50%',
              padding: '10px',
              display: 'inline', 
              position: 'fixed',
              right: 50,
              bottom: 100,
              zIndex:300}} onClick={this.showMessenger}>
                <img src={TalkLogo} width="50" style={{zIndex:300}} />
              </div>
            </Tooltip>
          </Hidden>
          <Hidden mdUp={true}>
            <Tooltip title={i18n.t("common.openChat")} placement="left-start">
              <Button className='button-maru-contained mx-4 mb-3' onClick={this.showMessenger}>
                <img src={TalkLogo} width="30" className="mr-1" />
                {i18n.t("common.openChat")}
              </Button>
            </Tooltip>
          </Hidden>
        </>
      )
    }
  }

  hideButton() {
    window.ChannelIO('hideChannelButton'); 
  }
}

export default new ChannelService();