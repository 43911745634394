import React, {Component} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button} from '@material-ui/core';
import CTextField from './CTextField';


class InputDialog extends Component {
  constructor() {
    super();
    
    this.state = {
      inputText: '',
      open: false,
      okCallback: _=>{}
    }

    this.inputElement = React.createRef();
    this.handleInputText = this.handleInputText.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }

  handleInputText(event) {
    this.setState({inputText: event.target.value});
  }

  open(callback) {
    this.setState({
      open: !this.state.open,
      okCallback: callback? callback : _ => {}
    });
  }
  close() {
    this.setState({open: false});
  }

  render() {
    const {title, placeholder, inputProps, okButtonName, cancelButtonName, getInput,
      inputType = 'text', contents = []} = this.props;

    return (
      <Dialog open={this.state.open} onClose={this.close}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <ul>
              {contents.map((content, index) => {
                return <li key={index}>{content}</li>
              })}
            </ul>
          </DialogContentText>
          
          {getInput && <DialogContentText>
            <CTextField
              ref={this.inputElement}
              className="text-dark input-maru-custom box-shadow-none bg-white"
              variant="outlined"
              size="small"
              autoFocus
              margin="dense"
              placeholder={placeholder}
              type={inputType}
              fullWidth
              value={this.state.inputText}
              onChange={this.handleInputText}
              InputProps={inputProps}
            />
          </DialogContentText>}
        </DialogContent>
        <DialogActions>
          <Button className="button-maru-contained" onClick={this.state.okCallback}>{okButtonName}</Button>
          <Button className="button-maru-second-contained" onClick={this.close}>{cancelButtonName}</Button>
        </DialogActions>
      </Dialog>
    )
  }
}
export default InputDialog;