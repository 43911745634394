
import React, {Component, createRef} from 'react';
import {withRouter} from 'react-router-dom';
import queryString from 'query-string';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import i18n from "../../../config/lang/i18n";
import {Button, Card, Grid, List, ListItem, Collapse} from "@material-ui/core";
import TitBreadcrumbs from "../../component/TitBreadcrumbs";
import clsx from 'clsx';
import {CodeBlock} from "react-code-blocks";
import TextCopyButton from "../../component/TextCopyButton";
import agentInstallJeus6_1 from "../../../assets/images/agentInstallJeus6_1.png";
import agentInstallJeus7_8_1 from "../../../assets/images/agentInstallJeus7_8_1.png";
import APIUtils from "../../../utils/APIUtils";
import utils from "../../../utils/Utils";
import notification from "../../component/Notification";

class AgentInstall extends Component {
  constructor() {
    super();

    this.state = {
      activeTab: '0',
      pid: undefined,
      openmaruHost: '',
      orderWasTab: [false, false, false, false, false, false, false, false, false, false],
      orderWasTabName: ['firewall', 'tomcat', 'jboss', 'jeus7', 'jeus6', 'weblogic', 'wildfly', 'jetty', 'regin'],
      orderSysTab: [false, false, false, false, false, false, false, false, false],
      project: {}
    }

    this.agentOptionTextRef = createRef();
    this.toggle = this.toggle.bind(this);
    this.orderTabToggle = this.orderTabToggle.bind(this);
    // this.getOpenmaruServerHost = this.getOpenmaruServerHost.bind(this);
    this.checkApmHealth = this.checkApmHealth.bind(this);
  }

  componentDidUpdate() {
    // this.getOpenmaruServerHost();
  }

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    if(query.type) {
      this.orderTabToggle(this.state.orderWasTabName.findIndex(type => type === query.type), 'orderWasTab');
    }
    // this.getOpenmaruServerHost();
    this.setState({healthCheck: setInterval(this.checkApmHealth, (5*1000))});
    this.checkApmHealth();
  }

  componentWillUnmount() {
    clearInterval(this.state.healthCheck);
  }

  checkApmHealth() {
    const query = queryString.parse(this.props.location.search);
    let pid = query.id;

    APIUtils.project.getProjectInfo({pid: pid}, (data) => {
      if(data.status === 200) {
        const newState = Object.assign(this.state, data.body, {project: data.body});

        this.setState(newState);
      }
    })
  }

  /*getOpenmaruServerHost() {
    const query = queryString.parse(this.props.location.search);
    let pid = query.id;

    if(query.id !== this.state.pid) {
      APIUtils.project.getOpenmaruServerHost({pid: pid}, (data) => {
        if (data.status === 200) {
            this.setState({pid: pid, openmaruHost: data.body});
        } else {
          notification.error(data.message)
        }
      });
    }
  }*/

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({activeTab: tab})
    }
  };

  orderTabToggle(tab, type) {
    const prevState = this.state[type];

    this.setState({
      [type]: prevState.map((value, index) => (tab === index? !value : false))
    })
  }

  go2APM(project) {
    utils.goToApm(project.apmServerDomain);
  }

  render() {
    const copyBlockText = {
      firewall: '' +
      '## IP Address\n' +
      '13.125.51.109\n' +
      '13.209.130.53\n\n' +
      '## PORT\n' +
      '443',
      wasAgentFilePath: window.location.origin + '/api/v1/projects/' + this.state.pid + '/download/was-agent.zip',
      openmaruServerHost: this.state.openmaruHost,
      openmaruServerHostDesc: '## khan-agent.conf\n\n' +
        'khan.host=' + this.state.openmaruHost,
      wasTomcat: 'export AGENT_OPTS=" -javaagent:/svc/test/khan-agent/khan-agent-5.1.0.jar "\n' +
        'export AGENT_OPTS="$AGENT_OPTS -Dkhan.config.file=khan-agent.conf "\n' +
        'export JAVA_OPTS="$AGENT_OPTS $JAVA_OPTS -noverify "\n',
      wasJBoss: 'export AGENT_OPTS=" -javaagent:/svc/test/khan-agent/khan-agent-5.1.0.jar "\n' +
        'export AGENT_OPTS="$AGENT_OPTS -Dkhan.config.file=khan-agent.conf "\n' +
        'export JAVA_OPTS="$JAVA_OPTS -Djava.util.logging.manager=org.jboss.logmanager.LogManager"\n' +
        'export JBOSS_LOGMANAGER_DIR="$JBOSS_HOME/modules/system/layers/base/org/jboss/logmanager/main"\n' +
        'export JBOSS_LOGMANAGER_JAR=`cd "$JBOSS_LOGMANAGER_DIR" && ls -1 *.jar`\n' +
        'export JBOSS_WILDCOMMON_DIR="$JBOSS_HOME/modules/system/layers/base/org/wildfly/common/main"\n' +
        'export JBOSS_WILDCOMMON_JAR=`cd "$JBOSS_WILDCOMMON_DIR" && ls -1 *.jar`\n' +
        'export JAVA_OPTS="$JAVA_OPTS -Xbootclasspath/p:$JBOSS_LOGMANAGER_DIR/$JBOSS_LOGMANAGER_JAR:$WILDFLY_COMMON_JAR:$JBOSS_WILDCOMMON_DIR/$JBOSS_WILDCOMMON_JAR"\n' +
        'export JAVA_OPTS=" $JAVA_OPTS -noverify" \n' +
        'export JAVA_OPTS=" $JAVA_OPTS -Djboss.modules.system.pkgs=org.jboss.byteman,com.opennaru.khan.agent,org.github.jamm,org.jboss.logmanager,org.wildfly.common.net.HostName" \n' +
        'export JAVA_OPTS="$AGENT_OPTS $JAVA_OPTS"  \n',
      wasJeus6: '' +
        '<engine-container>\n' +
        '<name>container1</name>\n' +
        '<id>22</id>\n' +
        '<base-port>22001</base-port>\n' +
        '...\n' +
        '<command-option>\n' +
        '  -XX:MaxPermSize=128m -Xms256m -Xmx512m -javaagent:/svc/test/khanagent/khan-agent-5.1.0.jar -Dkhan.config.file=khan-agent.conf -noverify\n' +
        '  ...\n' +
        '</command-option>\n' +
        '..',
      wasJeus7_8: '' +
        '<jvm-config>\n' +
        '  <jvm-option>-Xmx1024m -XX:MaxPermSize=128m</jvm-option>\n' +
        '  <jvm-option>-javaagent:/svc/test/khan-agent/khan-agent-5.1.0.jar -Dkhan.conf.file=khan-agent.conf -noverify -Djeus.jmx.usePlatformMBeanServer=true</jvm-option>\n' +
        '</jvm-config>\n' +
        '..',
      wasJeus7_8_2: '' +
        '<resource-permissions>\n' +
        '...\n' +
        '<resource-permission>\n' +
        '  <resource>jeus.server.*</resource>\n' +
        '  <actions>getstats</actions>\n' +
        '  <classname>jeus.security.resource.ResourcePermission</classname>\n' +
        '  <unchecked/>\n' +
        '</resource-permission>\n' +
        '...',
      wasJetty9: '' +
        'export AGENT_OPTS=" -javaagent:/svc/test/khan-agent/khan-agent-5.1.0.jar "\n' +
        'export AGENT_OPTS="$AGENT_OPTS -Dkhan.config.file=khan-agent.conf -noverify"\n' +
        'export JAVA_OPTIONS="$AGENT_OPTS $JAVA_OPTIONS"',
      wasResin4: '' +
      'jvm_args : .... -noverify -javaagent:/svc/test/khan-agent/khanagent.jar -Dkhan.config.file=khan-agent.conf',
      wasResin4_2: '' +
        '<server-default>\n' +
        ' <jvm-arg-line>${jvm_args}</jvm-arg-line>\n' +
        ' <jvm-mode>${jvm_mode}</jvm-mode>',
      wasWebLogic: '' +
        'export AGENT_OPTS=" -noverify -javaagent:/svc/test/khan-agent/khan-agent-5.1.0.jar "\n' +
        'export AGENT_OPTS="$AGENT_OPTS -Dkhan.config.file=khan-agent-test11.conf "\n' +
        '\n' +
        'DOMAIN_HOME="/svc/test/weblogic/wls1211/user_projects/domains/base_domain"\n' +
        '\n' +
        'JAVA_OPTIONS=" $JAVA_OPTIONS $AGENT_OPTS -Djavax.management.builder.initial=weblogic.management.jmx.mbeanserver.WLSMBeanServerBuilder"\n' +
        'export JAVA_OPTIONS',
      wasWildfly14: '' +
        'export AGENT_OPTS=" -javaagent:/svc/test/khan-agent/khan-agent-5.1.0.jar "\n' +
        'export AGENT_OPTS="$AGENT_OPTS -Dkhan.config.file=khan-agent.conf"\n' +
        'export JAVA_OPTS="$JAVA_OPTS -Djava.util.logging.manager=org.jboss.logmanager.LogManager"\n' +
        'export JBOSS_LOGMANAGER_DIR="$JBOSS_HOME/modules/system/layers/base/org/jboss/logmanager/main"\n' +
        'export JBOSS_LOGMANAGER_JAR=`cd "$JBOSS_LOGMANAGER_DIR" && ls -1 *.jar`\n' +
        'export JBOSS_WILDFLY_COMMON_DIR="$JBOSS_HOME/modules/system/layers/base/org/wildfly/common/main"\n' +
        'export JBOSS_WILDFLY_COMMON_JAR=`cd "$JBOSS_WILDFLY_COMMON_DIR" && ls -1 *.jar`\n' +
        'export JAVA_OPTS=" -Xbootclasspath/a:$JBOSS_LOGMANAGER_DIR/$JBOSS_LOGMANAGER_JAR:$JBOSS_WILDFLY_COMMON_DIR/$JBOSS_WILDFLY_COMMON_JAR $JAVA_OPTS"\n' +
        'export JAVA_OPTS=" $JAVA_OPTS -noverify"\n' +
        'export JAVA_OPTS=" -Dsun.util.logging.disableCallerCheck=true $JAVA_OPTS"\n' +
        'export JAVA_OPTS=" $JAVA_OPTS -Djboss.modules.system.pkgs=org.jboss.byteman,com.opennaru.khan.agent,org.github.jamm,org.jboss.logmanager"\n' +
        '\n' +
        'export JAVA_OPTS="$AGENT_OPTS $JAVA_OPTS" ',
      sysAgentFilePath: window.location.origin + '/api/v1/projects/' + this.state.pid + '/download/sys-agent.zip',
      sysAgentConfPath: 'vi khan-sys-agent/conf/khan-sys-agent.conf',
      sysAgentConf: 'khan.host=' + this.state.openmaruHost + '\n' +
        'khan.port=\n' +
        '# specify this machine\'s ip address, only valid ip address of this machine can be used.\n' +
        '#khan.agent.ip=\n' + '\n' +
        '# agent\'s compression algorithm : snappy(default), lzw\n' +
        '#agent.compress.type=lzw\n' + '\n' +
        '# Gathering netstat info : true(default)\n' +
        '#khan.netstat=false\n' + '\n' +
        '# include network interface\n' +
        '# configuration sample for openshift\n' +
        '#khan.network.interfaces=eth0,eth1,ens192,en0,en1,lo,tun0,vxlan_sys_4789\n' + '\n' +
        '# include disk mount\n' +
        '# configuration sample for openshift\n' +
        '#khan.disk.mounts=/,/home,/data,/var/lib/docker/containers,C,D\n' + '\n' +
        '# Memory sigar supported OS vendor version : Enterprise Linux 5,5,Enterprise Linux 6,6(default) startsWith\n' +
        '#khan.memory.sigar.supported.os.vendor.versions=Enterprise Linux 5,5,Enterprise Linux 6,6\n' +
        '',
      sysPluginsDisabledPath: 'vi khan-sys-agent/plugins/disabled.txt',
      sysPluginsDisabledConf: 'khan-plugin-apache \n' +
        'khan-plugin-nginx \n' +
        'khan-plugin-cubrid \n' +
        'khan-plugin-docker \n' +
        'khan-plugin-crio \n',
      sysAgentCliPath: 'cd khan-sys-agent/bin',
      sysAgentStart: './start.sh',
      sysAgentEnd: './kill.sh ',
      sysAgentLog: './tail.sh'
    }

    return (
      <>
        <TitBreadcrumbs title={i18n.t('agent.title', '에이전트 설치안내')}/>

        <div className="app-page-title">
          <div>
            <h1><FontAwesomeIcon icon={['fas', 'hands-helping']}
                                 className="icon"/> {i18n.t('agent.title', '에이전트 설치안내')}</h1>
          </div>
        </div>

        <Grid container>
          <Grid item lg={12}>
            <Card className="shadow-xxl p-3">
              <List className="nav-tabs nav-tabs-danger d-flex">
                <ListItem
                  button
                  className="font-weight-bold"
                  disableRipple
                  selected={this.state.activeTab === '0'}
                  onClick={() => {
                    this.toggle('0');
                  }}>
                  WAS Agent
                </ListItem>
                <ListItem
                  button
                  className="font-weight-bold"
                  disableRipple
                  selected={this.state.activeTab === '1'}
                  onClick={() => {
                    this.toggle('1');
                  }}>
                  SYS Agent
                </ListItem>
              </List>

              <div
                className={clsx('tab-item-wrapper', {
                  active: this.state.activeTab === '0'
                })}
                index={0}>
                <div className="text-center">
                  <Grid container>
                    <Grid item lg={12}>
                      <div className="timeline-list timeline-list--danger">
                        <div className="timeline-item timeline-item-icon">
                          <div className="timeline-item--content">
                            <div className="timeline-item--icon-wrapper rounded-pill bg-warning text-white">
                              <FontAwesomeIcon icon={['far', 'building']} />
                            </div>
                            <h4 className="timeline-item--label mb-2 font-weight-bold nav-link" onClick={_=>this.orderTabToggle(0, 'orderWasTab')}>
                              {i18n.t('agent.firewallOption', '(옵션) 내부 방화벽이 있을 경우')}
                            </h4>
                            <Collapse in={this.state.orderWasTab[0]}>
                              <p className="text-left">
                                {i18n.t('agent.firewallMsg', '내부 방화벽이 있는 경우(TCP Outbound) 목적지 정보에 아래 내역을 추가 해주십시요.')}
                                <TextCopyButton copyText={copyBlockText.firewall} positionTop="1rem"/>
                                <CodeBlock  text={copyBlockText.firewall}
                                            language="powershell"
                                            showLineNumbers={false}
                                            theme="dracula" />
                              </p>
                            </Collapse>
                          </div>
                        </div>
                        <div className="timeline-item timeline-item-icon">
                          <div className="timeline-item--content">
                            <div className="timeline-item--icon-wrapper rounded-pill bg-danger text-white">
                              <FontAwesomeIcon icon={['fas', 'download']} />
                            </div>
                            <h4 className="timeline-item--label mb-2 font-weight-bold">
                              WAS Agent Download
                            </h4>
                            <p className="text-left">
                              {i18n.t('agent.wasDownloadMsg', '다운로드 받는 파일을 애플리케이션 서버에 복사한 후 압축 해제해 주십시오.')}
                            </p>
                            <div className="avatar-wrapper-overlap mt-2 mb-1">
                              <a href="https://docs.openmaru.io/docs/agentInstall/03.chap/03.html#_khan_agent_conf_%EC%84%A4%EC%A0%95_%EC%83%81%EC%84%B8" target="_blank">{i18n.t('agent.agentInstallDocsLink')} <FontAwesomeIcon icon={['fas', 'external-link-alt']} /></a>
                            </div>
                            <div className="avatar-wrapper-overlap mt-2 mb-1">
                              <TextCopyButton copyText={copyBlockText.wasAgentFilePath} positionTop="0.5rem" tooltipMessage="Copy URL"/>
                              <Button
                                size="small"
                                onClick={() => window.location.href = '/api/v1/projects/' + this.state.pid + '/download/was-agent.zip'}
                                variant="text"
                                className="btn-outline-danger m-1">
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={['fas', 'download']}/>
                                </span>
                                <span className="btn-wrapper--label">Download WAS Agent</span>
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="timeline-item timeline-item-icon">
                          <div className="timeline-item--content">
                            <div className="timeline-item--icon-wrapper rounded-pill bg-danger text-white">
                              <FontAwesomeIcon icon={['fas', 'users-cog']} />
                            </div>
                            <h4 className="timeline-item--label mb-2 font-weight-bold">
                              WAS Configuration
                            </h4>
                          </div>
                        </div>
                        <div className="timeline-item">
                          <div className="timeline-item--content">
                            <div className="timeline-item--icon bg-warning" />
                            <h4 className="timeline-item--label mb-2 font-weight-bold nav-link" onClick={_=>this.orderTabToggle(1, 'orderWasTab')}>
                              Tomcat
                            </h4>
                            <Collapse in={this.state.orderWasTab[1]}>
                              <p className="text-left">
                                {i18n.t('agent.tomcatMsg', 'Tomcat 의 경우 catalina.sh 파일에 아래 설정을 추가합니다.')}
                              </p>
                              <div className="text-left" ref={this.agentOptionTextRef}>
                                <TextCopyButton copyText={copyBlockText.wasTomcat} positionTop="0.2rem"/>
                                <CodeBlock  text={copyBlockText.wasTomcat}
                                  language="powershell"
                                  showLineNumbers={false}
                                  theme="dracula" />
                              </div>
                            </Collapse>
                          </div>
                        </div>
                        <div className="timeline-item">
                          <div className="timeline-item--content">
                            <div className="timeline-item--icon bg-warning" />
                            <h4 className="timeline-item--label mb-2 font-weight-bold nav-link" onClick={_=>this.orderTabToggle(2, 'orderWasTab')}>
                              JBoss 7.x Standalone Mode
                            </h4>
                            <Collapse in={this.state.orderWasTab[2]}>
                              <p className="text-left">
                                {i18n.t('agent.eap7StandaloneMsg', 'JBoss EAP 7 의 경우..')}
                              </p>
                              <div className="text-left">
                                <TextCopyButton copyText={copyBlockText.wasJBoss} positionTop="0.2rem"/>
                                <CodeBlock text={copyBlockText.wasJBoss}
                                  language="powershell"
                                  showLineNumbers={false}
                                  theme="dracula" />
                              </div>
                            </Collapse>
                          </div>
                        </div>

                        <div className="timeline-item">
                          <div className="timeline-item--content">
                            <div className="timeline-item--icon bg-warning" />
                            <h4 className="timeline-item--label mb-2 font-weight-bold nav-link" onClick={_=>this.orderTabToggle(3, 'orderWasTab')}>
                              JEUS 6
                            </h4>
                            <Collapse in={this.state.orderWasTab[3]}>
                              <p className="text-left">
                                JeusMain.xml
                              </p>
                              <div className="text-left">
                                <TextCopyButton copyText={copyBlockText.wasJeus6} positionTop="0.2rem"/>
                                <CodeBlock text={copyBlockText.wasJeus6}
                                          language="powershell"
                                          showLineNumbers={false}
                                          theme="dracula" />
                                <br/>
                                <div>
                                  JEUS 의 데이터소스, 웹 세션 수를 모니터링 하려면, -Djeus.jmx.usePlatformMBeanServer=true
                                  옵션을 사용하여야 한다.
                                  <br/>
                                  또, 웹 세션 수 모니터링을 위하여 다음과 같이 JEUS 관리자 콘솔에서 “JEUS 매니저 리소스 →
                                  보안 → SYSTEM_DOMAIN → 정책 → ‘새 리소스 권한 생성’” 버튼을 클릭하여 다음과 같이
                                  설정하여야 한다.
                                  <br/>
                                  <img src={agentInstallJeus6_1} alt="" />
                                </div>
                              </div>
                            </Collapse>
                          </div>
                        </div>

                        <div className="timeline-item">
                          <div className="timeline-item--content">
                            <div className="timeline-item--icon bg-warning" />
                            <h4 className="timeline-item--label mb-2 font-weight-bold nav-link" onClick={_=>this.orderTabToggle(4, 'orderWasTab')}>
                              JEUS 7/8
                            </h4>
                            <Collapse in={this.state.orderWasTab[4]}>
                              <p className="text-left">
                                domain.xml
                              </p>
                              <div className="text-left">
                                <TextCopyButton copyText={copyBlockText.wasJeus7_8} positionTop="0.2rem"/>
                                <CodeBlock text={copyBlockText.wasJeus7_8}
                                          language="powershell"
                                          showLineNumbers={false}
                                          theme="dracula" />
                                <br/>
                                <div>
                                  JEUS 의 데이터소스, 웹 세션 수를 모니터링 하려면, -Djeus.jmx.usePlatformMBeanServer=true
                                  옵션을 사용하여야 한다.
                                  <br/>
                                  또, 웹 세션 수 모니터링을 위하여 다음과 같이 JEUS 관리자 콘솔에서 “Security → Accounts &
                                  Policies Management → policies → 하단 ‘Resource Permissions’의 ‘Add’ ”버튼을 클릭하여
                                  다음과 같이 설정하여야 한다.
                                  <br/>
                                  <img src={agentInstallJeus7_8_1} />
                                  <br/>
                                  위와 같이 설정하면 jeus_domain/config/security/SYSTEM_DOMAIN/policies.xml 파일의 아래
                                  굵은 글씨체 부분이 추가된다.
                                </div>
                                <CodeBlock text={copyBlockText.wasJeus7_8_2}
                                          language="powershell"
                                          showLineNumbers={false}
                                          theme="dracula" />
                              </div>
                            </Collapse>
                          </div>
                        </div>

                        <div className="timeline-item">
                          <div className="timeline-item--content">
                            <div className="timeline-item--icon bg-warning" />
                            <h4 className="timeline-item--label mb-2 font-weight-bold nav-link" onClick={_=>this.orderTabToggle(5, 'orderWasTab')}>
                              WebLogic
                            </h4>
                            <Collapse in={this.state.orderWasTab[5]}>
                              <p className="text-left">
                                startWebLogic.sh
                              </p>
                              <div className="text-left">
                                <TextCopyButton copyText={copyBlockText.wasWebLogic} positionTop="0.2rem"/>
                                <CodeBlock text={copyBlockText.wasWebLogic}
                                          language="powershell"
                                          showLineNumbers={false}
                                          theme="dracula" />
                              </div>
                            </Collapse>
                          </div>
                        </div>

                        <div className="timeline-item">
                          <div className="timeline-item--content">
                            <div className="timeline-item--icon bg-warning" />
                            <h4 className="timeline-item--label mb-2 font-weight-bold nav-link" onClick={_=>this.orderTabToggle(6, 'orderWasTab')}>
                              Wildfly 14(Java 11)
                            </h4>
                            <Collapse in={this.state.orderWasTab[6]}>
                              <p className="text-left">
                                Java 11 버전을 사용하는 Wildfly 14 는 경우 JBoss LogManager 의 설정이 필요합니다.
                                <br/>
                                jboss.modules.system.pkgs 에 com.opennaru.khan.agent 와 org.jboss.logmanager 패키지를
                                등록하여 합니다. 또, -Xbootclasspath/a 옵션을 사용하여 JBoss Logging 관련 JAR 파일들을
                                지정하여야 하며, -Dsun.util.logging.disableCallerCheck=true 옵션을 지정한다.
                                <br/>
                              </p>
                              <p className="text-left">
                                env.sh
                              </p>
                              <div className="text-left">
                                <TextCopyButton copyText={copyBlockText.wasWildfly14} positionTop="0.2rem"/>
                                <CodeBlock text={copyBlockText.wasWildfly14}
                                          language="powershell"
                                          showLineNumbers={false}
                                          theme="dracula" />
                              </div>
                            </Collapse>
                          </div>
                        </div>

                        <div className="timeline-item">
                          <div className="timeline-item--content">
                            <div className="timeline-item--icon bg-warning" />
                            <h4 className="timeline-item--label mb-2 font-weight-bold nav-link" onClick={_=>this.orderTabToggle(7, 'orderWasTab')}>
                              Jetty 9
                            </h4>
                            <Collapse in={this.state.orderWasTab[7]}>   
                              <p className="text-left">
                                jetty.sh
                              </p>
                              <div className="text-left">
                                <TextCopyButton copyText={copyBlockText.wasJetty9} positionTop="0.2rem"/>
                                <CodeBlock text={copyBlockText.wasJetty9}
                                          language="powershell"
                                          showLineNumbers={false}
                                          theme="dracula" />
                              </div>
                            </Collapse>
                          </div>
                        </div>

                        <div className="timeline-item">
                          <div className="timeline-item--content">
                            <div className="timeline-item--icon bg-warning" />
                            <h4 className="timeline-item--label mb-2 font-weight-bold nav-link" onClick={_=>this.orderTabToggle(8, 'orderWasTab')}>
                              Regin 4.x
                            </h4>
                            <Collapse in={this.state.orderWasTab[8]}>
                              <p className="text-left">
                                conf/resin.properties or resin.xml
                              </p>
                              <div className="text-left">
                                <TextCopyButton copyText={copyBlockText.wasResin4} positionTop="0.2rem"/>
                                <CodeBlock text={copyBlockText.wasResin4}
                                          language="powershell"
                                          showLineNumbers={false}
                                          theme="dracula" />
                                <br/>
                                conf/cluster-default.xml
                                <CodeBlock text={copyBlockText.wasResin4_2}
                                          language="powershell"
                                          showLineNumbers={false}
                                          theme="dracula" />
                              </div>
                            </Collapse>
                          </div>
                        </div>
                        {(this.state.apmStatus === '1003')?
                        (<div className="timeline-item timeline-item-icon">
                          <div className="timeline-item--content">
                            <div className="timeline-item--icon-wrapper rounded-pill bg-maru-link text-white">
                              <FontAwesomeIcon icon={['fas', 'desktop']}/>
                            </div>
                            <h4 className="timeline-item--label mb-2 font-weight-bold nav-link">
                              <Button
                                size="small"
                                onClick={() => {this.go2APM(this.state.project)}}
                                variant="text"
                                className="m-1 button-maru-second-outlined">
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={['far', 'eye']} className="font-size-sm" />
                                </span>
                                <span className="btn-wrapper--label">Monitoring</span>
                              </Button>
                            </h4>
                          </div>
                        </div>):
                        (<div className="timeline-item timeline-item-icon">
                          <div className="timeline-item--content">
                            <div className="timeline-item--icon-wrapper rounded-pill bg-maru-unlink text-white">
                              <FontAwesomeIcon icon={['fas', 'desktop']}/>
                            </div>
                            <h4 className="timeline-item--label mb-2 font-weight-bold nav-link" onClick={_=>this.orderTabToggle(8, 'orderWasTab')}>
                              <Button
                                size="small"
                                onClick={() => {this.go2APM(this.state.project)}}
                                variant="text"
                                className="m-1 button-maru-outlined">
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={['fas', 'spinner']} spin/>
                                </span>
                                <span className="btn-wrapper--label">Monitoring</span>
                              </Button>
                            </h4>
                          </div>
                        </div>)}
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
              <div
                className={clsx('tab-item-wrapper', {
                  active: this.state.activeTab === '1'
                })}
                index={1}>
                <div className="text-center">
                  <Grid container>
                    <Grid item lg={12}>
                      <div className="timeline-list timeline-list--danger mb-5">
                        <div className="timeline-item timeline-item-icon">
                          <div className="timeline-item--content">
                            <div className="timeline-item--icon-wrapper rounded-pill bg-warning text-white">
                              <FontAwesomeIcon icon={['far', 'building']} />
                            </div>
                            <h4 className="timeline-item--label mb-2 font-weight-bold nav-link" onClick={_=>this.orderTabToggle(0, 'orderSysTab')}>
                              {i18n.t('agent.firewallOption', '(옵션) 내부 방화벽이 있을 경우')}
                            </h4>
                            <Collapse in={this.state.orderSysTab[0]}>
                              <p className="text-left">
                                {i18n.t('agent.firewallMsg', '내부 방화벽이 있는 경우(TCP Outbound) 목적지 정보에 아래 내역을 추가 해주십시요.')}
                                <TextCopyButton copyText={copyBlockText.firewall} positionTop="1rem"/>
                                <CodeBlock  text={copyBlockText.firewall}
                                            language="powershell"
                                            showLineNumbers={false}
                                            theme="dracula" />
                              </p>
                            </Collapse>
                          </div>
                        </div>
                        <div className="timeline-item timeline-item-icon">
                          <div className="timeline-item--content">
                            <div className="timeline-item--icon-wrapper rounded-pill bg-danger text-white">
                              <FontAwesomeIcon icon={['fas', 'download']} />
                            </div>
                            <h4 className="timeline-item--label mb-2 font-weight-bold">
                              SYS Agent Download
                            </h4>
                            <p className="text-left">
                              {i18n.t('agent.sysDownloadMsg', '다운로드 받는 파일을 애플리케이션 서버에 복사한 후 압축 해제해 주십시오.')}
                            </p>
                            <div className="avatar-wrapper-overlap mt-2 mb-1">
                              <a href="https://docs.openmaru.io/docs/agentInstall/02.chap/02.html" target="_blank">{i18n.t('agent.agentInstallDocsLink')} <FontAwesomeIcon icon={['fas', 'external-link-alt']} /></a>
                            </div>
                            <div className="avatar-wrapper-overlap mt-2 mb-1">
                              <TextCopyButton copyText={copyBlockText.sysAgentFilePath} positionTop="0.2rem" tooltipMessage="Copy URL"/>
                              <Button
                                size="small"
                                onClick={() => window.location.href = '/api/v1/projects/' + this.state.pid + '/download/sys-agent.zip'}
                                variant="text"
                                className="btn-outline-danger m-1">
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={['fas', 'download']}/>
                                </span>
                                <span className="btn-wrapper--label">Download SYS Agent</span>
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="timeline-item timeline-item-icon">
                          <div className="timeline-item--content">
                            <div className="timeline-item--icon-wrapper rounded-pill bg-danger text-white">
                              <FontAwesomeIcon icon={['fas', 'users-cog']} />
                            </div>
                            <h4 className="timeline-item--label mb-2 font-weight-bold">
                              SYS Agent Configuration
                            </h4>
                            <p className="text-left">
                              {i18n.t('agent.wasConfigurationMsg', '해제된 파일을 아래와 같이 각 애플리케이션 서버별로 설정 해주십시오.')}
                            </p>
                          </div>
                        </div>
                        <div className="timeline-item">
                          <div className="timeline-item--content">
                            <div className="timeline-item--icon bg-warning" />
                            <h4 className="timeline-item--label mb-2 font-weight-bold nav-link" onClick={_=>this.orderTabToggle(1, 'orderSysTab')}>
                              conf/khan-sys-agent.conf
                            </h4>
                            <Collapse in={this.state.orderSysTab[1]}>
                              <p className="text-left">
                                {i18n.t('agent.sysMsg')}
                              </p>
                              <div className="text-left">
                                <TextCopyButton copyText={copyBlockText.sysAgentConfPath} positionTop="0.2rem"/>
                                <TextCopyButton copyText={copyBlockText.sysAgentConf} positionTop="2.5rem"/>
                                <CodeBlock text={
                                  `$ ${copyBlockText.sysAgentConfPath}\n\n` +
                                  copyBlockText.sysAgentConf}
                                  language="powershell"
                                  showLineNumbers={false}
                                  theme="dracula" />
                              </div>
                            </Collapse>
                          </div>
                        </div>
                        <div className="timeline-item">
                          <div className="timeline-item--content">
                            <div className="timeline-item--icon bg-warning" />
                            <h4 className="timeline-item--label mb-2 font-weight-bold nav-link" onClick={_=>this.orderTabToggle(2, 'orderSysTab')}>
                              plugins/disabled.txt
                            </h4>
                            <Collapse in={this.state.orderSysTab[2]}>
                              <p className="text-left">
                                {i18n.t('agent.sysPluginDisableMsg')}
                              </p>
                              <div className="text-left">
                                <TextCopyButton copyText={copyBlockText.sysPluginsDisabledPath} positionTop="0.3rem"/>
                                <TextCopyButton copyText={copyBlockText.sysPluginsDisabledConf} positionTop="2.5rem"/>
                                <CodeBlock text={
                                  `$ ${copyBlockText.sysPluginsDisabledPath}\n\n` +
                                  copyBlockText.sysPluginsDisabledConf}
                                  language="powershell"
                                  showLineNumbers={false}
                                  theme="dracula" />
                              </div>
                            </Collapse>
                          </div>
                        </div>
                        <div className="timeline-item">
                          <div className="timeline-item--content">
                            <div className="timeline-item--icon" />
                            <h4 className="timeline-item--label mb-2 font-weight-bold nav-link" onClick={_=>this.orderTabToggle(3, 'orderSysTab')}>
                              {i18n.t('agent.sysCtrlMsg', '시스템 에이전트 시작 및 종료')}
                            </h4>
                            <Collapse in={this.state.orderSysTab[3]}>
                              <div className="text-left">
                                <TextCopyButton copyText={copyBlockText.sysAgentCliPath} positionTop="1.5rem"/>
                                <TextCopyButton copyText={copyBlockText.sysAgentStart} positionTop="2.8rem"/>
                                <TextCopyButton copyText={copyBlockText.sysAgentEnd} positionTop="6.5rem"/>
                                <TextCopyButton copyText={copyBlockText.sysAgentLog} positionTop="10.3rem"/>
                                <CodeBlock text={ '## start\n' +
                                  `$ ${copyBlockText.sysAgentCliPath}\n` +
                                  `$ ${copyBlockText.sysAgentStart}\n\n` +
                                  '## stop\n' +
                                  `$ ${copyBlockText.sysAgentEnd} (or ./stop.sh)\n\n` +
                                  '## log\n' +
                                  `$ ${copyBlockText.sysAgentLog}\n`}
                                  language="powershell"
                                  showLineNumbers={false}
                                  theme="dracula" />
                              </div>
                            </Collapse>
                          </div>
                        </div>
                        {(this.state.apmStatus === '1003')?
                        (<div className="timeline-item timeline-item-icon">
                          <div className="timeline-item--content">
                            <div className="timeline-item--icon-wrapper rounded-pill bg-maru-link text-white">
                              <FontAwesomeIcon icon={['fas', 'desktop']}/>
                            </div>
                            <h4 className="timeline-item--label mb-2 font-weight-bold nav-link">
                              <Button
                                size="small"
                                onClick={() => {this.go2APM(this.state.project)}}
                                variant="text"
                                className="m-1 button-maru-second-outlined">
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={['far', 'eye']} className="font-size-sm" />
                                </span>
                                <span className="btn-wrapper--label">Monitoring</span>
                              </Button>
                            </h4>
                          </div>
                        </div>):
                        (<div className="timeline-item timeline-item-icon">
                          <div className="timeline-item--content">
                            <div className="timeline-item--icon-wrapper rounded-pill bg-maru-unlink text-white">
                              <FontAwesomeIcon icon={['fas', 'desktop']}/>
                            </div>
                            <h4 className="timeline-item--label mb-2 font-weight-bold nav-link">
                              <Button
                                size="small"
                                onClick={() => {this.go2APM(this.state.project)}}
                                variant="text"
                                className="m-1 button-maru-outlined">
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={['fas', 'spinner']} spin/>
                                </span>
                                <span className="btn-wrapper--label">Monitoring</span>
                              </Button>
                            </h4>
                          </div>
                        </div>)}
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Card>
          </Grid>
        </Grid>
      </>
    )
  }
}

export default withRouter(AgentInstall);
