import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import i18n from "../../config/lang/i18n";
import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, FormControl, FormHelperText, Grid, InputAdornment, MenuItem, Select, Typography } from "@material-ui/core";
import MaskedMobileNumber from "../component/MaskedMobileNumber";
import Dropzone from 'react-dropzone'
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';
import CheckIcon from '@material-ui/icons/Check';
import AccountCircleTwoToneIcon from '@material-ui/icons/AccountCircleTwoTone';
import utils from "../../utils/Utils";
import APIUtils from "../../utils/APIUtils";
import APIsLists from "../../utils/APIUtils";
import notification from "../component/Notification";
import {fileToBase64} from "./Signup";
import ConfirmDialog from "../component/ConfirmDialog";
import CTextField from "openmaru/component/CTextField";
import InputDialog from "openmaru/component/InputDialog";
import Jasypt from "jasypt";

class Account extends Component {
  constructor() {
    super()

    this.state = {
      userId: '',
      name: '',
      email: '',
      mobile: '',
      company: '',
      team: '',
      timeZone: '',
      mobileCheckMessage: '',
      emailCheckMessage: '',
      registerMessage: '',
      userImg: undefined,
      isPasswordEditView: false,
      nowPassword: '',
      toPassword: '',
      toPasswordConfirm: '',
      passwordValid: false,
      passwordSameCheckMessage: '',
      isClicked: false,
      deleteAccountConfirm: false,
      showNowPassword: false,
      showToPassword: false,
      showToPasswordConfirm: false,
      showProfileContent: false,
      showAuthPassword: false,
      passwordAuth: '',
      textDialog: false
    }

    this.updateUserConfirmDialog = React.createRef();
    this.nameInput = React.createRef();
    this.mobileInput = React.createRef();
    this.emailInput = React.createRef();
    this.inputDialogRef = React.createRef();

    this.nowPasswordInput = React.createRef();
    this.toPasswordInput = React.createRef();

    this.setInputValue = this.setInputValue.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.updateUserPassword = this.updateUserPassword.bind(this);
    this.passwordEditViewCancel = this.passwordEditViewCancel.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.toggleDeleteAccountDialog = this.toggleDeleteAccountDialog.bind(this);
    this.authPassword = this.authPassword.bind(this);
  }

  componentDidUpdate() {
  }

  componentDidMount() {
    APIsLists.user.getUserImg({}, (data) => {
      if (data.status === 200 && data.body) {
        this.setState({
          userImg: data.body.userImg
        });
      }
    });
  }

  setInputValue(event) {
    const inputId = event.target.name;
    const inputValue = (event.target.tagName === 'INPUT' && event.target.type === 'text')?utils.string.unescape(event.target.value):event.target.value;

    let changeStateValue = {[inputId]: inputValue};

    this.setState(changeStateValue, () => {
      if (inputId === "userId") {
        if (inputId !== '' && utils.string.checkUserId(this.state.userId)) {
          this.setState({isUserIdValid: true})
        } else {
          this.setState({isUserIdValid: false})
        }
      } else if (inputId === "password" || inputId === "confirmPw") {
        if (this.state.password === this.state.confirmPw) {
          if (this.state.password !== '' && utils.string.checkPassword(this.state.password)) {
            this.setState({
              passwordSameCheckMessage: i18n.t('signup.pwdPassMsg', '비밀번호가 일치하고, 보안에 적합합니다.'),
              passwordValid: true
            })
          } else {
            this.setState({
              passwordSameCheckMessage: i18n.t('signup.pwdRegexpMsg', '비밀번호는 숫자, 특문 각 1회 이상, 영문은 2개 이상 사용하여 8자리 이상 입력'),
              passwordValid: false
            })
          }
        } else {
          this.setState({
            passwordSameCheckMessage: i18n.t('signup.pwdDifferentMsg', '비밀번호가 일치하지 않습니다.'),
            passwordValid: false
          })
        }
      } else if (inputId === "mobile") {
        if (this.state.mobile !== '' && utils.string.checkMobile(this.state.mobile)) {
          this.setState({mobileCheckMessage: ''})
        } else {
          this.setState({mobileCheckMessage: i18n.t('signup.mobileRegexpMsg', '모바일 번호가 유효하지 않습니다.')})
        }
      } else if (inputId === "email") {
        if (this.state.email !== '' && utils.string.checkEmail(this.state.email)) {
          this.setState({emailCheckMessage: ''})
        } else {
          this.setState({emailCheckMessage: i18n.t('signup.emailRegexpMsg', '이메일 주소가 유효하지 않습니다.')})
        }
      } else {
        this.setState({inputId: inputValue})
      }
    });
  }

  updateUser() {
    this.setState({mobileCheckMessage: ''})
    this.setState({emailCheckMessage: ''})

    if (this.state.name === '') {
      this.nameInput.current.focus();
      return;
    }
    if (!utils.string.checkMobile(this.state.mobile)) {
      this.setState({mobileCheckMessage: i18n.t('signup.mobileRegexpMsg', '모바일 번호가 유효하지 않습니다.')})
      // this.mobileInput.current.focus();
      return;
    }
    if (!utils.string.checkEmail(this.state.email)) {
      this.setState({emailCheckMessage: i18n.t('signup.emailRegexpMsg', '이메일 주소가 유효하지 않습니다.')})
      this.emailInput.current.focus();
      return;
    }


    this.setState({isClicked: true});

    APIUtils.user.updateUser({
      body: {
        userId: this.state.userId,
        name: this.state.name,
        email: this.state.email,
        mobile: this.state.mobile,
        company: this.state.company,
        team: this.state.team,
        timeZone: this.state.timeZone,
        userImg: this.state.userImg
      }
    }, (data) => {
      if (data.status === 200) {
        this.setState({registerMessage: ''});
        if (this.state.timeZone && this.state.timeZone.indexOf('___') > -1) {
          utils.setTimeZone(this.state.timeZone.split('___')[1]);
        }
        notification.info(i18n.t('common.editSuccessful', '변경 되었습니다.'));
      } else {
        this.setState({registerMessage: data.message})
      }
      this.setState({isClicked: false});
    });
  }

  updateUserPassword() {
    if (!utils.string.checkPassword(this.state.toPassword)) {
      this.setState({
        passwordValid: false,
        passwordSameCheckMessage: i18n.t('signup.pwdRegexpMsg', '비밀번호는 숫자, 특문 각 1회 이상, 영문은 2개 이상 사용하여 8자리 이상 입력')
      });
      this.toPasswordInput.current.focus();
      return;
    }
    if (this.state.toPassword !== this.state.toPasswordConfirm) {
      this.setState({
        passwordValid: false,
        passwordSameCheckMessage: i18n.t('signup.pwdDifferentMsg', '비밀번호가 일치하지 않습니다.')
      });
      this.toPasswordInput.current.focus();
      return;
    }

    this.setState({passwordValid: true, passwordSameCheckMessage: ''})


    this.updateUserConfirmDialog.current.open({
      title: i18n.t('common.confirmEditMessage', '변경 하시겠습니까?'),
    }, () => {
      APIUtils.user.changePassword({
        body: {
          password: this.state.nowPassword,
          changePassword: this.state.toPassword
        }
      }, (data) => {
        if (data.status === 200 && data.success === true) {
          this.passwordEditViewCancel();
          notification.info(i18n.t('common.editSuccessful', '변경 되었습니다.'));
        } else {
          this.setState({registerMessage: data.message})
        }
        this.setState({isClicked: false});
      });
    });
  }

  passwordEditViewCancel() {
    this.setState({isPasswordEditView: false, registerMessage: '', nowPassword: '', toPassword: '', toPasswordConfirm: ''});
  }

  onDrop(acceptedFiles) {
    acceptedFiles.forEach(file => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (event) {
      }; // data url!
      fileToBase64(file).then(result => {
        this.setState({userImg: result});
      });
    });
  }

  toggleDeleteAccountDialog() {
    this.setState({
      deleteAccountConfirm: !this.state.deleteAccountConfirm
    })
  }

  authPassword() {
    const jasypt = new Jasypt();
    jasypt.setPassword(utils.auth.getUserId());

    APIUtils.checkPassAndUser({
      body: {userId: utils.auth.getUserId(), password: jasypt.encrypt(this.state.passwordAuth), isUser: true}
    }, (data) => {
      if (data.status === 200 && data.body && data.body.userId) {
        if (data.body.userId.indexOf('*') > -1) {
          notification.warn(i18n.t("account.passwordAuthorizationFailed", "비밀번호를 확인하세요."))
        } else {

          this.setState({
            showProfileContent: true,
            userId: data.body.userId,
            name: data.body.name,
            email: data.body.email,
            mobile: data.body.mobile,
            company: data.body.company,
            team: data.body.team,
            timeZone: data.body.timeZone
          });
        }
      } else {
        notification.warn(data.message)
      }
    })
  }

  render() {
    const { history } = this.props;

    return (
      <>
        <div className="app-page-title">
          <div>
            <h1><FontAwesomeIcon icon={['fas', 'user']} className="icon"/> {i18n.t('account.title', '사용자 프로필')}</h1>
          </div>
        </div>
        {this.state.showProfileContent?
        (<Card className="card-box card-maru-custom">
          <CardContent className="p-4">
            <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between">
              <div className="text-center text-lg-left mb-3 mb-lg-0">
                <h1 className="display-4 font-weight-bold">{this.state.userId}</h1>
              </div>
              <div hidden={this.state.isPasswordEditView} className="text-center text-lg-left">
                <Button size="small" className="button-maru-outlined m-1" onClick={()=> {history.push('/loginHistory')}}>
                  로그인 기록
                </Button>
                <Button
                  onClick={() => {
                    this.setState({isPasswordEditView: true})
                  }}
                  size="small"
                  variant="text"
                  className="button-maru-second-outlined m-1">
                <span className="btn-wrapper--icon">
                  <FontAwesomeIcon icon={['fas', 'key']}/>
                </span>
                  <span className="btn-wrapper--label">{i18n.t('account.updatePassword', '비밀번호 수정')}</span>
                </Button>
              </div>
            </div>
            <div className="divider my-3"/>
            <div hidden={this.state.isPasswordEditView}>
              <Grid item lg={6} className="d-flex align-items-center">
                <div className="divider-v d-none d-lg-block divider-v-md"/>
                <div className="w-100 pr-0 pr-lg-5">
                  <div className="mb-3">
                    <div className="d-flex justify-content-between">
                      <label className="font-weight-bold mb-2">
                        {i18n.t('userList.name', '이름')}
                      </label>
                    </div>
                    <CTextField
                      className="input-maru-custom"
                      name="name"
                      inputRef={this.nameInput}
                      inputProps={{maxLength: 50}}
                      variant="outlined"
                      size="small"
                      fullWidth
                      placeholder="Enter your name"
                      type="text"
                      onChange={this.setInputValue} value={this.state.name}
                    />
                  </div>
                  <div className="mb-3">
                    <div className="d-flex justify-content-between">
                      <label className="font-weight-bold mb-2">
                        {i18n.t('userList.mobile', '모바일')}
                      </label>
                    </div>
                    <CTextField
                      className="input-maru-custom"
                      name="mobile"
                      variant="outlined"
                      size="small"
                      fullWidth
                      placeholder="Enter your phone number"
                      type="text"
                      InputProps={{
                        value: this.state.mobile,
                        onChange: this.setInputValue
                      }}
                    />
                    <FormHelperText
                      error={this.state.mobileCheckMessage !== ''}>{this.state.mobileCheckMessage}</FormHelperText>
                  </div>
                  <div className="mb-3">
                    <div className="d-flex justify-content-between">
                      <label className="font-weight-bold mb-2">
                        {i18n.t('userList.email', '이메일')}
                      </label>
                    </div>
                    <CTextField
                      className="input-maru-custom"
                      name="email"
                      inputRef={this.emailInput}
                      variant="outlined"
                      size="small"
                      fullWidth
                      placeholder="Enter your email address"
                      type="email"
                      onChange={this.setInputValue} value={this.state.email}
                    />
                  </div>
                  <Grid container spacing={6}>
                    <Grid item md={6}>
                      <div className="mb-3">
                        <div className="d-flex justify-content-between">
                          <label className="font-weight-bold mb-2">
                            {i18n.t('userList.company', '회사')}
                          </label>
                        </div>
                        <CTextField
                          className="input-maru-custom"
                          name="company"
                          variant="outlined"
                          size="small"
                          fullWidth
                          placeholder="Enter your company name"
                          type="text"
                          onChange={this.setInputValue} value={this.state.company}
                        />
                      </div>
                      <div className="mb-3">
                        <div className="d-flex justify-content-between">
                          <label className="font-weight-bold mb-2">
                            {i18n.t('userList.team', '부서/팀')}
                          </label>
                        </div>
                        <CTextField
                          className="input-maru-custom"
                          name="team"
                          variant="outlined"
                          size="small"
                          fullWidth
                          placeholder="Enter your team name"
                          type="text"
                          onChange={this.setInputValue} value={this.state.team}
                        />
                      </div>
                      <div className="mb-3">
                        <div className="d-flex justify-content-between">
                          <label className="font-weight-bold mb-2">
                            {i18n.t('common.timeZone', '타임존')}
                          </label>
                        </div>
                        <FormControl fullWidth>
                          <Select
                            className="input-maru-custom"
                            name="timeZone"
                            value={this.state.timeZone}
                            onChange={this.setInputValue}>
                            {
                              Object.keys(utils.timeZones).map(key => {
                                return (<MenuItem value={utils.timeZones[key].GMT + '___' + key} key={key}>{'(' + utils.timeZones[key].GMT + ') ' + utils.timeZones[key].DESC}</MenuItem>)
                              })
                            }
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>
                    <Grid item md={6}>
                      <div className="mb-3">
                        <div className="d-flex justify-content-between">
                          <label className="font-weight-bold mb-2">
                            {i18n.t('signup.picture', '사진')}
                          </label>
                        </div>
                        {/*<div className="py-4 d-flex align-items-center justify-content-center">*/}
                        {/*<div className="dropzone rounded shadow-xxl">*/}
                        {/*<div className="dropzone rounded-circle shadow-sm">*/}
                        <div className="dropzone text-center p-2 flex-column">
                          <div className="dropzone-inner-wrapper dropzone-inner-wrapper-alt">
                            <Dropzone onDrop={this.onDrop}>
                              {({getRootProps, getInputProps, isDragAccept, isDragReject, isDragActive}) => (
                                <div {...getRootProps()}>
                                  <input {...getInputProps()}/>
                                  {/* <div className="dropzone-inner-wrapper d-100 rounded-circle dropzone-avatar">
                                    <div className="avatar-icon-wrapper d-100 rounded-circle m-2"> */}
                                      {/* <Button
                                        className="avatar-button badge shadow-xxl btn-icon badge-position badge-position--bottom-right border-0 text-indent-0 d-30 badge-circle btn-success hover-scale-lg text-white">
                                        <EditTwoToneIcon className="w-50"/>
                                      </Button> */}
                                      {this.state.userImg === undefined && isDragAccept && (
                                        // <div className="rounded overflow-hidden d-140 bg-success text-center font-weight-bold text-white d-flex justify-content-center align-items-center">
                                        <div className="btn-icon hover-scale-rounded shadow-light-sm rounded-lg text-first">
                                          {/*<CheckIcon className="d-40" />*/}
                                          <CheckIcon className="d-30"/>
                                        </div>
                                      )}
                                      {this.state.userImg === undefined && isDragReject && (
                                        // <div className="rounded overflow-hidden d-140 bg-danger text-center font-weight-bold text-white d-flex justify-content-center align-items-center">
                                        <div className="btn-icon hover-scale-rounded shadow-light-sm rounded-lg text-first">
                                          {/*<CloseTwoToneIcon className="d-60" />*/}
                                          <CloseTwoToneIcon className="d-40"/>
                                        </div>
                                      )}
                                      {this.state.userImg === undefined && !isDragActive && (
                                        /*<div className="rounded overflow-hidden d-140 bg-neutral-dark text-center font-weight-bold text-black-50 d-flex justify-content-center align-items-center">
                                          <AccountCircleTwoToneIcon className="d-50" />
                                        </div>*/
                                        <div className="btn-icon hover-scale-rounded shadow-light-sm rounded-lg text-maru-color">
                                          <AccountCircleTwoToneIcon className="d-30"/>
                                        </div>
                                      )}
                                      {this.state.userImg !== undefined && (
                                        /*<div
                                          className="rounded avatar-image overflow-hidden d-140 bg-neutral-success text-center font-weight-bold text-success d-flex justify-content-center align-items-center">
                                          <img
                                            className="img-fluid img-fit-container rounded-sm"*/
                                        <div className="btn-icon hover-scale-rounded shadow-light-sm rounded-lg text-first">
                                          <img
                                            className="img-fluid img-fit-container rounded-sm"
                                            src={this.state.userImg}
                                            alt="..."
                                          />
                                        </div>
                                      )}

                                    </div>
                              )}
                            </Dropzone>
                          </div>
                          <div className="my-1 text-center">
                            {i18n.t('common.uploadLayerMsg', 'Drag and drop Or Click')}
                          </div>
                        </div>
                        {/*</div>*/}
                        {/*</div>*/}
                      </div>
                    </Grid>
                  </Grid>

                  <FormHelperText
                    error={this.state.registerMessage !== ''}>{this.state.registerMessage}</FormHelperText>

                  <div className="text-center mb-4">
                    <Button className="button-maru-second-contained text-uppercase font-weight-bold font-size-sm my-3"
                            hidden={!this.state.isClicked}
                            onClick={this.registerUser}
                    >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={['fas', 'cog',]} spin/>
                                </span>
                      ...
                    </Button>
                    <Button className="button-maru-second-contained text-uppercase font-weight-bold font-size-sm my-3 mx-1"
                      hidden={this.state.isClicked}
                      onClick={(event) => {
                        this.updateUserConfirmDialog.current.open({
                          title: i18n.t('common.confirmEditMessage', '변경 하시겠습니까?'),
                        }, () => {
                          this.updateUser();
                        });
                        event.stopPropagation();
                      }}>
                      {i18n.t('common.edit', '수정')}
                    </Button>
                    <Button className="button-maru-contained text-uppercase font-weight-bold font-size-sm my-3 mx-1"
                      onClick={(event) => {
                        this.inputDialogRef.current.open(() => {
                          const inputId = this.inputDialogRef.current.state.inputText;
                          const inputElement = this.inputDialogRef.current.inputElement.current.input
                          
                          if(utils.string.isEmpty(inputId)) {
                            inputElement.focus();
                            notification.warn(i18n.t("account.enterUserId"));
                            return;
                          }
                          if(inputId !== utils.auth.getUserId()) {
                            inputElement.focus();
                            notification.warn(i18n.t("account.notMatchId"));
                            return;
                          }

                          APIUtils.user.deleteUser({}, (data) => {
                            if(data.status === 200) {
                              APIUtils.logout((data) => {
                                history.push('/login');
                                notification.info(i18n.t("common.deleteAccountMessage", "탈퇴 처리 되었습니다."));
                              });
                            } else {
                              notification.warn(data.message)
                            }
                          })
                        });
                      }}>
                      {i18n.t("common.deleteAccount", "탈퇴")}
                    </Button>
                    <Dialog
                      classes={{ paper: 'modal-content bg-white text-dark rounded-lg modal-dark' }}
                      open={this.state.deleteAccountConfirm}
                      onClose={this.toggleDeleteAccountDialog}
                      aria-labelledby="form-dialog-title">
                      <DialogTitle id="form-dialog-title">{i18n.t("common.deleteAccountInfo", "회원 탈퇴")}</DialogTitle>
                      <DialogContent className="p-4">
                        <DialogContentText>
                          <ul className="text-dark">
                            <li>{i18n.t("common.deleteAccountWarningMessage")}</li>
                            <li>{i18n.t("common.deleteAccountWarningMessageProject")}</li>
                          </ul>
                        </DialogContentText>
                        <DialogContentText className="mb-0">
                          <CTextField
                            className="text-dark"
                            variant="outlined"
                            size="small"
                            autoFocus
                            margin="dense"
                            id="input-with-icon-textfield134"
                            label={i18n.t("signup.passwordConfirm", "비밀번호 확인")}
                            type="password"
                            fullWidth
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                </InputAdornment>
                              )
                            }}
                          />
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions className="p-4">
                        <Button onClick={() => {
                          APIUtils.logout((data) => {
                            history.push('/login');
                            notification.warn(i18n.t("common.deleteAccountMessage", "탈퇴 처리 되었습니다."));
                          });
                        }} className="button-maru-contained shadow-none">
                          {i18n.t("common.deleteAccountInfo", "회원 탈퇴")}
                        </Button>
                        <Button
                          onClick={this.toggleDeleteAccountDialog}
                          variant="text"
                          className="button-maru-gray-contained mr-3 shadow-none">
                          {i18n.t("common.cancelDeleteAccount", "탈퇴 취소")}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                </div>
              </Grid>
            </div>
            <div hidden={!this.state.isPasswordEditView}>
              <Grid item lg={6} className="d-flex align-items-center">
                <div className="divider-v d-none d-lg-block divider-v-md"/>
                <div className="w-100 pr-0 pr-lg-5">
                  <div className="mb-3">
                    <div className="d-flex justify-content-between">
                      <label className="font-weight-bold mb-2">
                        {i18n.t('account.nowPassword', '비밀번호')}
                      </label>
                    </div>
                    <CTextField
                      className="input-maru-custom"
                      inputRef={this.nowPasswordInput}
                      id="nowPassword"
                      name="nowPassword"
                      variant="outlined"
                      size="small"
                      fullWidth
                      placeholder="Enter your password"
                      type={this.state.showNowPassword? 'text': 'password'}
                      InputProps={{
                        maxLength: 100,
                        endAdornment: (
                          <InputAdornment position="end">
                            <FontAwesomeIcon icon={['far', this.state.showNowPassword? 'eye':'eye-slash']} className="font-size-xl nav-link" onClick={(event) => {
                              this.setState({showNowPassword: !this.state.showNowPassword});
                            }}/>
                          </InputAdornment>
                        )
                      }}
                      onChange={this.setInputValue} value={this.state.nowPassword}
                    />
                  </div>
                  <div className="mb-3">
                    <div className="d-flex justify-content-between">
                      <label className="font-weight-bold mb-2">
                        {i18n.t('account.toPassword', '비밀번호')}
                      </label>
                    </div>
                    <CTextField
                      className="input-maru-custom"
                      inputRef={this.toPasswordInput}
                      id="toPassword"
                      name="toPassword"
                      variant="outlined"
                      size="small"
                      fullWidth
                      placeholder="Enter your password"
                      type={this.state.showToPassword? 'text': 'password'}
                      InputProps={{
                        maxLength: 100,
                        endAdornment: (
                          <InputAdornment position="end">
                            <FontAwesomeIcon icon={['far', this.state.showToPassword? 'eye':'eye-slash']} className="font-size-xl nav-link" onClick={(event) => {
                              this.setState({showToPassword: !this.state.showToPassword});
                            }}/>
                          </InputAdornment>
                        )
                      }}
                      onChange={this.setInputValue} value={this.state.toPassword}
                    />
                  </div>
                  <div className="mb-3">
                    <div className="d-flex justify-content-between">
                      <label className="font-weight-bold mb-2">
                        {i18n.t('account.toPasswordConfirm', '비밀번호 확인')}
                      </label>
                    </div>
                    <CTextField
                      className="input-maru-custom"
                      id="toPasswordConfirm"
                      name="toPasswordConfirm"
                      variant="outlined"
                      size="small"
                      fullWidth
                      placeholder="Enter your password"
                      type={this.state.showToPasswordConfirm? 'text': 'password'}
                      InputProps={{
                        maxLength: 100,
                        endAdornment: (
                          <InputAdornment position="end">
                            <FontAwesomeIcon icon={['far', this.state.showToPasswordConfirm? 'eye':'eye-slash']} className="font-size-xl nav-link" onClick={(event) => {
                              this.setState({showToPasswordConfirm: !this.state.showToPasswordConfirm});
                            }}/>
                          </InputAdornment>
                        )
                      }}
                      onChange={this.setInputValue} value={this.state.toPasswordConfirm}
                    />
                    <FormHelperText
                      error={!this.state.passwordValid}>{this.state.passwordSameCheckMessage}</FormHelperText>
                  </div>
                  <FormHelperText
                    error={this.state.registerMessage !== ''}>{this.state.registerMessage}</FormHelperText>

                  <div className="d-flex align-items-center justify-content-center flex-wrap">
                    <Button className="button-maru-contained text-uppercase font-weight-bold font-size-sm my-3 m-2"
                            hidden={!this.state.isClicked}
                            onClick={this.registerUser}
                    >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={['fas', 'cog',]} spin/>
                                </span>
                      ...
                    </Button>
                    <Button className="button-maru-contained text-uppercase font-weight-bold font-size-sm my-3 m-2"
                            hidden={this.state.isClicked}
                            onClick={(event) => {
                              this.updateUserPassword();
                            }}
                    >
                      {i18n.t('common.edit', '수정')}
                    </Button>

                    <Button className="button-maru-outlined text-uppercase font-weight-bold font-size-sm my-3 m-2"
                            onClick={(event) => {
                              this.passwordEditViewCancel();
                            }}
                    >
                      {i18n.t('common.cancel', '취소')}
                    </Button>
                  </div>
                </div>
              </Grid>
            </div>

          </CardContent>
        </Card>)
        :<Card className="card-box card-maru-custom h-100 d-flex align-items-center justify-content-center">
          <CardContent>
            <Typography className="text-dark mb-2" variant="h6">{i18n.t("account.passwordAuthorization")}</Typography>
            <CTextField
              className="input-maru-custom bg-white box-shadow-none"
              id="passwordAuth"
              name="passwordAuth"
              variant="outlined"
              size="small"
              fullWidth
              placeholder={i18n.t("account.passwordinputplaceholder")}
              type={this.state.showAuthPassword? 'text': 'password'}
              InputProps={{
                maxLength: 100,
                endAdornment: (
                  <InputAdornment position="end">
                    <FontAwesomeIcon icon={['far', this.state.showAuthPassword? 'eye':'eye-slash']} className="font-size-xl nav-link" onClick={(event) => {
                      this.setState({showAuthPassword: !this.state.showAuthPassword});
                    }}/>
                  </InputAdornment>
                )
              }}
              onKeyDown={(e)=> {if(e.keyCode === 13) {this.authPassword()}}}
              onChange={this.setInputValue} value={this.state.passwordAuth}
              />
              <div className="w-100 text-center mt-2 mb-5">
                <Button className="button-maru-contained" size="small" onClick={() => {
                  this.authPassword()
                }}>확인</Button>
              </div>
          </CardContent>
        </Card> }
        <InputDialog ref={this.inputDialogRef} 
          title={i18n.t("common.deleteAccountInfo")} 
          okButtonName={i18n.t("common.deleteAccountInfo")} 
          cancelButtonName={i18n.t("common.cancelDeleteAccount")}
          placeholder="ID를 입력하세요."
          getInput={true}
          contents={[i18n.t("common.deleteAccountWarningMessage"), i18n.t("common.deleteAccountWarningMessageProject")]} />
        <ConfirmDialog ref={this.updateUserConfirmDialog}/>
      </>
    )
  }
}

export default withRouter(Account);
