import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import { Box } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18n from "../../../../config/lang/i18n";
import MUITable from "../../../component/MUITable";
import TitBreadcrumbs from "../../../component/TitBreadcrumbs";
import APIUtils from '../../../../utils/APIUtils';
import notification from "../../../component/Notification";
import utils from "../../../../utils/Utils";
import queryString from 'query-string';
import DateFnsUtils from '@date-io/date-fns';
import koLocale from 'date-fns/locale/ko';

class EventList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      events: [],
      searchDate: undefined,
      start: undefined,
      end: undefined,
      isLoading: false,
    }

    this.handleDateChange = this.handleDateChange.bind(this);
    this.getEventList = this.getEventList.bind(this);
  }

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    let startStr = query.start;
    let endStr = query.end;

    let start;
    let end;


    if (utils.string.isNotBlank(startStr) && utils.string.isNotBlank(endStr)) {
      start = parseInt(startStr);
      end = parseInt(endStr);

      this.setState({searchDate: utils.date.dateTimeToJsDate(utils.date.millisToDateTime(start))});
    } else {
      let selectedDate = utils.date.now();
      let startDateTime = utils.date.getFirstTime(selectedDate);
      let endDateTime = utils.date.getLastTime(startDateTime);

      start = startDateTime.toMillis()
      end = endDateTime.toMillis();
    }

    this.setState({start: start , end: end}, () => {
      this.getEventList();
    })
  }

  getEventList() {
    this.setState({ isLoading: true });
    APIUtils.superAdmin.getEvents({
      start: this.state.start,
      end: this.state.end
    }, (data) => {
      if (data.status === 200) {
        this.setState({events: data.body})
      } else {
        notification.error(data.message);
      }
      this.setState({ isLoading: false });
    });
  }

  handleDateChange(value) {
    let selectedDate = utils.date.jsDateToDateTime(value);
    let startDateTime = utils.date.getFirstTime(selectedDate);
    let endDateTime = utils.date.getLastTime(startDateTime);

    let start = startDateTime.toMillis()
    let end = endDateTime.toMillis();

    this.setState({
      searchDate: value,
      start: start,
      end: end
    }, () => {
      this.getEventList();
    })
  }

  render() {
    const columns = [
      {
        label: i18n.t('event.alertTime', '발생 시간'), name: 'created', options: {
          sort: false,
          setCellProps: (_ => ({style: {minWidth: "7vw", maxWidth: "7vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center', cursor: 'pointer'}}>{utils.date.millisToString(value)}</div>
          }
        }
      },
      {
        label: i18n.t('common.level', '레벨'), name: 'level', options: {
          setCellProps: (_ => ({style: {minWidth: "4vw", maxWidth: "4vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            let color = 'info';
            if (value === 'ERROR') {
              color = 'danger';
            } else if (value === 'WARN') {
              color = 'warning';
            }

            return (<div className="d-flex justify-content-between" style={{cursor: 'pointer'}}>
              <div className={'badge badge-' + color} style={{textAlign: 'center', margin: '0 auto'}}>{value}</div>
            </div>)
          }
        }
      },
      {
        label: i18n.t('common.message', '메시지'), name: 'message', options: {
          setCellProps: (_ => ({style: {minWidth: "28vw", maxWidth: "28vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div className="text-overflow-ellipsis text-left nav-link">{utils.string.unescape(value)}</div>
          }
        }
      }
    ];

    const options = {
      onTableInit: (e) => {
      },
      refresh: () => {
        this.getEventList();
      },
      draggableColumns: {
        enabled: true
      },
      serverSide: false,
      rowsPerPage: 15,
      filter: false,
      responsive: 'simple',
      selectableRows: 'none',
      search: true,
      searchOpen: true,
      customToolbar: () => {
        return (<>
          <Box className="d-inline-flex align-items-center mx-2" style={{width: '6rem'}}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={koLocale}>
              <DatePicker
                views={["year", "month", "date"]}
                // minDate={new Date("2018-03-01")}
                maxDate={utils.date.now()}
                value={this.state.searchDate}
                // onChange={this.handleDateChange}
                format="yyyy-MM-dd"
                variant="inline"
                autoOk={true}
                onChange={(value)=>{
                  this.handleDateChange(value, this.getEventList)
                }}
              />
            </MuiPickersUtilsProvider>
          </Box>
        </>)
      }
    };

    return (
      <>
        <TitBreadcrumbs title={i18n.t('event.title', '이벤트')} />

        <div className="app-page-title">
          <div>
            <h1><FontAwesomeIcon icon={['fas', 'receipt']}
                                 className="icon"/> {i18n.t('event.title', '이벤트')}</h1>
          </div>
        </div>
        <MUITable columns={columns} data={this.state.events} options={options} loading={this.state.isLoading}/>
      </>
    )
  }
}

export default withRouter(EventList);
