import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import clsx from 'clsx';
import {setSidebarToggleMobile} from '../../reducers/ThemeOptions';
import HeaderUserbox from './HeaderUserBox';
import HeaderAlarm from './HeaderAlarm';
import MenuIcon from '@material-ui/icons/Menu';

const Header = (props) => {
  const {
    headerShadow,
    headerBgTransparent,
    sidebarToggleMobile,
    setSidebarToggleMobile,
  } = props;

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  return (
    <>
      <div
        className={clsx('app-header', {
          'app-header--shadow': headerShadow,
          'app-header--opacity-bg': headerBgTransparent
        })}>
        <div className="app-header--pane">
          <button
            className={clsx(
              'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
              {'is-active': sidebarToggleMobile}
            )}
            onClick={toggleSidebarMobile}>
            <MenuIcon className="text-white" fontSize="large"/>
          </button>
          {/* <HeaderMenu/> */}
        </div>
        <div className="app-header--pane d-flex align-items-center">
          <HeaderAlarm />
          <HeaderUserbox />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerBgTransparent: state.ThemeOptions.headerBgTransparent,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));