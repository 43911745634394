import React, {Component} from 'react';
import {connect} from 'react-redux';
import queryString from 'query-string';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import i18n from "../../../config/lang/i18n";
import {Button, Tooltip} from "@material-ui/core";
import APIUtils from "../../../utils/APIUtils";
import TitBreadcrumbs from "openmaru/component/TitBreadcrumbs";
import ConfirmDialog from "openmaru/component/ConfirmDialog";
import notification from 'openmaru/component/Notification';
import MUITable from "openmaru/component/MUITable";
import utils from "../../../utils/Utils";
import BillDetailList from "./BillDetailList";
import {setShowBodyLoading, setUserProjects} from "../../../reducers/OpenmaruReducer";

class BillManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTime: undefined,
      pid: undefined,
      projectBills: [],
      isLoading: false,
    }

    this.confirmDialog = React.createRef();
    this.detailPopup = React.createRef();

    this.showBillDetail = this.showBillDetail.bind(this);
    this.payment = this.payment.bind(this);
  }

  componentDidUpdate() {
    const query = queryString.parse(this.props.location.search);
    if (query.id !== this.state.pid) {
      this.setState({pid: query.id}, () => {
        this.getProjectBills();
      });
    }
  }

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    this.setState({pid: query.id}, () => {
      this.getProjectBills();
    })
  }

  getProjectBills() {
    this.setState({isLoading: true})

    APIUtils.project.getProjectBills({pid: this.state.pid}, (data) => {
      if (data.status === 200 && data.body && data.body.length >= 0) {
        const projectBills = [];
        let totalCount = data.body.length;

        data.body.forEach((projectBill, i) => {
          projectBill.num = totalCount--;
          projectBills.push(projectBill);
        });
        
        this.setState({currentTime: data.currentTime, projectBills: projectBills}, () => {
        })
      } else if (data.status === 403) {
        notification.warn(i18n.t('common.permissionErrorMessage', {}))
        this.props.history.push('/projectsDashboard');
      }
      this.setState({isLoading: false})
    });
  }

  showBillDetail(bill) {
    this.detailPopup.current.showBillDetail(bill.billId, bill.pid);
  }

  payment(bill) {
    APIUtils.project.payment({
      pid: bill.pid,
      billId: bill.billId
    }, (data) => {
      if (data.status === 200) {
        notification.info(i18n.t('common.requestSuccessful', '요청 정보를 처리 하였습니다.'))
      } else {
        notification.error(data.message);
      }

      if (data.status !== 500) {
        this.getProjectBills();
      }
    });
  }

  render() {
    const columns = [
      { label: i18n.t('billManagement.billId', '청구서 아이디'), name: 'billId',
        options: {
          setCellProps: (_ => ({style: {minWidth: "10vw", maxWidth: "10vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center'}}>{value}</div>
          }
        }
      },
      { label: i18n.t('billManagement.billingDate', '청구일'), name: 'billingDate',
        options: {
          setCellProps: (_ => ({style: {minWidth: "10vw", maxWidth: "10vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center'}}>{utils.date.fromToString(value, 'yyyyMMdd', 'yyyy-MM-dd')}</div>
          }
        }
      },
      { label: i18n.t('billManagement.amount', '청구금액'), name: 'amount',
        options: {
          setCellProps: (_ => ({style: {minWidth: "10vw", maxWidth: "10vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center'}}>{utils.string.numberToCommas(value)}</div>
          }
        }
      },
      { label: i18n.t('billManagement.paymentDueDate', '결제예정일'), name: 'paymentDate',
        options: {
          setCellProps: (_ => ({style: {minWidth: "6vw", maxWidth: "6vw"}})),
          customBodyRenderLite: (dataIndex, rowIndex) => {
            const rowData = this.state.projectBills[rowIndex]? this.state.projectBills[rowIndex]: {};
            const paymentDate = rowData.paymentDate;
            return <div style={{textAlign: 'center'}}>{utils.date.fromToString(paymentDate, 'yyyyMMdd', 'yyyy-MM-dd')}</div>
          }
        }
      },
      { label: `${i18n.t('common.status', '상태')}`, name: 'pay',
        options: {
          setCellProps: (_ => ({style: {minWidth: "6vw", maxWidth: "6vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            let color = 'dark';
            if (value && value.status === '1001') {
              color = 'success';
            } else if (value && value.status === '1002') {
              color = 'warning';
            } else if (value && value.status === '1003') {
              color = 'danger';
            }
            return (<div className="d-flex justify-content-between">
              <Tooltip title={value?(value.resultMessage===null)?'': value.resultMessage:''} arrow placement="top-start" classes={{tooltip: "p-3"}}>
                <div className={'badge badge-' + color} style={{textAlign: 'center', margin: '0 auto'}}>{value?value.statusNm:'결제대기'}</div>
              </Tooltip>
            </div>)
          }
        }
      },
      { label: i18n.t('paymentsManagement.amount', '결제 금액'), name: 'pay',
        options: {
          setCellProps: (_ => ({style: {minWidth: "7vw", maxWidth: "7vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center'}}>{value?utils.string.numberToCommas(value.amount):''}</div>
          }
        }
      },
      { label: i18n.t('common.command'), name: "",
        options: {
          setCellProps: (_ => ({style: {minWidth: "10vw", maxWidth: "10vw"}})),
          customBodyRenderLite: (dataIndex, rowIndex) => {
            const rowData = this.state.projectBills[rowIndex]? this.state.projectBills[rowIndex]: {};

            return (<div className="text-center">
              <Tooltip title={i18n.t('billManagement.billDetail')} arrow placement="top-start" classes={{tooltip: "p-3"}}>
                <span>
                  <Button
                    onClick={() => this.showBillDetail(rowData)}
                    className="btn-dark mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                    disabled={(rowData.billId)?false:true}>
                    <FontAwesomeIcon icon={['fas', 'list-alt']} className="font-size-md"/>
                  </Button>
                </span>
              </Tooltip>
              <Tooltip title={i18n.t('paymentsManagement.requestPayment')} arrow placement="top-start" classes={{tooltip: "p-3"}}>
                <span>
                  <Button
                    onClick={() =>
                      this.confirmDialog.current.open({
                        title: utils.date.getYearMonthStr(rowData.billingDate, 'yyyyMMdd') + ' - ' + i18n.t('paymentsManagement.requestPaymentMessage'),
                        type: 'info'
                      }, () => {
                        this.payment(rowData)
                      })
                    }
                    className="btn-dark mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                    disabled={(utils.string.isBlank(rowData.billId) || rowData.amount === 0 || (rowData.billId && (rowData.pay && rowData.pay.status === '1001')))?true:false}
                  >
                    <FontAwesomeIcon icon={['fas', 'money-check-alt']} className="font-size-md"/>
                  </Button>
                </span>
              </Tooltip>
            </div>)
          }
        }
      },
    ];

    const options = {
      onTableInit: (e) => {
      },
      draggableColumns: {
        enabled: true
      },
      refresh: () => {
        this.getProjectBills();
      },
      serverSide: false,
      rowsPerPage: 15,
      filter: false,
      responsive: 'simple',
      selectableRows: 'none',
      search: true,
      searchOpen: true,
      setTableProps: () => {
        return {
          size: 'small'
        };
      },
      display: false
    };

    return (

      <>
        <TitBreadcrumbs title={i18n.t('billManagement.title', '프로젝트 결제내역')} />

        <div className="app-page-title">
          <div>
            <h1><FontAwesomeIcon icon={['fas', 'money-check-alt']}
                                 className="icon"/> {i18n.t('billManagement.title', '프로젝트 결제내역')}</h1>
          </div>
        </div>
        <MUITable columns={columns} options={options} data={this.state.projectBills} loading={this.state.isLoading}/>
        <ConfirmDialog ref={this.confirmDialog}/>
        <BillDetailList ref={this.detailPopup} />
      </>
    )
  }
}


BillManagement = connect(
  (state) => ({
    showBodyLoading: state.OpenmaruReducer.showBodyLoading,
    userProjects: state.OpenmaruReducer.userProjects,
    userSelectedProject: state.OpenmaruReducer.userSelectedProject
  }),
  (dispatch) => ({
    setShowBodyLoading: (enable) => dispatch(setShowBodyLoading(enable)),
    setUserProjects: (projects) => dispatch(setUserProjects(projects))
  })
)(BillManagement);
export default BillManagement;
