import React, {Component} from 'react';
import {connect} from "react-redux";
import LoadingImage from '../../assets/images/loading/openmaru_loading.gif';

export default class LoadingBody extends Component {
  render() {
    return (
      <div hidden={!this.props.showBodyLoading}
           className="js-imgSizeSame-wrap"
           style={{
              position: 'absolute',
              background: '#FFFFFF',
              opacity: 0.5,
              left: this.props.bodyState.left + 'px',
              top: this.props.bodyState.top + 'px',
              width: this.props.bodyState.width + 'px',
              height: this.props.bodyState.height + 'px',
              zIndex: 100000}}
           >
        <table border="0" width="100%" height="100%">
          <tbody>
          <tr>
            <td align="center">
              <img width='200' src={LoadingImage} loading='lazy' alt='loading_image'/>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

LoadingBody = connect(
  (state) => ({
    showBodyLoading: state.OpenmaruReducer.showBodyLoading,
    bodyState: state.OpenmaruReducer.bodyState
  })
)(LoadingBody);

