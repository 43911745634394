import React, {Suspense, useState, useEffect} from 'react';
import {Switch, Route, Redirect, useLocation} from 'react-router-dom';
import {ClimbingBoxLoader} from 'react-spinners';

import MuiTheme from './theme';

import {ThemeProvider} from '@material-ui/styles';
import {AnimatePresence, motion} from 'framer-motion';

// Layout Blueprints
import LeftSidebar from './openmaru/layout-blueprints/LeftSidebar';

import AuthRoute from './AuthRoute';

import Main from './openmaru/page/Main';
import Login from './openmaru/page/Login';
import Signup from './openmaru/page/Signup';
import FindId from './openmaru/page/FindId';
import FindPw from './openmaru/page/FindPw';
import ResetPw from './openmaru/page/ResetPw';
import Terms from './openmaru/page/Terms';
import Marketing from 'openmaru/page/Marketing';
import Privacy from './openmaru/page/Privacy';
import Account from './openmaru/page/Account';
import Dashboard from './openmaru/page/admin/Dashboard';
import ProjectList from './openmaru/page/admin/ProjectList';
import UserList from './openmaru/page/admin/UserList';
import AdminBillManagement from './openmaru/page/admin/bill/BillManagement';
import AdminBillRangeManagement from './openmaru/page/admin/bill/BillRangeManagement';
import AdminQnaList from './openmaru/page/admin/qna/QnaList';
import EventList from "./openmaru/page/admin/event/EventList";
import InfoPage from './openmaru/page/admin/info/InfoPage';
import MessageSetting from './openmaru/page/admin/message/MessageSetting';

import ProjectsDashboard from './openmaru/page/project/ProjectsDashboard';
import AgentInstall from './openmaru/page/agent/AgentInstall';
import SystemUsage from './openmaru/page/sysUsage/SystemUsage';
import ProjectInformation from './openmaru/page/project/ProjectInformation';
import QnaList from './openmaru/page/qna/QnaList';
import AdminQnaContent from './openmaru/page/admin/qna/QnaContent';
import BillManagement from 'openmaru/page/bill/BillManagement';
import Invite from "./openmaru/page/member/Invite";
import ChangePw from './openmaru/page/ChangePw';
import PwChangeCheck from './openmaru/page/PwChangeCheck';
import PasswordHelp from 'openmaru/page/PasswordHelp';
import MemberList from "./openmaru/page/member/MemberList";
import QnaContent from "./openmaru/page/qna/QnaContent";
import PaymentResult from "./openmaru/page/bill/PaymentResult";
import ProjectRegisterSubscription from "./openmaru/page/project/ProjectRegisterSubscription";
import LoginHistory from "./openmaru/page/LoginHistory";
import utils from './utils/Utils';

const Routes = () => {
  const location = useLocation();
  const pageVariants = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'linear',
    duration: 0.3
  };

  const SuspenseLoading = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
      let timeout = setTimeout(() => setShow(true), 300);
      return () => {
        clearTimeout(timeout);
      };
    }, []);

    return (
      <>
        <AnimatePresence>
          {show && (
            <motion.div
              key="loading"
              initial={{opacity: 0}}
              animate={{opacity: 1}}
              exit={{opacity: 0}}
              transition={{duration: 0.4}}>
              <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
                <div className="d-flex align-items-center flex-column px-4">
                  <ClimbingBoxLoader color={'#3c44b1'} loading={true}/>
                </div>
                <div className="text-muted font-size-xl text-center pt-3">
                  Please wait while we load the live preview examples
                  <span className="font-size-lg d-block text-dark">
                        This live preview instance can be slower than a real
                        production build!
                      </span>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    );
  };

  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading/>}>

          <Switch>
            <Redirect exact from="/index.html" to="/" />

            {/* <Redirect exact from="/" to="/login" /> */}
            <Route exact path={'/'} component={Main}/>
            <Route path={'/login'} render={() => 
              utils.auth.isLogin()? 
                (utils.auth.hasSuperAdmin()? (<Redirect to={{ pathname: '/admin/dashboard'}} />)
                : (<Redirect to={{ pathname: '/projectsDashboard'}} />))
              : (<Login />)
            }></Route>
            <Route path={'/signup'} component={Signup}></Route>
            <Route path={'/findId'} component={FindId}></Route>
            <Route path={'/findPw'} component={FindPw}></Route>
            <Route path={'/resetPw'} component={ResetPw}></Route>
            <Route path={'/terms'} component={Terms}></Route>
            <Route path={'/marketing'} component={Marketing}></Route>
            <Route path={'/privacy'} component={Privacy}></Route>
            <Route path={'/invite'} component={Invite}></Route>
            <Route path={'/passwordHelp'} component={PasswordHelp}></Route>
            <AuthRoute path={'/changePw'} component={ChangePw}></AuthRoute>
            <AuthRoute path={'/pwChangeCheck'} component={PwChangeCheck}></AuthRoute>
            <AuthRoute path={'/projectRegisterSubscription'} component={ProjectRegisterSubscription}></AuthRoute>
            <AuthRoute path={'/paymentResult'} component={PaymentResult}></AuthRoute>
            <LeftSidebar>
              <Switch location={location} key={location.pathname}>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}  style={{height: 'calc(100% - 70px)'}}>
                  <AuthRoute path={'/admin/projectList'} component={ProjectList}></AuthRoute>
                  <AuthRoute path={'/projectsDashboard'} component={ProjectsDashboard}></AuthRoute>
                  <AuthRoute path={'/admin/billManagement'} component={AdminBillManagement}></AuthRoute>
                  <AuthRoute path={'/admin/billRangeManagement'} component={AdminBillRangeManagement}></AuthRoute>
                  <AuthRoute path={'/admin/userList'} component={UserList}></AuthRoute>
                  <AuthRoute exact path={'/admin/qnaList'} component={AdminQnaList}></AuthRoute>
                  <AuthRoute path={'/admin/qnaList/qnaContent'} component={AdminQnaContent}></AuthRoute>
                  <AuthRoute path={'/admin/dashboard'} component={Dashboard}></AuthRoute>
                  <AuthRoute path={'/admin/infoPage'} component={InfoPage}></AuthRoute>
                  <AuthRoute path={'/admin/messageSetting'} component={MessageSetting}></AuthRoute>
                  <AuthRoute path={'/memberList'} component={MemberList}></AuthRoute>
                  <AuthRoute exact path={'/billManagement'} component={BillManagement}></AuthRoute>
                  <AuthRoute path={'/agentInstall'} component={AgentInstall}></AuthRoute>
                  <AuthRoute path={'/systemUsage'} component={SystemUsage}></AuthRoute>
                  <AuthRoute path={'/projectInformation'} component={ProjectInformation}></AuthRoute>
                  <AuthRoute exact path={'/qnaList'} component={QnaList}></AuthRoute>
                  <AuthRoute path={'/admin/eventList'} component={EventList}></AuthRoute>
                  <AuthRoute path={'/qnaList/qnaContent'} component={QnaContent}></AuthRoute>
                  <AuthRoute path={'/account'} component={Account}></AuthRoute>
                  <AuthRoute path={'/loginHistory'} component={LoginHistory}></AuthRoute>
                </motion.div>
              </Switch>
            </LeftSidebar>
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  )
}

export default Routes;
