import React, {Component} from 'react';

import {Button, Checkbox, FormHelperText} from '@material-ui/core';
import i18n from "../../../config/lang/i18n";
import APIUtils from '../../../utils/APIUtils';
import utils from "../../../utils/Utils";
import queryString from 'query-string';
import '../../../assets/pricetable.scss';

class ProjectRegisterSubscription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pid: undefined,
      subscriptionCheck: false,
      nonCheckMessage: ""
    }

    this.checkBoxRef = React.createRef();
    this.handleCheck = this.handleCheck.bind(this);
    this.register = this.register.bind(this);
  }

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    this.setState({pid: query.pid});
  }

  handleCheck(event) {
    const target = event.target;
    this.setState({[target.name]: target.checked});
  }

  register() {
    if(!this.state.subscriptionCheck) {
      this.checkBoxRef.current.focus();
      this.setState({
        nonCheckMessage: i18n.t("billManagement.checkAgreementSubscription")
      })
      return;
    }
    APIUtils.project.subscriptionRegister({pid: this.state.pid}, (data) => {
      if(data.status === 200) {
        let returnUrl = '';
        if(utils.isMobile()) {
          returnUrl = data.body.mobile_url;
        } else {
          returnUrl = data.body.online_url;
        }

        document.getElementById('PayletterWindow').value = window;
        window.location.replace(returnUrl);
      }
    })
  }

  render() {
    const {SERVER, WASDB, URL} = utils.PRICE_TABLE;
    return (
      <>
        <div className="popup-background">
          <div className="popup-inner">
            <h3>{i18n.t("billManagement.pricing")}</h3>
            <div className='price-tag-table w-100'>
              <div className='price-tag-row'>
                <div className='product-name'>Server</div>
                <div className='product-price'>{i18n.t("billManagement.serverPrice", {serverPrice: SERVER.toLocaleString('ko-KR')})}</div>
              </div>
              <div className='price-tag-row'>
                <div className='product-name'>Application/Database</div>
                <div className='product-price'>{i18n.t("billManagement.wasDbPrice", {wasDbPrice: WASDB.toLocaleString('ko-KR')})}</div>
              </div>
              <div className='price-tag-row'>
                <div className='product-name'>URL</div>
                <div className='product-price'>{i18n.t("billManagement.urlPrice", {urlPrice: URL.toLocaleString('ko-KR')})}</div>
              </div>
            </div>
            <div>
              <Checkbox
                inputRef={this.checkBoxRef}
                size="small"
                checked={this.state.subscriptionCheck}
                onChange={this.handleCheck}
                name="subscriptionCheck"
                color="primary"
              />
              {i18n.t("billManagement.agreeDescriptionMessage")}
              <FormHelperText className="ml-4 pl-3" style={{marginTop: '-10px'}}
                error={this.state.subscriptionCheck !== ''}>{this.state.nonCheckMessage}</FormHelperText>
            </div>
            <p className="font-size-sm ml-4 pl-3 pt-1">{i18n.t("billManagement.billTermsGuideMessage")}</p>
            <div className="px-5 d-flex justify-content-between w-100">
              <Button className='button-maru-contained w-43' onClick={this.register}>
                {i18n.t('billManagement.registerPayments')}
              </Button>
              <Button className='button-maru-gray-contained w-43' onClick={()=>{
                window.close();
              }}>{i18n.t('common.cancel')}
              </Button>
            </div>
          </div>
        </div>
        <div id='PayletterWindow' className="d-none" />
      </>
    )
  }
}

export default ProjectRegisterSubscription;
