import React, { Component } from 'react';
import { Box, Button } from '@material-ui/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import APIUtils from '../../utils/APIUtils';
import i18n from "../../config/lang/i18n";
import OpenmaruLogo from '../../assets/images/logos/logo192.png'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false
    };
  }

  componentDidCatch(error, info) {
    this.setState({ 
      hasError: true,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box className="bg-maru-grady vh-100 p-4" style={{border: "1px solid red"}}>
          <div className="bg-white h-100 p-4 rounded d-flex flex-column align-items-center">
            <img src={OpenmaruLogo} width="160" alt="openmaru_logo"/>
            <div className="pt-2">
              <h1 className="pt-2 pb-2">{i18n.t("common.webPageErrorMessage")}</h1>
              <div className="font-size-xl">
                {i18n.t("common.contactUsMessage")}
                <div className="py-3 d-flex align-items-center">
                  <Button className="button-maru-contained p-1 m-0 font-size-md"
                    onClick={() => {
                      window.open("http://support.opennaru.com/", "_blank");
                    }}>
                    Opennaru {i18n.t("common.customerService")}
                  </Button>
                  <Button className="button-maru-second-contained p-1 m-0 ml-2 font-size-md"
                   onClick={() => {
                    APIUtils.logout(data => {
                      window.location.replace('/');
                    })
                  }}>
                    {i18n.t("common.moveToFirstPage")}
                  </Button>
                </div>
                <div className="font-size-md">
                  <div className="p-1"><FontAwesomeIcon icon={['fas', 'phone-alt']} className="mr-1" /><a href="tel:02-469-5426">02-469-5426</a></div>
                  <div className="p-1"><FontAwesomeIcon icon={['fas', 'fax']} className="mr-1" />02-469-7247</div>
                  <div className="p-1"><FontAwesomeIcon icon={['fas', 'envelope']} className="mr-1" /><a href="mailto:sales@opennaru.com">sales@opennaru.com</a></div>
                  <div className="p-1"><FontAwesomeIcon icon={['fas', 'map-marked-alt']} className="mr-1" /><a href="https://goo.gl/maps/jiPYPuedNaw67e787">(04778){i18n.t("common.companyAddress")}</a></div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      )
    }
    return this.props.children;
  }
}

export default ErrorBoundary;