import React, { Component } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Container, Grid, TextField, InputAdornment } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToastContainer } from 'react-toastify';
import notification from 'openmaru/component/Notification';

import APIUtils from 'utils/APIUtils';
import utils from '../../utils/Utils';
import i18n from '../../config/lang/i18n';
import Footer from '../layout-component/Footer';
import '../../assets/login.scss';
import queryString from 'query-string';
import ChannelService from 'utils/ChannelTalk';
import Jasypt from "jasypt";
import OtpReg from "openmaru/page/OtpReg";
import OtpInput from "openmaru/page/OtpInput";

class Login extends Component {
  constructor(props) {
    super(props);

    this.regOtpPopup = React.createRef();
    this.otpInputPopup = React.createRef();
    this.loginFinishCallback = this.loginFinishCallback.bind(this);
    this.state = {
      userId: '',
      userIdReadonly: false,
      password: ''
    };
  }

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    let {userId} = query;

    if (utils.string.isNotBlank(userId)) {
      this.setState({userId: decodeURIComponent(userId), userIdReadonly: true});
    }
  }

  keyboardNavigation = (event) => {
    if (event.key === 'Enter') {
      this.login();
    }
  }

  handleChangeId = (event) => {
    this.setState({ userId: event.target.value });
  }

  handleChangePassword = (event) => {
    this.setState({ password: event.target.value });
  }



  tmpLogin(id) {
    this.setState({ userId: id }, () => {
      this.setState({ password: 'qwer0987' }, () => {
        this.login();
      });
    });
  }

  login = () => {
    const jasypt = new Jasypt();
    jasypt.setPassword(this.state.userId);

    if (this.state.userId === '' || this.state.password === '') {
      notification.warn(i18n.t('login.enterIdPassword','로그인하시려면 사용자 아이디와 비밀번호를 입력하세요.'), {});
      return;
    }

    APIUtils.login({
      body: {userId: this.state.userId, password: jasypt.encrypt(this.state.password)}
    }, (data, response) => {

      if (data.status === 200 && response.headers.token) {

        localStorage.setItem("serviceProviders", data.body.serviceProviders);
        utils.auth.setToken(response.headers.token);

        if (data.body.user.timeZone && data.body.user.timeZone.indexOf('___') > -1) {
          utils.setTimeZone(data.body.user.timeZone.split('___')[1]);
        } else {
          utils.setTimeZone('Asia/Seoul');
        }
        if (response.data.body.twoFaOtpEnabled) {
          if (response.data.body.otpQr && response.data.body.otpQr.length > 0) {
            this.regOtpPopup.current.handleClickOpen(response.data.body.otpSecret, response.data.body.otpQr);
          } else {
            this.otpInputPopup.current.handleClickOpen();
          }
        } else {
          if(response.data.body.pwChangeCheck!== undefined  && !response.data.body.pwChangeCheck){
            this.props.history.push('/pwChangeCheck');
            return
          }
          this.loginFinishCallback();
        }
      } else {
        notification.error(data.message, {});
        console.log('can not login', response);
      }
    })
  }

  loginFinishCallback() {
    const query = queryString.parse(this.props.location.search);
    
    if (utils.string.isBlank(query.returnUrl)) {
      const previousPage = utils.auth.getPageId();
      if(utils.auth.hasSuperAdmin()) {
        this.props.history.push(previousPage? previousPage: '/admin/dashboard');
      } else {
        this.props.history.push(previousPage? previousPage: '/projectsDashboard');
      }
    } else {
      let returnUrl = atob(query.returnUrl);
      if (window.location.href.indexOf("https") > -1) {
        if (returnUrl.indexOf('http:') > -1) {
          returnUrl = returnUrl.replace(/http:/ig, 'https:');
        }
      }
      window.location.href  = returnUrl;
    }
  }

  render() {
    const { history } = this.props;

    return (
      <>
        <div className="app-wrapper bg-white min-vh-100">
          <div className="app-main min-vh-100">
            <div className="app-content p-0 bg-maru-gray">
              <div className="app-content--inner d-flex align-items-center">
                <div className="flex-grow-1 w-100 d-flex align-items-center">
                  <div className="bg-composed-wrapper--content py-5">
                    <Container maxWidth='md'>
                      <Grid container spacing={10} className="maru-box-shadow">
                        <Grid  item md={5} className="d-flex flex-column justify-content-between align-items-center bg-maru-grady">
                          <div className="logo-style mt-3"></div>
                          <div className="w-100">
                            <div className="text-center">
                              <span className="text-white">
                                {/* to use all features<br />of the cloud APM */}
                                {i18n.t(
                                'account.signupMsg',
                                '계정이 없으십니까?'
                              )}{' '}
                              </span>
                            </div>
                            <div className="text-center pt-4 text-white">
                              <Button className="w-50 button-maru-reversed p-2 font-size-sm"
                                onClick={(e) => {
                                  history.push('/signup');
                                  e.preventDefault();
                                }}>
                                {i18n.t('account.signup', '회원가입')}
                              </Button>
                            </div>
                          </div>
                        </Grid>
                        <Grid item md={7} className="d-flex align-items-center bg-white">
                          <div className="w-100 px-2">
                            <div className="text-black mt-3">
                              <span className="text-maru-color">
                                <h1 className="display-4 mb-5 font-weight-bold">
                                  {i18n.t("login.title", "로그인")}
                                </h1>
                              </span>
                              <div>
                                <div className="mb-3">
                                  <label className="font-weight-bold mb-2">
                                    {i18n.t("login.id", "아이디")}
                                  </label>
                                  <TextField
                                    disabled={this.state.userIdReadonly}
                                    className="input-maru-custom"
                                    size="small"
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    value={this.state.userId}
                                    onChange={this.handleChangeId}
                                    onKeyDown={this.keyboardNavigation}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end" className="mr-2 text-maru-color font-weight-lighter">
                                          <FontAwesomeIcon icon={['far', 'user-circle']} />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </div>
                                <div className="mb-4">
                                  <div className="d-flex justify-content-between">
                                    <label className="font-weight-bold mb-2">
                                      {i18n.t("login.password", "비밀번호")}
                                    </label>
                                  </div>
                                  <TextField
                                    className="input-maru-custom"
                                    size="small"
                                    fullWidth
                                    type="password"
                                    variant="outlined"
                                    value={this.state.password}
                                    onChange={this.handleChangePassword}
                                    onKeyDown={this.keyboardNavigation}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end" className="mr-2 text-maru-color font-weight-lighter">
                                          <FontAwesomeIcon icon={['fas', 'key']} />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="d-flex justify-content-end">
                                <div className="text-center pb-4 text-maru-color">
                                  <a className="nav-link" onClick={(e) => {
                                      history.push('/findId');
                                      e.preventDefault();
                                    }}>
                                    {i18n.t('login.findId', '아이디 찾기')}
                                  </a>
                                  <span className="padding-left-right-10">/</span>
                                  <a className="nav-link" 
                                    onClick={(e) => {
                                      history.push('/findPw');
                                      e.preventDefault();
                                    }}>
                                    {i18n.t(
                                      'login.findPassword',
                                      '비밀번호 찾기'
                                    )}
                                  </a>
                                </div>
                              </div>
                              <div className="w-100 d-flex justify-content-center">
                                <Button
                                  className="w-43 button-maru-contained font-weight-bold font-size-sm"
                                  onClick={this.login}>
                                  {i18n.t('login.title', '로그인')}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Container>
                  </div>
                </div>
              </div>
              {ChannelService.showButton()}
            </div>
            <Footer />
            <ToastContainer />
          </div>
        </div>
        <OtpInput ref={this.otpInputPopup} closeCallback={this.loginFinishCallback} classes={{paper: 'modal-content dialog-maru-custom'}} />
        <OtpReg ref={this.regOtpPopup} closeCallback={this.loginFinishCallback} />
      </>
    );
  }
}

export default withRouter(Login);
