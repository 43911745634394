import React, {Component} from 'react';

import {Button, FormHelperText, Grid, InputAdornment, TextField} from '@material-ui/core';
import {NavLink} from 'react-router-dom';
import LockIcon from '@material-ui/icons/Lock';
import i18n from "../../config/lang/i18n";
import utils from "../../utils/Utils";
import APIUtils from "../../utils/APIUtils";
import queryString from 'query-string';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';


export default class FindPw extends Component {
  constructor() {
    super()

    this.state = {
      token: '',
      password: '',
      confirmPw: '',
      passwordValid: true,
      isClicked: false,
      infoMessage: '',
      errorMessage: ''
    }

    this.passwordInput = React.createRef();

    this.setInputValue = this.setInputValue.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
  }

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    this.setState({token: query.token})
  }

  setInputValue(event) {
    const inputId = event.target.id;
    const inputValue = event.target.value;
    let changeStateValue = {[inputId]: inputValue};

    this.setState(changeStateValue, () => {
      if (inputId === "password" || inputId === "confirmPw") {
        if (this.state.password === this.state.confirmPw) {
          if (this.state.password !== '' && utils.string.checkPassword(this.state.password)) {
            this.setState({
              passwordSameCheckMessage: i18n.t('signup.pwdPassMsg', '비밀번호가 일치하고, 보안에 적합합니다.'),
              passwordValid: true
            })
          } else {
            this.setState({
              passwordSameCheckMessage: i18n.t('signup.pwdRegexpMsg', '비밀번호는 숫자, 특문 각 1회 이상, 영문은 2개 이상 사용하여 8자리 이상 입력'),
              passwordValid: false
            })
          }
        } else {
          this.setState({
            passwordSameCheckMessage: i18n.t('signup.pwdDifferentMsg', '비밀번호가 일치하지 않습니다.'),
            passwordValid: false
          })
        }
      }
    });
  }

  resetPassword() {
    this.setState({userIdValid: true, passwordValid: true, errorMessage: ''})

    if (!utils.string.checkPassword(this.state.password)) {
      this.setState({passwordValid: false, errorMessage: i18n.t('signup.pwdRegexpMsg', '')})
      return;
    }
    if (this.state.password !== this.state.confirmPw) {
      this.setState({passwordValid: false, errorMessage: i18n.t('signup.pwdDifferentMsg', '')})
      return;
    }

    this.setState({isClicked: true})
    APIUtils.user.resetPassword({body: {
        password: this.state.password,
        tokenResetPassword: this.state.token
      }}, (data) => {
      if (data.status === 200) {
        this.props.history.push('/login')
      } else {
        this.setState({errorMessage: data.message})
      }
      this.setState({isClicked: false});
    });
  }


  render() {
    return (
      <>
        <div className="app-wrapper bg-white min-vh-100">
          <div className="app-main min-vh-100">
            <div className="app-content p-0">
              <div className="app-content--inner d-flex align-items-center">
                <div className="flex-grow-1 w-100 d-flex align-items-center">
                  <div className="bg-composed-wrapper--content py-5">
                    <Grid item md={10} lg={8} xl={4} className="mx-auto">
                      <div className="text-center mb-5">
                        <h1 className="display-4 mb-1 font-weight-bold">
                          {i18n.t('signup.resetPassword', '비밀번호 초기화')}
                        </h1>
                      </div>
                      <div>
                        <label className="font-weight-bold mb-2">
                          {i18n.t('signup.password','비밀번호')}
                        </label>
                        <TextField
                          inputRef={this.passwordInput}
                          id="password"
                          variant="outlined"
                          size="small"
                          fullWidth
                          placeholder="Enter your password"
                          type="password"
                          inputProps={{maxLength: 100}}
                          onChange={this.setInputValue} value={this.state.password}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LockIcon />
                              </InputAdornment>
                            )
                          }}
                        />
                      </div>
                      <div>
                        <label className="font-weight-bold mb-2">
                          {i18n.t('signup.passwordConfirm','비밀번호 확인')}
                        </label>
                        <TextField
                          id="confirmPw"
                          variant="outlined"
                          size="small"
                          fullWidth
                          placeholder="Enter your password"
                          type="password"
                          inputProps={{maxLength: 100}}
                          onChange={this.setInputValue} value={this.state.confirmPw}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LockIcon />
                              </InputAdornment>
                            )
                          }}
                        />
                        <FormHelperText
                          error={!this.state.passwordValid}>{this.state.passwordSameCheckMessage}</FormHelperText>
                      </div>
                      <FormHelperText className="text-center font-weight-bold">{this.state.infoMessage}</FormHelperText>
                      <FormHelperText className="text-center font-weight-bold" error={true}>{this.state.errorMessage}</FormHelperText>
                      <div className="text-center mb-5">
                        <Button
                          hidden={!this.state.isClicked}
                          fullWidth
                          className="text-uppercase font-weight-bold font-size-sm mt-4 btn-primary">
                          <span className="btn-wrapper--icon">
                            <FontAwesomeIcon icon={['fas', 'cog',]} spin/>
                          </span>
                          ...
                        </Button>
                        <Button
                          hidden={this.state.isClicked}
                          fullWidth
                          onClick={() => this.resetPassword()}
                          className="text-uppercase font-weight-bold font-size-sm mt-4 btn-primary">
                          {i18n.t('signup.resetPassword','비밀번호 초기화')}
                        </Button>
                        <div className="my-4">
                          <NavLink to="/login">{i18n.t('signup.moveToLoginFromMsg', '로그인 페이지로 이동')}</NavLink>
                        </div>
                      </div>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
