import React, { Component } from 'react';
import { Dialog, DialogContent, DialogTitle, DialogActions, Grid, Button, 
        Select, MenuItem, FormControl, InputAdornment } from '@material-ui/core';
import CTextField from '../component/CTextField';
import utils from '../../../src/utils/Utils';
import i18n from '../../../src/config/lang/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import APIUtils from '../../../src/utils/APIUtils';
import notification from './Notification';

class MessageInputForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      key: '',
      lang: '',
      value: '',
      created: '',
      updated: '',
      createdId: '',
      isUpdate: '',
      callback: () => {}
    }

    this.save = this.save.bind(this);
    this.close = this.close.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  save() {
    APIUtils.superAdmin.editMessage({
      body: {
        key: this.state.key,
        lang: this.state.lang,
        value: this.state.value
      }
    }, (data) => {
      if(data.status === 200) {
        notification.info(i18n.t("messageSetting.successMessageRegister"));
        this.close();
        this.state.callback();
      } else {
        notification.error(data.message);
      }
    })
  }

  close() {
    this.setState({
      open: !this.state.open,
      key: '',
      lang: '',
      value: '',
      created: '',
      updated: '',
      createdId: '',
      isUpdate: false
    })
  }

  handleClickOpen({lang, ...others}, callback=()=>{}) {
    this.setState({
      open: true,
      lang: lang? lang: ' ',
      isUpdate: lang ? true : false,
      callback: callback,
      ...others
    })
  }

  handleChange(event) {
    const target = event.target;
    this.setState({ 
      [target.name]: (event.target.tagName === 'INPUT' && event.target.type === 'text')?utils.string.unescape(target.value):target.value 
    });
  }

  render() {
    return (
      <Dialog
        classes={{paper: 'dialog-maru-custom'}}
        maxWidth="md"
        open={this.state.open}
        onClose={this.close}
      >
        <DialogTitle>{i18n.t("messageSetting.inputPopup", {action: this.state.isUpdate? i18n.t("messageSetting.update"): i18n.t("messageSetting.register")})}</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <label className="font-weight-bold mb-2">
                {i18n.t('messageSetting.language', {})}
              </label>
              <FormControl fullWidth>
                <Select 
                  className="input-maru-custom"
                  size="small"
                  name="lang"
                  value={this.state.lang}
                  disabled={this.state.isUpdate}
                  onChange={this.handleChange}>
                  <MenuItem value={" "} disabled>
                    <div>{i18n.t('messageSetting.selectLanguage')}</div>
                  </MenuItem>                    
                  <MenuItem value="ko">
                    <div>{i18n.t('messageSetting.ko')}</div>
                  </MenuItem>
                  <MenuItem value="en">
                    <div>{i18n.t('messageSetting.en')}</div>
                  </MenuItem>
                </Select>
              </FormControl>    
            </Grid>
            <Grid item xs={12}>
              <div>
                <label className="font-weight-bold mb-2">
                  {i18n.t('common.message')}
                </label>
                <CTextField
                  className="input-maru-custom"
                  fullWidth
                  name="value"
                  size="small"
                  value={this.state.value}
                  onChange={this.handleChange}
                  InputProps={{
                    endAdornment: (<InputAdornment position="end" className="mr-2 text-black-50 font-weight-lighter nav-link"
                      onClick={_ => {this.setState({value: ''})}}>
                      <FontAwesomeIcon icon={['fas', 'times']} />
                    </InputAdornment>)
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <div></div>
          <div>
            <Button className="button-maru-contained mr-1" onClick={this.save}>
              {this.state.isUpdate? i18n.t("messageSetting.update"): i18n.t("messageSetting.register")}
            </Button>
            <Button className="button-maru-gray-contained" onClick={this.close}>
              {i18n.t('common.close')}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    )
  }
}

export default MessageInputForm;