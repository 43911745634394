import React, { Component } from 'react';
import queryString from 'query-string';
import APIUtils from '../../../utils/APIUtils';
import Utils from '../../../utils/Utils';
import notification from 'openmaru/component/Notification';
import i18n from '../../../config/lang/i18n';

class PaymentResult extends Component {
  constructor() {
    super()

    this.state = {
      title: '',
      messages: [],
      returnUrl: '',
      subscriptionCheck: false,
      nonCheckMessage: '',
      registerComplete: false,
      billingDayOfMonth: 0,             // 청구서 발행일
      paymentDayOfMonth: 0,             // 청구서 결제일
      apmViewStopAfterDay: 0,           // 결제일 값후 뷰 중단
      apmStopAfterDay: 0,               // 결제일 값후 수집중단
      apmDeleteAfterDay: 0              // 결제일 값후 데이터 삭제
    }

    this.getSubscriptionPolicy = this.getSubscriptionPolicy.bind(this);
  }

  componentDidMount() {
    this.getSubscriptionPolicy();
  }

  getSubscriptionPolicy() {
    const langCode = (localStorage.getItem('langCode') === null) ? (window.navigator.language.substring(0,2) === 'ko' ? 'ko' : 'en') : localStorage.getItem('langCode')
    APIUtils.subscriptionPolicy({}, (data)=> {
      if(data.status === 200) {
        let dayOfMonth = Object.assign({}, data.body);
        if(langCode === 'en') {
          dayOfMonth = {
            billingDayOfMonth: Utils.date.getCountOfOrder(data.body.billingDayOfMonth),
            paymentDayOfMonth: Utils.date.getCountOfOrder(data.body.paymentDayOfMonth),
            apmViewStopAfterDay: data.body.apmViewStopAfterDay + ((data.body.apmViewStopAfterDay > 1)? ' days': ' day'),
            apmStopAfterDay: data.body.apmStopAfterDay + ((data.body.apmStopAfterDay > 1)? ' days': ' day'),
            apmDeleteAfterDay: data.body.apmDeleteAfterDay + ((data.body.apmDeleteAfterDay > 1)? ' days': ' day')
          }
        }

        this.setState(dayOfMonth, () => {
          const query = queryString.parse(this.props.location.search);
          const responseMessage = {
            success: {
              title: i18n.t("project.subscriptionRegistered"),
              messages: [
                i18n.t("project.subscriptionBillingDay", {billingDayOfMonth: this.state.billingDayOfMonth}),
                i18n.t("project.subscriptionPaymentDay", {paymentDayOfMonth: this.state.paymentDayOfMonth}),
                i18n.t("project.apmViewStopDay", {apmViewStopAfterDay: this.state.apmViewStopAfterDay}),
                i18n.t("project.apmStopDay", {apmStopAfterDay: this.state.apmStopAfterDay}),
                i18n.t("project.apmDeleteDay", {apmDeleteAfterDay: this.state.apmDeleteAfterDay})
              ]
            },
            failed: {
              title: i18n.t("project.subscriptionRegisterFailed"),
              messages: [i18n.t("project.subscriptionRegisterFailedMessage")]
            }
          }
      
          if(query.success === 'true') {
            this.setState({
              title: responseMessage.success.title,
              messages: responseMessage.success.messages
            })
          } else {
            this.setState({
              title: responseMessage.failed.title,
              messages: query.message? [query.message, `${query.pgResultCode} : ${query.pgResultMessage}`]: responseMessage.failed.messages
            })
          }
        });
      } else {
        notification.error(data.message)
      }
    })
  }

  render() {
    return (
      <>
        <div className="popup-background">
          <div className="popup-inner">
            <h2 className='text-maru-color font-weight-bold text-center'>
              {this.state.title}
            </h2>
            <div className="flex-grow-1 d-flex justify-content-center align-items-center font-size-md font-weight-bold">
              <ul className="mr-3">
                {
                  this.state.messages.map((message, index) => {
                    return <li key={index}>{message}</li>
                  })
                }
              </ul>
            </div>

            {/* <div className='d-flex flex-column align-items-center'>
              <Button className='button-maru-contained w-43 b-1' onClick={()=>{              
                window.close();
              }}>닫기</Button>
              <div className="font-size-sm mt-2">* [닫기]버튼이 동작하지 않는다면, 우측 상단의 브라우저 닫기[X] 버튼을 사용하세요.</div>
            </div> */}
          </div>
        </div>
      </>
    )
  }
}

export default PaymentResult;