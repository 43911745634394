import React, { useState, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Box } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

import MUITable from "../component/MUITable";
import { DateTime } from "luxon";
import utils from "../../utils/Utils";
import i18n from "../../config/lang/i18n";
import APIUtils from "../../utils/APIUtils";
import DateFnsUtils from '@date-io/date-fns';
import koLocale from 'date-fns/locale/ko';


function LoginHistory(props) {
  const { history } = props;
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(DateTime.local().minus({days: 1}).toMillis());
  const [endDate, setEndDate] = useState(DateTime.local().toMillis());

  const columns = [
    {
      label: i18n.t('account.loginIp'), name: 'loginIp', options: {
        setCellProps: (_ => ({style: {minWidth: "10vw", maxWidth: "10vw"}})),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div style={{textAlign: 'center'}}>{value}</div>
        }
      }
    },
    {
      label: i18n.t('account.loginSucceded'), name: 'loginSucceded', options: {
        setCellProps: (_ => ({style: {minWidth: "10vw", maxWidth: "10vw"}})),
        customBodyRender: (value, tableMeta, updateValue) => {
          let rowData = tableData[tableMeta.rowIndex];
          return <div style={{textAlign: 'center'}}>{rowData.type.name === 'RESET_PASSWORD' ? i18n.t("signup.resetPassword"):value ? i18n.t("account.loginSuccessMessage") : i18n.t("account.loginFailedMessage")}</div>
        }
      }
    },
    {
      label: i18n.t('account.loginCreated'), name: 'created', options: {
        setCellProps: (_ => ({style: {minWidth: "10vw", maxWidth: "10vw"}})),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div style={{textAlign: 'center'}}>{DateTime.fromMillis(value).toFormat("yyyy'-'LL'-'dd HH:mm:ss")}</div>
        }
      }
    },
    {
      label: i18n.t('account.userAgent'), name: 'userAgent', options: {
        setCellProps: (_ => ({style: {minWidth: "10vw", maxWidth: "10vw"}})),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div style={{textAlign: 'center'}}>{utils.isMobile(value)? 'Mobile': 'PC'}</div>
        }
      }
    },
  ];

  const options = {
    onTableInit: (e) => {
    },
    refresh: () => {getLoginHistory()},
    draggableColumns: {
      enabled: true
    },
    serverSide: false,
    rowsPerPage: 15,
    filter: false,
    responsive: 'simple',
    selectableRows: 'none',
    search: true,
    searchOpen: true,
    display: false,
    customToolbar: () => {
      return (<>
        <Box className="d-inline-flex align-items-center mx-2" style={{width: '15rem'}}>
          <MuiPickersUtilsProvider className="text-center" utils={DateFnsUtils} locale={koLocale}>
            <DatePicker          
              maxDate={Date.now()}
              format="yyyy-MM-dd"
              value={startDate}
              onChange={(value) => setStartDate(DateTime.fromJSDate(value).toMillis())}
              variant="inline"
              autoOk={true}
            />
          </MuiPickersUtilsProvider>
          <span className="mx-1">~</span>
          <MuiPickersUtilsProvider className="mr-2 text-center" utils={DateFnsUtils} locale={koLocale}>
            <DatePicker
              minDate={startDate}
              maxDate={Date.now()}
              format="yyyy-MM-dd"
              value={endDate}
              onChange={(value) => setEndDate(DateTime.fromJSDate(value).toMillis())}
              variant="inline"
              autoOk={true}
            />
          </MuiPickersUtilsProvider>
          <Button size="small" className="button-maru-gray-contained p-0 m-0 w-43" onClick={()=> {
            getLoginHistory()
          }}>조회</Button>
        </Box>
      </>)
    }
  };

  const getLoginHistory = (start, end) => {
    setIsLoading(true);
    let startCheck = DateTime.fromMillis(start? start: startDate).startOf('day').toMillis();
    let endCheck = DateTime.fromMillis(end? end: endDate).endOf('day').toMillis();

    APIUtils.loginHistory({
      start: startCheck,
      end: endCheck
    }, (data) => {
      if(data.status === 200) {
        setTableData(data.body)
        setIsLoading(false);
      }
    })
  }

  useEffect(() => {
    getLoginHistory();
  }, [])

  return (<>
    <div className="app-page-title">
      <div>
        <h1><FontAwesomeIcon icon={['fas', 'file-alt']} className="icon"/> {i18n.t("account.loginHistory")}</h1>
      </div>
      <div className="d-flex align-items-center">
        <Button className="button-maru-second-contained" onClick={() => history.push('/account')}>
          <span className="btn-wrapper--icon">
            <FontAwesomeIcon icon={['fas', 'user-circle']} size='1x' className='mr-1'/>
          </span>
          <span className="btn-wrapper--label">{i18n.t('account.title', '사용자 프로필')}</span>
        </Button>
      </div>
    </div>
    <MUITable columns={columns} options={options} data={tableData} loading={isLoading}/>
  </>)
}

export default LoginHistory;
