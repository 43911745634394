import React, {useState, useRef, useEffect} from 'react';
import { connect } from 'react-redux';
import { Badge, Popover, Typography, Box, Button, Menu, MenuItem } from '@material-ui/core';
import { makeStyles  } from '@material-ui/core/styles';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import APIUtils from '../../utils/APIUtils';
import notification from 'openmaru/component/Notification';
import ChannelService from 'utils/ChannelTalk';
import utils from '../../utils/Utils';
import i18n from '../../config/lang/i18n';
import { setUserNotis } from '../../reducers/OpenmaruReducer';
import TalkLogo from '../../assets/images/logos/openmaru_talk_icon.png'

const selectStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#2F2F2F',
    width: 200
  },
  listStyle: {
    color: 'white',
    fontWeight: 'normal',
  },
}));

const HeaderAlarm = (props) => {
  const classes = selectStyles();
  const langCode = (localStorage.getItem('langCode') === null) ? (window.navigator.language.substring(0,2) === 'ko' ? 'ko' : 'en') : localStorage.getItem('langCode');

  const [helpAncherEl, setHelpAnchorEl] = useState(false);
  const helpAnchorClick = (event) => {
    setHelpAnchorEl(event.currentTarget);
  };
  const helpMenuClose = () => {
    setHelpAnchorEl(null);
  };

  const { userNotis, setUserNotis } = props;

  const popoverEl = useRef();
  const [messages, setMessages] = useState([]);
  const [alarmAnchorEl, setAlarmAnchorEl] = useState(false);
  const alarmAnchorClick = (event) => {
    setAlarmAnchorEl(event.currentTarget);
  };
  const alarmPopoverClose = () => {
    setAlarmAnchorEl(null);
  };

  const accept = (index) => {
    APIUtils.project.acceptInvite({pid: messages[index].projectId}, (data) => {
      if(data.status === 201) {
        notification.info(i18n.t("common.acceptInvitation", "초대 수락"));
        getNotis();
      } else {
        notification.warn(data.message);
      }
    })
  }

  const deny = (index) => {
    APIUtils.project.rejectInvite({pid: messages[index].projectId}, (data) => {
      if(data.status === 200) {
        notification.info(i18n.t("common.rejectInvitation", "초대 거절"));
        getNotis();
      } else {
        notification.warn(data.message);
      }
    })
  }

  const getNotis = () => {
    APIUtils.user.getNotis({}, (data) => {
      if (data.status === 200) {
        if (data.body && data.body.length >= 0) {
          setUserNotis(data.body);
        }
      } else {
        notification.error(data.message)
      }
    });
  }
  
  useEffect(() =>{
    if(!utils.auth.hasSuperAdmin()) {
      getNotis();
    }
  },[])

  useEffect(() => {
    if (userNotis && userNotis.length >= 0) {
      let invites = [];
      let totalCount = userNotis.length;

      userNotis.forEach((invite) => {
        invite.num = totalCount--;
        if(invite.num && invite.status && invite.status === "1001") {
          invites.push({
            num: invite.num,
            fromUserId: invite.fromUserId,
            projectName: invite.projectName,
            projectId: invite.pid,
            role: invite.role
          });
        } else if (invite.title && invite.message) {
          invites.push(invite);
        }
      });
      setMessages(invites);
    }
  }, [userNotis])

  return (
    <>
      <Badge anchorOrigin={{ vertical: 'top', horizontal: 'left' }} 
        className="mr-4 nav-link text-white" onClick={() => {
          ChannelService.showMessenger();
        }}>
        <img width="28" src={TalkLogo}/>
      </Badge>
      <Badge anchorOrigin={{ vertical: 'top', horizontal: 'left' }} 
        className="mr-4 nav-link" onClick={helpAnchorClick}>
        <ContactSupportIcon className="text-white" style={{ fontSize: 26 }} />
      </Badge>
      <Menu
        anchorEl={helpAncherEl}
        keepMounted
        getContentAnchorEl={null}
        classes={{ paper: classes.root, list: classes.listStyle}}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(helpAncherEl)}
        onClose={helpMenuClose}>
        <MenuItem className={`align-items-center ${(langCode==='en') && 'px-1'}`}>
          <FontAwesomeIcon icon={['fas', 'list-ul']} style={{ width: 20 }}/>
          <Typography className="pl-2" variant='inherit' onClick={() => {
            window.open('https://www.openmaru.io/support/', '_blank');
          }}>{i18n.t("common.faq")}</Typography>
        </MenuItem>
        <MenuItem className={`align-items-center ${(langCode==='en') && 'px-1'}`}>
          <FontAwesomeIcon icon={['fas', 'map-signs']} style={{ width: 20 }}/>
          <Typography className="pl-2" variant='inherit' onClick={() => {
            window.open('https://docs.openmaru.io/', '_blank');
          }}>{i18n.t("common.userGuide")}</Typography>
        </MenuItem>
        <MenuItem disabled className={`align-items-center ${(langCode==='en') && 'px-1'}`}>
          <MenuBookIcon className="text-white" style={{ width: 20 }}/>
          <Typography className="pl-2" variant='inherit' onClick={() => {
            // window.open('https://docs.openmaru.io', '_blank');
          }}>{i18n.t("common.releaseNotes")}</Typography>
        </MenuItem>
        <MenuItem className={`align-items-center ${(langCode==='en') && 'px-1'}`}>
          <FontAwesomeIcon icon={['fas', 'headset']} style={{ width: 20 }}/>
          <Typography className="pl-2" variant='inherit' onClick={() => {
            window.open('http://support.opennaru.com/', '_blank');
          }}>{i18n.t("common.technicalSupport")}</Typography>
        </MenuItem>
      </Menu>
      <Badge anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        badgeContent={messages.length} color="error" className="mr-4 nav-link"
        onClick={alarmAnchorClick}>
        <NotificationsIcon className="text-white" style={{ fontSize: 26 }}/>
      </Badge>
      <Popover
        ref={popoverEl}
        open={Boolean(alarmAnchorEl)}
        anchorEl={alarmAnchorEl}
        onClose={alarmPopoverClose}
        className='mt-2 messages-alarm'
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <div className="bg-composed-wrapper bg-midnight-bloom border-0 rounded-sm m-1">
          <div className="bg-composed-img-3 bg-composed-wrapper--image" />
          <div className="bg-composed-wrapper--content text-white px-1 py-2">
            <p className="opacity-8 mb-0">
              <b className="font-size-xl font-weight-bold mb-2 mx-2">{i18n.t('common.alarm', '알림')}</b>
              { messages.length?
                (<><b className="ml-2 text-success">{messages.length}</b>{i18n.t('common.newMessages', '건의 새로운 메시지가 있습니다.')}</>)
                : (<span className="ml-2">{i18n.t('common.noMessages', '새로운 메시지가 없습니다.')}</span>)
              }
            </p>
          </div>
        </div>
        {
          messages.length? messages.map((message, index) => {
            if (message.title && message.message){
              return (<Box
                className="message-content-box m-1 p-1 border-gray border-1 bg-white d-flex justify-content-between"
                key={index}>
                <div>
                  <Typography className='font-weight-bold ' variant='subtitle1'
                              color='textPrimary'>{message.title}</Typography>
                  <div className='mt-1'>
                    {message.message}
                  </div>
                </div>
              </Box>)
            } else {
              return (<Box
                className="message-content-box m-1 p-1 border-gray border-1 bg-white d-flex justify-content-between"
                key={index}>
                <div>
                  <Typography className='font-weight-bold ' variant='subtitle1'
                              color='textPrimary'>{i18n.t("invite.inviteHeader", {inviteProjectName: message.projectName})}</Typography>
                  <div className='mt-1'>
                    {i18n.t("invite.inviteMessage",
                      {
                        inviteProjectName: message.projectName,
                        inviteFromUserId: message.fromUserId,
                        inviteRoleName: i18n.t("common.role." + message.role.toLowerCase())
                      })}
                  </div>
                </div>
                <div className='d-flex align-items-end'>
                  <Button className='btn-danger px-3 py-2 mr-2' onClick={() => {
                    accept(index)
                  }}>{i18n.t("common.accept", "수락")}</Button>
                  <Button className='btn-dark px-3 py-2' onClick={() => {
                    deny(index)
                  }}>{i18n.t("common.reject", "거절")}</Button>
                </div>
              </Box>)
            }
          }): (<Box className="message-content-box m-2 p-2 border-gray border-1 bg-white">
            <div>
              <div className='mt-1'>{i18n.t("common.noInvitationMessage", "초대 내역이 없습니다.")}</div>
            </div>
          </Box>)
        }
      </Popover>
    </>
  )
}

const mapStateToProps = (state) => ({
  userNotis: state.OpenmaruReducer.userNotis
})

const mapDispatchToProps = (dispatch) => ({
  setUserNotis: (invites) => dispatch(setUserNotis(invites))
})

export default connect(mapStateToProps, mapDispatchToProps)(HeaderAlarm);
