import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {withRouter} from 'react-router-dom';
import {Grid, Button, Card, CardContent, Tooltip} from '@material-ui/core';
import queryString from 'query-string';
import i18n from "../../../config/lang/i18n";
import APIUtils from "../../../utils/APIUtils";
import utils from "../../../utils/Utils";
import {connect} from "react-redux";
import {setShowBodyLoading, setUserSelectedProject} from "../../../reducers/OpenmaruReducer";
import ProjectForm from "./ProjectForm";
import TitBreadcrumbs from "../../component/TitBreadcrumbs";
import DateTimeDialog from "../../component/DateTimeDialog";
import notification from "../../component/Notification";

class ProjectInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pid: undefined,
      project: {},
      poc: undefined,
      license: undefined,
      cardInfo: undefined,
      role: '',
      statusColorStyle: {},
      statusNm: ''
    }


    this.formPopup = React.createRef();
    this.subscriptionRegisterWindowRef = React.createRef();
    this.datTimeDialogRef = React.createRef();

    this.getInfo = this.getInfo.bind(this);
    this.getProjectInfo = this.getProjectInfo.bind(this);
    this.getCardInfo = this.getCardInfo.bind(this);
    this.showUpdateForm = this.showUpdateForm.bind(this);
    this.subscriptionRegister = this.subscriptionRegister.bind(this);
    this.subscriptionStop = this.subscriptionStop.bind(this);
  }

  componentDidUpdate() {
    const query = queryString.parse(this.props.location.search);
    if (query.id !== this.state.pid) {
      this.setState({pid: query.id}, () => {
        this.getInfo();
      })
    }
  }

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    this.setState({pid: query.id}, () => {
      this.getInfo();
    })
  }

  getInfo() {
    this.getProjectInfo();
  }

  getProjectInfo() {
    this.setState({poc: undefined, license: undefined})

    APIUtils.project.getProjectInfo({pid: this.state.pid, params: {logoImg: true}}, (data) => {
      if (data.status === 200 && data.body) {
        let project = data.body;
        this.props.setUserSelectedProject(project);
        this.setState({project: this.props.userSelectedProject});
        this.setState( {role: this.state.project.projectMember.role});

        if (this.state.project.type === '1001') {
          APIUtils.project.getProjectPoc({pid: this.state.pid}, (data) => {
            if (data.status === 200 && data.body) {
              this.setState({poc: data.body})
            }
          });
        }

        if (this.state.project.type === '1003') {
          APIUtils.project.getProjectLicense({pid: this.state.pid}, (data) => {
            if (data.status === 200 && data.body) {
              this.setState({license: data.body})
            }
          });
        }

        // 유료 - success
        // 무료 - blue
        // 무료만료 - danger
        let type = this.state.project?this.state.project.type:'';
        let status = this.state.project?this.state.project.status:'';
        let statusNm = '';
        let statusColorStyle = '';

        if (type === '1001') {
          statusNm = i18n.t('project.poc', '무료사용');
          statusColorStyle = 'blue';
        } else if (type === '1002') {
          statusNm = i18n.t('project.paid', '구독중');
          statusColorStyle = 'success';
        } else if (type === '1003') {
          statusNm = i18n.t('project.licensed', '계약됨');
          statusColorStyle = 'success';
        }

        if (status === '1002') {
          statusNm += '(' + i18n.t('project.status.' + status, '만료') +')';
          statusColorStyle = 'danger';
        } else if (status === '1003') {
          statusNm += '(' + i18n.t('project.status.' + status, '연체') +')';
          statusColorStyle = 'danger';
        } else if (status === '1004') {
          statusNm += '(' + i18n.t('project.status.' + status, '중지') +')';
          statusColorStyle = 'danger';
        }

        if (utils.string.isNotBlank(statusNm)) {
          this.setState({statusNm: statusNm, statusColorStyle: { color : statusColorStyle}});
        }

        if (project && project.projectMember && project.projectMember.role === 'BILL') {
          this.getCardInfo();
        }
      } else {
        notification.error(data.message)
      }
    });
  }

  getCardInfo() {
    APIUtils.project.getCardInfo({pid: this.state.pid}, (data) => {
      if (data.status === 200) {
        this.setState({cardInfo: data.body}, () => {
        })
      }
    });
  }

  subscriptionRegister() {
    this.setState({
      returnUrl: '/console/projectRegisterSubscription?pid=' + this.state.pid
    }, () => {
      const popupOptions = 'left=200, top=150, width=500, height=400, status=no, menubar=no, toolbar=no, resizable=no';
      const subscriptionWindow = window.open(this.state.returnUrl, '', popupOptions);

      subscriptionWindow.addEventListener("beforeunload", (event) => {
        const payletterWindow = subscriptionWindow.document.getElementById('PayletterWindow').value;

        if(payletterWindow) {
          window.onfocus = (event) => {
            this.getInfo();
            if(payletterWindow.closed) { 
              window.onfocus = () => {};
            }
          };
        }
      })
    })
  }

  subscriptionStop(dateTime) {
    APIUtils.project.subscriptionStop({pid: this.state.pid, endMillis: dateTime.toMillis()}, (data) => {
      if (data.status === 200) {
        notification.info('common.requestSuccessful')
        this.datTimeDialogRef.current.close();
        this.getInfo();
      } else {
        notification.error(data.message)
      }
    });
  }

  showUpdateForm(project) {
    this.formPopup.current.showCreateAndUpdateForm(project)
  }

  go2APM(project) {
    utils.goToApm(project.apmServerDomain);
  }

  render() {
    return (
      <>
        <TitBreadcrumbs title={i18n.t('project.management', '프로젝트 관리')} />

        <div className="app-page-title">
          <div>
            <h1><FontAwesomeIcon icon={['fas', 'project-diagram']} className="icon"/> {i18n.t('project.management', '프로젝트 상세')}</h1>
          </div>
        </div>
        <Card className="card-box card-maru-custom">
          <CardContent className="p-4">
            <div className="d-flex flex-column flex-lg-row align-items-start justify-content-between">
              <div className="text-center text-lg-left mb-3 mb-lg-0 max-w-70">
                <h1>{utils.string.unescape(this.state.project.name)}</h1>
                <p className="mb-0">{utils.string.unescape(this.state.project.description, '-')}</p>
                <div hidden={utils.string.isEmpty(this.state.role)} className={'badge badge-danger'} style={{textAlign: 'center', margin: '0 auto'}}>{this.state.role?i18n.t(("common.role." + this.state.role.toLowerCase())):''}</div>
              </div>
              <div className="text-center text-lg-left">
                {(utils.string.isBlank(this.state.project.paidDate) && this.state.role===utils.BILL) &&
                (<Button size="small" className='button-maru-second-outlined m-1' onClick={this.subscriptionRegister}>
                  {i18n.t("project.paidConversion", "구독하기")}
                </Button>)}
                {(utils.string.isNotBlank(this.state.project.paidDate) && this.state.role===utils.BILL) &&
                (<Button size="small" className='button-maru-second-outlined m-1' onClick={this.subscriptionRegister}>
                    <span className="btn-wrapper--icon">
                        <FontAwesomeIcon icon={['fas', 'plus-square']}/>
                    </span>
                  <span className="btn-wrapper--label">{i18n.t("common.reRegist", "구독 정보 재등록")}</span>
                </Button>)}
                <Button
                  onClick={() => this.showUpdateForm(this.state.project)}
                  size="small"
                  variant="text"
                  className="button-maru-gray-outlined m-1">
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={['fas', 'cog']} />
                  </span>
                  <span className="btn-wrapper--label">{i18n.t('common.edit', '수정')}</span>
                </Button>
                <Button
                  size="small"
                  onClick={() => {this.go2APM(this.state.project)}}
                  variant="text"
                  className="button-maru-outlined m-1">
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={['fas', 'desktop']}/>
                  </span>
                  <span className="btn-wrapper--label">Monitoring</span>
                </Button>
              </div>
            </div>
            <div className="divider my-3"/>
            <Grid container wrap='nowrap' className='project-info-grid'>
              <Grid container item direction="column" xs={12}>
                <Grid item className="list-item-maru-custom">
                  <div className="mb-1">
                    <FontAwesomeIcon icon={['fas', 'square']}/>
                    <span>{i18n.t('project.id', 'ID')}</span>
                  </div>
                  <div className='font-weight-bold'>
                    {utils.string.toString(this.state.project.pid)}
                  </div>
                </Grid>
                <Grid item className="list-item-maru-custom">
                  <div className="mb-1">
                    <FontAwesomeIcon icon={['fas', 'square']}/>
                    <span>{i18n.t('project.company', '회사명')}</span>
                  </div>
                  <div className='font-weight-bold'>
                    {utils.string.unescape(this.state.project.company, '-')}
                  </div>
                </Grid>
                <Grid item className="list-item-maru-custom">
                  <div className="mb-1">
                    <FontAwesomeIcon icon={['fas', 'square']}/>
                    <span>{i18n.t('project.team', '부서/팀명')}</span>
                  </div>
                  <div className='font-weight-bold'>
                    {utils.string.unescape(this.state.project.team, '-')}
                  </div>
                </Grid>
                <Grid item className="list-item-maru-custom">
                  <div className="mb-1">
                    <FontAwesomeIcon icon={['fas', 'square']}/>
                    <span>{i18n.t('common.status', '상태')}</span>
                  </div>
                  <div className='font-weight-bold' style={this.state.statusColorStyle}>
                    {
                      this.state.statusNm
                    }
                  </div>
                </Grid>
                <Grid item className="list-item-maru-custom" hidden={!(this.state.poc || this.state.license)}>
                  <div className="mb-1">
                    <FontAwesomeIcon icon={['fas', 'square']}/>
                    <span>{i18n.t('project.expired', '만료일')}</span>
                  </div>
                  <div className='font-weight-bold'>
                    {
                      this.state.license ? (
                        utils.date.fromToString(this.state.license.startDate, 'yyyyMMdd', 'yyyy-MM-dd') + ' ~ ' +
                        utils.date.fromToString(this.state.license.endDate, 'yyyyMMdd', 'yyyy-MM-dd'))
                        : (utils.date.millisToString(this.state.project.pocExpired, 'yyyy-MM-dd'))
                    }
                  </div>
                </Grid>
              </Grid>
              <Grid container item direction="column" xs={12}>
                <Grid item className="list-item-maru-custom">
                  <div className="mb-1">
                    <FontAwesomeIcon icon={['fas', 'square']}/>
                    <span>{i18n.t('project.subDomain', '서브 도메인')}</span>
                  </div>
                  <div className='font-weight-bold'>
                    {this.state.project.subDomain}
                  </div>
                </Grid>
                <Grid item className="list-item-maru-custom">
                  <div className="mb-1">
                    <FontAwesomeIcon icon={['fas', 'square']}/>
                    <span>{i18n.t('project.createdId', '생성한 사용자')}</span>
                  </div>
                  <div className='font-weight-bold'>
                    {this.state.project.createdId}
                  </div>
                </Grid>
                <Grid item className="list-item-maru-custom">
                  <div className="mb-1">
                    <FontAwesomeIcon icon={['fas', 'square']}/>
                    <span>{i18n.t('common.created', '생성일')}</span>
                  </div>
                  <div className='font-weight-bold'>
                    {utils.date.millisToString(this.state.project.created)}
                  </div>
                </Grid>
                <Grid item className="list-item-maru-custom">
                  <div className="mb-1">
                    <FontAwesomeIcon icon={['fas', 'square']}/>
                    <span>{i18n.t('project.paidDate', '유료 전환일')}</span>
                  </div>
                  <div className="text-value-point font-weight-bold">
                    {utils.string.toString(utils.date.millisToString(this.state.project.paidDate), '-')}
                  </div>
                </Grid>
              </Grid>
              <Grid container item direction="column">
                <Grid item className="list-item-maru-custom">
                  <div className="mb-1">
                    <FontAwesomeIcon icon={['fas', 'square']}/>
                    <span>OPENMARU APM</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between font-weight-bold">
                    <a href="" onClick={() => this.go2APM(this.state.project)}>{this.state.project.apmServerDomain}</a>
                    <Tooltip arrow title="Monitoring" placement="top">
                      <Button
                        className={'p-0 border-0 btn-outline-first'}
                        variant="outlined"
                        hidden={this.state.project.apmStatus!=='1003'}
                        onClick={() => this.go2APM(this.state.project)}>
                                  <span className="btn-wrapper--icon">
                                    <FontAwesomeIcon
                                      icon={['far', 'eye']}
                                      className="font-size-xl"
                                    />
                                  </span>
                      </Button>
                    </Tooltip>
                    <Tooltip arrow title={i18n.t('project.apmStatusIng', '진행중')} placement="top">
                      <Button
                        hidden={this.state.project.apmStatus!=='1002'}
                        className={'p-0 btn-transition-none border-0 ' + (this.state.project.apmStatus==='1003'?'btn-outline-first':'btn-outline-danger')}
                        variant="outlined">
                                  <span className="btn-wrapper--icon">
                                    <FontAwesomeIcon
                                      icon={['fas', 'spinner']} spin
                                      className="font-size-lg"
                                    />
                                  </span>
                      </Button>
                    </Tooltip>
                    <Tooltip arrow title={this.state.project.apmStatus?i18n.t('project.apmStatus_' + this.state.project.apmStatus, 'fail'):''} placement="top">
                      <Button
                        hidden={this.state.project.apmStatus==='1003'}
                        className={'p-1 m-1 btn-transition-none border-0 btn-outline-danger'}
                        variant="outlined">
                              <span className="btn-wrapper--icon">
                                <FontAwesomeIcon
                                  icon={['fas', 'ban']}
                                  className="font-size-sm"
                                />
                              </span>
                      </Button>
                    </Tooltip>
                  </div>
                </Grid>
                <Grid item className="list-item-maru-custom">
                  <div className="mb-1">
                    <FontAwesomeIcon icon={['fas', 'square']}/>
                    <span>{i18n.t('project.logo', '로고 이미지')}</span>
                  </div>
                  <div className="logo-image font-weight-bold">
                    {
                      this.state.project.logoImg?
                        <img src={this.state.project.logoImg}/>:
                        <div className="text-center">
                          <FontAwesomeIcon icon={['far', 'file-image']} />
                          <p>No Image</p>
                        </div>
                    }
                  </div>
                </Grid>
                <Grid item className="list-item-maru-custom">
                  <div className="mb-1">
                    <FontAwesomeIcon icon={['fas', 'square']}/>
                    <span>{i18n.t('common.timeZone', '타임존')}</span>
                  </div>
                  <div className='font-weight-bold'>
                    {this.state.project.timeZone}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <div hidden={this.state.project.type !== '1002' || (this.state.project.projectMember && this.state.project.projectMember.role !== 'BILL') || !this.state.cardInfo}>
          <div className="h-20px"/>
          <Card className="card-box card-maru-custom">
            <CardContent className="p-4">
              <div className="d-flex flex-column flex-lg-row align-items-start justify-content-between">
                <div className="text-center text-lg-left mb-3 mb-lg-0">
                  <h1>{i18n.t('project.subscriptionPayInfo', '구독 결제 정보')}</h1>
                </div>
                <div className="text-center text-lg-left"
                hidden={'1004'===this.state.project.status}>
                  <Button size="small" className='button-maru-second-outlined m-1' onClick={() => {
                    this.datTimeDialogRef.current.open((date) => {
                      this.subscriptionStop(utils.date.jsDateToDateTime(date));

                    });
                  }}>
                    <span className="btn-wrapper--icon">
                        <FontAwesomeIcon icon={['fas', 'strikethrough']}/>
                    </span>
                    <span className="btn-wrapper--label">{i18n.t("project.paidConversionCancel")}</span>
                  </Button>
                </div>
              </div>
              <div className="divider my-3"/>
              <Grid container wrap='nowrap' className='project-info-grid'>
                <Grid container item direction="column" xs={12}>
                  <Grid item className="list-item-maru-custom">
                    <div className="mb-1">
                      <FontAwesomeIcon icon={['fas', 'square']}/>
                      <span>{i18n.t('paymentsManagement.pgCodeNm', '결제수단')}</span>
                    </div>
                    <div className='font-weight-bold'>
                      {this.state.cardInfo?this.state.cardInfo.pgCodeNm:''}
                    </div>
                  </Grid>
                  <Grid item className="list-item-maru-custom">
                    <div className="mb-1">
                      <FontAwesomeIcon icon={['fas', 'square']}/>
                      <span>{i18n.t('paymentsManagement.billKey', '자동결제 키')}</span>
                    </div>
                    <div className='font-weight-bold'>
                      {this.state.cardInfo?this.state.cardInfo.billKey:''}
                    </div>
                  </Grid>
                </Grid>
                <Grid container item direction="column" xs={12}>
                  <Grid item className="list-item-maru-custom">
                    <div className="mb-1">
                      <FontAwesomeIcon icon={['fas', 'square']}/>
                      <span>{i18n.t('paymentsManagement.cardName', '카드정보')}</span>
                    </div>
                    <div className='font-weight-bold'>
                      {this.state.cardInfo?this.state.cardInfo.payInfo:''}
                    </div>
                  </Grid>
                  <Grid item className="list-item-maru-custom">
                    <div className="mb-1">
                      <FontAwesomeIcon icon={['fas', 'square']}/>
                      <span>{i18n.t('project.createdId', '생성한 사용자')}</span>
                    </div>
                    <div className='font-weight-bold'>
                      {this.state.cardInfo?this.state.cardInfo.userId:''}
                    </div>
                  </Grid>
                </Grid>
                <Grid container item direction="column" xs={12}>
                  <Grid item className="list-item-maru-custom">
                    <div className="mb-1">
                      <FontAwesomeIcon icon={['fas', 'square']}/>
                      <span>{i18n.t('paymentsManagement.cardNumber', '카드 번호')}</span>
                    </div>
                    <div className='font-weight-bold'>
                      {this.state.cardInfo?this.state.cardInfo.cardInfo:''}
                    </div>
                  </Grid>
                  <Grid item className="list-item-maru-custom">
                    <div className="mb-1">
                      <FontAwesomeIcon icon={['fas', 'square']}/>
                      <span>{i18n.t('common.created', '등록일')}</span>
                    </div>
                    <div className='font-weight-bold'>
                      {this.state.cardInfo?utils.date.millisToString(this.state.cardInfo.created, 'yyyy-MM-dd HH:mm'):''}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
        <DateTimeDialog ref={this.datTimeDialogRef} 
          title={i18n.t("project.subscriptionFinished")} 
          contents={[
            i18n.t("project.subscriptionFinishedMessage1"),
            i18n.t("project.subscriptionFinishedMessage2"),]}
          okButtonName={i18n.t("common.confirm")}
          cancelButtonName={i18n.t("common.cancel")}
        />
        <ProjectForm ref={this.formPopup} closeCallback={this.getInfo} />
      </>
    )
  }
}

ProjectInformation = connect(
  (state) => ({
    showBodyLoading: state.OpenmaruReducer.showBodyLoading,
    userSelectedProject: state.OpenmaruReducer.userSelectedProject
  }),
  (dispatch) => ({
    setShowBodyLoading: (enable) => dispatch(setShowBodyLoading(enable)),
    setUserSelectedProject: (selectedProject) => dispatch(setUserSelectedProject(selectedProject))
  })
)(ProjectInformation);
export default withRouter(ProjectInformation);
