import React, {Component} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Dialog, Button} from '@material-ui/core';
import i18n from "../../config/lang/i18n";

export default class ConfirmDialog extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      title: '',
      content: '',
      type: {},
      callbackFunction: () => {}
    }

    this.open = this.open.bind(this);
    this.toggle = this.toggle.bind(this);
    this.clickHandler = this.clickHandler.bind(this);
  }

  open(settings, callback) {
    this.setState({callbackFunction: callback});

    const baseIconStyle = 'd-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper m-0 d-130 '
    const baseButtonStyle = 'btn-pill mx-1 '

    if (settings.type === 'delete') {
      this.setState({
        title: settings.title,
        content: settings.content? settings.content: '',
        type: {
          icon: 'times',
          iconStyle: baseIconStyle + 'bg-neutral-danger text-danger',
          okButtonName: i18n.t('alertDialog.deleteButton', '삭제'),
          okButtonStyle: baseButtonStyle + 'btn-danger',
        }
      })
    } else if (settings.type === 'recover') {
      this.setState({
        title: settings.title,
        content: settings.content? settings.content: '',
        type: {
          icon: 'undo-alt',
          iconStyle: baseIconStyle + 'bg-success text-white',
          okButtonName: i18n.t('alertDialog.recoverButton', '복구'),
          okButtonStyle: baseButtonStyle + 'btn-success',
        }
      })
    } else if (settings.type === 'warn') {
      this.setState({
        title: settings.title,
        content: settings.content? settings.content: '',
        type: {
          icon: 'dot-circle',
          iconStyle: baseIconStyle + 'bg-warning text-white',
          okButtonName: i18n.t('alertDialog.okButton', '확인'),
          okButtonStyle: baseButtonStyle + 'btn-warning',
        }
      })
    } else {
      this.setState({
        title: settings.title,
        content: settings.content? settings.content: '',
        type: {
          icon: 'lightbulb',
          iconStyle: baseIconStyle + 'bg-neutral-first text-first',
          okButtonName: i18n.t('alertDialog.okButton', '확인'),
          okButtonStyle: baseButtonStyle + 'btn-first',
        }
      })
    }

    this.setState({isOpen: true});
  }

  close() {
    this.setState({isOpen: false});
  }

  toggle() {
    this.setState({isOpen: !this.state.isOpen});
  }

  clickHandler() {
    if (this.state.callbackFunction && typeof this.state.callbackFunction === 'function') {
      this.close();
      this.state.callbackFunction();
    }
  }

  render() {
    return (
      <div>
        <Dialog
          open={this.state.isOpen}
          onClose={this.toggle}
          classes={{paper: 'shadow-lg rounded'}}>
          <div className="text-center p-5">
            <div className="avatar-icon-wrapper rounded-circle m-0">
              <div className={this.state.type.iconStyle}>
                <FontAwesomeIcon
                  icon={['fas', this.state.type.icon]}
                  className="d-flex align-self-center display-3"
                />
              </div>
            </div>
            <h4 className="font-weight-bold mt-4">
              {this.state.title}
            </h4>
            {this.state.content && (<h4>{this.state.content}</h4>)}
            <div className="pt-4">
              <Button onClick={() => this.clickHandler()} className={this.state.type.okButtonStyle}>
                <span className="btn-wrapper--label">{this.state.type.okButtonName}</span>
              </Button>
              <Button onClick={this.toggle} className="btn-neutral-secondary btn-pill mx-1">
                <span className="btn-wrapper--label">{i18n.t('alertDialog.cancelButton', '취소')}</span>
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    )
  }
}

