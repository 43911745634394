import React, { Component } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import i18n from "../../../../config/lang/i18n";
import TitBreadcrumbs from "../../../component/TitBreadcrumbs";
import MUITable from "openmaru/component/MUITable";
import APIUtils from '../../../../utils/APIUtils';
import utils from "../../../../utils/Utils";
import { Button, Tooltip, Box } from '@material-ui/core';
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import koLocale from 'date-fns/locale/ko';
import { DateTime } from 'luxon';

import BillDetailList from "../../bill/BillDetailList";
import notification from "../../../component/Notification";
import ConfirmDialog from "../../../component/ConfirmDialog";
class BillRangeManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      billingMonth: undefined,
      pid: undefined,
      data: [],
      page: 1,
      size: 15,
      totalCount: 0,
      searchText: '',
      searchYear: DateTime.local().year,
      searchMaxDate: new Date().setFullYear(new Date().getFullYear() + 1),
      sort: {},
      isLoading: false
    }

    this.detailPopup = React.createRef();

    this.handleDateChange = this.handleDateChange.bind(this);
    this.showBillDetail = this.showBillDetail.bind(this);
    this.getBillsList = this.getBillsList.bind(this);
    this.createRangeBill = this.createRangeBill.bind(this);
    this.recreateRangeBill = this.recreateRangeBill.bind(this);
    this.confirmDialog = React.createRef();
  }

  getBillsList() {
    this.setState({ isLoading: true });

    APIUtils.superAdmin.getSalesBillsList({
      year: this.state.searchYear
    }, (data) => {
      if(data.status === 200) {
        this.setState({data: data.body.map((row) => {
          row.name = row.project.name;
          return row;
        })});
      } else {
        notification.error(data.message);
      }
      this.setState({ isLoading: false });
    })
  }

  showBillDetail(bill) {
    this.detailPopup.current.showBillDetail(bill.billId, bill.pid, bill.project.name);
  }

  createRangeBill(bill) {
    APIUtils.superAdmin.createRangeBill({
      pid: bill.project.pid,
      licenseSeq: bill.project.licenseSeq
    }, (data) => {
      if (data.status === 200) {
        this.getBillsList();
        notification.info(i18n.t('common.requestSuccessful', '요청 정보를 처리 하였습니다.'))
      } else {
        notification.error(data.message);
      }
    });
  }

  recreateRangeBill(bill) {
    APIUtils.superAdmin.recreateRangeBill({
      pid: bill.pid,
      licenseSeq: bill.project.licenseSeq,
      billId: bill.billId
    }, (data) => {
      if (data.status === 200) {
        this.getBillsList();
        notification.info(i18n.t('common.requestSuccessful', '요청 정보를 처리 하였습니다.'))
      } else {
        notification.error(data.message);
      }
    });
  }

  handleDateChange(value, callback) {
    this.setState({
      searchYear: value.getFullYear()
    }, () => {
      if(callback) {
        callback();
      }
    })
  }

  render () {
    const columns = [
      { label: i18n.t('project.projectId', '프로젝트 아이디'), name: 'pid',
        options: {
          setCellProps: (_ => ({style: {minWidth: "7vw", maxWidth: "7vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center'}}>{value}</div>
          }
        }
      },
      { label: i18n.t('project.projectName', '프로젝트 이름'), name: 'name',
        options: {
          setCellProps: (_ => ({style: {minWidth: "7vw", maxWidth: "7vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center'}}>{utils.string.unescape(value)}</div>
          }
        }
      },
      { label: i18n.t('billManagement.contractDuration', '계약 기간'), name: 'project.startDate',
        options: {
          setCellProps: (_ => ({style: {minWidth: "10vw", maxWidth: "10vw"}})),
          customBodyRenderLite: (dataIndex) => {
            let rowData = this.state.data[dataIndex];
            if (rowData) {
              let licenseStartDate = rowData.project ? rowData.project.licenseStartDate : '';
              let licenseEndDate = rowData.project ? rowData.project.licenseEndDate : '';
              return <div style={{textAlign: 'center'}}>{utils.date.fromToString(licenseStartDate, 'yyyyMMdd', 'yyyy-MM-dd')} ~ {utils.date.fromToString(licenseEndDate, 'yyyyMMdd', 'yyyy-MM-dd')}</div>
            }
          }
        }
      },
      { label: i18n.t('billManagement.billId', '청구서 아이디'), name: 'billId',
        options: {
          setCellProps: (_ => ({style: {minWidth: "7vw", maxWidth: "7vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center'}}>{value}</div>
          }
        }
      },
      { label: i18n.t('billManagement.paymentDuration', '사용 기간'), name: 'useStartDate',
        options: {
          setCellProps: (_ => ({style: {minWidth: "10vw", maxWidth: "10vw"}})),
          customBodyRenderLite: (dataIndex) => {
            let rowData = this.state.data[dataIndex];
            if (rowData) {
              let useStartDate = rowData ? rowData.useStartDate : '';
              let useEndDate = rowData ? rowData.useEndDate : '';
              return <div style={{textAlign: 'center'}}>{utils.date.fromToString(useStartDate, 'yyyyMMdd', 'yyyy-MM-dd')} ~ {utils.date.fromToString(useEndDate, 'yyyyMMdd', 'yyyy-MM-dd')}</div>
            }
          }
        }
      },
      { label: i18n.t('billManagement.billingDate', '청구일'), name: 'billingDate',
        options: {
          setCellProps: (_ => ({style: {minWidth: "7vw", maxWidth: "7vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center'}}>{utils.date.fromToString(value, 'yyyyMMdd', 'yyyy-MM-dd')}</div>
          }
        }
      },
      { label: i18n.t('billManagement.amount', '청구금액'), name: 'amount',
        options: {
          setCellProps: (_ => ({style: {minWidth: "7vw", maxWidth: "7vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center'}}>{utils.string.numberToCommas(value)}</div>
          }
        }
      },
      { label: `${i18n.t('common.status', '상태')}`, name: 'pay',
        options: {
          setCellProps: (_ => ({style: {minWidth: "7vw", maxWidth: "7vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            let color = 'dark';
            if (value && value.status === '1001') {
              color = 'success';
            } else if (value && value.status === '1002') {
              color = 'dark';
            } else if (value && value.status === '1003') {
              color = 'danger';
            } else if ( value && value.status === '1004') {
              color = 'warning';
            }
            return (<div className="d-flex justify-content-between">
              <Tooltip title={value?value.resultMessage:''} arrow placement="top-start" classes={{tooltip: "p-3"}}>
                <div className={'badge badge-' + color} style={{textAlign: 'center', margin: '0 auto'}}>{value?value.statusNm:'결제대기'}</div>
              </Tooltip>
            </div>)
          }
        }
      },
      { label: i18n.t('paymentsManagement.amount', '결제 금액'), name: 'pay',
        options: {
          setCellProps: (_ => ({style: {minWidth: "7vw", maxWidth: "7vw"}})),
          customBodyRenderLite: (dataIndex) => {
            let rowData = this.state.data[dataIndex];
            let status = (rowData&&rowData.pay)?rowData.pay.status:'';
            let amount = (rowData&&rowData.pay)?utils.string.numberToCommas(rowData.pay.amount):''
            return <div style={{textAlign: 'center'}} className={(status==='1002')?'textCancelLine':''}>{amount}</div>
          }
        }
      },
      { label: i18n.t('common.command'), name: "",
        options: {
          setCellProps: (_ => ({style: {minWidth: "9vw", maxWidth: "9vw"}})),
          customBodyRenderLite: (dataIndex) => {
            let rowData = this.state.data[dataIndex];
            if (rowData)
              return (<div className="text-center">
                <Tooltip title={i18n.t('billManagement.billDetail')} arrow placement="top-start" classes={{tooltip: "p-3"}}>
                  <span>
                    <Button
                      onClick={() => this.showBillDetail(rowData)}
                      className="btn-dark mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                      disabled={rowData.billId?false:true}
                    >
                      <FontAwesomeIcon icon={['fas', 'list-alt']} className="font-size-md"/>
                    </Button>
                  </span>
                </Tooltip>
                <Tooltip title={i18n.t('paymentsManagement.issueBills')} arrow placement="top-start" classes={{tooltip: "p-3"}}>
                  <span>
                    <Button
                      onClick={() =>
                        this.confirmDialog.current.open({
                          title: utils.string.unescape(rowData.project.name) + ' ' +  ' - ' + i18n.t('billManagement.createMessage'),
                          type: 'info'
                        }, () => {
                          this.createRangeBill(rowData)
                        })
                      }
                      className="btn-dark mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                      disabled={utils.string.isNotBlank(rowData.billId)?true:false}
                    >
                      <FontAwesomeIcon icon={['fas', 'file-invoice-dollar']} className="font-size-md"/>
                    </Button>
                  </span>
                </Tooltip>
                <Tooltip title={i18n.t('paymentsManagement.reissueBills')} arrow placement="top-start" classes={{tooltip: "p-3"}}>
                  <span>
                    <Button
                      onClick={() =>
                        this.confirmDialog.current.open({
                          title: utils.string.unescape(rowData.project.name) + ' ' +  ' - ' + i18n.t('billManagement.recreateMessage'),
                          type: 'info'
                        }, () => {
                          this.recreateRangeBill(rowData)
                        })
                      }
                      className="btn-dark mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                      disabled={(utils.string.isBlank(rowData.billId) || (rowData.pay && rowData.pay.status === '1001'))?true:false}
                    >
                      <FontAwesomeIcon icon={['fas', 'redo-alt']} className="font-size-md"/>
                    </Button>
                  </span>
                </Tooltip>
              </div>)
          }
        }
      },
    ];

    const options = {
      onTableInit: (e) => {
        this.getBillsList();
      },
      draggableColumns: {
        enabled: true
      },
      serverSide: false,
      rowsPerPage: 15,
      filter: false,
      responsive: 'simple',
      selectableRows: 'none',
      search: true,
      searchOpen: true,
      customToolbar: () => {
        return (<>
          <Box className="d-inline-flex align-items-center mx-2" style={{width: '5rem'}}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={koLocale}>
              <DatePicker
                views={["year"]}
                maxDate={this.state.searchMaxDate}
                value={DateTime.local(this.state.searchYear, 1, 1)}
                onChange={this.handleDateChange}
                format="yyyy"
                variant="inline"
                autoOk={true}
                onYearChange={(value)=>{
                  this.handleDateChange(value, this.getBillsList)
                }}
              />
            </MuiPickersUtilsProvider>
          </Box>
        </>)
      }
    };

    return (
      <>
        <TitBreadcrumbs title={i18n.t('paymentsManagement.salesTitle', '계약 관리')} />

        <div className="app-page-title">
          <div>
            <h1><FontAwesomeIcon icon={['fas', 'receipt']}
                                  className="icon"/> {i18n.t('paymentsManagement.salesTitle', '계약 관리')}</h1>
          </div>
        </div>
        <MUITable columns={columns} data={this.state.data} options={options} loading={this.state.isLoading}/>
        <BillDetailList ref={this.detailPopup} />
        <ConfirmDialog ref={this.confirmDialog}/>
      </>
    )
  }
}

export default BillRangeManagement;
