import React, {Component} from 'react';
import {Button} from '@material-ui/core';
import {NavLink} from 'react-router-dom';
import utils from '../../utils/Utils';

export default class Main extends Component {
  constructor(props) {
    super(props);
    // console.log("utils.auth.isLogin(): ", utils.auth.isLogin())
    // console.log("utils.auth.hasSuperAdmin(): ", utils.auth.hasSuperAdmin())
    // console.log("utils.auth.getRoles(): ", utils.auth.getRoles())
    if (utils.auth.isLogin()) {
      if (utils.auth.hasSuperAdmin()) {
        this.props.history.push('/admin/projectList');
      } else {
        this.props.history.push('/projectsDashboard');
      }
    } else {
      this.props.history.push('/login');
    }

  }

  render() {
    return (
      <div>
        <h1>Intro Page</h1>
        <NavLink to='/login'>
          <Button>로그인</Button>
        </NavLink>
        <NavLink to='/signup'>
          <Button>회원가입</Button>
        </NavLink>
      </div>
    )
  }
}
