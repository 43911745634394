import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import { Button, Tooltip } from '@material-ui/core';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import i18n from "../../../../config/lang/i18n";
import MUITable from "../../../component/MUITable";
import TitBreadcrumbs from "../../../component/TitBreadcrumbs";
import APIUtils from '../../../../utils/APIUtils';
import notification from "../../../component/Notification";
import utils from "../../../../utils/Utils";
import queryString from 'query-string';
import CustomSearchRender from '../../../component/CustomSearchRender';
import ConfirmDialog from '../../../component/ConfirmDialog';

class QnaList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      page: 1,
      size: 15,
      totalCount: 0,
      searchText: '',
      sort: {},
      isLoading: false
    }

    this.deleteMemberConfirmDialog = React.createRef();
    this.getQnaList = this.getQnaList.bind(this);
  }

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    this.setState({page: (query.page?query.page:1)}, () => {
      this.getQnaList();
    });
  }

  getQnaList(params) {
    let newParams;
    this.setState({isLoading: true});

    if (params && params.paging) {
      newParams = {
        params: {
          page: (this.state.page),
          size: this.state.size
        }
      };
    } else {
      newParams = {
        params: {
          page: 1,
          size: this.state.size
        }
      }
    }

    if (this.state.searchText) {
      const encodingURL =
        'userId=' + this.state.searchText + '&projectName=' + this.state.searchText + '&subject=' + this.state.searchText + '&company=' + this.state.searchText;
        newParams.params.q = encodeURIComponent(encodingURL);
        newParams.params.c = 'OR';
    }

    if (this.state.sort.name) {
      newParams.params.s = this.state.sort.name + ':' + this.state.sort.direction
    }

    APIUtils.superAdmin.getQnaList(newParams, (data) => {
      if(data.status === 200) {
        const { list, totalCount } = data.body;
        const tempList = [];

        list.forEach((qna, index) => {
          tempList.push({
            num: (totalCount-((this.state.page-1)*this.state.size))-(index),
            ...qna
          })
        })

        this.setState({
          data: tempList,
          totalCount: totalCount,
          isLoading: false
        })
      } else {
        notification.error(data.message);
      }
    })
  }

  render() {
    const columns = [
      {
        label: i18n.t('common.num', '번호'), name: 'num', options: {
          sort: false,
          setCellProps: (_ => ({style: {minWidth: "2vw", maxWidth: "2vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center', cursor: 'pointer'}}>{value}</div>
          }
        }
      },
      {
        label: i18n.t('qna.subject', '제목'), name: 'subject', options: {
          setCellProps: (_ => ({style: {minWidth: "14vw", maxWidth: "14vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div className="text-overflow-ellipsis" style={{textAlign: 'left', cursor: 'pointer'}} dangerouslySetInnerHTML={{__html: value}}/>
          }
        }
      },
      {
        label: i18n.t('project.projectName', '프로젝트'), name: 'projectName', options: {
          setCellProps: (_ => ({style: {minWidth: "7vw", maxWidth: "7vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center', cursor: 'pointer'}}>{utils.string.unescape(value)}</div>
          }
        }
      },
      {
        label: i18n.t('userList.userId', '아이디'), name: 'userId', options: {
          setCellProps: (_ => ({style: {minWidth: "4vw", maxWidth: "4vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center', cursor: 'pointer'}}>{value}</div>
          }
        }
      },
      {
        label: i18n.t('common.status', '상태'), name: 'doneed', options: {
          setCellProps: (_ => ({style: {minWidth: "4vw", maxWidth: "4vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            let isLastCommentSuperAdmin = this.state.data[tableMeta.rowIndex].isLastCommentSuperAdmin;
            let ing = '';
            let color = 'dark';
            if (isLastCommentSuperAdmin && isLastCommentSuperAdmin === true) {
              ing = i18n.t('qna.checkingCustomer', '답변됨(고객)');
            } else if (isLastCommentSuperAdmin && isLastCommentSuperAdmin === false) {
              ing = i18n.t('qna.reComment', '추가 질의(확인 중 - 관리자)');
              color = 'danger';
            } else {
              ing = i18n.t('qna.checkingOpennaru', '확인 중 - 관리자');
              color = 'danger';
            }
            if (value&&value === true) {
              color = 'success';
            }
            return (<div className="d-flex justify-content-between" style={{cursor: 'pointer'}}>
                <div className={'badge badge-' + color} style={{textAlign: 'center', margin: '0 auto'}}>{(value&&value===true)?i18n.t('qna.resolved', '해결 완료'):ing}</div>
            </div>)
          }
        }
      },
      {
        label: i18n.t('common.created', '생성일'), name: 'created', options: {
          setCellProps: (_ => ({style: {minWidth: "7vw", maxWidth: "7vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div style={{textAlign: 'center', cursor: 'pointer'}}>{utils.date.millisToString(value)}</div>
          }
        }
      },
      {
        label: i18n.t('common.command'), name: '', options: {
          sort: false,
          filter: false,
          setCellProps: (_ => ({style: {minWidth: "4vw", maxWidth: "4vw"}})),
          customBodyRenderLite: (dataIndex, rowIndex) => {
            return (
              <div style={{textAlign: 'center'}}>
                <Tooltip title={i18n.t('common.delete')} arrow placement="top-start" classes={{tooltip: "p-3"}}>
                  <Button
                    className="btn-dark mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                    onClick={(event) => {
                      this.deleteMemberConfirmDialog.current.open({
                        title: this.state.data[dataIndex].num + '. ' + this.state.data[dataIndex].subject + ' - ' + i18n.t('common.confirmDeleteMessage', '삭제 하시겠습니까?'),
                        type: 'delete'
                      }, () => {
                        APIUtils.superAdmin.removeQnaContent({
                          pid: this.state.data[dataIndex].pid,
                          seq: this.state.data[dataIndex].seq
                        }, (data) => {
                          if (data.status === 200) {
                            notification.info(i18n.t('common.deleteSuccessful', '삭제되었습니다.'));
                            this.getQnaList();
                          }
                        });
                      });
                      event.stopPropagation();
                    }}>
                    <FontAwesomeIcon icon={['fas', 'ban']} className="font-size-sm"/>
                  </Button>
                </Tooltip>
              </div>
            );
          }
        }
      }
    ];
    const options = {
      onTableInit: (e) => {},
      rowsPerPage: this.state.size,
      page: this.state.page-1,
      count: this.state.totalCount,
      refresh: this.getQnaList,
      searchText: this.state.searchText,
      customSearchRender: (searchText, handleSearch, hideSearch, options) => {
        return (
          <CustomSearchRender
            searchText={searchText}
            onSearch={handleSearch}
            onHide={hideSearch}
            options={options}
            searchAction={(customText) => {
              this.setState({searchText: customText}, this.getQnaList);
            }}
          />
        );
      },
      onTableChange: (action, tableState) => {
        if (action === "changePage") {
          const setCountPage = tableState.page+1
          this.setState({page: setCountPage}, ()=> {
            this.getQnaList({paging: true});
          })
          this.props.history.replace(`/admin/qnaList?page=${setCountPage}`);
        }

        if (action === "changeRowsPerPage") {
          this.setState({
            size: tableState.rowsPerPage
          }, this.getQnaList)
        }

        if (action === "sort") {
          this.setState({sort: tableState.sortOrder}, _ => {
            this.getQnaList({paging: true})
          })
        }
      },
      onRowClick: (rowData, rowMeta) => {
        let board = this.state.data[rowMeta.dataIndex];
        this.props.history.push(`/admin/qnaList/qnaContent?id=${board.pid}&seq=${board.seq}&page=${this.state.page}`);
      },
    };

    return (
      <>
        <TitBreadcrumbs title={i18n.t('qna.title', '문의하기')} />
        <div className="app-page-title">
          <div>
            <h1><FontAwesomeIcon icon={['fas', 'question-circle']} className="icon"/> {i18n.t('qna.title', '1:1 문의')}</h1>
          </div>
        </div>
        <MUITable columns={columns} options={options} data={this.state.data} loading={this.state.isLoading}/>
        <ConfirmDialog ref={this.deleteMemberConfirmDialog}/>
      </>
    )
  }
}

export default withRouter(QnaList);
