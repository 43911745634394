import React, {Component} from 'react';

import {Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, Grid, MenuItem, Select, InputAdornment} from '@material-ui/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';
import CheckIcon from '@material-ui/icons/Check';
import CloudUploadTwoToneIcon from '@material-ui/icons/CloudUploadTwoTone';
import Dropzone from 'react-dropzone'

import i18n from "../../../config/lang/i18n";
import APIUtils from '../../../utils/APIUtils';
import utils from "../../../utils/Utils";
import notification from "../../component/Notification";
import ConfirmDialog from "../../component/ConfirmDialog";
import CTextField from "openmaru/component/CTextField";

export const fileToBase64 = (file) => {
  return new Promise(resolve => {
    var reader = new FileReader();
    reader.onload = function (event) {
      resolve(event.target.result);
    };
    reader.readAsDataURL(file);
  });
};

class ProjectForm extends Component {
  constructor() {
    super();

    this.state = {
      pid: undefined,
      subDomain: '',
      invalidSubDomain: false,
      name: '',
      description: '',
      company: '',
      team: '',
      service: utils.getDefaultServiceProvider(),
      timeZone: 'Asia/Seoul',
      projectRole: '',
      createFormState: false,
      clickState: false,
      logoImg: undefined,
      userInfo: {},
      returnUrl: ''
    }

    this.deleteConfirmDialog = React.createRef();
    this.nameInput = React.createRef();
    this.subDomainInput = React.createRef();
    this.subscriptionRegisterWindowRef = React.createRef();

    this.handleChange = this.handleChange.bind(this);
    this.createProject = this.createProject.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.subscriptionRegister = this.subscriptionRegister.bind(this);
  }

  componentDidMount() {
  }

  showCreateAndUpdateForm(project) {
    if (project && project.pid) {
      this.setState({
        pid: project.pid,
        subDomain: project.subDomain,
        name: project.name,
        description: project.description,
        company: project.company,
        team: project.team,
        service: project.serviceProvider + '-' + project.serviceRegion,
        timeZone: project.timeZone,
        logoImg: project.logoImg,
        projectRole: project.projectMember.role,
        paid: utils.string.isNotBlank(project.paidDate)? true: false
      });
    } else {
      APIUtils.user.getUser({}, (data) => {
        if(data.status === 200) {
          let userInfo = data.body;
          let company = userInfo.company ? userInfo.company : '';
          let team = userInfo.team ? userInfo.team : '';

          this.setState({
            userInfo: userInfo,
            pid: undefined,
            subDomain: '',
            name: '',
            description: '',
            company: company,
            team: team,
            service: utils.getDefaultServiceProvider(),
            timeZone: 'Asia/Seoul',
            logoImg: undefined
          });
        }
      })

    }
    this.setState({createFormState: true})
  }

  createProject() {
    let serviceProvider = '';
    let serviceRegion = '';
    if (this.state.service !== '') {
      if (this.state.service.indexOf('-') > -1) {
        let serviceArr = this.state.service.split('-');
        serviceProvider = serviceArr[0];
        serviceRegion = serviceArr[1];
      }
    }

    if (utils.string.isBlank(this.state.name) || utils.string.byteLen(this.state.name) > 100) {
      notification.warn(i18n.t('project.nameErrorMessage'));
      this.nameInput.current.focus();
      return;
    }

    if (!utils.string.checkSubDomain(this.state.subDomain)) {
      notification.warn(i18n.t('project.subDomainErrorMessage'));
      this.subDomainInput.current.focus();
      return;
    }

    this.setState({clickState: true});
    if (this.state.pid) {
      APIUtils.project.updateProjects({
        body: {
          pid: this.state.pid,
          subDomain: this.state.subDomain,
          name: this.state.name,
          description: this.state.description,
          company: this.state.company,
          team: this.state.team,
          serviceProvider: serviceProvider,
          serviceRegion: serviceRegion,
          timeZone: this.state.timeZone,
          logoImg: this.state.logoImg
        }
      }, (data) => {
        if (data.status === 200) {
          notification.info(i18n.t('common.saveSuccessful'), {});
          this.props.closeCallback();
          this.setState({createFormState: false});
        } else {
          notification.error(i18n.t('common.saveFailed'), {});
        }

        this.setState({clickState: false});
      });
    } else {
      APIUtils.project.createProjects({
        body: {
          subDomain: this.state.subDomain,
          name: this.state.name,
          description: this.state.description,
          company: this.state.company,
          team: this.state.team,
          serviceProvider: serviceProvider,
          serviceRegion: serviceRegion,
          timeZone: this.state.timeZone,
          logoImg: this.state.logoImg,
        }
      }, (data) => {
        if (data.status === 201) {
          notification.info(i18n.t('common.saveSuccessful'), {});
          this.props.closeCallback();
          this.setState({createFormState: false});
        } else {
          notification.error(data.message, {});
        }
        this.setState({clickState: false});
      });
    }
  }

  deleteProject(pid) {
    this.setState({clickState: true});

    APIUtils.project.deleteProject({projectId: pid}, (data) => {
      if (data.status === 200) {
        this.props.closeCallback();
        notification.info(i18n.t('common.deleteSuccessful', {}))
        this.deleteConfirmDialog.current.close();
        this.setState({createFormState: false});
        // this.history.push("/projectsDashboard");
        // this.context.router.history.push("/projectsDashboard");
        // history.goBack();
        // TODO : Reload하지 않고 프로젝트 메뉴를 초기화 해야 함
        window.location.href = '/console/projectsDashboard';
      } else {
        notification.info(data.message);
      }
      this.setState({clickState: false});
    });
  }

  toggle() {
    this.setState({createFormState: !this.state.createFormState});
  }

  handleChange(event) {
    const target = event.target;
    this.setState({[target.name]: (event.target.tagName === 'INPUT' && event.target.type === 'text')?utils.string.unescape(target.value):target.value}, () => {
      if (target.name === "subDomain") {
        if (utils.string.checkSubDomain(this.state.subDomain)) {
          this.setState({invalidSubDomain: false})
        } else {
          this.setState({invalidSubDomain: true})
        }
      }
    });
  }

  onDrop(acceptedFiles) {
    acceptedFiles.forEach(file => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (event) {}; // data url!
      fileToBase64(file).then(result => {
        this.setState({logoImg: result});
      });
    });
  }

  subscriptionRegister() {
    this.setState({
      returnUrl: '/console/projectRegisterSubscription?pid=' + this.state.pid
    }, () => {
      const popupOptions = 'left=200, top=150, width=500, height=400, status=no, menubar=no, toolbar=no, resizable=no';
      const subscriptionWindow = window.open(this.state.returnUrl, '', popupOptions);

      subscriptionWindow.addEventListener("beforeunload", (event) => {
        const payletterWindow = subscriptionWindow.document.getElementById('PayletterWindow').value;

        if(payletterWindow) {
          window.onfocus = (event) => {
            this.props.closeCallback();
            if(payletterWindow.closed) { 
              window.onfocus = () => {};
            }
          };
        }
      })
    })
  }

  render() {
    return (
      <>
        <Dialog
          classes={{paper: 'modal-content dialog-maru-custom'}}
          fullWidth
          maxWidth="md"
          open={this.state.createFormState}
          onClose={this.toggle}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            { this.state.pid ? i18n.t('project.edit', '프로젝트 수정') + ' - (' + this.state.pid + ')' : i18n.t('project.create', '프로젝트 생성')}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <div hidden={true}>
                  <label className="font-weight-bold mb-2">
                    {i18n.t('project.id', {})}
                  </label>
                  <CTextField
                    className="input-maru-custom"
                    fullWidth
                    disabled={true}
                    name="pid"
                    size="small"
                    value={this.state.pid}
                  />
                </div>

                <label className="font-weight-bold mb-2">
                  {i18n.t('project.name', {})}
                </label>
                <CTextField
                  className="input-maru-custom"
                  // autoFocus={true}
                  inputRef={this.nameInput}
                  fullWidth
                  name="name"
                  size="small"
                  inputProps={{maxLength: 100}}
                  value={this.state.name}
                  onChange={this.handleChange}
                  // error={this.state.name}
                />

                <label className="font-weight-bold my-2">
                  {i18n.t('project.subDomain', {})}
                </label>
                <CTextField
                  className="input-maru-custom"
                  fullWidth
                  inputRef={this.subDomainInput}
                  name="subDomain"
                  size="small"
                  inputProps={{maxLength: 20}}
                  value={this.state.subDomain}
                  onChange={this.handleChange}
                  error={this.state.invalidSubDomain}
                  helperText={i18n.t('project.subDomainHelperText', '영문으로 시작하고 숫자와 - 기호를 사용 할 수 있습니다.(최대 10자)')}
                  InputProps={{
                    startAdornment: (<InputAdornment position="start">
                      <span className="font-size-xs text-dark">https://</span>
                    </InputAdornment>),
                    endAdornment: (<InputAdornment position="end">
                      <span className="font-size-xs text-dark">.apm.{this.state.service}.openmaru.io</span>
                    </InputAdornment>)
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <div className="d-flex justify-content-between">
                  <label className="font-weight-bold mb-2">
                    {i18n.t('project.logo', '로고 이미지')}
                  </label>
                </div>
                <div className="dropzone text-center">
                  <div className="dropzone-inner-wrapper dropzone-inner-wrapper-alt">
                    <Dropzone onDrop={this.onDrop}>
                      {({getRootProps, getInputProps, isDragAccept, isDragReject, isDragActive}) => (
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div>
                            {utils.string.isEmpty(this.state.logoImg) && isDragAccept && (
                              <div
                                className="btn-icon hover-scale-rounded shadow-light-sm rounded-lg text-first">
                                <CheckIcon className="d-30"/>
                              </div>
                            )}
                            {utils.string.isEmpty(this.state.logoImg) && isDragReject && (
                              <div
                                className="btn-icon hover-scale-rounded shadow-light-sm rounded-lg text-first">
                                <CloseTwoToneIcon className="d-40"/>
                              </div>
                            )}
                            {utils.string.isEmpty(this.state.logoImg) && !isDragActive && (
                              <div
                                className="btn-icon hover-scale-rounded shadow-light-sm rounded-lg">
                                <CloudUploadTwoToneIcon className="d-40"/>
                              </div>
                            )}
                            {utils.string.isNotEmpty(this.state.logoImg) && (
                              <div
                                className="btn-icon hover-scale-rounded shadow-light-sm rounded-lg text-first">
                                <img
                                  className="img-fluid img-fit-container rounded-sm"
                                  src={this.state.logoImg}
                                  alt="..."
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <label className="font-weight-bold mb-2">
                  {i18n.t('project.description', {})}
                </label>
                <CTextField
                  className="input-maru-custom"
                  fullWidth
                  name="description"
                  size="small"
                  inputProps={{maxLength: 1000}}
                  value={this.state.description}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <label className="font-weight-bold mb-2">
                  {i18n.t('project.company', {})}
                </label>
                <CTextField
                  className="input-maru-custom"
                  fullWidth
                  name="company"
                  size="small"
                  inputProps={{maxLength: 100}}
                  value={this.state.company}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <label className="font-weight-bold mb-2">
                  {i18n.t('project.team', {})}
                </label>
                <CTextField
                  className="input-maru-custom"
                  fullWidth
                  name="team"
                  size="small"
                  inputProps={{maxLength: 100}}
                  value={this.state.team}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <label className="font-weight-bold mb-2">
                    {i18n.t('project.service', {})}
                  </label>
                  <Select
                    value={this.state.service}
                    onChange={this.handleChange}
                    disabled={ this.state.pid ? true : false }
                    displayEmpty>
                    {
                      utils.getServiceProviders().map(provider => {
                        return (<MenuItem value={provider} key={provider}>{i18n.t('project.region.' + provider, {})}</MenuItem>)
                      })
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <label className="font-weight-bold mb-2">
                    {i18n.t('common.timeZone', {})}
                  </label>
                  <Select
                    name="timeZone"
                    value={this.state.timeZone}
                    onChange={this.handleChange}
                    disabled={ this.state.pid ? true : false }>
                    {
                      Object.keys(utils.timeZones).map(key => {
                        return (<MenuItem value={key} key={key}>{'(' + utils.timeZones[key].GMT + ') ' + utils.timeZones[key].DESC}</MenuItem>)
                      })
                    }
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button className='m-3 btn-gradient shadow-none buttn-maru-contained'
                    hidden={!this.state.clickState}>
              <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={['fas', 'cog',]} spin/>
              </span>
              ...
            </Button>
            <div>
              <div hidden={this.state.projectRole !== 'BILL'}>
                <Button className='btn-gradient shadow-none button-maru-outlined'
                        onClick={() => {
                          this.deleteConfirmDialog.current.open({
                            title: this.state.name + ' - ' + i18n.t('common.confirmDeleteMessage'),
                            type: 'delete'
                          }, () => {
                            this.deleteProject(this.state.pid);
                          })
                        }}
                        hidden={this.state.clickState || (!this.state.pid || this.state.pid === '')}>
                  {i18n.t('common.delete', {})}
                </Button>
              </div>
            </div>
            <div>
            {(!this.state.paid && (this.state.projectRole===utils.BILL)) &&
              (<Button className='button-maru-second-outlined m-1' onClick={this.subscriptionRegister}>
                {i18n.t("project.paidConversion", "유료 전환")}
              </Button>)}
              <Button onClick={this.createProject} className='m-3 shadow-none button-maru-contained'
                      hidden={this.state.clickState}>
                {i18n.t('common.save', {})}</Button>
              <Button onClick={() => this.setState({
                createFormState: false
              })} className='button-maru-gray-contained'>{i18n.t('common.cancel', {})}</Button>
            </div>
          </DialogActions>
        </Dialog>
        <ConfirmDialog ref={this.deleteConfirmDialog}/>
      </>
    )
  }
}

export default ProjectForm;
