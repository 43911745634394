import React, { Component } from 'react';
import { withRouter } from 'react-router';

import { Container, Box } from '@material-ui/core';

class Terms extends Component {
	render() {
		return (
			<Container>
					<div className="app-page-title">
						<div>
							<h1>이용약관</h1>
						</div>
					</div>
					<Box>
						{`
						제1조 [목적]
						본 약관은 오픈나루 (이하 “회사” )에서 제공하는 서비스 이용과 관련하여 당사와 이용자(이하 “회원”) 에 관한 제반 사항을 규정함을 목적으로 합니다.

						제2조 [약관의 효력]
						(1) 약관은 공시하고 상대방이 동의함으로써 효력을 발생합니다. 본 약관의 공시는 회사 홈페이지에 게시하는 방법으로 합니다.
						(2) 회사는 ‘약관의규제에관한법률’ 등 관련 법을 위배하지 않는 범위 내에서 약관을 개정할 수 있습니다.
						(3) 회사가 약관을 개정할 수 있으며 개정할 경우에는 회사 홈페이지에 시행일 7일 전까지 공지합니다.
						(4) 제3항의 방법으로 변경 고지된 약관은 기존의 회원에게도 유효하게 적용됩니다. 

						제3조 [용어의 정의]
						(1) ‘회원’ 은 회사에 개인정보를 제공하여 회원등록을 한 자로서, 회사가 제공하는 서비스를 계약된 기간 안에서 계속적으로 이용할 수 있는 자를 말합니다. 
						(2) ‘아이디 (ID)’ 라 함은 ‘회원’ 의 식별과 서비스 이용을 위하여 회원이 정하고 회사가 승인하는 문자와 숫자의 조합을 의미합니다.
						(3) ‘비밀번호’ 라 함은 ‘회원’이 부여 받은 ‘아이디’ 와 일치되는 ‘회원’ 임을 확인하고 회사가 승인하는 문자와 숫자의 조합을 의미합니다. 

						제4조 [약관의 해석 및 관할법원] 
						(1) 약관에 정하지 않은 사항과 이 약관의 해석에 관하여는 관계법령 및 상관례에 따릅니다.
						(2) 회사와 회원사이에 분쟁이 발생할 경우에 관할 법원은 서울 중앙 지방 법원으로 하며 회사에 의해서 지정될 수 있습니다.

						제5조 [이용계약 체결]
						(1) 이용계약은 이용자가 약관의 내용에 대하여 동의를 한 다음 회사가 제시하는 양식과 절차에 따라 이용신청을 하고 회사가 승낙함으로써 체결됩니다. 
						(2) 이용자는 원칙적으로 회사가 서비스 이용 신청에 대한 승낙 후 이용 가능합니다. 다만, 회사는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다. 
						1. 허위의 정보를 기재하거나, 회사가 요청하는 내용을 정확히 기재하지 않은 경우
						2. 만 14세 미만의 신청자인 경우
						3. 명백하게 사회질서 및 미풍양속에 반할 상당한 이유가 있는 경우 
						4. 다른 사람의 이용을 방해하거나 그 정보를 도용하는 등 전자상거래질서를 위협하는 경우
						5. 계약해지를 당한 이후 1년이 경과하지 않은 경우 
						6.
						7.
						8.
						(3) 제 1항에 따른 신청에 있어 회사는 이용자의 종류에 따라 전문기관을 통한 실명확인 및 본인인증을 요청하거나 증빙자료를 요청할 수 있습니다.
						(4) 회사는 서비스 관련 내부 여유가 없거나 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다. 
						(5) 이용자는 서비스 이용을 위해 허위 사실이 없는 진실된 정보를 제공하여야 하며, 회사는 제공하는 서비스 상품에 따라 필요한 경우 이용자에게 추가 정보를 요청할 수 있습니다. 
						(6) 회사와 이용자가 서비스 이용에 관하여 별도 계약을 체결한 경우, 해당 별도 계약이 본 약관에 우선하여 적용됩니다. 
						(7) 회사는 이용자가 상당한 기타 귀책사유로 회원 자격을 박탈할 수 있으며 이 경우 회원에게 사전 통지하여 소명할 기회를 부여합니다. 

						제6조 [개인정보 수집]
						(1) 회사는 적법하고 공정한 수단에 의하여 이용계약의 성립 및 이행에 필요한 최소한의 개인정보를 수집합니다.
						(2) 회사는 개인정보의 수집 시 관련 법규에 따라 개인정보 처리방침에 그 수집 범위 및 목적을 사전고지합니다. 
						(3) 회사는 서비스 화면에서 회사가 수집한 개인정보의 수집, 이용 또는 제공에 대한 동의를 철회할 수 있도록 필요한 조치를 취합니다. 
						(4) 회사는 원칙적으로 사전 동의 없이 제3자에게 제공할 수 없습니다. 단, 다음 각호에 해당하는 경우에는 예외로 합니다.
						1. 전기통신기본법 등 관계법령에 의하여 국가기관의 요청에 의한 경우
						2. 범죄에 대한 수사상의 목적이 있거나 정보통신유리위원회의 요청이 있는 경우
						3. 통계작성, 홍보자료, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 고객임을 식별할 수 없는 형태로 제공되는 경우

						제7조 [회원에 통지] 
						회사는 회원에 대한 통지를 하는 경우 회원이 회사에 등록한 전자우편 주소로 할 수 있습니다.

						제8조 [쿠폰의 발행 및 사용]
						(1) 쿠폰이란 일정 금액 또는 비율을 회사 서비스 비용 결제 시 할인 받을 수 있는 온라인 또는 오프라인 쿠폰을 말하며, 회원은 해당 쿠폰을 회사의 승인이 후 사용할 수 있습니다. 회사 사정에 의하여 승인이 나지 않을 수 있습니다.
						(2) 쿠폰은 회사만 발행할 수 있으며, 쿠폰의 사용 범위 및 할인한도, 유효기간, 제한 사항 등은 쿠폰 또는 서비스 화면에 표시합니다. 
						(3) 쿠폰은 해당 유효기간 내 사용을 원칙으로 하며 재발행, 양도, 매매, 환불이 불가능합니다. 

						제9조 [게시물의 저작권과 관리]
						(1) 회원이 서비스 내에 게시한 게시물의 저작권은 해당 게시물의 저작자에게 귀솝됩니다.
						(2) 게시물 등은 검색결과 내지 “서비스” 및 관련 프로모션 등에 노출될 수 있으며, 해당 노출을 위해 필요한 범위 내에서는 일부 수정, 복제, 편집되어 게시될 수 있습니다. 이 경우 회사는 저작권법 규정을 준수하며, 이용자는 원하지 않으면 고객센터 또는 해당 게시물을 삭제할 수 있습니다.
						(3) 회원의 게시물을 회사가 이용하고자 하는 경우 전화, 팩스, 전자우편으로 사전에 회원의 동의를 얻습니다.
						(4) 회원의 게시물이 정보통신망법 및 저작권법 등 관련법에 위반되는 내용을 포함하는 경우, 관리자는 관련법이 정한 절차에 따라 해당 게시물의 게시중단 및 삭제 등을 요청할 수 있으며, 관리자의 요청이 없는 경우라도 가비아는 해당 게시물에 대해 임시조치 등을 취할 수 있습니다.

						제10조 [이용자의 아이디 및 비밀번호 관리에 대한 의무] 
						(1) 이용자의 아이디와 비밀번호에 관한 관리책임은 이용자에게 있으며 이를 제3자가 이용하도록 하여서는 안 됩니다. 이용자(관리자, 구성원 포함)의 고의 또는 관리소홀 등의 과실로 인하여 아이디 또는 비밀번호가 유출되어 발생한 제3자의 도용, 부정사용 등에 대해서 회사는 책임을 지지 않습니다.
						(2) 이용자는 아이디 및 비밀번호가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 회사에 통지하고 회사의 안내에 따라야 하며, 회사에 제3자 도용, 부정사용 사실을 통지하지 않거나 통지한 경우에도 회사의 안내에 따르지 않아 발생한 불이익에 대해서 회사는 책임을 지지 않습니다.
						(3) 회사는 이용자의 아이디가 개인정보 유출 우려가 있거나 반사회적 또는 미풍양속에 어긋나거나 회사 또는 회사의 운영자로 오인할 우려가 있는 경우 해당 아이디의 활용을 제한할 수 있습니다.

						제11조 [회사의 의무]
						(1) 회사는 본 약관이 정하는 바에 따라 지속적이고 안정적인 서비스를 제공하는데 최선을 다합니다.
						(2) 회사는 등록자의 정보를 포함한 개인신상정보에 대하여 관리적, 기술적 안전조치를 강구하여 정보보안에 최선을 다합니다.
						(3) 회사는 공정하고 건전한 운영을 통하여 전자상거래 질서유지에 최선을 다하고 지속적인 연구개발을 통하여 양질의 서비스를 제공함으로써 고객만족을 극대화하여 인터넷 사업 발전에 기여합니다.
						(4) 회사는 회원으로부터 제기되는 불편사항 및 문제에 대해 정당하다고 판단될 경우 우선적으로 그 문제를 즉시 처리합니다. 단, 신속한 처리가 곤란할 경우, 회원에게 그 사유와 처리일정을 즉시 통보합니다.
						(5) 회사는 소비자 보호단체 및 공공기관의 소비자 보호업무의 추진에 필요한 자료 등의 요구에 적극 협력합니다.

						제12조 [회원의 의무] 
						(1) 회원은 서비스 이용의 대가로 회사가 정한 서비스 이용요금을 지정된 일자를 준수하여 납입하여야 합니다.
						(2) 아이디와 비밀번호에 관한 모든 관리의 책임은 회원에게 있습니다.
						(3) 회원은 다음 행위를 하여서는 안 됩니다. 
						1. 서비스 이용 신청 또는 서비스 내용 변경 시 허위내용을 등록하는 행위
						2. 타인의 정보나 명의를 도용하거나 부정하게 사용하는 행위
						3. 다른 이용자의 개인정보를 그 동의 없이 수집, 저장, 공개하는 행위
						4. 회사가 게시한 정보를 변경하거나 제3자에게 제공하는 행위
						5. 제3자의 코드 ∙ 파일 ∙ 프로그램을 포함하고 있는 자료를 무단으로 게시하거나 메일로 발송하는 행위 기타 회사 또는 제3자의 지식재산권을 침해하는 행위
						6. 회사 또는 제3자의 명예를 손상시키거나 업무를 방해하는 행위
						7. 외설 ∙ 폭력적 ∙ 비방적인 내용 기타 풍기문란을 조장하거나 공서양속에 반하는 내용(어린이에게 유해하거나 제3자의 사생활을 침해하는 내용을 포함합니다) 또는 수신자의 동의 없이 발송하는 광고성 내용[정크메일(junk mail), 스팸메일(spam mail), 행운의 편지(chain letters), 피라미드 조직에 가입할 것을 권유하는 내용을 포함합니다]의 메시지, 팩스, 음성, 메일, 정보를 서비스에 공개 또는 게시하거나 제3자에게 전송하는 행위
						8. 회사의 동의 없이 본 약관의 목적 범위를 벗어나 영리를 목적으로 서비스를 이용하는 행위
						9. 컴퓨터 소프트웨어 ∙ 하드웨어 ∙ 전기통신 장비의 정상적인 가동을 방해 ∙ 파괴할 목적으로 고안된 소프트웨어 바이러스, 악성코드 등 관련 법령에 의하여 전송 또는 게시가 금지되는 정보를 이용 또는 전송하는 행위
						10. 기타 불법적이거나 부당한 행위
						(4) 회원은 본 약관 및 관계 법령에서 규정한 사항을 준수합니다.

						제13조 [서비스의 변경]
						(1) 회사는 상당한 이유가 있는 경우에 회사의 정책상, 운영상, 기술상의 필요에 따라 제공하고 있는 서비스의 전부 또는 일부를 변경할 수 있으며, 이에 대하여 관련법에 특별한 규정이 없는 한 이용자에게 별도 보상을 하지 않습니다.
						(2) 회사는 서비스의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는 변경사유, 변경될 서비스의 내용 및 제공 일자 등을 그 변경 전에 제 7조의 방법으로 이용자에게 통지합니다.

						제14조 [서비스 이용요금] 
						(1) 회사가 제공하는 서비스 이용과 관련하여 이용자가 납부하여야 할 서비스 이용요금 및 납부방법은 홈페이지에 게재한 바에 따릅니다.

						(2) 서비스 이용요금은 후불결제를 원칙으로 합니다. 단 회사는 이용자와 합의하에 별도의 결제를 할 수 있습니다.

						(3) 이용자가 서비스 이용 도중 서비스를 추가하는 경우, 결제 시점에 통합되어 계산됩니다. 

						(4) 서비스 이용요금의 과금 시작 기준일은 회사와 이용자 간에 이용계약이 체결되고, 이용자의 관리자가 관리자 ID 및 비밀번호로 회사 서비스에 로그인 할 수 있게 된 시점 (이하가 “개설된 시점일”이라 합니다)부터 과금을 시작합니다. 
						단, 이용계약에서 별도 과금 기준일을 정한 경우 해당 내용이 본 약관에 우선하여 적용됩니다.

						(5) 서비스 이용 도중 요금 과금 기준이 변경되더라도 이미 결제가 완료된 서비스 이용분에 대하여 증감된 서비스 이용요금을 추가로 청구하거나 환불하지 않습니다.

						(6) 회사는 판매 촉진, 이용자 유치 등의 목적을 위해 회사의 정책에 따라 가격 할인, 이용기간 연장 등 프로모션에 따른 혜택을 제공할 수 있습니다. 이용자의 서비스 신청 내용이 회사의 프로모션 제공 조건에 부합하지 않는 경우 회사는 프로모션에 따른 혜택을 제공하지 않을 수 있으며, 회사는 그에 대한 어떠한 책임도 부담하지 않습니다.

						(7) 회사는 서비스 이용 요금 결제가 정상적으로 이루어지지 않은 경우 이용자의 서비스 이용을 제한할 수 있으며, 서비스 이용 요금을 미납하는 등 이용자의 귀책사유로 회사가 서비스 이용을 제한한 경우 이용자에게 발생한 손해에 대해서는 회사는 면책됩니다.

						(8) 회사는 회사의 사정에 따라 결제 방식, 결제 수단을 추가, 삭제, 변경할 수 있으며, 사전에 그 사유와 적용일자를 공지합니다.

						(9) 서비스 이용요금에 대하여 회사와 이용자 간에 별도 약정이 있는 경우, 당해 약정 내용이 본 약관에 우선하여 적용됩니다.

						15조 [서비스 이용요금 반환] 

						(1) 회사는 서비스 이용요금 등의 과납 또는 오납이 있을 때에는 그 과납 또는 오납된 서비스 이용요금을 반환하고, 회사의 상당한 귀책사유로 과납 또는 오납이 발생한 경우에는 법정이율로서 적정이자를 함께 반환합니다. 

						(2) 서비스 이용기간 중 서비스 이용이 중도에 해지되는 경우, 회사는 서비스 이용기간 중 잔여기간에 대한 서비스 이용요금을 일할 계산하여 잔여금액을 환불합니다. 회사가 무상으로 제공한 서비스 이용기간은 잔여기간에 포함되지 않습니다.

						(3) 회사가 프로모션을 통해 서비스 이용요금을 할인한 경우 회사는 잔여금액에서 이용자가 할인 받은 금액을 공제하고 환불합니다. 전단의 경우, 잔여금액보다 이용자가 할인 받은 금액이 더 큰 경우 이용자는 초과된 금액(=할인금액 – 잔여금액)을 회사에 즉시 지급하여야 합니다.

						(4) 본 조에 따른 환불 시 회사는 송금비용, 전자결제대행 수수료 등의 사유로 환불 금액의 10%를 공제합니다. 단, 회사의 상당한 귀책사유로 환불의 경우에는 전단의 비용을 공제하지 않습니다. 

						(5) 본 조에 따른 환불금액을 중도 해지일을 기준으로 익월 말일까지 이용자 명의 계좌로 입금하거나, 결제대행업체에게 대금 청구를 정지하거나 결제승인을 취소하도록 요청하는 등 환불에 필요한 조치를 이행합니다. 단, 이용자가 회사에게 부담하는 금전지급채무가 있거나 기타 회사에 부담하는 채무 이행을 지연하고 있을 경우, 회사는 중도해지 요금 환불을 보류 또는 이용자의 채무 변제에 우선 충당할 수 있습니다.

						(6) 이용자가 신용카드 결제 후 결제를 취소하는 경우 환급 금액, 환급 방법과 환급일은 해당 신용카드 정책에 따르며, 회사는 결제 취소에 필요한 조치를 하는 것 외에 환급에 관하여 책임을 부담하지 않습니다. 단, 이용자의 결제 취소가 회사의 고의 또는 과실에 의한 경우에는 그러하지 아니합니다.

						제16조 [계약해지] 
						(1) 이용자는 이용계약을 해지 하고자 할 때 본인이 직접 서비스를 통하여 신청하거나 전자우편, 전화 등의 방법을 통하여 회사에 신청하여야 합니다.

						(2) 회사는 전1항의 규정에 의하여 해지신청이 접수되면 이용계약을 해지합니다. 단, 별도의 채권•채무관계가 있을 경우에는 그러하지 아니합니다.
						(3) 회사는 이용자가 다음 각 호에 해당할 경우에는 이용자의 동의 없이 이용계약을 해지할 수 있으며 그 사실을 이용자에게 통지합니다. 다만 회사가 긴급하게 해지할 필요가 있다고 인정하는 경우나 이용자의 귀책사유로 인하여 통지할 수 없는 경우에는 지체 없이 사후 통지로 대체할 수 있습니다.

						1. 이용자가 제12조 [회원의 의무]를 포함한 본 약관을 위반하고 일정 기간 이내에 위반 내용을 해소하지 않는 경우
						2. 회사의 서비스 제공목적 외의 용도로 서비스를 이용하거나 제3자에게 임의로 서비스를 임대한 경우
						3. 회사의 이용요금 등의 납입청구에 대하여 이용자가 이용요금을 30일 이상 체납할 경우

						제17조 [서비스 보호] 

						(1) 이용자는 회사 서비스를 무단 복제하거나 고의 또는 과실로 회사서비스에 관한 정보를 외부에 유출할 수 없습니다.
						(2) 이용자는 이용계약 도중 또는 종료 후라고 하더라도 회사 서비스의 솔루션의 제작/ 판매 행위를 할 수 없으며, 제 3자를 통한 우회적 제작 및 판매 행위도 이용자 본인의 행위로 간주합니다.
						(3) 이용자는 전 1항 또는 2항을 위반할 경우, 민형사상의 모든 법적 책임을 부담합니다.

						제18조 [데이터의 보존 및 파기] 
						(1) 이용자는 서비스 이용 중 데이터를 별도로 상시 백업, 저장하여야 하며, 회사가 이용자의 데이터를 백업하거나 이용자에게 제공할 의무는 없습니다. 이용자가 전단의 의무를 소홀히 하여 발생한 데이터의 유출, 누락이나 손실에 대해서 회사는 책임을 부담하지 않습니다.
						(2) 서비스 이용기간 만료, 서비스 이용계약의 해제 또는 해지 등의 사유로 회사가 서비스 제공을 종료한 경우 회사는 관련법 및 개인정보처리방침에 따라 보존하는 경우를 제외하고는 관리자, 구성원의 개인정보, 이용자 정보, 데이터 등 회사가 보관하는 모든 정보를 6개월 간 보존 후 파기합니다. 다만, 이름, 생년월일, 성별, 아이디, 연락처, 주소 등 서비스 이용에 혼란 및 부정사용을 방지하기 위하여 필요한 확인정보는 이용기간 만료 이후에도 개인정보 처리방침에 따라 일정기간 보관합니다.
						(3) 본 조 제2항에도 불구하고 이용자가 별도로 데이터 삭제를 요청한 경우에는 회사는 6조 4항의 1,2,3 호 에 해당하지 않는 한 해당 데이터를 삭제합니다.
						(4) 이용자의 서비스 이용요금 결제정보는 이력확인을 위해 이용자의 해지 유무와 상관없이 관계법령에 따라 일정기간 동안 별도로 보관할 수 있습니다.

						제19조 [기술지원] 
						(1) 회사가 제공하는 기술지원은 홈페이지에 게재한 바에 따릅니다.

						제20조 [면책] 
						(1) 회사는 다음 각 호의 경우로 서비스를 제공할 수 없는 경우로 인하여 이용자 또는 제3자에게 발생한 손해에 대해서는 책임을 부담하지 않습니다.

						1. 천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우
						2. 이용자의 시스템 환경이나 통신사 또는 호스팅 업체의 장애 등 회사의 관리 범위 밖의 사정으로 인한 경우
						3. 현재의 보안기술 수준으로는 방어가 곤란한 네트워크 해킹 등으로 인한 경우
						4. 서비스의 효율적인 제공을 위한 시스템 개선, 장비 증설 등 계획된 서비스 중지 일정을 사전에 공지한 경우
						5. 이용자의 귀책사유로 메일 또는 데이터가 유출되거나 서비스 이용에 장애가 있는 경우
						6. 회사의 고의 및 과실이 없는 경우

						(2) 회사는 이용자가 서비스를 통해 얻은 정보 또는 자료 등으로 인해 발생한 손해와 서비스를 이용하거나 이용할 것으로부터 발생하거나 기대하는 부가적, 영업적 손해에 대하여 책임을 면합니다.

						(3) 회사는 이용자가 게시 또는 전송한 데이터의 내용에 대해서는 책임을 면합니다.

						(4) 회사는 이용자 상호 간 또는 이용자와 제3자 상호 간에 서비스를 매개로 하여 거래 등을 한 경우 이에 대해 개입할 의무가 없으며, 이로 인한 손해를 배상할 책임도 없습니다.

						(5) 회사는 무료로 제공하는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.

						(6) 회사는 이용자가 서비스 이용 종료 후 제18조에 따라 파기된 데이터와 이용자가 직접 삭제를 요청한 데이터는 기타 서비스 이용 중인지 여부를 불문하고 백업의무를 소홀히 하여 발생한 데이터의 소실에 대해서는 책임을 지지 않습니다.

						(7) 회사는 서비스를 통하여 이용자에게 제공된 정보, 자료, 사실의 신뢰도, 정확성 등 그 내용에 대해서는 책임을 지지 않습니다. 

						*부칙*
						1. 본 약관은 2020년 xx월 xx일로부터 적용됩니다.
						2. 2020년 xx월 xx일로부터 시행되던 종전의 약관은 본 약관으로 대체합니다. 
						`}
					</Box>
			</Container>
		)
	}
}

export default withRouter(Terms);