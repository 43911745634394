import React, {Component} from 'react';

import {Button, FormHelperText, Grid, InputAdornment, TextField, Container} from '@material-ui/core';
import { withRouter} from 'react-router-dom';

import i18n from "../../config/lang/i18n";
import utils from "../../utils/Utils";
import APIUtils from "../../utils/APIUtils";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';


class FindPw extends Component {
  constructor() {
    super()

    this.state = {
      userId: '',
      name: '',
      email: '',
      userIdValid: true,
      nameValid: true,
      emailValid: true,
      isClicked: false,
      infoMessage: '',
      errorMessage: ''
    }

    this.userIdInput = React.createRef();
    this.nameInput = React.createRef();
    this.emailInput = React.createRef();

    this.setInputValue = this.setInputValue.bind(this);
    this.findPassword = this.findPassword.bind(this);
  }


  setInputValue(event) {
    const inputId = event.target.id;
    const inputValue = event.target.value;
    let changeStateValue = {[inputId]: inputValue};

    this.setState(changeStateValue, () => {

    });
  }

  findPassword() {
    this.setState({userIdValid: true, nameValid: true, emailValid: true})

    if (!utils.string.checkUserId(this.state.userId)) {
      this.setState({userIdValid: false})
      return;
    }
    if (this.state.name === '') {
      this.setState({nameValid: false})
      return;
    }
    if (!utils.string.checkEmail(this.state.email)) {
      this.setState({emailValid: false})
      return;
    }

    this.setState({isClicked: true})
    APIUtils.user.findPassword({body: {
        userId: this.state.userId,
        name: this.state.name,
        email: this.state.email
      }}, (data) => {
      if (data.status === 200) {
        this.setState({infoMessage: i18n.t('signup.passwordResetSendMsg', '비밀번호 초기화 절차가 메일로 발송되었습니다.')})
        this.setState({errorMessage: ''})
      } else {
        this.setState({errorMessage: data.message})
      }
      this.setState({isClicked: false});
    });
  }


  render() {
    const history = this.props.history;
    return (
      <>
        <div className="app-wrapper bg-white min-vh-100">
          <div className="app-main min-vh-100">
            <div className="app-content p-0 bg-maru-gray">
              <div className="app-content--inner d-flex align-items-center">
                <div className="flex-grow-1 w-100 d-flex align-items-center">
                  <div className="bg-composed-wrapper--content py-5">
                    <Container maxWidth='md'>
                      <Grid container spacing={10} className="maru-box-shadow">
                        <Grid  item md={5} className="d-flex flex-column justify-content-between align-items-center bg-maru-grady">
                          <div className="logo-style mt-3" />
                          <div className="w-100">
                            <div className="text-center pt-4 text-white">
                              <Button className="w-50 button-maru-reversed p-2 font-size-sm"
                                onClick={(e) => {
                                  history.push('/login');
                                  e.preventDefault();
                                }}>
                                {i18n.t('login.title', '로그인')}
                              </Button>
                            </div>
                          </div>
                        </Grid>
                        <Grid item md={7} className="d-flex align-items-center bg-white">
                          <div className="w-100 px-2">
                            <div className="text-black mt-3">
                              <span className="text-maru-color">
                                <h1 className="display-4 mb-5 font-weight-bold">
                                  {i18n.t("login.findPassword", "비밀번호 찾기")}
                                </h1>
                              </span>
                              <div>
                                <div className="mb-3">
                                  <label className="font-weight-bold mb-2">
                                    {i18n.t("login.id", "아이디")}
                                  </label>
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    id="userId"
                                    inputRef={this.userIdInput}
                                    onChange={this.setInputValue}
                                    value={this.state.userId}
                                    error={!this.state.userIdValid}
                                    className="input-maru-custom"
                                    size="small"
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end" className="mr-2 text-maru-color font-weight-lighter">
                                          <FontAwesomeIcon icon={['fas', 'portrait']} />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </div>
                                <div className="mb-4">
                                  <div className="d-flex justify-content-between">
                                    <label className="font-weight-bold mb-2">
                                      {i18n.t("login.name", "이름")}
                                    </label>
                                  </div>
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    id="name"
                                    inputRef={this.nameInput}
                                    onChange={this.setInputValue}
                                    value={this.state.name}
                                    error={!this.state.nameValid}
                                    className="input-maru-custom"
                                    size="small"
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end" className="mr-2 text-maru-color font-weight-lighter">
                                          <FontAwesomeIcon icon={['far', 'user-circle']} />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label className="font-weight-bold mb-2">
                                    {i18n.t("login.email", "이메일")}
                                  </label>
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    id="email"
                                    inputRef={this.emailInput}
                                    onChange={this.setInputValue}
                                    value={this.state.email}
                                    error={!this.state.emailValid}
                                    className="input-maru-custom"
                                    size="small"
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end" className="mr-2 text-maru-color font-weight-lighter">
                                          <FontAwesomeIcon icon={['fas', 'at']} />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="d-flex justify-content-end">
                                <div className="text-center pb-0 text-maru-color">
                                  <span className="nav-link" 
                                    onClick={(e) => {
                                      history.push('/findId');
                                      e.preventDefault();
                                    }}>
                                    {i18n.t(
                                      'login.findId',
                                      '아이디 찾기'
                                    )}
                                  </span>
                                </div>
                              </div>
                              <FormHelperText className="mb-2 text-center font-weight-bold">{this.state.infoMessage}</FormHelperText>
                              <FormHelperText className="mb-2 text-center font-weight-bold" error={true}>{this.state.errorMessage}</FormHelperText>
                              <div className="w-100 d-flex justify-content-center">
                                <Button
                                  // size=""
                                  // fullWidth
                                  className="w-75 button-maru-contained font-weight-bold font-size-sm"
                                  onClick={() => this.findPassword()}>
                                    <span hidden={!this.state.isClicked}>
                                      <FontAwesomeIcon icon={['fas', 'cog',]} spin/>...
                                    </span>
                                    <span hidden={this.state.isClicked}>
                                      {i18n.t('login.sendPasswordResetEmail', '비밀번호 변경 이메일 발송')}
                                    </span>
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(FindPw);