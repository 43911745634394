import React, {useState, useEffect} from 'react';
import {Tooltip, Button} from '@material-ui/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export default function TextCopyButton(props) {
  const {copyText, positionTop = 0} = props;
  const tooltipMessage = 'Copy';
  const [copyMessage, setCopyMessage] = useState(tooltipMessage);

  function textCopy(text) {
    const element = document.createElement('textarea');
    let responseMessage = 'Copied!';

    document.body.appendChild(element);

    element.value = text;
    element.select();
    element.focus();

    const returnValue = document.execCommand('copy');

    if (!returnValue) {
      responseMessage = 'Not Selected';
    }

    setCopyMessage(responseMessage);
    document.body.removeChild(element);
  }

  useEffect(() => {
    setTimeout(()=> {
      setCopyMessage(tooltipMessage)
    }, 1000)
  }, [copyMessage])

  return (
    <Tooltip placement="bottom" disableFocusListener title={copyMessage} arrow>
      <Button className="btn-white shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center" 
        onClick={(e)=>{
          textCopy(copyText)
        }} 
        style={{position:'absolute', right: '0.5rem', marginTop: positionTop}}>
        <FontAwesomeIcon icon={['fas', 'copy']} size="1x" color="gray" />
      </Button>
    </Tooltip>
  )
}
