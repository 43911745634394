import React, {Component} from 'react';
import {TextField} from '@material-ui/core';

export default class CTextField extends Component {
  constructor(props) {
    super(props);
    this.input = props.inputRef ? props.inputRef: React.createRef()
    this.callEventHandler = this.callEventHandler.bind(this)
    this.sendOnChangeEvent = this.sendOnChangeEvent.bind(this)
  }

  componentDidMount() {
    // console.log("componentDidMount: ", this.props.value)
    this.sendOnChangeEvent();
  }

  componentDidUpdate(prevProps) {
    // console.log("componentDidUpdate: ", this.props.value, prevProps.value)
    if (this.props.value !== prevProps.value) {
      this.sendOnChangeEvent();
    }
  }

  sendOnChangeEvent() {
    if (this.props && this.props.value && this.props.value.length > 0) {
      if (this.props.onChange) {
        this.props.onChange({
          target: {
            name: this.props.name,
            value: this.props.value,
            type: 'text',
            tagName: 'INPUT'
          }
        });
      }
    }
  }

  callEventHandler(event, eventName) {
    if (!this.props[eventName]) return
    this.props[eventName](event, event.target.value)
  }

  render() {

    const otherProps = {};
    [
      "id",
      "label",
      "className",
      "autoFocus",
      "variant",
      "style",
      "error",
      "disabled",
      "type",
      "name",
      "defaultValue",
      "tabIndex",
      "fullWidth",
      "rows",
      "rowsMax",
      "select",
      "required",
      "helperText",
      "unselectable",
      "margin",
      "SelectProps",
      "multiline",
      "size",
      "FormHelperTextProps",
      "placeholder",
      "InputProps",
      "inputProps"
    ].forEach(prop => (otherProps[prop] = this.props[prop]))

    return (
      <TextField
        inputRef={this.input}
        onChange={e => this.callEventHandler(e, "onChange")}
        onFocus={e => this.callEventHandler(e, "onFocus")}
        onBlur={e => this.callEventHandler(e, "onBlur")}
        onKeyPress={e => this.callEventHandler(e, "onKeyPress")}
        onKeyUp={e => this.callEventHandler(e, "onKeyUp")}
        onKeyDown={e => this.callEventHandler(e, "onKeyDown")}
        value={this.props.value}
        {...otherProps}
      />
    );
  }
}


