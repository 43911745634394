import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';

const Footer = (props) => {
  const { footerShadow, footerBgTransparent } = props;
  return (
    <>
      <div
        className={clsx('app-footer text-black-50', {
          'app-footer--shadow': footerShadow,
          'app-footer--opacity-bg': footerBgTransparent
        })}>
        <div className="app-footer--second">
          오픈나루(주) | 서울시 성동구 뚝섬로1길 31 906~907호(성수동1가, 서울숲 M타워) | 대표전화: 02-469-5426 | 팩스: 02-469-7247 | 고객지원: support@opennaru.coom, 070-4044-5426<br/>
          대표이사: 전준식 | 사업자등록번호: 206-86-83165 | 통신판매업 신고번호: 제2020-서울성동-02880호 | 개인정보관리 책임: privacy@opennaru.com | <a href='https://www.gov.openmaru.io/privacy-policy/'><b>개인정보처리방침</b></a><br/>
          Copyright © 2020 Opennaru, Inc. All Rights Reserved.
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  footerShadow: state.ThemeOptions.footerShadow,
  footerBgTransparent: state.ThemeOptions.footerBgTransparent
});

export default connect(mapStateToProps)(Footer);