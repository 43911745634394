import React, { Component, createRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, Button, TableRow, TableCell } from '@material-ui/core';
import i18n from '../../../../config/lang/i18n';
import MUITable from '../../../component/MUITable';
import TitBreadcrumbs from '../../../component/TitBreadcrumbs';
import APIUtils from '../../../../utils/APIUtils';
import notification from 'openmaru/component/Notification';
import MessageInputForm from '../../../component/MessageInputForm';
import { DateTime } from "luxon";
import { IconButton } from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import RemoveIcon from '@material-ui/icons/Remove';

const ExpandButton = ({
  areAllRowsExpanded,
  buttonClass,
  expandableRowsHeader,
  expandedRows,
  iconClass,
  iconIndeterminateClass,
  isHeaderCell,
  onExpand,
}) => {
  return (
    <>
      {isHeaderCell && !areAllRowsExpanded() && areAllRowsExpanded && expandedRows.data.length > 0 ? (
        <IconButton
          onClick={onExpand}
          style={{ padding: 0 }}
          disabled={expandableRowsHeader === false}
          className={buttonClass}>
          <RemoveIcon id="expandable-button" className={iconIndeterminateClass} />
        </IconButton>
      ) : (
        <IconButton
          onClick={onExpand}
          style={{ padding: 0 }}
          disabled={expandableRowsHeader === false}
          className={buttonClass}>
          <KeyboardArrowRightIcon id="expandable-button" className={iconClass} />
        </IconButton>
      )}
    </>
  );
};

class MessageSetting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      messageList: [],
      dataSet: [],
      inputOpen: false,
      targetMessage: {}
    }

    this.inputForm = createRef();
    this.getMessageList = this.getMessageList.bind(this);
  }

  componentDidMount() {
    this.getMessageList()
  }

  getMessageList() {
    this.setState({isLoading: true});
    APIUtils.superAdmin.getMessageSetting({}, (data) => {
      let dataList = [];
      let dataSet = {};
      if(data.status === 200) {
        dataSet = data.body.reduce((acc, message, index) => {
          if(!acc[message.key]) {
            acc[message.key] = { messages: [],index: index }
          }

          if(!message.lang) {
            dataList.push({
              key: message.key,
              lang: message.lang,
              value: message.value,
              createdId: message.createdId,
              created: message.created,
              updated: message.updated
            })
          } else {
            acc[message.key].messages.push(message)
          }
          
          return acc;
        }, {})
      } else {
        notification.error(data.message);
      }

      this.setState({
        messageList: dataList,
        dataSet: dataSet,
        isLoading: false
      })
    })
  }

  render () {
    const columns = [
      {
        label: '키', name: 'key', options: {
          display: false,
          setCellProps: (_ => ({style: {minWidth: "4vw", maxWidth: "4vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return (<div className="text-center">{value}</div>)
          }
        }
      },
      {
        label: i18n.t('messageSetting.language'), name: 'lang', options: {
          display: false,
          setCellProps: (_ => ({style: {minWidth: "1vw", maxWidth: "1vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div className="text-center">{value}</div>
          }
        }
      },
      {
        label: i18n.t('common.message'), name: 'value', options: {
          setCellProps: (_ => ({style: {minWidth: "8vw", maxWidth: "8vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div className="text-overflow-ellipsis">{value}</div>
          }
        }
      },
      {
        label: i18n.t('messageSetting.createdId'), name: 'createdId', options: {
          setCellProps: (_ => ({style: {minWidth: "2vw", maxWidth: "2vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div className="text-center">{value}</div>
          }
        }
      },
      {
        label: i18n.t('common.created'), name: 'created', options: {
          setCellProps: (_ => ({style: {minWidth: "3vw", maxWidth: "3vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div className="text-center">{DateTime.fromMillis(value).toFormat("yyyy'-'LL'-'dd HH:mm:ss")}</div>
          }
        }
      },
      {
        label: i18n.t('common.updated'), name: 'updated', options: {
          setCellProps: (_ => ({style: {minWidth: "3vw", maxWidth: "3vw"}})),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div className="text-center">{DateTime.fromMillis(value).toFormat("yyyy'-'LL'-'dd HH:mm:ss")}</div>
          }
        }
      },
      {
        label: i18n.t('common.command'), name: 'command', options: {
          setCellProps: (_ => ({style: {minWidth: "1vw", maxWidth: "1vw"}})),
          customBodyRenderLite: (dataIndex, rowIndex) => {
            return (<div className="d-flex justify-content-center">
              <Tooltip title={i18n.t('messageSetting.inputPopup', {action: i18n.t('messageSetting.update')})} arrow placement="top-start"
                        classes={{tooltip: "p-3"}}>
                <Button
                  className="btn-dark mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                  onClick={(event) => {
                    this.inputForm.current.handleClickOpen(this.state.messageList[dataIndex], this.getMessageList);
                    event.stopPropagation();
                  }}>
                  <FontAwesomeIcon icon={['fas', 'comment-medical']} className="font-size-sm"/>
                </Button>
              </Tooltip>
            </div>)
          }
        }
      }
    ];
  
    const options = {
      onTableInit: (e) => {
      },
      refresh: () => {
        this.getMessageList();
      },
      draggableColumns: {
        enabled: true
      },
      serverSide: false,
      rowsPerPage: 15,
      filter: false,
      responsive: 'simple',
      selectableRows: 'none',
      search: true,
      searchOpen: true,
      expandableRows: true,
      expandableRowsOnClick: true,
      isRowExpandable: (dataIndex, expandedRows) => {
        if(dataIndex) {
          const dataKey = this.state.messageList[dataIndex].key;
          if (this.state.dataSet[dataKey].messages.length) return true; 
        }
        return false;
      },
      renderExpandableRow: (rowData, rowMeta) => {
        const colSpan = rowData.length + 1;
        const key = rowData[0].props.children;
        const messages = this.state.dataSet[key].messages;

        return (
          <>
            <TableRow className="bg-gray">
              <TableCell colSpan={colSpan} className="ml-3">
                <div className="d-flex font-weight-bold font-size-sm">
                  <div className="text-center" style={{padding: '4px', flex: 1}}>{i18n.t("messageSetting.language")}</div>
                  <div className="text-center text-overflow-ellipsis" style={{padding: '4px', flex: 8}} >{i18n.t("common.message")}</div>
                  <div className="text-center" style={{padding: '4px', flex: 2}} >{i18n.t("messageSetting.createdId")}</div>
                  <div className="text-center" style={{padding: '4px', flex: 3, wordBreak: 'keep-all'}} >{i18n.t("common.created")}</div>
                  <div className="text-center" style={{padding: '4px', flex: 3}} >{i18n.t("common.updated")}</div>
                  <div className="text-center" style={{width: '125px'}}>{i18n.t("common.command")}</div>
                </div>
              </TableCell>
            </TableRow>
          {messages.length ? messages.map((message, index) => {
            return (<TableRow className="bg-gray" key={index}>
              <TableCell className="ml-3" colSpan={colSpan} >
                <div className="d-flex">
                  <div style={{padding: '4px', flex: 1, textAlign: 'center'}}>{(message.lang === 'ko')? i18n.t("messageSetting.ko"): i18n.t("messageSetting.en")}</div>
                  <div className="text-overflow-ellipsis" style={{padding: '4px', flex: 8}} >{message.value}</div>
                  <div style={{padding: '4px', flex: 2, textAlign: 'center'}} >{message.createdId}</div>
                  <div style={{padding: '4px', flex: 3, wordBreak: 'keep-all', textAlign: 'center'}} >{DateTime.fromMillis(message.created).toFormat("yyyy'-'LL'-'dd HH:mm:ss")}</div>
                  <div style={{padding: '4px', flex: 3, textAlign: 'center'}} >{DateTime.fromMillis(message.updated).toFormat("yyyy'-'LL'-'dd HH:mm:ss")}</div>
                  <div className="d-flex justify-content-center" style={{width: '125px'}}>
                    <Tooltip title={i18n.t('messageSetting.inputPopup', {action: i18n.t('messageSetting.update')})} arrow placement="top-start"
                        classes={{tooltip: "p-3"}}>
                      <Button
                        className="btn-dark mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                        onClick={(event) => {
                          this.inputForm.current.handleClickOpen(message, this.getMessageList);
                        }}>
                        <FontAwesomeIcon icon={['far', 'edit']} className="font-size-sm"/>
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </TableCell>
            </TableRow>);
          })          
          : <></>}
        </>);
      }
    };

    const components = {
      ExpandButton: (props) => {
        if(props.dataIndex) {
          const dataKey = this.state.messageList[props.dataIndex].key;
          if (this.state.dataSet[dataKey].messages.length) return <ExpandButton {...props} />; 
        }
        return <div style={{width:'24px'}} />;
      }
    };
  
    return (
      <>
        <TitBreadcrumbs title={i18n.t('messageSetting.title', '메시지 설정')} />
        <div className="app-page-title">
          <div>
            <h1><FontAwesomeIcon icon={['fas', 'comment-medical']} 
              className="icon"/> {i18n.t('messageSetting.title', '메시지 설정')}
            </h1>
          </div>
        </div>
        <MUITable columns={columns} data={this.state.messageList} options={options} loading={this.state.isLoading} components={components}/>
        <MessageInputForm messageInfo={this.state.targetMessage} ref={this.inputForm}></MessageInputForm>
      </>
    )
  }
}


export default MessageSetting;