import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {NavLink, withRouter} from 'react-router-dom';

import {Divider, FormControl, InputLabel, MenuItem, Select} from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import {makeStyles} from '@material-ui/core/styles';
import notification from '../component/Notification';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {setSidebarToggleMobile} from '../../reducers/ThemeOptions';
import {setUserProjects, setUserSelectedProject} from '../../reducers/OpenmaruReducer';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import queryString from 'query-string';
import APIUtils from '../../utils/APIUtils';
import utils from '../../utils/Utils';
import i18n from '../../config/lang/i18n';

const selectStyles = makeStyles(theme => ({
  dropdownStyle: {
    backgroundColor: '#2F2F2F',
    color: 'white',
  },
  listStyle: {
    color: 'white',
    fontWeight: 'normal',
    backgroundColor: '#2F2F2F',
  }
}));

const SidebarMenu = (props) => {
  const classes = selectStyles();
  const { setSidebarToggleMobile, history } = props;

  const toggleSidebarMobile = () => setSidebarToggleMobile(false);

  const { userProjects,  setUserProjects } = props;
  const { userSelectedProject = {pid: '', name: '', subDomain: ''}, setUserSelectedProject } = props;
  const [ thisUserSelectedProject, setThisUserSelectedProject] = useState({});

  const isSuperAdmin = utils.auth.hasSuperAdmin();
  const [hasNoProjects, setHasNoProjects] = useState(true);

  function getProjects() {
    if (utils.auth.hasSuperAdmin()) {
      return;
    }
    APIUtils.project.getProjects({}, (response)=> {
      let selectedProject = {}
      if( response.body !== undefined && response.body !== null && response.body.length > 0 ) {
        setUserProjects(response.body)
        setHasNoProjects(false);
        if(history.location.search) {
          const query = queryString.parse(history.location.search);

          for(const project of response.body) {
            if(project.pid === query.id) {
              selectedProject = project;
              break;
            }
          }
        }
      }

      if (utils.string.isBlank(selectedProject.pid)) {
        defaultSelected(response.body);
      } else {
        onChangeMenus(selectedProject, false);
      }
    });
  }

  function defaultSelected(projects) {
    let selectedProject = {};
    let foundInProject = false;

    if (utils.string.isEmpty(selectedProject.pid) && projects && projects.length > 0) {
      let lastSelectedPid = localStorage.getItem('lastSelectedPid');
      if (utils.string.isBlank(lastSelectedPid)) {
        selectedProject = projects[0];
      } else {
        for(const project of projects) {
          if( project.pid === lastSelectedPid ) {
            selectedProject = project;
            foundInProject = true;
            break;
          }
        }

        if( foundInProject === false ) {
          selectedProject = projects[0];
        }
      }
    }

    if( selectedProject && !utils.string.isEmpty(selectedProject.pid) ) {
      onChangeMenus(selectedProject, false);
    }
  }

  useEffect(_ => {
    if (Object.entries(thisUserSelectedProject).toString() !== Object.entries(userSelectedProject).toString()) {
      getProjects();
    }

    if (history.location.pathname === '/projectsDashboard') {
      // console.log('call /projectsDashboard');
      if (userProjects && userProjects.length > 0) {
        defaultSelected(userProjects);
      }
      return;
    }
    return () => {};
  }, [props.userSelectedProject])


  if (!userProjects || userProjects.length === 0) {
    getProjects();
  }

  if(isSuperAdmin === null) {
    notification.warn(i18n.t('common.permissionErrorMessage'))
    history.push('/login');
  }

  i18n.on('languageChanged', () => {
    if (userSelectedProject && utils.string.isNotBlank(userSelectedProject.pid)) {
      onChangeMenus(userSelectedProject, false);
    }
  })

  const onChangeMenus = (project, fromList) => {
    if(project && utils.string.isNotBlank(project.pid)) {
      if (userSelectedProject === undefined 
          || (userSelectedProject && userSelectedProject.pid !== project.pid)
          || (userSelectedProject.projectMember.userId !== utils.auth.getUserId())) {
        setThisUserSelectedProject(project);
        setUserSelectedProject(project);
        localStorage.setItem('lastSelectedPid', project.pid);
      }

      if(fromList) {
        const pathname = props.history.location.pathname;
        const userRole = project.projectMember.role;

        if ((pathname === '/projectsDashboard')
          || ((pathname === '/billManagement') && (userRole !== utils.BILL))
          || ((pathname === '/memberList') && (userRole === utils.VIEWER))) {
          history.replace(`/projectInformation?id=${project.pid}`);
        } else {
          history.replace(`${pathname}?id=${project.pid}`);
        }
      }
    }
  }

  return (
    <>
      <PerfectScrollbar>
        <div className='sidebar-navigation'>
          {(isSuperAdmin) ? (
            <ul>
              <li onClick={() => {toggleSidebarMobile();}}>
                <NavLink
                  className='nav-link-simple'
                  to='/admin/dashboard'>
                  <span className='sidebar-icon'>
                    <DashboardIcon />
                  </span>
                  {i18n.t('adminDashboard.title', '대시보드')}
                </NavLink>
              </li>
              <li onClick={() => {toggleSidebarMobile();}}>
                <NavLink
                  className='nav-link-simple'
                  to='/admin/projectList'>
                  <span className='sidebar-icon'>
                    <FontAwesomeIcon icon={['fas', 'project-diagram']}/>
                  </span>
                  {i18n.t('project.list', '프로젝트 리스트')}
                </NavLink>
              </li>
              <li onClick={() => {toggleSidebarMobile();}}>
                <NavLink
                  className='nav-link-simple'
                  to='/admin/userList'>
                  <span className='sidebar-icon'>
                      <FontAwesomeIcon icon={['fas', 'users']}/>
                  </span>
                  {i18n.t('userList.title', '사용자 관리')}
                </NavLink>
              </li>
              <li onClick={() => {toggleSidebarMobile();}}>
                <NavLink
                  className='nav-link-simple'
                  to='/admin/billManagement'>
                  <span className='sidebar-icon'>
                    <FontAwesomeIcon icon={['fas', 'receipt']}/>
                  </span>
                  {i18n.t('paymentsManagement.title', '결제 관리')}
                </NavLink>
              </li>
              <li onClick={() => {toggleSidebarMobile();}}>
                <NavLink
                  className='nav-link-simple'
                  to='/admin/billRangeManagement'>
                  <span className='sidebar-icon'>
                    <FontAwesomeIcon icon={['fas', 'receipt']}/>
                  </span>
                  {i18n.t('paymentsManagement.salesTitle', '결제 관리(계약)')}
                </NavLink>
              </li>
              <li onClick={() => {toggleSidebarMobile();}}>
                <NavLink
                  className='nav-link-simple'
                  to='/admin/qnaList'>
                  <span className='sidebar-icon'>
                    <FontAwesomeIcon icon={['fas', 'question-circle']} />
                  </span>
                  {i18n.t('qnaList.title', '문의하기')}
                </NavLink>
              </li>
              <li onClick={() => {toggleSidebarMobile();}}>
                <NavLink
                  className='nav-link-simple'
                  to='/admin/eventList'>
                  <span className='sidebar-icon'>
                    <FontAwesomeIcon icon={['fas', 'bell']} />
                  </span>
                  {i18n.t('event.title', '이벤트')}
                </NavLink>
              </li>
              <li onClick={() => {}}>
                <NavLink
                  className='nav-link-simple'
                  to='/admin/messageSetting'>
                  <span className='sidebar-icon'>
                    <FontAwesomeIcon icon={['fas', 'comment-medical']} />
                  </span>
                  {i18n.t('messageSetting.title', '메시지 설정')}
                </NavLink>
              </li>
              <li onClick={() => {toggleSidebarMobile();}}>
                <NavLink
                  className='nav-link-simple'
                  to='/admin/infoPage'>
                  <span className='sidebar-icon'>
                    <FontAwesomeIcon icon={['fas', 'info-circle']} />
                  </span>
                  {i18n.t('adminInfoPage.title', '관리자 정보')}
                </NavLink>
              </li>
            </ul>) : (<ul>
            <li onClick={() => {toggleSidebarMobile();}}>
              <NavLink
                className='nav-link-simple'
                to='/projectsDashboard'>
                <span className='sidebar-icon'>
                  <FontAwesomeIcon icon={['fas', 'tachometer-alt']} />
                </span>
                {i18n.t('project.dashboard', '프로젝트 대시보드')}
              </NavLink>
            </li>
            <Divider className='menu-divider'/>
            <li onClick={() => {toggleSidebarMobile();}}>
              <FormControl className='project-select-name' >
                <InputLabel shrink id='project-select-label' className='project-select-label'>
                  {i18n.t('project.title', '프로젝트')}
                </InputLabel>
                <Select
                  className='project-select-list'
                  labelId='project-select-label'
                  id='project-select-list'
                  value={userSelectedProject.pid}
                  MenuProps={{ classes: { paper: classes.dropdownStyle, list: classes.listStyle } }}
                  onChange={(event, child)=>{
                    if (utils.string.isNotBlank(event.target.value)) {
                      onChangeMenus(child.props.project, true);
                    }
                  }}
                >
                  <MenuItem className='project-menus-item text-gray text-sm-center border-bottom justify-content-center' value={' '} disabled>
                    <FontAwesomeIcon className='menus-label-icon' icon={['fas', 'project-diagram']} /> {i18n.t('project.select', '프로젝트 선택')}
                  </MenuItem>
                  {
                    userProjects.map(project => {
                      return (<MenuItem className='project-menus-item' projectrole={project.projectMember.role} value={project.pid} key={project.pid} project={project}>{utils.string.unescape(project.name)} ({project.subDomain})</MenuItem>)
                    })
                  }
                </Select>
              </FormControl>
            </li>
            <Divider className='menu-divider'/>
            <ul hidden={isSuperAdmin || hasNoProjects }>
              <li onClick={() => {toggleSidebarMobile();}}>
                <NavLink
                  className='nav-link-simple'
                  to={`/projectInformation?id=${userSelectedProject.pid}`}>
                  <span className='sidebar-icon'>
                      <FontAwesomeIcon icon={['fas', 'project-diagram']} />
                  </span>
                  {i18n.t('project.management', '프로젝트 관리')}
                </NavLink>
              </li>
              {((userSelectedProject.projectMember && userSelectedProject.projectMember.role !== utils.VIEWER)) 
              && (<li onClick={() => {toggleSidebarMobile();}}>
                <NavLink
                  className='nav-link-simple'
                  to={`/memberList?id=${userSelectedProject.pid}`}>
                  <span className='sidebar-icon'>
                    <FontAwesomeIcon icon={['fas', 'user-friends']} />
                  </span>
                  {i18n.t('memberList.title', '멤버 관리')}
                </NavLink>
              </li>)}
              {(userSelectedProject.projectMember && userSelectedProject.projectMember.role === utils.BILL) 
              && (<li onClick={() => {toggleSidebarMobile();}}>
                <NavLink
                  className='nav-link-simple'
                  to={`/billManagement?id=${userSelectedProject.pid}`}>
                  <span className='sidebar-icon'>
                    <FontAwesomeIcon icon={['fas', 'money-check']} />
                  </span>
                  {i18n.t('billManagement.title', '결제 내역')}
                </NavLink>
              </li>)}
              <li onClick={() => {toggleSidebarMobile();}}>
                <NavLink
                  className='nav-link-simple'
                  to={`/agentInstall?id=${userSelectedProject.pid}`}>
                  <span className='sidebar-icon'>
                    <FontAwesomeIcon icon={['fas', 'hands-helping']} />
                  </span>
                  {i18n.t('agent.title', '에이전트 설치 안내')}
                </NavLink>
              </li>
              {(userSelectedProject.projectMember && userSelectedProject.projectMember.role !== utils.VIEWER)
              && (<li onClick={() => {toggleSidebarMobile();}}>
                <NavLink
                  className='nav-link-simple'
                  to={`/systemUsage?id=${userSelectedProject.pid}`}>
                  <span className='sidebar-icon'>
                    <FontAwesomeIcon icon={['fas', 'cogs']} />
                  </span>
                  {i18n.t('systemUsage.title', '시스템 사용량')}
                </NavLink>
              </li>)}
              <li onClick={() => {toggleSidebarMobile();}}>
                <NavLink
                  className='nav-link-simple'
                  to={`/qnaList?id=${userSelectedProject.pid}`}>
                  <span className='sidebar-icon'>
                    <FontAwesomeIcon icon={['fas', 'question-circle']} />
                  </span>
                  {i18n.t('qnaList.title', '문의하기')}
                </NavLink>
              </li>
            </ul>
          </ul>)}
        </div>
      </PerfectScrollbar>
    </>
  );
};


const mapStateToProps = (state) => ({
  userProjects: state.OpenmaruReducer.userProjects,
  userSelectedProject: state.OpenmaruReducer.userSelectedProject,
  sidebarUserbox: state.ThemeOptions.sidebarUserbox,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
  setUserProjects: (projects) => dispatch(setUserProjects(projects)),
  setUserSelectedProject: (projects) => dispatch(setUserSelectedProject(projects))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SidebarMenu));
