import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Slide, toast} from "react-toastify";
import {Tooltip} from '@material-ui/core';
import '../../assets/toast.scss';
import i18n from "../../config/lang/i18n";

const globalOptions = {
  autoClose: 5000,
  className: 'toast',
  hideProgressBar: false,
  position: toast.POSITION.TOP_RIGHT,
  pauseOnHover: true,
  transition: Slide,  // Bounce, Slide, Zoom, Flip
};

const Msg = (message, options) => {
    const defaultOptions = {
      icon: 'exclamation-circle',
      title: 'Info'
    }

    const mergedOptions = Object.assign(defaultOptions, options);

    return (
      <Tooltip arrow title={message} placement="bottom-start">
        <div className='contents'>
          <div className='icon-area'>
            <FontAwesomeIcon icon={['fas', mergedOptions.icon]} className="icon"/>
          </div>
          <div className='message-area'>
            <div className='title'>
              {mergedOptions.title}
            </div>
            <div className='subtext'>
              {message}
            </div>
          </div>
        </div>
      </Tooltip>
    )
}

// Options Props
// options 적용 우선 순위 : userOptions > typeOptions > globalOptions
// ----- About message options
// title: 컨텐츠 제목
// icon: Fontawesome 아이콘 이름
// ----- About toastify options
// autoClose: 닫힘 대기 시간
// position: toast 열림 위치
// transition: open/close 애니메이션
// pauseOnHover: mouse over 시 정지
// delay: 등장 시간 지연
// hideProgressBar: 노출 시간 progressbar 숨기기
// onOpen: 창 열림 시 실행 함수
// onClose: 창 닫힘 시 실행 함수
// ...
const notification = {
    info(message, userOptions = {}) {
        // info 지정 돕션
        const typeOptions = {
          autoClose: 3000
        };
        
        const contentsOptions = {
          title: (userOptions.title)? userOptions.title: i18n.t('notification.info', 'Info'),
          icon: userOptions.icon? userOptions.icon: 'check',
        }

        delete userOptions.title;
        delete userOptions.icon;

        const toastOptions = Object.assign(globalOptions, typeOptions, userOptions);

        toast.info(Msg(message, contentsOptions), toastOptions)
    },
    warn(message, userOptions = {}) {
        // warn 지정 돕션
        const typeOptions = {
          autoClose: 4000
        }

        const contentsOptions = {
          title: userOptions.title? userOptions.title: i18n.t('notification.warn', 'Warn'),
          icon: userOptions.icon? userOptions.icon: 'exclamation-circle',
        }

        delete userOptions.title;
        delete userOptions.icon;

        const toastOptions = Object.assign(globalOptions, typeOptions, userOptions);

        toast.warn(Msg(message, contentsOptions), toastOptions)
    },
    error(message, userOptions = {}) {
        // error 지정 돕션
        const typeOptions = {
          autoClose: 5000
        }

        const contentsOptions = {
          title: userOptions.title? userOptions.title: i18n.t('notification.error', 'Error'),
          icon: userOptions.icon? userOptions.icon: 'exclamation-triangle',
        }

        delete userOptions.title;
        delete userOptions.icon;
        
        const toastOptions = Object.assign(globalOptions, typeOptions, userOptions);

        toast.error(Msg(message, contentsOptions), toastOptions)
    },
}

export default notification;
